import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "casinolust",
  casinoId: 70,
  GA_TRACKING: "G-BMCW4CRKFG",

  rivalChatGroupName: "Casino Lust",
  prettyName: "Casino Lust",
  contactEmail: "support@casinolust.net",
  docsEmail: "support@casinolust.net",

  //SEO
  metaDescription: "Play premier gaming at Online Casino Lust with slots (slot machine), live dealers, bingo, jackpot games. Real deposit bonuses, VIP loyalty program, secure play, trusted affiliates and fair gaming for cash wins and big money prizes!",
  logoName: "casino-lust-online-casino-logo.png",
  logoAltText: "Casino Lust official logo, presenting a sleek and modern design that embodies an exciting online casino with a great variety in slots, table games, and live casino experiences. Play safely, and enjoy quality online gambling at Casino Lust."
};


