<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.SUPPORT.TITLE' | translate }}</h1>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SUPPORT.CHAT.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.SUPPORT.CHAT.P1' | translate }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SUPPORT.EMAIL.TITLE' | translate | uppercase}}</h3>
  <p class="my-0 text-darker">{{ 'LEGAL.SUPPORT.EMAIL.P1' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail1}}"> {{ contactEmail1 }}</a></p>
  <p class="text-darker">{{ 'LEGAL.SUPPORT.EMAIL.P2' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail2}}"> {{ contactEmail2 }}</a></p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SUPPORT.PHONE.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.SUPPORT.PHONE.FR' | translate }}: (+33) (0)1 789 00368 </p>
  <p class="text-darker">{{ 'LEGAL.SUPPORT.PHONE.ES' | translate }}: (+34) 91 290 1420 </p>
  <p *ngIf="!contactEmail1.includes('pure')" class="text-darker">{{ 'LEGAL.SUPPORT.PHONE.INTL' | translate }}: (+1) 718 732 0154</p>
  <p *ngIf="contactEmail1.includes('pure')" class="text-darker">{{ 'LEGAL.SUPPORT.PHONE.INTL' | translate }}: (+1) 910 665 7465</p>

</section>
