<main>
  <header class="d-flex align-items-center justify-content-between py-4 px-2 px-md-4 position-fixed top-0 start-0 w-100 z-3 gap-4">
    <a routerLink=""><img class="header_logo" src="{{assetsSource}}/{{logoFileName}}" [alt]="logoAltText" title="Homepage"/></a>
    <div class="d-flex align-items-center gap-2 gap-lg-4">
      <button *ngIf="currentSlide !== 5" class="btn rounded-pill px-5 py-2 fw-bold text-uppercase go_to_calendar_button" id="go-to-calendar"
              (click)="goToCalendar()">{{ "XMAS.CALENDAR.BTN" | translate }}</button>
      <button class="btn p-0" (click)="toggleAudio()"><img src="assets/landingPages/xmas/sound.png" alt="sound button"></button>
    </div>
  </header>

  <div id="XmasCarousel" class="carousel slide vh-100" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#XmasCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#XmasCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#XmasCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#XmasCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
      <button type="button" data-bs-target="#XmasCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
      <button type="button" data-bs-target="#XmasCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
    </div>
    <div class="carousel-inner">
<!--     page 1 -->
      <div class="carousel-item active" data-bs-interval="10000">
        <div class="slide-container" id="page1" [ngStyle]="{'background-image': getBackGroundImage(1), 'background-position': getBackGroundPosition(1)}">
          <div class="flare-container after" [ngStyle]="{'background-image': getFlareImage(1, 'after', 1), 'background-position': getBackGroundPosition(1)}"></div>
          <div class="flare-container before" [ngStyle]="{'background-image': getFlareImage(1, 'before', 2), 'background-position': getBackGroundPosition(1)}"></div>

          <div class="carousel-caption">
            <div class="slide_content_text d-flex flex-column align-items-center justify-content-center gap-2 gap-md-4">
              <h2 class="fw-bold mb-0">{{ "XMAS.GET_READY" | translate }} <br/> {{ "XMAS.GET_READY2" | translate: {brandName: brandName} }}</h2>
              <h1 class="fw-bold mb-0">{{ "XMAS.AMOUNT" | translate }} {{ "XMAS.AMOUNT_TEXT" | translate }}</h1>
            </div>
            <div class="my-3 my-md-5">
              <a class="btn rounded-pill px-5 py-2 fw-bold claim_daily_bonus_button" routerLink="/instantplay">{{ "XMAS.CLAIM_BTN" | translate }}</a>
            </div>
          </div>
        </div>
      </div>

<!--      page 2 -->
      <div class="carousel-item">
        <div class="slide-container" id="page2" [ngStyle]="{'background-image': getBackGroundImage(2), 'background-position': getBackGroundPosition(2)}" >
          <div class="flare-container after" [ngStyle]="{'background-image': getFlareImage(2, 'after', 1), 'background-position': getBackGroundPosition(2)}"></div>
          <div class="flare-container before" [ngStyle]="{'background-image': getFlareImage(2, 'before', 2), 'background-position': getBackGroundPosition(2)}"></div>

          <div class="carousel-caption d-flex flex-column gap-4">
            <div class="slide_content_text d-flex flex-column align-items-center">
              <h2 class="fw-bold mb-0">{{ "XMAS.WIN" | translate }}</h2>
              <h1 class="fw-bold mb-0">{{ "XMAS.AMOUNT" | translate }}</h1>
              <h1 class="fw-bold"> {{ "XMAS.AMOUNT_TEXT" | translate }}</h1>
              <h2 class="fw-bold w-75">{{ "XMAS.CONDITIONS.1" | translate }}</h2>
              <h2 class="fw-bold w-75">{{ "XMAS.CONDITIONS.2" | translate }}</h2>
            </div>
            <div class="">
              <a class="btn rounded-pill py-2 fw-bold claim_daily_bonus_button" href="/instantplay">{{ "XMAS.CLAIM_BTN" | translate }}</a>
            </div>
          </div>
        </div>
      </div>

      <!--      page 3 -->
      <div class="carousel-item">
        <div class="slide-container" id="page3" [ngStyle]="{'background-image': getBackGroundImage(3), 'background-position': getBackGroundPosition(3)}">
          <div class="flare-container after" [ngStyle]="{'background-image': getFlareImage(3, 'after', 1), 'background-position': getBackGroundPosition(3)}"></div>
          <div class="flare-container before" [ngStyle]="{'background-image': getFlareImage(3, 'before', 2), 'background-position': getBackGroundPosition(3)}"></div>

          <div class="carousel-caption d-flex flex-column gap-4">
            <div class="slide_content_text">
              <h2 class="fw-bold mb-0">{{ "XMAS.WIN" | translate }}</h2>
              <h1 class="fw-bold mb-0">{{ "XMAS.AMOUNT" | translate }}</h1>
              <h1 class="fw-bold"> {{ "XMAS.AMOUNT_TEXT" | translate }}</h1>
              <h2 class="fw-bold mb-0">{{ "XMAS.CONDITIONS.3" | translate }}</h2>
              <h2 class="fw-bold mb-0">IPHONE, IPAD, IMAC,</h2>
              <h2 class="fw-bold">{{ "XMAS.CONDITIONS.3_2" | translate }}</h2>
            </div>
            <div class="">
              <a class="btn rounded-pill py-2 fw-bold claim_daily_bonus_button" href="/instantplay">{{ "XMAS.CLAIM_BTN" | translate }}</a>
            </div>
          </div>
        </div>
      </div>

      <!--      page 4 -->
      <div class="carousel-item">
        <div class="slide-container" id="page4" [ngStyle]="{'background-image': getBackGroundImage(4), 'background-position': getBackGroundPosition(4)}">
          <div class="flare-container after" [ngStyle]="{'background-image': getFlareImage(4, 'after', 1), 'background-position': getBackGroundPosition(4)}"></div>
          <div class="flare-container before" [ngStyle]="{'background-image': getFlareImage(4, 'before', 2), 'background-position': getBackGroundPosition(4)}"></div>

          <div class="carousel-caption d-flex flex-column gap-4">
            <div class="slide_content_text d-flex flex-column align-items-center">
              <h2 class="fw-bold w-75">{{ "XMAS.HOW_TO_1" | translate }}</h2>
              <h1 class="fw-bold w-50">{{ "XMAS.HOW_TO_2" | translate }}</h1>
            </div>
            <div class="">
              <a class="btn rounded-pill py-2 fw-bold claim_daily_bonus_button" href="/instantplay">{{ "XMAS.CLAIM_BTN" | translate }}</a>
            </div>
          </div>
        </div>
      </div>

      <!--      page 5 -->
      <div class="carousel-item">
        <div class="slide-container" id="page5" [ngStyle]="{'background-image': getBackGroundImage(5), 'background-position': getBackGroundPosition(5)}">
          <div class="flare-container after" [ngStyle]="{'background-image': getFlareImage(5, 'after', 1), 'background-position': getBackGroundPosition(5)}"></div>
          <div class="flare-container before" [ngStyle]="{'background-image': getFlareImage(5, 'before', 2), 'background-position': getBackGroundPosition(5)}"></div>

          <div class="carousel-caption d-flex flex-column gap-4">
            <div class="slide_content_text d-flex flex-column align-items-center">
              <h2 class="fw-bold mb-0 mb-lg-2">{{ "XMAS.EXTRA.WAIT" | translate }}</h2>
              <h2 class="fw-bold">{{ "XMAS.EXTRA.WAIT2" | translate }}</h2>
              <h2 class="fw-bold w-75">{{ "XMAS.EXTRA.OFFER" | translate }}</h2>
              <h1 class="fw-bold">{{ "XMAS.EXTRA.AMOUNT" | translate }}</h1>
              <h1 class="fw-bold"> {{ "XMAS.EXTRA.AMOUNT_TEXT" | translate }}</h1>
            </div>
            <div class="">
              <a class="btn rounded-pill py-2 fw-bold claim_daily_bonus_button" href="/instantplay">{{ "XMAS.CLAIM_BTN" | translate }}</a>
            </div>
          </div>
        </div>
      </div>

      <!--      page 6 calendar -->
      <div class="carousel-item" data-bs-interval="10000">
        <div class="slide-container" id="page6" [ngStyle]="{'background-image': getBackGroundImage(6), 'background-position': getBackGroundPosition(6)}">
          <div class="flare-container after" [ngStyle]="{'background-image': getFlareImage(6, 'after', 1), 'background-position': getBackGroundPosition(6)}"></div>
          <div class="flare-container before" [ngStyle]="{'background-image': getFlareImage(6, 'before', 2), 'background-position': getBackGroundPosition(6)}"></div>

          <div class="carousel-caption d-none d-sm-flex flex-column justify-content-between">
            <div class="slide_content_text d-flex flex-column align-items-center">
              <h2 class="fw-bold mb-0 mb-lg-2">{{ "XMAS.CALENDAR.TITLE" | translate }}</h2>
            </div>
            <div class="calendar-container d-flex justify-content-center">
              <table class="table">
                <tbody class="d-flex flex-column justify-content-center align-items-center gap-1 gap-lg-2">
                <tr *ngFor="let week of calendar" class="d-inline-flex justify-content-center align-items-center gap-1 gap-lg-2">
                  <td *ngFor="let day of week" [ngClass]="{ 'empty': !day, 'open': (day && thisMonth === 11 && day && day <= today) }" id="{{ day ? ('day'+day) : ''}}" class="d-flex flex-column justify-content-center align-items-center p-0">
                    <div *ngIf="day" class="day-content">
                      <span>{{ day }}</span>
                    </div>
                    <div *ngIf="day && winners && thisMonth === 11 && day <= today" class="day-winner position-absolute w-100">

                      <div *ngIf="getCurrentPlayer(day-1)" [ngClass]="{'rotate': winners.days[day-1].players.length > 1}">
                        <span class="d-block text-capitalize">{{ getCurrentPlayer(day-1)?.name }}</span>
                        <span class="d-block text-lowercase fw-bold"> {{ "XMAS.CALENDAR.WON" | translate }} </span>
                        <span class="d-block text-capitalize fw-bold">{{ getCurrentPlayer(day-1)?.prizetitle }}</span>
                      </div>

                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <a class="btn rounded-pill py-2 fw-bold claim_daily_bonus_button" href="/instantplay">{{ "XMAS.CLAIM_BTN" | translate }}</a>
            </div>
          </div>
          <div class="rotate-phone d-sm-none">
            <div>{{ "XMAS.CALENDAR.ROTATE" | translate }}</div>
          </div>
        </div>
      </div>


    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#XmasCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#XmasCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</main>

<audio id="bgMusic" autoplay loop>
  <source src="assets/landingPages/xmas/calendar/sound.mp3" type="audio/mp3">
  Your browser does not support the audio element.
</audio>


