<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.SECURITY.TITLE' | translate }}</h1>

  <p class="text-darker">{{ 'LEGAL.SECURITY.INTRO' | translate: {brandName}  }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.INTRO_2' | translate: {brandName}  }}</p>


  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SECURITY.RIVAL.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.SECURITY.RIVAL.P1' | translate  }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.RIVAL.P2' | translate  }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.RIVAL.P3' | translate  }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.RIVAL.P4' | translate  }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.RIVAL.P5' | translate  }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SECURITY.BANK.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.SECURITY.BANK.P1' | translate: {brandName} }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.BANK.P2' | translate: {brandName} }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.BANK.P3' | translate  }}</p>


  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SECURITY.FAIRNESS.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.SECURITY.FAIRNESS.P1' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.SECURITY.FAIRNESS.P2' | translate: {brandName} }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.SECURITY.ECOGRA.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.SECURITY.ECOGRA.P1' | translate  }}</p>

  <p class="text-darker fw-bold">{{ 'LEGAL.SECURITY.CONCLUSION' | translate  }}</p>

</section>

