import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {catchError, map, Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageOptions = [
    {value: "en", label: "English", height: 15},
    {value: "de", label: "Deutsch", height: 12},
    {value: "fr", label: "Français", height: 12.5},
    {value: "es", label: "Español", height: 15},
    {value: "it", label: "Italiano", height: 15},
    {value: "pt", label: "Português", height: 13}
  ]

  selectedLang = 'bla';

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }


  initializeLanguage(): void {
    this.translate.setDefaultLang('en');

    /**
     set language at the initialization by going throught these options
     - from localStorage: selected by user
     - from browser
     - from cloudflare
     - default: english
     */

    const savedLang = localStorage.getItem('lang');
    if(savedLang){
      this.selectedLang = savedLang;
      this.translate.use(savedLang);
    } else {
      if (navigator.language) {
        const lang = navigator.language.slice(0,2)
        this.checkAndSetSelectedLanguage(lang)
      } else {
        // no browser language available
        this.setLangFromCloudflare();
      }
    }
  }

  checkAndSetSelectedLanguage(lang:string): void {
    const isLanguageSupported = this.languageOptions.some(option => option.value === lang);
    if (isLanguageSupported){
      this.selectedLang = lang;
    } else {
      this.selectedLang = "en";
      // console.log(`${lang} is not supported. Defaulting to 'en'.`);
    }
    localStorage.setItem('lang', this.selectedLang);
    this.translate.use(this.selectedLang);
  }

  setLangFromCloudflare() {
    this.getUserLocationFromCloudflare().subscribe({
      next: (location: string)=> {
        const loc = location.toLowerCase()
        this.checkAndSetSelectedLanguage(loc)
      },
      error: err => {
        // console.error('Error fetching user location:', err);
        this.checkAndSetSelectedLanguage('en')
      }
    })
  }


  getUserLocationFromCloudflare(): Observable<string> {
    return this.http.get('https://www.cloudflare.com/cdn-cgi/trace', { responseType: 'text' }).pipe(
      map((response: string)=> {
        let data = response.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
        data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
        const jsonData = JSON.parse(data);
        return jsonData.loc;
      }),
      catchError(error => {
        // console.error('Error:', error);
        return of('else'); // Return a fallback value in case of error
      })
    )
  }

  setUserSelectedLang(language: string) {
    this.selectedLang = language;
    localStorage.setItem('lang', language ?? 'en');
    this.translate.use(language);

    if (language === 'en') {
      //as translate.currentLang does not changes to english if we have different lang saved in localstorage: we have to set it manually:
      this.translate.reloadLang('en');
    }

  }





}


