<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.AML.TITLE2' | translate }}</h1>

  <p class="text-darker">{{ 'LEGAL.AML.P1' | translate: {brandName}  }}</p>

  <ul>
    <li class="mb-2 text-darker">{{ 'LEGAL.AML.LI1' | translate }} </li>
    <li class="mb-2 text-darker">{{ 'LEGAL.AML.LI2' | translate }} </li>
  </ul>

  <p class="text-darker">{{ 'LEGAL.AML.P2' | translate: {brandName} }}</p>

  <ul>
    <li class="text-darker">{{ 'LEGAL.AML.LI3' | translate }}</li>
    <li class="text-darker">{{ 'LEGAL.AML.LI4' | translate }}
      <ul>
        <li class="my-2">{{ 'LEGAL.AML.LI4_A' | translate }}</li>
        <li class="mb-2">{{ 'LEGAL.AML.LI4_B' | translate }}</li>
      </ul>
    </li>
    <li class="text-darker">{{ 'LEGAL.AML.LI5' | translate }}</li>
  </ul>

  <p class="text-darker">{{ 'LEGAL.AML.P3' | translate:{brandName} }}</p>

</section>

