import { Component } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TitleService} from "../../services/title.service";
import {ChatService} from "../../services/chat.service";
import {TawkService} from "../../services/tawk.service";
import {TrackingHelperService} from "../../services/tracking-helper.service";
import {Router} from "@angular/router";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.scss']
})
export class LegacyComponent {

  url: SafeResourceUrl = ""
  lang:string;

  chatSystem: string;


  constructor(
    private sanitizer:DomSanitizer,
    private configs: ConfigService,
    private titleService: TitleService,
    private chatService: ChatService,
    private tawkService: TawkService,
    private trackingService: TrackingHelperService,
    private router: Router
  ) {
    this.chatSystem = this.configs.chatSystem;
    if (!this.configs.isRivalCasino) {
      //on not rival brands: do not open the lobby iframe but navigate away like if it was unexisting page
      //todo: make it with a guard instead
      this.router.navigate(['not-found'])
    }

    this.lang = localStorage.getItem('lang') ?? 'en';

    this.titleService.setTitle("Lobby / Legacy");

    let returnUrl = window.location.href.split("/legacy")[0];
    let urlWithParams = `https://www.casinocontroller.com/${this.configs.name}/engine/EmbedGame/EmbedGame.php?mode=lobby&lang=${this.lang}&returnUrl=${returnUrl}`;

    //add btag and tracker to url
    let bTag = this.trackingService.getBannerTag();
    if (bTag) {
      urlWithParams += `&btag=${bTag}`
    }
    let t = this.trackingService.getTracker();
    if (t) {
      urlWithParams += `&t=${t}&tracker=${t}`
    }

    // let urlWithParams = `https://www.casinocontroller.com/${this.rivalName}/engine/EmbedGame/EmbedGame.php?mode=lobby`;

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);
  }

  ngOnInit(){
    //hide chat button from the beginning
    if (this.chatSystem === 'tawk'){
      this.tawkService.SetChatVisibility(false);
    } else {
      //rival or other chat systems
      this.chatService.toggleChatBtnShown(false);
    }
  }

  ngOnDestroy(): void {
    //show chat button
    if (this.chatSystem === 'tawk'){
      this.tawkService.SetChatVisibility(true);
    } else {
      //rival or other chat systems
      this.chatService.toggleChatBtnShown(true);
    }

  }


}
