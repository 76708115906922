<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.RESPONSIBLE.TITLE2' | translate }}</h1>

  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.INTRO' | translate: {brandName}  }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.INTRO_2' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.INTRO_3' | translate: {brandName}  }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.RESPONSIBLE.MINORS.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.MINORS.P1' | translate: {brandName} }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.MINORS.P2' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.MINORS.P3' | translate }}</p>
  <ul class="text-darker">
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.MINORS.LI1' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.MINORS.LI2' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.MINORS.LI3' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.MINORS.LI4' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.MINORS.LI5' | translate }} </li>
  </ul>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.MINORS.P4' | translate: {brandName} }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a></p>


  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.P1' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.P2' | translate }}</p>
  <ul class="text-darker">
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI1' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI2' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI3' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI4' | translate }} </li>
  </ul>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.P3' | translate }}</p>
  <ul class="text-darker">
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI5' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI6' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI7' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI8' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI9' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.LI10' | translate }} </li>
  </ul>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.COMPULSIVE.P4' | translate }}</p>


  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.RESPONSIBLE.HELP.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.HELP.P1' | translate }}</p>
  <ul class="text-darker">
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI1' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI2' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI3' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI4' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI5' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI6' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI7' | translate }} </li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI8' | translate }} </li>
  </ul>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.HELP.P2' | translate }}</p>
  <ul class="text-darker">
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI9' | translate }}:
      <a class="link-primary link-underline-opacity-0" [href]="linkUrl" target="_blank"> {{ linkUrl }}</a></li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI10' | translate }}:
      <a class="link-primary link-underline-opacity-0" href="https://www.gamblingtherapy.org/{{currentLang === 'pt' ? 'pt-pt' : currentLang}}/" target="_blank"> https://www.gamblingtherapy.org/{{currentLang === 'pt' ? 'pt-pt' : currentLang}}/</a></li>
    <li class="mb-2">{{ 'LEGAL.RESPONSIBLE.HELP.LI11' | translate }}:
      <a class="link-primary link-underline-opacity-0" href="https://www.gx4.com/" target="_blank">https://www.gx4.com/</a></li>
  </ul>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.RESPONSIBLE.SELFLIMIT.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.SELFLIMIT.P1' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.SELFLIMIT.P2' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.SELFLIMIT.P3' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a> {{ 'LEGAL.RESPONSIBLE.SELFLIMIT.P3_LINK_END' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.SELFLIMIT.P4' | translate }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.RESPONSIBLE.SUPPORT.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.RESPONSIBLE.SUPPORT.P1' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a></p>


</section>

