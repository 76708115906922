import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GameModel} from "../../models/game.model";
import {GameService} from "../../services/game.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CarouselModel} from "../../models/carousel.model";
import {ProviderModel} from "../../models/provider.model";

declare var bootstrap: any;


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnChanges {

  @Input() dataList!: (ProviderModel | GameModel)[];
  contentType?: string;
  @Input() identifier!: string;

  svgPaths: { [key: string]: string };
  title!: string;
  routerLink?: string;
  translateParam: string = "";

  currentSlideIndex: number = 0;

  numOfSlides: number = 3;
  public carousels: CarouselModel[] = [
    {
      size: 'Xs',
      displayClass: 'd-block d-sm-none',
      itemsPerSlide: 3,
      sliceStart: (idx: number) => idx * 3,
      sliceEnd: (idx: number) => (idx + 1) * 3,
    },
    {
      size: 'Sm',
      displayClass: 'd-none d-sm-block d-md-none',
      itemsPerSlide: 4,
      sliceStart: (idx: number) => idx * 4,
      sliceEnd: (idx: number) => (idx + 1) * 4
    },
    {
      size: 'Md',
      displayClass: 'd-none d-md-block d-lg-none',
      itemsPerSlide: 5,
      sliceStart: (idx: number) =>  idx * 4,
      sliceEnd: (idx: number) => (idx + 1) * 4 + 1
    },
    {
      size: 'Lg',
      displayClass: 'd-none d-lg-block',
      itemsPerSlide: 6,
      sliceStart: (idx: number) => idx * 5,
      sliceEnd: (idx: number) => (idx+1) * 5+1
    }
  ];

  constructor(
    private gameService: GameService,
    private sanitizer: DomSanitizer
  ) {
    this.svgPaths = this.gameService.svgPaths;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataList']) {
      if (this.dataList && this.dataList.length > 0) {
        if ('publisher' in this.dataList[0]) {
          this.contentType = 'game'
          switch (this.identifier) {
            case 'all':
              this.routerLink = ""
              this.title = "SELECTED_GAME.LINK_ALL"
              this.translateParam = "";
              break;
            case 'provider':
              let provider = this.gameService.providers.find(p => (<GameModel>this.dataList[0]).publisher.toLowerCase().includes(p.name.toLowerCase()))

              this.routerLink = `/providers/${provider ? provider.routerLink : this.dataList[0].publisher.toLowerCase()}`
              this.title = 'SELECTED_GAME.LINK_PROV'
              this.translateParam = `${provider ? provider.name : this.dataList[0].publisher}`

              if (provider){
                  this.routerLink = `/providers/${provider.routerLink}`
                  this.title = 'SELECTED_GAME.LINK_PROV'
                  this.translateParam = provider.name
              }
              break;
            case 'live':
                this.routerLink = "/games/live"
                this.title = 'CATEGORIES.LIVE'
              break;
            case 'recent':
              this.routerLink = ""
              this.title = "CATEGORIES.RECENT"
              break;
            case 'recommended':
              this.routerLink = ""
              this.title = "CATEGORIES.RECOMMENDED"
              break;
            default:
              let category = this.gameService.getCategoryToNavigate(this.identifier)
              this.routerLink = `/games/${category}`
              this.title = 'CATEGORIES.' + category.toUpperCase();
              this.translateParam = ""
              break;
          }
        } else {
          this.contentType = 'provider'
          this.routerLink = "/providers"
          this.title = "PROVIDERS.TITLE"
          this.translateParam = ""
        }
      }

      this.currentSlideIndex = 0;


      this.carousels.forEach(carousel=>{
        carousel.slides = [];
        for (let i = 0; i < this.numOfSlides; i ++) {
          let slide =  this.dataList.slice(carousel.sliceStart(i), carousel.sliceEnd(i))
          if (slide.length) {
            //add placeholder if needed
            if (slide.length < carousel.itemsPerSlide){
              let placeholders = Array(carousel.itemsPerSlide - slide.length).fill({});
              slide = [...slide, ...placeholders];
            }
            carousel.slides.push(slide)
          }
        }
      })

    }
  }


  moveSlide(direction: 'prev' | 'next', size: string, identifier: string) {
    let carouselId = `carousel${size}${identifier.toUpperCase()}`;
    let myCarousel = document.getElementById(carouselId);

    if (myCarousel) {
      const bsCarousel = new bootstrap.Carousel(myCarousel);
      this.currentSlideIndex = direction === 'prev' ? this.currentSlideIndex - 1 : this.currentSlideIndex + 1;

      direction === 'next' ? bsCarousel.next() : bsCarousel.prev();
    }
  }


  getSanitizedSvgPath(identifier: string): SafeHtml {
    if (identifier === 'providerList'){
      return this.sanitizer.bypassSecurityTrustHtml(this.svgPaths['provider'])
    }
    return this.sanitizer.bypassSecurityTrustHtml(this.svgPaths[identifier.toLowerCase()]);
  }


  //use placeholder when the image is not found
  updateNotFoundImgUrl(event: Event) {
    (event.target as HTMLImageElement).src = `https://placehold.co/512x512/3b3b3b/F0F0F0/png?text=${(event.target as HTMLImageElement).alt}`;
  }
}


