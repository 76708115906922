import { Component } from '@angular/core';
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent {
  contactEmail:string;

  legalSectionIndexes: any[] = [];
  legalSubs?: Subscription;

  constructor(
    private configs: ConfigService,
    private translate: TranslateService
  ) {
    this.contactEmail = this.configs.contactEmail;
    // get the content dynamically (!special elements, ex link at the last section TODO style in html by index)
    this.translate.get('LEGAL.COOKIE.SECTIONS').subscribe(data => {
      if (Object.keys(data).length) {
        this.legalSectionIndexes = Array.from(Object.keys(data)).map(key => ({
          index: key,
          contentCount: Object.keys(data[key]).filter(k => k.startsWith('CONTENT_')).length
        }));
      }
      // console.log(this.legalSectionIndexes)
    });
  }

  ngOnDestroy(){
    this.legalSubs?.unsubscribe();
  }



}
