import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {CafComponent} from "./caf/caf.component";
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-banking',
  templateUrl: './banking.component.html',
  styleUrls: ['./banking.component.scss']
})
export class BankingComponent {
  brandName: string;
  contactEmail:string;

  @ViewChild('hiddenContainer', {read: ViewContainerRef}) container!: ViewContainerRef;

  constructor(
    private configs: ConfigService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef
  ) {
    this.brandName = this.configs.brandName;
    this.contactEmail = this.configs.contactEmail;
  }


  downloadFile() {
    let currentLanguage = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;

    const componentRef = this.viewContainerRef.createComponent(CafComponent);
    this.container.insert(componentRef.hostView);

    // Make the component invisible by moving it off-screen
    componentRef.location.nativeElement.style.position = 'absolute';
    componentRef.location.nativeElement.style.left = '-5000px';

    // Set the width and height of the component
    componentRef.location.nativeElement.style.width = '800px';
    componentRef.location.nativeElement.style.height = '2000px';

    this.cdr.detectChanges();

    html2canvas(componentRef.location.nativeElement).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF();

      let scale = Math.min(pdf.internal.pageSize.getWidth() / canvas.width, 1);
      let imgWidth = canvas.width * scale;
      let imgHeight = canvas.height * scale;

      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);

      pdf.save(`caf_${currentLanguage}.pdf`);
    });
  }

}
