<header>
    <nav class="navbar shadow fixed-top bg-main">
      <div class="container-fluid d-block d-sm-flex align-items-sm-center">
         <div *ngIf="!isRival" class="d-flex justify-content-between justify-content-sm-start flex-nowrap flex-grow-1" [ngClass]="{'logo-and-nav': sidebarToggled}">
           <button class="navbar-toggler" type="button" (click)="toggleSidebar()" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
           <a class="navbar-brand p-0 m-0 ms-1" routerLink=""><img src="{{assetsSource}}/{{logoFileName}}" [alt]="logoAltText" style="max-width: 150px;"></a>
         </div>
        <div *ngIf="isRival" class="d-flex justify-content-between justify-content-sm-start flex-nowrap flex-grow-1" [ngClass]="{'logo-and-nav': sidebarToggled}">
          <button class="navbar-toggler" type="button" (click)="toggleSidebar()" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
          <a id="rivalHeaderNavAndLogoSection" class="navbar-brand p-0 m-0 ms-1" routerLink=""><img src="{{assetsSource}}/{{logoFileName}}" [alt]="logoAltText"></a>
        </div>

         <div *ngIf="!user" class="d-flex justify-content-center justify-content-sm-end flex-grow-1 flex-sm-grow-0 mt-2 mt-sm-0 row gx-2">
           <div class="col">
             <button class="btn btn-outline-primary w-100" type="button" (click)="toggleSignInModal()" >{{'LOGIN.BTN'| translate}}</button>
           </div>
           <div class="col">
             <button class="btn btn-primary w-100" type="button" (click)="toggleSignUpModal()">{{'REGISTER.BTN'| translate}}</button>
           </div>
         </div>

        <div *ngIf="user" class="d-none d-sm-flex justify-content-center justify-content-sm-end flex-grow-1 flex-sm-grow-0 mt-2 mt-sm-0 row gx-2">
          <div class="col">
            <button class="btn btn-outline-primary w-100" disabled><span class="fw-bold">{{user.balance | currency: user.currency }}</span></button>
          </div>
          <div class="col">
            <button class="btn btn-primary w-100" type="button" (click)="goToCashier()">{{ "ACCOUNT.BTN_TO_CASHIER" | translate }}</button>
          </div>
<!--          <div class="btn-group">-->
<!--            <button id="balanceInBtn" class="btn btn-outline-primary" disabled><span class="fw-bold">{{user.balance | currency: user.currency }}</span></button>-->
<!--            <button class="btn btn-primary" type="button" (click)="goToCashier()">{{ "ACCOUNT.BTN_TO_CASHIER" | translate }}</button>-->
<!--          </div>-->
        </div>

      </div>
    </nav>
</header>


<!-- Sign In Modal-->
<app-login-modal></app-login-modal>

<!-- logout Modal -->
<app-logout-modal></app-logout-modal>

<!-- Sign up modal -->
<app-reg-modal></app-reg-modal>

<!-- Reset Password modal -->
<app-reset-password-modal></app-reset-password-modal>










