<form class="needs-validation" novalidate (ngSubmit)="submitForm()" [formGroup]="regStep1Form">

  <div class="mb-2">
    <label class="form-label mb-2" for="signupEmail">{{ 'REGISTER.EMAIL_LABEL' | translate }}</label>
    <input class="form-control" type="email" id="signupEmail" placeholder="{{ 'REGISTER.EMAIL_PLACEHOLDER' | translate }}" required formControlName="email" [ngClass]="{'is-invalid': regSubmit && regForm['email'].errors}">
    <div class="invalid-feedback" *ngIf="!regStep1Form.controls['email'].errors?.['validationErrorFromServer']">{{ 'REGISTER.EMAIL_ALERT' | translate }}</div>
    <div class="invalid-feedback" *ngIf="regStep1Form.controls['email'].errors?.['validationErrorFromServer']">
      {{ regStep1Form.controls['email'].errors?.['validationErrorFromServer'] }}
    </div>
  </div>

  <div class="mb-2">
    <label class="form-label mb-2" for="username">{{ 'REGISTER.LOGIN_LABEL' | translate }} </label>
    <input class="form-control" type="text" id="username" placeholder="{{ 'REGISTER.LOGIN_PLACEHOLDER' | translate }}" required formControlName="username" [ngClass]="{'is-invalid': regSubmit && regForm['username'].errors}" autocomplete="off">
    <div class="invalid-feedback" *ngIf="!regStep1Form.controls['username'].errors?.['validationErrorFromServer']">{{ 'REGISTER.LOGIN_ALERT' | translate }}</div>
    <div class="invalid-feedback" *ngIf="regStep1Form.controls['username'].errors?.['validationErrorFromServer']">
      {{ regStep1Form.controls['username'].errors?.['validationErrorFromServer'] }}
    </div>
  </div>

  <div class="row row-cols-1 row-cols-sm-2">
    <div class="col mb-2">
      <label class="form-label mb-1" for="firstName">{{ 'REGISTER.FNAME_LABEL' | translate }}</label>
      <input class="form-control" type="text" id="firstName" placeholder="{{ 'REGISTER.FNAME_LABEL' | translate }}" required formControlName="fname" [ngClass]="{'is-invalid': regSubmit && regForm['fname'].errors}" autocomplete="off">
      <div class="invalid-feedback">{{ 'REGISTER.FNAME_ALERT' | translate }}</div>
    </div>
    <div class="col mb-2">
      <label class="form-label mb-1" for="lastName">{{ 'REGISTER.LNAME_LABEL' | translate }}</label>
      <input class="form-control" type="text" id="lastName" placeholder="{{ 'REGISTER.LNAME_LABEL' | translate }}" required formControlName="lname" [ngClass]="{'is-invalid': regSubmit && regForm['lname'].errors}" autocomplete="off">
      <div class="invalid-feedback">{{ 'REGISTER.FNAME_ALERT' | translate }}</div>
    </div>
  </div>

  <div class="">
    <label class="form-label mb-1" for="birthYear">{{ 'REGISTER.BIRTH_DATE_LABEL' | translate }}</label>
    <div class="row">
      <div class="col-12 col-sm-4 mb-2 mb-sm-0">
        <select class="form-select" id="birthYear" formControlName="birthYear" [ngClass]="{'is-invalid': regSubmit && (regForm['birthYear'].errors || regStep1Form.errors?.['ageInvalid'])}">
          <option selected value="">{{ 'REGISTER.BIRTH_YEAR_LABEL' | translate }}</option>
          <option *ngFor="let year of yearOptions" [value]="year">{{year}}</option>
        </select>
      </div>
      <div class="col-12 col-sm-5 mb-2 mb-sm-0" *ngIf="monthOptions.length">
        <select class="form-select" id="birthMonth" formControlName="birthMonth" [ngClass]="{'is-invalid': regSubmit && (regForm['birthMonth'].errors || regStep1Form.errors?.['ageInvalid'])}">
          <option selected value="">{{ 'REGISTER.BIRTH_MONTH_LABEL' | translate }}</option>
          <option *ngFor="let month of monthOptions" [value]="month">{{ 'REGISTER.BIRTH_MONTH_'+ month | translate  }}</option>
        </select>
      </div>
      <div class="col-12 col-sm-3 mb-2 mb-sm-0" *ngIf="dayOptions.length">
        <select class="form-select" id="birthDay" formControlName="birthDay" [ngClass]="{'is-invalid': regSubmit && (regForm['birthDay'].errors || regStep1Form.errors?.['ageInvalid'])}">
          <option selected value="">{{ 'REGISTER.BIRTH_DAY_LABEL' | translate }}</option>
          <option *ngFor="let day of dayOptions" [value]="day">{{ day }}</option>
        </select>
      </div>
    </div>
    <div class="invalid-feedback d-block" *ngIf="regSubmit && regStep1Form.errors?.['ageInvalid']">
      {{ 'REGISTER.BIRTH_ALERT' | translate }}
    </div>

  </div>


  <button *ngIf="!isLoading, else loadingSpinner" class="btn btn-success btn-lg w-100 mt-4" type="submit">
    {{ 'REGISTER.NEXT_LABEL' | translate }}</button>
  <ng-template #loadingSpinner>
    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>

</form>




<!--first version-->
<!--<form class="needs-validation" novalidate (ngSubmit)="submitForm()" [formGroup]="regStep1Form">-->

<!--  <div class="mb-2">-->
<!--    <label class="form-label mb-2" for="signupEmail">{{ 'REGISTER.STEP1.EMAIL_LABEL' | translate }}</label>-->
<!--    <input class="form-control" type="email" id="signupEmail" placeholder="{{ 'REGISTER.STEP1.EMAIL_PLACEHOLDER' | translate }}" required formControlName="email" [ngClass]="{'is-invalid': regSubmit && regForm['email'].errors}">-->
<!--    <div class="invalid-feedback" *ngIf="!regStep1Form.controls['email'].errors?.['validationErrorFromServer']">{{ 'REGISTER.STEP1.EMAIL_ALERT' | translate }}</div>-->
<!--    <div class="invalid-feedback" *ngIf="regStep1Form.controls['email'].errors?.['validationErrorFromServer']">-->
<!--      {{ regStep1Form.controls['email'].errors?.['validationErrorFromServer'] }}-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="mb-2">-->
<!--    <label class="form-label mb-2" for="username">{{ 'REGISTER.STEP1.LOGIN_LABEL' | translate }} </label>-->
<!--    <input class="form-control" type="text" id="username" placeholder="{{ 'REGISTER.STEP1.LOGIN_PLACEHOLDER' | translate }}" required formControlName="username" [ngClass]="{'is-invalid': regSubmit && regForm['username'].errors}" autocomplete="off">-->
<!--    <div class="invalid-feedback" *ngIf="!regStep1Form.controls['username'].errors?.['validationErrorFromServer']">{{ 'REGISTER.STEP1.LOGIN_ALERT' | translate }}</div>-->
<!--    <div class="invalid-feedback" *ngIf="regStep1Form.controls['username'].errors?.['validationErrorFromServer']">-->
<!--      {{ regStep1Form.controls['username'].errors?.['validationErrorFromServer'] }}-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="mb-2">-->
<!--    <label class="form-label mb-2" for="password">{{ 'REGISTER.STEP1.PW_LABEL' | translate }}</label>-->
<!--    <div class="position-relative">-->
<!--      <input class="form-control" [type]="fieldTextType ? 'text' : 'password'" id="password" required formControlName="password"-->
<!--               placeholder="{{ 'REGISTER.STEP1.PW_PLACEHOLDER' | translate }}"-->
<!--               [ngClass]="{'is-invalid': regSubmit && regForm['password'].errors}">-->
<!--      <label class="password-toggle-btn" [ngClass]="{'invalid-toggle-btn': regSubmit && regForm['password'].errors}" aria-label="Show/hide password">-->
<!--        <input class="password-toggle-check" type="checkbox">-->
<!--        <span class="password-toggle-indicator" [ngClass]="{'opacity-50': !fieldTextType, 'opacity-1': fieldTextType}" (click)="toggleFieldTextType()">-->
<!--          <svg class="eye-off" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g><path d="M9.25,4.5A8.87,8.87,0,0,0,1,10.125a8.862,8.862,0,0,0,16.5,0A8.87,8.87,0,0,0,9.25,4.5Zm0,9.375A3.75,3.75,0,1,1,13,10.125,3.751,3.751,0,0,1,9.25,13.875Zm0-6a2.25,2.25,0,1,0,2.25,2.25A2.247,2.247,0,0,0,9.25,7.875Z"></path></g></svg>-->
<!--        </span>-->
<!--      </label>-->
<!--      <div class="invalid-feedback" *ngIf="regSubmit && regForm['password'].errors">{{ 'REGISTER.STEP1.PW_ALERT' | translate }}</div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <button *ngIf="!isLoading, else loadingSpinner" class="btn btn-success btn-lg w-100 mt-4" type="submit">-->
<!--    {{ 'REGISTER.NEXT_LABEL' | translate }}</button>-->
<!--  <ng-template #loadingSpinner>-->
<!--    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">-->
<!--      <div class="spinner-border" role="status">-->
<!--        <span class="visually-hidden">Loading...</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->

<!--</form>-->

