<main class="container-fluid px-0 pb-3 pb-lg-5">

  <div class="px-3 px-md-5 py-2 py-md-0 bg-darkest">
    <div class="container-lg">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="h3 fw-bold mb-0">{{'PROVIDERS.TITLE' | translate}}</h1>
        <div class="category-icon-overlay">
          <img src="assets/icons/providers_big.svg" alt="provider background image" width="100%">
        </div>
      </div>
    </div>
  </div>

  <div class="px-3 px-md-5">
    <div class="container-lg">
      <section class="py-2 py-md-5" *ngIf="providers$ | async as providers">
        <div class="row gy-4 justify-content-center justify-content-sm-start">
          <ng-container *ngFor="let p of providers">
            <div class="col-6 col-sm-4 col-md-3 col-xl-1" style="min-width: 200px;" *ngIf="p.gameList && p.gameList.length">
              <div class="p-2 bg-lighter rounded-3 clickable shadow h-100 d-flex justify-content-center">
                <a class="d-flex justify-content-center" routerLink="/providers/{{p.routerLink}}">
                  <img [src]=p.imagePath alt="{{p.name}} logo" style="width: 100%; max-width: 80%; height: auto;">
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </section>
    </div>
  </div>


</main>



