import { Component } from '@angular/core';
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  brandName: string;
  contactEmail: string;

  constructor(
    private configs: ConfigService,
  ) {
    this.brandName = this.configs.brandName;
    this.contactEmail = this.configs.contactEmail;
  }


}
