import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {GameService} from "../../../services/game.service";
import {TranslateService} from "@ngx-translate/core";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {TrackingHelperService} from "../../../services/tracking-helper.service";

declare var bootstrap: any;


@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {

  loginForm!: UntypedFormGroup;
  loginSubmit!: boolean;
  fieldTextType!: boolean;


  @ViewChild('closeSignInButton') closeSignInButton!: ElementRef;

  isLoading:boolean = false;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private gaService:GoogleAnalyticsService,
    private trackingService: TrackingHelperService
  ) {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
      mode: ['real', [Validators.required]]
    });
  }

  submitLoginForm() {
    this.loginSubmit = true;
    if (this.logForm['login'].valid && this.logForm['password'].valid && this.logForm['mode'].valid) {
      const loginData =  {
        username: this.logForm['login'].value,
        password: this.logForm['password'].value,
        mode: this.logForm['mode'].value,
      }
      // console.log(loginData);
      this.isLoading = true;

      this.authService.login(loginData).subscribe({
        next: ( response ) => {
          this.loginForm.reset();
          this.logForm['mode'].setValue('real');
          this.loginSubmit = false;

          // console.log(response);
          this.isLoading = false;
          this.closeSignInButton.nativeElement.click();
        },
        error: (err) => {
          this.loginForm.reset();
          this.logForm['mode'].setValue('real');
          this.isLoading = false;

          // if there is a rival error message:
          //todo solve the translation
          if (err.error && err.error.code && err.error.message){
            this.toastr.error(`Error ${err.error.code} ${err.error.message}`, this.translate.instant('LOGIN.TOASTR_FAILED_H1'), {toastClass: 'ngx-toastr yourclass'})
          } else {
            //fallback case for non-descriptive errors
            console.error(err)
            this.toastr.error(`${this.translate.instant('LOGIN.TOASTR_FAILED_MESSAGE')}`, this.translate.instant('LOGIN.TOASTR_FAILED_H1'), {toastClass: 'ngx-toastr yourclass'})
          }
        }
      })
    }
  }


  onRegModalOpened(): void {
    //send event to GA
    this.gaService.event('register_page_loaded', undefined, undefined, undefined, undefined,{play_mode: "real", ...this.trackingService.getAffiliateOptions() });
  }


  get logForm() {
    return this.loginForm.controls;
  }

  //Hide/show password
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
