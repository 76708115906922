import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ConfigService} from "../../../services/config.service";



@Component({
  selector: 'app-responsible-gaming',
  templateUrl: './responsible-gaming.component.html',
  styleUrls: ['./responsible-gaming.component.scss']
})
export class ResponsibleGamingComponent {

  brandName: string;
  contactEmail: string;

  public linkUrl: string = "https://www.gamblersanonymous.org/ga/";
  public currentLang: string = 'en';
  public langChangeSubs? : Subscription;

  constructor(
    private configs: ConfigService,
    private translate: TranslateService
  ) {
    this.brandName = this.configs.brandName;
    this.contactEmail = this.configs.contactEmail;
  }


  ngOnInit(){
    this.currentLang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
    this.updateLink(this.translate.currentLang);

    this.langChangeSubs = this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
      this.updateLink(this.currentLang);
    });
  }

  private updateLink(lang: string) {
    if (lang === 'de'){
      this.linkUrl = 'https://www.check-dein-spiel.de/';
    } else {
      this.linkUrl = 'https://www.gamblersanonymous.org/ga/';
    }
  }

  ngOnDestroy(){
    this.langChangeSubs?.unsubscribe();
  }


}
