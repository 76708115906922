export const games=[
  {
    "id": 334,
    "name": "SugarPop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SugarPop3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 336,
    "name": "The True Sheriff",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheTrueSheriff3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 340,
    "name": "Greedy Goblins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreedyGoblins3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 341,
    "name": "Boomanji",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Boomanji3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 342,
    "name": "Puppy Love",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PuppyLove3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 343,
    "name": "Under the Bed",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_UndertheBed3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 344,
    "name": "At The Copa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AtTheCopa3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 345,
    "name": "After Night Falls",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AfterNightFalls3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 346,
    "name": "Sushi Bar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SushiBar3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 347,
    "name": "Safari Sam",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariSam3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 349,
    "name": "At the Movies",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AttheMovies3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 351,
    "name": "Rook's Revenge",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RooksRevenge3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 353,
    "name": "Under the Sea",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_UndertheSea3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 354,
    "name": "Slots Angels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlotsAngels3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 355,
    "name": "Black Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackGold3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 356,
    "name": "Lost",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Lost3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 357,
    "name": "Madder Scientist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MadderScientist3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 359,
    "name": "Mamma Mia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MammaMia3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 360,
    "name": "True Illusions",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TrueIllusions3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 362,
    "name": "Tycoons PLUS",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Tycoons3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 363,
    "name": "Viking Age",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingAge3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 364,
    "name": "Arrival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Arrival3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 365,
    "name": "Paco and the Popping Peppers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PacoandthePoppingPeppers3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 366,
    "name": "2 Million BC",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_2MillionBC3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 368,
    "name": "House Of Fun",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HouseOfFun3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 370,
    "name": "Mr. Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrVegas3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 372,
    "name": "Barbary Coast",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BarbaryCoast3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 373,
    "name": "Once Upon A Time",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OnceUponATime3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 375,
    "name": "Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Heist3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 376,
    "name": "Gladiator",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Gladiator3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 378,
    "name": "Genie's Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeniesFortune.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 379,
    "name": "The Glam Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheGlamLife3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 381,
    "name": "Treasure Room",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TreasureRoom3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 395,
    "name": "Lucky Seven",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckySeven3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 403,
    "name": "7th Heaven",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7thHeaven3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 458,
    "name": "More Gold Diggin",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MoreGoldDiggin3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 463,
    "name": "Good Girl, Bad Girl",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoodGirlBadGirl3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 468,
    "name": "Gypsy Rose",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GypsyRose3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 485,
    "name": "Vivo Deuces Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "category": "Video Poker",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 554,
    "name": "Live Gaming Lobby",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VivoLiveGamingLobby3D.png",
    "category": "Table Games",
    "publisher": "Vivo Live",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 555,
    "name": "Live European Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "category": "Table Games",
    "publisher": "Vivo Live",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 556,
    "name": "Live Baccarat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "category": "Table Games",
    "publisher": "Vivo Live",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 558,
    "name": "Live Caribbean Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "category": "Table Games",
    "publisher": "Vivo Live",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 559,
    "name": "Live Dragon Tiger",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "category": "Table Games",
    "publisher": "Vivo Live",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 561,
    "name": "A Night In Paris",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ANightInParis3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 563,
    "name": "The SlotFather",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheSlotFather3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 579,
    "name": "Fruit Zen",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitZen.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 580,
    "name": "Dr Jekyll",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DrJekyll.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 818,
    "name": "Mega Gems",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaGems3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 836,
    "name": "Pinocchio",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Pinocchio3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 848,
    "name": "Mega Glam Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaGlamLife3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 858,
    "name": "Weekend In Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WeekendInVegas3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 861,
    "name": "The Tipsy Tourist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheTipsyTourist3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 869,
    "name": "A Christmas Carol",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AChristmasCarol3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 870,
    "name": "Alkemor's Tower",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlkemorsTower3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 881,
    "name": "4 Seasons",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_4Seasons3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 884,
    "name": "Frankenslots Monster",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FrankenslotsMonster3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 887,
    "name": "Birds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Birds.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 992,
    "name": "Charms and Clovers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CharmsAndClovers.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1009,
    "name": "Great88",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Great883D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1010,
    "name": "Kawaii Kitty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KawaiiKitty3D.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1101,
    "name": "Sin City Nights",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SinCityNights.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1106,
    "name": "Fa-Fa Twins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Fa-FaTwins.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1109,
    "name": "SlotFatherII",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlotFatherII.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1115,
    "name": "The Angler",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheAngler.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1129,
    "name": "Magic Shoppe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MagicShoppe.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1130,
    "name": "Fire and Steel",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FireandSteel.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1141,
    "name": "Giovanni's Gems",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GiovannisGems.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1149,
    "name": "Blood Eternal",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BloodEternal.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1162,
    "name": "Legend of the Nile",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendoftheNile.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1170,
    "name": "SugarPop 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SugarPop2.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1173,
    "name": "Card Clash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CardClash.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1176,
    "name": "Diggin’ Deep",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DigginDeep.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "HOT",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1178,
    "name": "Tigers's Claw",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TigerssClaw.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1185,
    "name": "Ogre Empire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OgreEmpire.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1186,
    "name": "Big Cash Win",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigCash.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1190,
    "name": "Alien Spinvasion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlienSpinvasion.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1192,
    "name": "Dragon Kings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonKings.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1195,
    "name": "Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Blackjack.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1200,
    "name": "Plunk-Oh",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlunkOh.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1201,
    "name": "Penguin Payday",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PenguinPayday.png",
    "category": "Scratch & Win",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1202,
    "name": "Mythic Wolf",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MysticWolf.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1203,
    "name": "Jacks or Better",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JorB.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1204,
    "name": "Ten Suns",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TenSuns.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ]
  },
  {
    "id": 1205,
    "name": "Diamond Cherries",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondCherries.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1206,
    "name": "Sevens and Bars",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7andBar.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1207,
    "name": "Five Times Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FiveTimesWins.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1208,
    "name": "Ten Times Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TenTimesWins.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1209,
    "name": "Arabian Tales",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ArabianTales.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1210,
    "name": "Juicy Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JuicyJewels.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1211,
    "name": "Mighty Aphrodite",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MightyAphrodite.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1212,
    "name": "Johnny Jungle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JohnnyJungle.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1213,
    "name": "Lion’s Roar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LionsRoar.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1215,
    "name": "Windy Farm",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WindyFarm.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1216,
    "name": "5 Reel Circus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_5reel.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1217,
    "name": "Chilli Pop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChilliPop.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1218,
    "name": "Cleopatra’s Coins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CleosCoins.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1279,
    "name": "Feng Fu",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FengFu.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1280,
    "name": "Savannah King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannahKing.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1289,
    "name": "Book Of Spells",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfSpells.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1292,
    "name": "Thrones Of Persia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThronesOfPersia.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1293,
    "name": "Geisha's Fan",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeishasFan.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1295,
    "name": "Fire'n'Hot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FireandHot.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1296,
    "name": "Monster Madness 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterMadness2.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1297,
    "name": "Pandas Run",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandasRun.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1298,
    "name": "243 Crystal Fruits",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_243CrystalFruits.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1309,
    "name": "Donut Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DonutRush.png",
    "category": "Scratch & Win",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1310,
    "name": "Monsters' Scratch",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonstersScratch.png",
    "category": "Scratch & Win",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1331,
    "name": "Abundance Spell",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AbundanceSpell.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1338,
    "name": "Nights Of Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NightsOfFortune.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1354,
    "name": "Greedy Servants",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreedyServants.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1356,
    "name": "Chest Of Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChestOfFortunes.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1362,
    "name": "Samurai's Path",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SamuraiPath.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1369,
    "name": "Wild Wild Spin",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildWildSpin.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1389,
    "name": "8 Lucky Charms Xtreme",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_8LuckyCharmsXtreme.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1390,
    "name": "Lilith's Passion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LilithPassion.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1391,
    "name": "Hunting Treasures Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HuntingTreasuresDeluxe.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1394,
    "name": "Demi Gods II",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemiGods2.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1395,
    "name": "Catsino",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Catsino.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1397,
    "name": "Chariots of Fire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChariotsOfFire.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1399,
    "name": "Diamond Dragon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondDragon.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1401,
    "name": "Golden Gorilla",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenGorilla.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1405,
    "name": "A Day at the Derby",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_aDayAtTheDerby.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1406,
    "name": "Jumping Jaguar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JumpingJaguar.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ]
  },
  {
    "id": 1408,
    "name": "Misfit Toyland",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MisfitToyland.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1412,
    "name": "Wild Weather",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildWeather.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1413,
    "name": "Frozen Queen",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FrozenQueen.png",
    "category": "Tom Horn Slots",
    "publisher": "Tom Horn Games",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1415,
    "name": "Lucky Labyrinth",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyLabyrinth.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ]
  },
  {
    "id": 1417,
    "name": "Wishing Cup",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WishingCup.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1418,
    "name": "Vintage Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VintageVegas.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1419,
    "name": "Rise of Poseidon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseofPoseidon.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1420,
    "name": "Dark Hearts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DarkHearts.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1424,
    "name": "Yak, Yeti & Roll",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YakYetiRoll.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1426,
    "name": "Wild Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildHeist.png",
    "category": "Spinomenal Slots",
    "publisher": "Spinomenal",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1432,
    "name": "7 Chakras",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChakrasVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1433,
    "name": "Age Of Spartans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpartanWarriorSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1434,
    "name": "Big Game",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigGameVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1435,
    "name": "Vikingdom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingdomVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1436,
    "name": "Gems N Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GemsNJewelsVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1437,
    "name": "Traces Of Evidence",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TracesOfEvidenceVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1438,
    "name": "Wild Berry",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildBerryVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1439,
    "name": "Elemental 7",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Elemental7VideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1440,
    "name": "Lucky Leprechauns",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyLeprechaunsVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1441,
    "name": "Rise Of Spartans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfSpartansVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1442,
    "name": "Wild Wizards",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildWizardsSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1443,
    "name": "Tanzakura",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TanzakuraVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1444,
    "name": "Sweet Success",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SweetSuccessVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1445,
    "name": "Monkey Business",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonkeyBusinessVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1446,
    "name": "Samba Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SambaSpinsVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1447,
    "name": "Wolf Street",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WolfStreetVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1448,
    "name": "Mrs. Green's Plant Emporium",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrsGreensPlantEmporiumVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1449,
    "name": "Tomahawk",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TomahawkVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1450,
    "name": "Zodiac",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZodiacVideoSlot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1455,
    "name": "Big Bang Buckaroo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBangBuckaroo.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1456,
    "name": "Party Parrot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PartyParrot.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1457,
    "name": "Almighty Dollar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlmightyDollar.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1458,
    "name": "King Winalot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KingKasholot.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1459,
    "name": "Dr. Acula",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DrAcula.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": []
  },
  {
    "id": 1460,
    "name": "Jack Frost",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JackFrost.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1461,
    "name": "Hot Hand",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HotHand.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1462,
    "name": "Smoking Gun",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SmokingGun.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ]
  },
  {
    "id": 1466,
    "name": "Fruitbat Crazy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitbatCrazy.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1468,
    "name": "Roulette - American",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RouletteAmericanUnified.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1469,
    "name": "Fruit Splash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitSplash.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1472,
    "name": "Blazin’ Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlazinBuffalo.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1474,
    "name": "Bust-A-Vault",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BustAVault.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1475,
    "name": "Champs Élysées",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChampsElysees.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1476,
    "name": "Dollars to Donuts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DollarsToDonuts.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1477,
    "name": "Frogged",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Frogged.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1484,
    "name": "Gold Bricks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldBricks.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1485,
    "name": "Gold Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldRush.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1486,
    "name": "Jolly Roger’s Jackpot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JollyRogersJackpot.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1487,
    "name": "Viking Voyage",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingVoyage.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1488,
    "name": "Leonardo’s Loot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeonardosLoot.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1490,
    "name": "For Love and Money",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FLAM.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1491,
    "name": "MegaWins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Megawins.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1492,
    "name": "Midas Touch",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MidasTouch.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1493,
    "name": "Milk the Cash Cow",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashCow.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1494,
    "name": "Ocean Treasure",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_oceantreasure.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1495,
    "name": "Reel Party Platinum",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelPartyPlat.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1496,
    "name": "Scary Rich 3",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScaryRich3.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1497,
    "name": "Sensational Sixes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SensationalSixes.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1498,
    "name": "So 80’s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_So80s.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1499,
    "name": "Swinging Sweethearts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SwingingSweethearts.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1501,
    "name": "Thunderbird",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Thunderbird.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1503,
    "name": "Tycoon Towers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TycoonTowers.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1504,
    "name": "Whale O’Winnings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WhaleOWinnings.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1505,
    "name": "World of Oz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WorldofOz.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1507,
    "name": "Spinfinity Man",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpinfinityMan.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1509,
    "name": "Band Outta Hell",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BandOuttaHell.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1513,
    "name": "Nordic Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NordicWild.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1515,
    "name": "Show Me The Honey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShowMeTheHoney.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1516,
    "name": "Sirens' Serenade",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SirensSerenade.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1517,
    "name": "Spartians",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Spartians.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1518,
    "name": "Spirit of the Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpiritOfTheWild.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1519,
    "name": "Stones and Bones",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StonesAndBones.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1520,
    "name": "Tales of Time Travel",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TalesOfTimeTravel.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1523,
    "name": "Winsanity",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Winsanity.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1524,
    "name": "Gold Canyon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldCanyon.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1525,
    "name": "Popping Piñatas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PoppingPinatas.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round"
    ]
  },
  {
    "id": 1527,
    "name": "Lost Secret of Atlantis",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LostSecretofAtlantis.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1528,
    "name": "Mobilé",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Mobile.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1529,
    "name": "Silver Unicorn",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SilverUnicorn.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1530,
    "name": "Dog Pound",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DogPound.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1531,
    "name": "Chicken Little",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChickenLittle.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1532,
    "name": "Scary Rich 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScaryRich2.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1533,
    "name": "Scary Rich",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScaryRich.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1534,
    "name": "Pistols & Roses",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Pistols&Roses.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "HOT",
    "features": []
  },
  {
    "id": 1535,
    "name": "Diamond Dazzle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondDazzle.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1536,
    "name": "Wolf Moon Rising",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WolfMoonRising.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1537,
    "name": "Firestorm 7",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Firestorm7.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1541,
    "name": "BamBoo Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BamBooRush.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1542,
    "name": "Winter Wonders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinterWonders.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1543,
    "name": "Snow Wonder",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SnowWonder.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1544,
    "name": "Loco 7’s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Loco7s.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1545,
    "name": "Red White and Bleu",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RedWhiteAndBleu.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1546,
    "name": "Tahiti Time",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TahitiTime.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1547,
    "name": "Crazy Camel Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrazyCamelCash.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1548,
    "name": "Nuclear Fishin’",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NuclearFishing.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "HOT",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1550,
    "name": "Win Mill",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Winmill.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round"
    ]
  },
  {
    "id": 1551,
    "name": "Gnome Sweet Home",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GnomeSweetHome.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1553,
    "name": "Hobo’s Hoard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Hobos.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1554,
    "name": "Star Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StarJewels.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "expanding_wilds"
    ]
  },
  {
    "id": 1555,
    "name": "Eggstravaganza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Eggstravaganza.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1557,
    "name": "Flea Market",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FleaMarket.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1558,
    "name": "Global Cup Soccer",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GlobalCupSoccer.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1559,
    "name": "Surf Paradise",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SurfParadise.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1561,
    "name": "Blackjack (American)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackAmeriNuc.png",
    "category": "Table Games",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1562,
    "name": "Aces and Faces",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AcesFaces.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1563,
    "name": "Gemmed!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Gemmed.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1565,
    "name": "Caishen's Arrival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaishensArrival.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1566,
    "name": "Cash Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashVegas.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1567,
    "name": "Small Soldiers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SmallSoldiers.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1602,
    "name": "Tens or Better",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TorB.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1603,
    "name": "Deuces and Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeuceJoker.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1605,
    "name": "Joker Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Jokerpoker.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1606,
    "name": "Double Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DoubleJoker.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1607,
    "name": "Deuces Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeucesWild.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1609,
    "name": "Dark Mystic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DarkMystic.png",
    "category": "Felix Slots",
    "publisher": "Felix Gaming (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1610,
    "name": "Lines of Magic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LinesofMagic.png",
    "category": "Felix Slots",
    "publisher": "Felix Gaming (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1611,
    "name": "Mr. Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Mr.Luck.png",
    "category": "Felix Slots",
    "publisher": "Felix Gaming (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1612,
    "name": "Light Dance",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LightDance.png",
    "category": "Felix Slots",
    "publisher": "Felix Gaming (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1618,
    "name": "Warlock's Book",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WarlocksBook.png",
    "category": "Fugaso Slots",
    "publisher": "Fugaso (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1619,
    "name": "Wild Rodeo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildRodeo.png",
    "category": "Fugaso Slots",
    "publisher": "Fugaso (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1620,
    "name": "Imhotep Manuscript",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ImhotepManuscript.png",
    "category": "Fugaso Slots",
    "publisher": "Fugaso (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1622,
    "name": "Sahara's Dreams",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaharasDreams.png",
    "category": "Fugaso Slots",
    "publisher": "Fugaso (Groove)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1624,
    "name": "Treasure of the Nile",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TreasureOfTheNile.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1632,
    "name": "Ice Picks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IcePicks.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1634,
    "name": "Wrath of Medusa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WrathOfMedusa.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1657,
    "name": "Shamrock Isle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShamrockIsle.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1659,
    "name": "Astral Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AstralLuck.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "HOT",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1660,
    "name": "Coins of Olympus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoinsOfOlympus.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1662,
    "name": "Twin Dragons",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TwinDragons.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1666,
    "name": "Panda Party",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaParty.png",
    "category": "Rival i-Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1669,
    "name": "Gushers Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GushersGold.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1670,
    "name": "Live Table Games",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1671,
    "name": "FreshDeck Baccarat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1672,
    "name": "FreshDeck European Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1673,
    "name": "FreshDeck American Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1674,
    "name": "FreshDeck Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1675,
    "name": "FreshDeck Casino Holdem",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1676,
    "name": "FreshDeck Euro: European Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1677,
    "name": "FreshDeck Euro: Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1678,
    "name": "Daikoku Blessings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DaikokuBlessings.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1679,
    "name": "Stampede",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Stampede.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1680,
    "name": "Jumbo Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JumboJoker.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1681,
    "name": "Golden Owl of Athena",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenOwl.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1682,
    "name": "Cleopatra's Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CleopatrasFortune.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1683,
    "name": "El Mariachi",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ElMariachi.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1684,
    "name": "Empire of Riches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EmpireofRiches.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1685,
    "name": "Fruity Feast",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruityFeast.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1686,
    "name": "Gold Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldHeist.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1687,
    "name": "iScream",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_iScream.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1688,
    "name": "Lucky Macau",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyMacau.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1689,
    "name": "Mythical Creatures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MythicalCreatures.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1690,
    "name": "Panda Playtime",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaPlaytime.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1691,
    "name": "The Defenders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheDefenders.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1692,
    "name": "Wilderness Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildernessWins.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1693,
    "name": "Winning Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinningVegas.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1694,
    "name": "European Roulette (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EuropeanRoulette(Dragon).png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1695,
    "name": "American Roulette (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AmericanRoulette(Dragon).png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1696,
    "name": "Blackjack (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Blackjack(Dragon).png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1704,
    "name": "Max Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MaxCash.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1705,
    "name": "Captain Shockwave",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaptainShockwave.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1706,
    "name": "Horn of Plenty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HornOfPlenty.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1707,
    "name": "Elementium Spin16",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ElementiumSpin16.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1709,
    "name": "The Prize Is Right",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThePrizeIsRight.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1710,
    "name": "Shells 'n Swells",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShellsNSwells.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1711,
    "name": "Big British Bake",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBritishBake.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1712,
    "name": "Buckaneers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Buckaneers.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1713,
    "name": "Zombie FC",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZombieFC.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1715,
    "name": "Cricket Legends",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CricketLegends.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1722,
    "name": "Bingo Ribeirinhos",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BingoRibeirinhos.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1725,
    "name": "Fast & Sexy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FastAndSexy.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1733,
    "name": "Gridiron Glory",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GridironGlory.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1735,
    "name": "Cataratas Bingo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CataratasBingo.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1738,
    "name": "Bingo Goal",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BingoGoal.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1739,
    "name": "Streetball Star",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StreetballStar.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1740,
    "name": "Tribo Bingo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TriboBingo.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1741,
    "name": "Hockey Enforcers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HockeyEnforcers.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1749,
    "name": "Gold Rush Gus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldRushGus.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1751,
    "name": "Take The Bank",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeTheBank.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1752,
    "name": "Dragon & Phoenix",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonAndPhoenix.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1753,
    "name": "Super Sweets",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperSweets.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1754,
    "name": "Total Overdrive",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TotalOverdrive.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1755,
    "name": "Spring Tails",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpringTails.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1756,
    "name": "Monster Pop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterPop.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1757,
    "name": "Back to Venus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BackToVenus.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1758,
    "name": "Quest To The West",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_QuestToTheWest.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1759,
    "name": "The Hive",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Hive.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1767,
    "name": "Wild Carnival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildCarnival.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1768,
    "name": "Blackjack Multi-Hand",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackMultihand.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1769,
    "name": "Mystic Hive",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MysticHive.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1771,
    "name": "Merlin’s Mystical Multipliers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MerlinsMysticalMultipliers.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers"
    ]
  },
  {
    "id": 1772,
    "name": "Golden Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenBuffalo.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1773,
    "name": "Viking Victory",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingVictory.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1776,
    "name": "Zombiezee Money",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZombiezeeMoney.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1778,
    "name": "Jacks or Better (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JacksOrBetterMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1779,
    "name": "Aces and Faces (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AcesAndFacesMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1780,
    "name": "Tens or Better (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TensOrBetterMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1781,
    "name": "Deuces and Joker (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeucesJokerMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1782,
    "name": "Joker Poker (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JokerPokerMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1783,
    "name": "Double Joker (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DoubleJokerMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1784,
    "name": "Deuces Wild (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeucesWildMultiHand.png",
    "category": "Video Poker",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1785,
    "name": "The Wicked Witches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheWickedWitches.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1786,
    "name": "Costume Party",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CostumeParty.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1787,
    "name": "Bucksy Malone",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BucksyMalone.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1788,
    "name": "Charmz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Charmz.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1789,
    "name": "Destiny Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DestinyWild.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1790,
    "name": "Double Trouble",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DoubleTrouble.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1791,
    "name": "Electron",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Electron.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1792,
    "name": "Fairy Dust Forest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FairyDustForest.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1793,
    "name": "The Last Pharaoh",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LastPharaoh.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1794,
    "name": "Ride ’em Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RideEmPoker.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1795,
    "name": "Future Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FutureFortunes.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1796,
    "name": "Heroes’ Realm",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HeroesRealm.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "HOT",
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1797,
    "name": "Cast for Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CastForCash.png",
    "category": "Scratch & Win",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1798,
    "name": "Beach Bums",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BeachBums.png",
    "category": "Scratch & Win",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1799,
    "name": "Pirate’s Pillage",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiratesPillage.png",
    "category": "Scratch & Win",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1800,
    "name": "Gunslinger’s Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GunslingersGold.png",
    "category": "Scratch & Win",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1801,
    "name": "Tiki Treasure",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TikiTreasure.png",
    "category": "Scratch & Win",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1802,
    "name": "Amazonia Bingo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AmazoniaBingo.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1805,
    "name": "Seirei Academy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SeireiAcademy.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1806,
    "name": "Hero School",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HeroSchool.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1807,
    "name": "Buffalo Bounty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BuffaloBounty.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1808,
    "name": "Safari Stampede",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariStampede.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1809,
    "name": "Shinobi Wars",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShinobiWars.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1810,
    "name": "Roulette - European",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RouletteEuropeanUnified.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1812,
    "name": "Lucky Ox Jackpots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyOxJackpots.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1816,
    "name": "Diamond Rhino Classic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondRhinoClassic.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1818,
    "name": "Spartan Warrior",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpartanWarrior.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1819,
    "name": "Dublin Your Dough",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DublinYourDough.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1821,
    "name": "Basketball Legends",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BasketballLegends.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1822,
    "name": "The Ingot Ox",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IngotOx.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1823,
    "name": "Legend of Horus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendofHorus.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1824,
    "name": "Blackjack Rolling Stacks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackRollingStacks.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1825,
    "name": "Monster Breakout",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterBreakout.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1826,
    "name": "Well Oil Be!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WellOilBe.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1828,
    "name": "Vegas Triple Pay Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VegasTriplePayDeluxe.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1829,
    "name": "Golden Boot Football",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenBootFootball.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1830,
    "name": "Saiyan Warriors",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaiyanWarriors.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1831,
    "name": "Play With Cleo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlayWithCleo.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1832,
    "name": "Reels & Wheels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelsAndWheels.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1833,
    "name": "Monster Manor",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterManor.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1834,
    "name": "5 Times Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_5TimesVegas.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1835,
    "name": "Larry's Lucky Tavern",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LarrysLuckyTavern.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1836,
    "name": "Gunspinners Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GunspinnersGold.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1837,
    "name": "Arcadia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Arcadia.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1838,
    "name": "Aladdin's Loot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AladdinsLoot.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1839,
    "name": "Bright Star",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BrightStar.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1840,
    "name": "Alpha Squad Double Cross",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlphaSquadDoubleCross.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1841,
    "name": "Whodunit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Whodunit.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1842,
    "name": "Millionaire's Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MillionairesLife.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1843,
    "name": "Beijing Nights",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BeijingNights.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1844,
    "name": "Spice of Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpiceOfLife.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1845,
    "name": "Tails of New York",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TailsOfNewYork.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1846,
    "name": "The Cash King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashKing.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1847,
    "name": "Keno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Keno.png",
    "category": "Specialty Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1848,
    "name": "Reels & Wheels XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelsWheelsXL.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1850,
    "name": "Fortune Frog",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FortuneFrog.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1851,
    "name": "Dead Beats",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeadBeats.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1852,
    "name": "Irish Wishes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IrishWishes.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1853,
    "name": "Win Another Day",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinAnotherDay.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1854,
    "name": "Yeti Hunt",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YetiHunt.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1855,
    "name": "Sands of Space",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SandsOfSpace.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1856,
    "name": "Sherwood Forest Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SherwoodForestFortunes.png",
    "category": "Rival i-Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1859,
    "name": "Wynloch Keep",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WynlochKeep.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1860,
    "name": "Year of the Rat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YearOfTheRat.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1861,
    "name": "Gods of Giza Enhanced",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GodsOfGizaEnhanced.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1862,
    "name": "Rich Panda",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RichPanda.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1863,
    "name": "Rise of the Titans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfTheTitans.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1866,
    "name": "Roaring 20s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Roaring20s.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1867,
    "name": "Football Fever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FootballFever.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1868,
    "name": "Cricket Fever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CricketFever.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1869,
    "name": "Book of Darkness",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfDarkness.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1870,
    "name": "Carnaval Forever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CarnavalForever.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1871,
    "name": "Dim Sum Prize",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DimSumPrize.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1872,
    "name": "Faerie Spells",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FaerieSpells.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1873,
    "name": "Gears of Time",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GearsOfTime.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1874,
    "name": "Primal Hunt",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PrimalHunt.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1875,
    "name": "Reels of Wealth",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelsOfWealth.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1876,
    "name": "Take Santa's Shop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeSantasShop.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1877,
    "name": "Lawless Ladies",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LawlessLadies.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1878,
    "name": "The Bank Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheBankHeist.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1879,
    "name": "Red Hot Slots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RedHotSlots.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1880,
    "name": "Party On Deck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PartyOnDeck.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1882,
    "name": "Golden Horns",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenHorns.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1883,
    "name": "Take Olympus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeOlympus.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1884,
    "name": "Stacked",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Stacked.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1885,
    "name": "Safari Sam 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariSam2.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1886,
    "name": "Lava Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LavaGold.png",
    "category": "Cluster Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1887,
    "name": "Hat Trick Hero",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HatTrickHero.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1888,
    "name": "Jungle Stripes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JungleStripes.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1889,
    "name": "Mississippi Wilds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MississippiWilds.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1890,
    "name": "Neptune's Bounty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NeptunesBounty.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1891,
    "name": "Pirates of the Grand Line",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiratesOfTheGrandLine.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1892,
    "name": "Fairytale Fortunes: Queen of Hearts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_QueenOfHearts.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1894,
    "name": "Miami Dice",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MiamiDice.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1895,
    "name": "The Wild Show",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheWildShow.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1896,
    "name": "Dragon Blast",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonBlast.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1897,
    "name": "Year of the Ox",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YearOfTheOx.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1898,
    "name": "Savanna King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannaKing.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1899,
    "name": "Panda Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaFortune.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1900,
    "name": "Sushi Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SushiWins.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1901,
    "name": "Vegas Jackpot Keno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KenoVegasJackpot.png",
    "category": "Specialty Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1902,
    "name": "Aztec Warrior",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AztecWarrior.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1903,
    "name": "Roll Out the Barrels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RollOutTheBarrels.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1904,
    "name": "Witches of Salem",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WitchesOfSalem.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1905,
    "name": "Baccarat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Baccarat.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1906,
    "name": "Fury of Zeus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FuryOfZeus.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1907,
    "name": "Prosperous Bloom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProsperousBloom.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1910,
    "name": "Gongxi Facai",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GongxiFacai.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1911,
    "name": "Crystal Crater",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrystalCrater.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1912,
    "name": "Reindeer Wild Wins XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReindeerWildWinsXL.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1913,
    "name": "FaFaFa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FaFaFa.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1914,
    "name": "Cream of the Crop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CreamOfTheCrop.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1920,
    "name": "Pai Gow",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PaiGow.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1921,
    "name": "Great White Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreatWhiteBuffalo.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1922,
    "name": "Wild Fishing",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildFishing.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1923,
    "name": "Return of Ra",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReturnOfRa.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1925,
    "name": "Storm Rider",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StormRider.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1926,
    "name": "Super Wilds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperWilds.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1927,
    "name": "Safari Stampede Qora",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariStampedeQora.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1928,
    "name": "Bigger Cash Win",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BiggerCashWin.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 1929,
    "name": "Reel Crime: Stealing Christmas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StealingChristmas.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1932,
    "name": "Zeus Rising",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZeusRising.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1933,
    "name": "Jingle Bell Jackpots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JingleBellJackpots.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1934,
    "name": "10 Times Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_10TimesVegas.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1935,
    "name": "Crazy Christmas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrazyChristmas.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1936,
    "name": "European Roulette Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EuropeanRouletteDeluxe.png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1937,
    "name": "Sea Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SeaTreasures.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1938,
    "name": "Gifts From Santa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GiftsFromSanta.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1939,
    "name": "Golden Children",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenChildren.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1940,
    "name": "Super Wilds XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperWildsXL.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1941,
    "name": "Panda Pursuit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaPursuit.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1942,
    "name": "Cirque du Slots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CirqueDuSlots.png",
    "category": "Rival i-Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ]
  },
  {
    "id": 1946,
    "name": "Galaxy Stars",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GalaxyStars.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1947,
    "name": "Viking Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingTreasures.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1948,
    "name": "Mythic Wolf: Sacred Moon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MythicWolfSacredMoon.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1949,
    "name": "Kaboom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Kaboom.png",
    "category": "Specialty Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1950,
    "name": "Bingo - European",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo90.png",
    "category": "Instant Bingo",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1951,
    "name": "Bingo - American",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo75.png",
    "category": "Instant Bingo",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1952,
    "name": "Bingo (80-Ball)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo80.png",
    "category": "Instant Bingo",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1953,
    "name": "Bingo (30-Ball)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo30.png",
    "category": "Instant Bingo",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1954,
    "name": "Solfire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Solfire.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1955,
    "name": "Reindeer Wild Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReindeerWildWins.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1956,
    "name": "Savanna King XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannaKingXL.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1957,
    "name": "FaFaFa XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FaFaFaXL.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1958,
    "name": "Demon Train",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemonTrain.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1959,
    "name": "Polar Heatwave",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PolarHeatwave.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1960,
    "name": "Love Beach",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LoveBeach.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1963,
    "name": "Wish Me Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WishMeLuck.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1964,
    "name": "Genesis Island",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GenesisIsland.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1965,
    "name": "Raving Wildz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RavingWildz.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1966,
    "name": "Jason's Quest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JasonsQuest.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1967,
    "name": "Leprechaun Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeprechaunFrenzy.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1968,
    "name": "DG Club",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DGClub.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1969,
    "name": "Rise of the Titans Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfTheTitansScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1970,
    "name": "Saiyan Warriors Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaiyanWarriorsScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1971,
    "name": "Play With Cleo Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlayWithCleoScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1975,
    "name": "Lion Dance Festival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LionDanceFestival.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1976,
    "name": "Wild Drops",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildDrops.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1977,
    "name": "Mr. Macau",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrMacau.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1978,
    "name": "Tower of Fortuna",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TowerOfFortuna.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1979,
    "name": "Best of Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BestOfLuck.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 1980,
    "name": "Hail Caesar!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HailCaesar.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1981,
    "name": "Incan Rich",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IncanRich.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 1982,
    "name": "Volcano Blast 10X",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VolcanoBlast.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1983,
    "name": "Zombie Invasion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZombieInvasion.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1984,
    "name": "Baccarat (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BaccaratDragon.png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1985,
    "name": "Aztec Warrior Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AztecWarriorScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1986,
    "name": "Leprechaun Frenzy Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeprechaunFrenzyScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1987,
    "name": "7 Fortune Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7FortuneFrenzy.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1988,
    "name": "Return to Paris",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReturnToParis.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1989,
    "name": "88 Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_88FrenzyFortune.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1990,
    "name": "Take the Kingdom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeTheKingdom.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1991,
    "name": "Hawaiian Dreams",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HawaiianDreams.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1992,
    "name": "Primal Wilderness",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PrimalWilderness.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1993,
    "name": "Alkemor's Elements",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlkemorsElements.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1994,
    "name": "Kensei Blades",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KenseiBlades.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1995,
    "name": "Fantasy Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FantasyFortune.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins"
    ]
  },
  {
    "id": 1996,
    "name": "Gods of Giza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GodsOfGiza.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1997,
    "name": "Great Golden Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreatGoldenBuffalo.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 1999,
    "name": "Luxe 555",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Luxe555.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2000,
    "name": "Mafia Family",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MafiaFamily.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2001,
    "name": "Stay Frosty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StayFrosty.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2002,
    "name": "Thai Blossoms",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThaiBlossoms.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2003,
    "name": "Lost Mystery Chests",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LostMysteryChests.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2004,
    "name": "Gold Tiger Ascent",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldTigerAscent.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2005,
    "name": "All Heart",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AllHeart.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2006,
    "name": "Wheel Big Winner",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WheelBigWinner.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2008,
    "name": "Caishen's Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaishensFortune.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2009,
    "name": "Book of Helios",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfHelios.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2010,
    "name": "The Richest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheRichest.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2011,
    "name": "Make You Rich",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MakeYouRich.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2012,
    "name": "FreshDeck American Auto Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2015,
    "name": "Mystic Wilds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MysticWilds.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2016,
    "name": "Sheriff vs Bandits",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SheriffVsBandits.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2017,
    "name": "Piggy Game",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiggyGame.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2018,
    "name": "Sea Treasures Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SeaTreasuresScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2019,
    "name": "Wilds of Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildsOfFortune.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2020,
    "name": "Olympus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Olympus.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2021,
    "name": "Luck Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckVegas.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2022,
    "name": "Demon’s Delight",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemonsDelight.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2023,
    "name": "Queen of Aces",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_QueenOfAces.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2025,
    "name": "Pirates Pick",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiratesPick.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2026,
    "name": "Da Hong Bao",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DaHongBao.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2027,
    "name": "Winds of Wealth",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WindsOfWealth.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2028,
    "name": "Gobblers Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GobblersGold.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "HOT",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 2029,
    "name": "Klondike Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KlondikeGold.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "free_spins"
    ]
  },
  {
    "id": 2030,
    "name": "Captain's Quest: Treasure Island",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaptainsQuestTreasureIsland.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2031,
    "name": "Rags To Witches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RagsToWitches.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2033,
    "name": "Genie's Riches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeniesRiches.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2034,
    "name": "Prosperous Bloom Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProsperousBloomScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2035,
    "name": "The American Diner",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AmericanDiner.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2036,
    "name": "Plucky Lucky",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PluckyLucky.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "free_spins"
    ]
  },
  {
    "id": 2037,
    "name": "Lady's Magic Charms",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LadysMagicCharms.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2038,
    "name": "Rumpel Thrill Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RumpelThrillSpins.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2041,
    "name": "Bananas Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BananasWild.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2042,
    "name": "Floral Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FloralFortunes.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2044,
    "name": "Chase the Turkey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChaseTheTurkey.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2045,
    "name": "Oriental Flower",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OrientalFlower.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2046,
    "name": "Trinity Reels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TrinityReels.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2047,
    "name": "Sleighin' It",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SleighinIt.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2048,
    "name": "Golden Dragon Inferno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenDragonInferno.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2051,
    "name": "Super Wild Race",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperWildRace.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2052,
    "name": "Demon Train Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemonTrainScratch.png",
    "category": "Scratch & Win",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2053,
    "name": "Triple Berry Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleBerryWild.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2054,
    "name": "Pharaoh's Falls",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PharaohsFalls.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2055,
    "name": "Woodlanders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Woodlanders.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2056,
    "name": "Bounding Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BoundingLuck.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2057,
    "name": "Year of the Rabbit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YearOfTheRabbit.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2059,
    "name": "Project Space",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProjectSpace.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2061,
    "name": "Cobra King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CobraKing.png",
    "category": "Rival i-Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ]
  },
  {
    "id": 2098,
    "name": "Majestic Mermaid",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MajesticMermaid.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2101,
    "name": "The 7th Element",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7thElement.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2102,
    "name": "Jolly Gelato",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JollyGelato.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2103,
    "name": "Age of Gladiators",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AgeOfGladiators.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2104,
    "name": "Temple of Athena",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TempleOfAthena.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2105,
    "name": "Hearts Desire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HeartsDesire.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2107,
    "name": "Easy Honey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EasyHoney.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2108,
    "name": "Oasis Dreams",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OasisDreams.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2109,
    "name": "Rabbit's Riches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RabbitsRiches.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2110,
    "name": "Bankers Gone Bonkers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BankersGoneBonkers.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 2111,
    "name": "Honey Hive XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HoneyHive.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 2113,
    "name": "Fairytale Fortunes: Jack and the Giants",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JackAndTheGiants.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 2116,
    "name": "Crocodile Hunt",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrocodileHunt.png",
    "category": "Cluster Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 2117,
    "name": "Mr. Vegas 2: Big Money Tower",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrVegas2.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2119,
    "name": "Dragon Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonRoulette.png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2120,
    "name": "Moolah Miner",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MoolahMiner.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2122,
    "name": "Triple Cash or Crash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleCashOrCrash.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2123,
    "name": "Charms and Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CharmsAndTreasures.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2124,
    "name": "Sahara Queen",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaharaQueen.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2125,
    "name": "Bounty Hunter",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BountyHunter.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2126,
    "name": "Hear Me Roar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HearMeRoar.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2127,
    "name": "Candy Factory",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyFactory.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2128,
    "name": "Hot Lucky 7's",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HotLucky7s.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2129,
    "name": "Da Vinci Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DaVinciJewels.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2130,
    "name": "Funfair Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FunfairFortune.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2131,
    "name": "Gemini Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeminiJoker.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2132,
    "name": "Phở Sho",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PhoSho.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2133,
    "name": "Triple Juicy Drops",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleJuicyDrops.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2134,
    "name": "Battle Ops",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BattleOps.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2136,
    "name": "Candy Carnival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyCarnival.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2137,
    "name": "Reel Crime: Coffin Up Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoffinUpCash.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": [
      "free_spins"
    ]
  },
  {
    "id": 2139,
    "name": "Wicked Ways",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WickedWays.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2140,
    "name": "Book of Mayans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfMayans.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2141,
    "name": "Fortune 8",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Fortune8.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2142,
    "name": "Golden Wolf",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenWolf.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2143,
    "name": "Happy 4th of July",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Happy4thOfJuly.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2144,
    "name": "Riches in the Rough",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RichesInTheRough.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2145,
    "name": "Wolf Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WolfRush.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2148,
    "name": "Wild Acres Farm",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildAcresFarm.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2149,
    "name": "Wheel Big Winner Red Hot Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RedHotSpins.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2150,
    "name": "Kung Food Panda",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KungFoodPanda.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2151,
    "name": "April Fury and the Chamber of Scarabs",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AprilFury.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2152,
    "name": "Wish Granted",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WishGranted.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2153,
    "name": "72 Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_72Fortunes.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2154,
    "name": "Blackjack (Qora)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackQora.png",
    "category": "Table Games",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2155,
    "name": "Tri Card Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TriCardPoker.png",
    "category": "Table Games",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2156,
    "name": "Savannah Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannahTreasures.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2157,
    "name": "World Cup Football",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WorldCupFootball.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2158,
    "name": "Legends of Baseball",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendsOfBaseball.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2159,
    "name": "Falling Fossils",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FallingFossils.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2160,
    "name": "Screaming Chillis",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScreamingChillis.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2161,
    "name": "Jingle Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JingleJewels.png",
    "category": "Cluster Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 2162,
    "name": "Teen Patti",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TeenPatti.png",
    "category": "Table Games",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2163,
    "name": "Super Golden Dragon Inferno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperGoldenDragonInferno.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2165,
    "name": "888 Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_888Deluxe.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2167,
    "name": "Slots of Money",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlotsOfMoney.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2168,
    "name": "Gems Gala",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GemsGala.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2169,
    "name": "Blackjack Deluxe 21+3",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackDeluxe213.png",
    "category": "Table Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2170,
    "name": "Wilderness Wolves",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildernessWolves.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2171,
    "name": "Cai Fu Dai Panda",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaiFuDaiPanda.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2172,
    "name": "Rise of Triton",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfTriton.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2173,
    "name": "Golden Blitz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenBlitz.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2175,
    "name": "Big Blue Fishing",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBlueFishing.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2176,
    "name": "Legends of Hockey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendsOfHockey.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2179,
    "name": "Clash of Queens",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ClashOfQueens.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2180,
    "name": "Instant Inferno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantInferno.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2181,
    "name": "Dragon Harmony",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonHarmony.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "free_spins"
    ]
  },
  {
    "id": 2183,
    "name": "Cashablanca",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Cashablanca.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2184,
    "name": "Metal Detector: Mayan Magic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MayanMagic.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2194,
    "name": "Enchanted: Forest of Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ForestOfFortune.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2195,
    "name": "Triple Lucky 8’s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleLucky8s.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2196,
    "name": "Majestic Beasts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MajesticBeasts.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2197,
    "name": "Santa’s Vacation",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SantasVacation.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2200,
    "name": "Finlay’s Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FinlaysFortunes.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2201,
    "name": "Book of Tutankhamun",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfTutankhamun.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2231,
    "name": "Big Shrimpin’",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigShrimpin.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 2232,
    "name": "Coyote Canyon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoyoteCanyon.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 2234,
    "name": "Tycoons Billionaire Bucks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TycoonsBillionaireBucks.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2237,
    "name": "El Bandito",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ElBandito.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2238,
    "name": "Sultan of Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SultanOfSpins.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2239,
    "name": "Pearl Pursuit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PearlPursuit.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2243,
    "name": "Bigfoot Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigfootFortunes.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ]
  },
  {
    "id": 2247,
    "name": "Dublin Your Dough: Rainbow Clusters",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RainbowClusters.png",
    "category": "Cluster Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 2248,
    "name": "Rockstar World Tour",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RockstarWorldTour.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2249,
    "name": "Plinko Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlinkoRush.png",
    "category": "Specialty Games",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2250,
    "name": "Eagle Eye",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EagleEye.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2251,
    "name": "Winter Heart",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinterHeart.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2252,
    "name": "Lucky Dragon Year",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyDragonYear.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2254,
    "name": "Dragon Fortune Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonFortuneFrenzy.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2258,
    "name": "Dawn of El Dorado",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DawnOfElDorado.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2264,
    "name": "Bierfest Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BierfestBonanza.png",
    "category": "Cluster Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "NEW",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 2265,
    "name": "Take the Vault",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeTheVault.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2266,
    "name": "Tiger's Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TigersLuck.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2268,
    "name": "Lucky Golden Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyGoldenJoker.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2269,
    "name": "Cash Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashHeist.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2270,
    "name": "Cash Vegas Triple Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashVegasTripleWIld.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2271,
    "name": "Leprechaun's Golden Trail",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeprechaunsGoldenTrail.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2272,
    "name": "Lair of the White Tiger",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LairOfTheWhiteTiger.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2273,
    "name": "Souls of the Dead",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SoulsOfTheDead.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "NEW",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ]
  },
  {
    "id": 2277,
    "name": "Midnight Mustang",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MidnightMustang.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2278,
    "name": "Grandma's Attic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GrandmasAttic.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ]
  },
  {
    "id": 2280,
    "name": "Zeus Thunder Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZeusThunderFortunes.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2281,
    "name": "Crown of Camelot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrownOfCamelot.png",
    "category": "Rival 3-Reel Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2283,
    "name": "Coins of Ra",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoinsOfRa.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2285,
    "name": "Eastern Wonder",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EasternWonder.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2286,
    "name": "Band Outta Hell Back on the Road",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BackOnTheRoad.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2287,
    "name": "Eggsplosion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Eggsplosion.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2288,
    "name": "22 Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_22Blackjack.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2289,
    "name": "Lawless Ladies Return",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LawlessLadiesReturn.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2292,
    "name": "Fruity Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitySpins.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2295,
    "name": "Candy Cascade",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyCascade.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2296,
    "name": "Giga Fruits",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GigaFruits.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2297,
    "name": "Fuzang Dragon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FuzangDragon.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2300,
    "name": "Shell Shock",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShellShock.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "FEATURED",
    "features": []
  },
  {
    "id": 2301,
    "name": "Mandarin Tiger",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MandarinTiger.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2302,
    "name": "Shake Your Mummymaker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Mummymaker.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2306,
    "name": "Stampede Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StampedeGold.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2307,
    "name": "Lucha Royale",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuchaRoyale.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2308,
    "name": "Wild Archer",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildArcher.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2309,
    "name": "Realm of the Gods",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RealmOfTheGods.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2315,
    "name": "Forest of Forbidden Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ForestOfForbiddenTreasures.png",
    "category": "Rival Video Slots",
    "publisher": "Rival",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2317,
    "name": "FreshDeck Limitless Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "category": "Table Games",
    "publisher": "FreshDeck (Vivo)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2434,
    "name": "Big Bass Fishin’ Fever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBass.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2435,
    "name": "Viking Reels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingReels.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2437,
    "name": "The Slotfather: Book of Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfWins.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2438,
    "name": "Mega Deep",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaDeep.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2439,
    "name": "Basketball Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BasketballBonanza.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2474,
    "name": "Foxy Lady Forest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INFoxyLadyForest.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2475,
    "name": "Funky Frogs",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INFunkyFrogs.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2476,
    "name": "Deep Sea Dazzle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_INDeepSeaDazzle.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2477,
    "name": "Lucky Cat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INLuckyCat.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2478,
    "name": "Wild and Wanted",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoWildAndWanted.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2483,
    "name": "Dragon Era",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoDragonEra.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2484,
    "name": "Fruit Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoFruitFortune.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2486,
    "name": "Duck Hunt Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoDuckHuntBonanza.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2487,
    "name": "Treasure of Minos",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoTreasureOfMinos.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2488,
    "name": "Crime Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoCrimeCash.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2493,
    "name": "Texas Gold Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoTexasGoldRush.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2494,
    "name": "Gorgon's Stash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoGorgonsStash.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2498,
    "name": "Aztec Gold Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoAztecGoldBonanza.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2499,
    "name": "Wildshot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoWildShot.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2505,
    "name": "Wild Plinko",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoWildPlinko.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2507,
    "name": "Cosmic Reels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INCosmicReels.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2508,
    "name": "Royal Tumble",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RoyalTumble.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2509,
    "name": "Triple 7s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Triple7s.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2513,
    "name": "Vegas Triple Pay Spin n Win",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VegasTriplePay.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2514,
    "name": "Crystals on Fire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrystalsOnFire.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2517,
    "name": "Nodeposit Bonus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INNodepositBonus.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2518,
    "name": "God of Luxor",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INGodofLuxor.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2519,
    "name": "Bingo Slot (InstaNet)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INBingoSlotInstaNet.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2520,
    "name": "Triple 8s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Triple8s.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2521,
    "name": "Lucky Mega Wheel",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyMegaWheel.png",
    "category": "Dragon Slots",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2523,
    "name": "Golden Destiny",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenDestiny.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2524,
    "name": "Winning Waterfall",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinningWaterfall.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2525,
    "name": "Champions of Olympia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChampionsOfOlympia.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2526,
    "name": "Wild Bill",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildBill.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2527,
    "name": "Western Legends",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WesternLegends.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2528,
    "name": "Prosperity Pot 88",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProsperityPot88.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2532,
    "name": "Chipy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INChipy.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2533,
    "name": "DragonEgg",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INDragonEgg.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2534,
    "name": "LotterySlot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INLotterySlot.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2537,
    "name": "ChilliPop: Get em All",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChilliPopGetEmAll.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2538,
    "name": "Treasures of Cleopatra",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TreasuresOfCleopatra.png",
    "category": "Betsoft Slots",
    "publisher": "Betsoft",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2539,
    "name": "Dice Breaker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiceBreaker.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": null,
    "features": []
  },
  {
    "id": 2540,
    "name": "Legends of Football",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendsOfFootball.png",
    "category": "Saucify Slots",
    "publisher": "Saucify",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2542,
    "name": "Thunderbird Spirit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThunderbirdSpirit.png",
    "category": "Qora Slots",
    "publisher": "Qora",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2545,
    "name": "Candy Bat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyBat.png",
    "category": "Wingo",
    "publisher": "Wingo",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2547,
    "name": "The Cursed Sea",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INTheCursedSea.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2575,
    "name": "Delivery",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INDelivery.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2578,
    "name": "Plinko Blitz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlinkoBlitz.png",
    "category": "Specialty Games",
    "publisher": "Dragon",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  },
  {
    "id": 2589,
    "name": "Jackowins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INJackowins.png",
    "category": "InstaNet Games",
    "publisher": "InstaNet (Sandstorm)",
    "launchUrl": null,
    "tag": "NEW",
    "features": []
  }
]