import { Component } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {RegistrationService} from "../../../../services/registration.service";
import {count, Subscription} from "rxjs";

import {countryData, currencyData, subdivisionData} from './data';
import {RegdataModel} from "../../../../models/registration/regdata.model";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {DetailsMapModel} from "../../../../models/registration/detailsMap.model";


@Component({
  selector: 'app-reg-step2',
  templateUrl: './reg-step2.component.html',
  styleUrls: ['./reg-step2.component.scss']
})
export class RegStep2Component {

  /** step 2 - for phone and address
    * make the server calls to send data to API: addDetails
    * validation on form - on our side todo: be more precise
       - PROVINCE is only required for US and Australia. These should be submitted as the ISO 3166-2 codes without country code (e.g. for Australia's New South Wales, 'NSW'). For other countries, any value (null included) is accepted, except empty string.
       - ZIP CODE is already/only enforced for players in the US
  */


  regStep2Form!: UntypedFormGroup;
  regSubmit: boolean = false;

  countryOptions: {name: string, code: string}[] = [];
  currencyOptions: {name: string, code: string}[] = [];

  provinceOptions: {name: string, code: string}[] = [];
  formSubs?: Subscription;
  subdivisions: {country_code: string, subdivision_name: string, "code": string}[] = []
  previousCountry: string = "";


  regSubs?: Subscription;
  regObject: RegdataModel | null = null;

  isLoading: boolean = false;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private regService: RegistrationService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.countryOptions = countryData;
    this.currencyOptions = currencyData;
    this.subdivisions = subdivisionData;

    this.regStep2Form = this.formBuilder.group({
      //TODO add validators ex not onlySpace
      phone: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern('[0-9]*')]],
      address: ['', [Validators.required, this.notOnlySpaceValidator]],
      address2: [''],
      city: ['', [Validators.required, this.notOnlySpaceValidator]],
      province: [''],
      zip: ['', [Validators.required,this.notOnlySpaceValidator]], //??3-10characters
      country: ['', [Validators.required]],
      currency: ['', [Validators.required]],
    }, {
      validators: this.provinceOptionalValidator
    });
  }

  ngOnInit(){
    this.formSubs?.unsubscribe();
    this.formSubs = this.regStep2Form.valueChanges.subscribe(values => {
      //filtering provinceOptions if countryValue changes
      if (values.country && values.country !== this.previousCountry){
        const provinces = this.subdivisions.filter(province => province.country_code === values.country)
        this.provinceOptions = provinces.map(p=>{
            return {
              name: p.subdivision_name,
              code: p.code
            }
        })
        this.previousCountry = values.country;

        if(this.regObject && this.regObject.details.country !== values.country){
          this.regStep2Form.patchValue({
             province: ""
          })
        }

        // Trigger validation whenever the country changes (provinceValidator)
        this.regStep2Form.updateValueAndValidity();


      }
    })


    this.regSubs?.unsubscribe()
    this.regSubs = this.regService.regData.subscribe(regData => {
      // console.log(regData)
      this.regObject = regData;
      if (regData){
        this.regStep2Form.patchValue({
          phone: regData.details.phone ?? "",
          address: regData.details.address ?? "",
          address2: regData.details.address2 ?? "",
          city: regData.details.city ?? "",
          province: regData.details.province ?? "",
          zip: regData.details.postCode ?? "",
          country: regData.details.country ?? "",
          currency: regData.details.currency ?? ""
        })
      }

    })

  }


  goToPrevStep(){
    //save data in BS and go back to step 1
    const regData:RegdataModel =  {
      email: this.regObject!.email,
      username: this.regObject!.username,
      locale: this.regObject!.locale,
      details:{
        ...this.regObject!.details,
        phone: this.regStep2Form.value.phone,
        address: this.regStep2Form.value.address,
        address2: this.regStep2Form.value.address2,
        city: this.regStep2Form.value.city,
        province: this.regStep2Form.value.province,
        postCode: this.regStep2Form.value.zip,
        country: this.regStep2Form.value.country,
        currency: this.regStep2Form.value.currency
      }
    }

    //todo send province optionnally - maybe gonna be required later - see validator (this is not good because without countycode we have many provinces with one letter or number and this way we don't send them)
    if (this.provinceOptions.length < 2 || this.regStep2Form.value.province.length < 2) {
      delete regData.details.province
    }

    this.regService.saveData(regData);
    this.regService.setStep(1);
  }

  submitForm() {
    this.regSubmit = true;

    if (this.regStep2Form.valid) {
      const regStep2Data: DetailsMapModel =  {
          phone: this.regStep2Form.value.phone,
          address: this.regStep2Form.value.address,
          address2: this.regStep2Form.value.address2,
          city: this.regStep2Form.value.city,
          province: this.regStep2Form.value.province,
          postCode: this.regStep2Form.value.zip,
          country: this.regStep2Form.value.country,
          currency: this.regStep2Form.value.currency

      }

      //todo: send province optionally - maybe gonna be required later - see validator (this is not good because without countycode we have many provinces with one letter or number and this way we don't send them)
      if (this.provinceOptions.length < 2 || this.regStep2Form.value.province.length < 2) {
        delete regStep2Data.province
      }

      this.isLoading = true;

      this.regService.addDetails(regStep2Data).subscribe({
        next: ( response ) => {
          // console.log(response); //result is empty

          const regData:RegdataModel =  {
            email: this.regObject!.email,
            username: this.regObject!.username,
            locale: this.regObject!.locale,
            details:{
              ...this.regObject!.details,
              phone: this.regStep2Form.value.phone,
              address: this.regStep2Form.value.address,
              address2: this.regStep2Form.value.address2,
              city: this.regStep2Form.value.city,
              province: this.regStep2Form.value.province,
              postCode: this.regStep2Form.value.zip,
              country: this.regStep2Form.value.country,
              currency: this.regStep2Form.value.currency
            }
          }

          if (this.provinceOptions.length < 2 || this.regStep2Form.value.province.length < 2) {
            delete regData.details.province;
          }

          //if OK -> save data to RegData BS, navigate to step 3
          this.regStep2Form.reset();
          this.regSubmit = false;
          this.isLoading = false;

          this.regService.saveData(regData)
          this.regService.setStep(3)
        },
        error: (err) => {
          // console.log("Error in regstep2:", err)

          //invalid token
         if (err.error.message === 'Could not authorize request.' || err.status === 401){
           this.toastr.error(`${this.translate.instant('REGISTER.TOASTR_TOKEN_ERROR_MESSAGE')}`, this.translate.instant('REGISTER.TOASTR_TOKEN_ERROR_H1'), {toastClass: 'ngx-toastr yourclass'})
         }

          this.isLoading = false;

          //validation errors from addDetails method
         if (err.error && err.error.data){
            //means that error comes from server in 200 ok code
            for (const key in err.error.data) {
              if (err.error.data.hasOwnProperty(key)) {
                //rival send the error in different format: details.country but province (without details)
                let propName: string;
                if (key.includes('details')){
                  propName = key.split(".")[1]
                } else {
                  propName = key
                }

                //set invalid fields invalid
                if (this.regStep2Form.controls[propName]) {
                  const formControlWithError = this.regStep2Form.get(propName);
                  if (formControlWithError){
                    formControlWithError.setErrors({validationErrorFromServer: err.error.data[key].message})
                  }
                }
                //warn user about the error
                this.toastr.error(`${err.error.data[key].message}`, `${err.error.message}`, {toastClass: 'ngx-toastr yourclass'})
              }
            }
         }

         //todo handle other errors ex. network error

        }
      })

    }
  }

  get regForm() {
    return this.regStep2Form.controls;
  }

  notOnlySpaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const onlySpace = !control.value || !control.value.trim();
    return onlySpace ? { onlySpaces: true } : null;
  }

  provinceOptionalValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const country = control.get('country');
    const province = control.get('province');
    if (!country || !province){
      return null
    }
    //province is required for US and for AU: ISO 3166-2 codes (from data.ts) without country code
    //for other countries, any value (null included) is accepted, except empty string
    if (country.value === "US" || country.value === "AU"){
      province?.setValidators([Validators.required, this.notOnlySpaceValidator])
    } else {
      province?.clearValidators();
    }

    return null; // No form-level errors to return

  }


  ngOnDestroy(){
    this.formSubs?.unsubscribe();
    this.regSubs?.unsubscribe();
  }

}

