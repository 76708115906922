<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-4">{{ 'LEGAL.ABOUT.TITLE' | translate }}</h1>

  <div *ngIf="legalSectionIndexes.length">
    <div *ngFor="let item of legalSectionIndexes">
      <h3 class="h5 fw-bold mt-4 mb-3">{{ ('LEGAL.ABOUT.SECTIONS.' + item.index + '.TITLE') | translate | uppercase }}</h3>
      <ng-container *ngIf="item.index !== '6'">
        <div *ngFor="let i of [].constructor(item.contentCount); let contentIndex = index">
          <p class="text-darker" [ngClass]="{'text-uppercase': item.index === '7' && contentIndex === 0}">
            {{ ('LEGAL.ABOUT.SECTIONS.' + item.index + '.CONTENT_' + (contentIndex + 1)) | translate: {brandName: brandName, licenseNumber: licenseN} }}</p>
        </div>
      </ng-container>
      <div *ngIf="item.index === '6'">
        <p class="text-darker">{{ ('LEGAL.ABOUT.SECTIONS.' + item.index + '.CONTENT_1') | translate: {brandName: brandName} }}</p>
        <ul>
          <ng-container *ngFor="let i of [].constructor(item.contentCount); let contentIndex = index">
            <li class="text-darker mb-2" *ngIf="contentIndex !==0">{{ ('LEGAL.ABOUT.SECTIONS.' + item.index + '.CONTENT_' + (contentIndex + 1)) | translate: {brandName: brandName} }}</li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

<!--  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.ABOUT.SECTIONS.1.TITLE' | translate | uppercase}}</h3>-->
<!--  <p class="text-secondary">{{ 'LEGAL.ABOUT.SECTIONS.1.CONTENT_1' | translate: {brandName: brandName} }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.1.CONTENT_2' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.1.CONTENT_3' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.1.CONTENT_4' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.1.CONTENT_5' | translate: {brandName: brandName} }}</p>-->

<!--  <h3 class="mt-5 mb-4">{{ 'LEGAL.ABOUT.SECTIONS.2.TITLE' | translate | uppercase}}</h3>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.2.CONTENT_1' | translate: {brandName: brandName} }}</p>-->

<!--  <h3 class="mt-5 mb-4">{{ 'LEGAL.ABOUT.SECTIONS.3.TITLE' | translate | uppercase}}</h3>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_1' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_2' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_3' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_4' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_5' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_6' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.3.CONTENT_7' | translate }}</p>-->

<!--  <h3 class="mt-5 mb-4">{{ 'LEGAL.ABOUT.SECTIONS.4.TITLE' | translate | uppercase}}</h3>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.4.CONTENT_1' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.4.CONTENT_2' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.4.CONTENT_3' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.4.CONTENT_4' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.4.CONTENT_5' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.4.CONTENT_6' | translate }}</p>-->

<!--  <h3 class="mt-5 mb-4">{{ 'LEGAL.ABOUT.SECTIONS.5.TITLE' | translate | uppercase}}</h3>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.5.CONTENT_1' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.5.CONTENT_2' | translate }}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.5.CONTENT_3' | translate }}</p>-->

<!--  <h3 class="mt-5 mb-4">{{ 'LEGAL.ABOUT.SECTIONS.6.TITLE' | translate | uppercase}}</h3>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.6.CONTENT_1' | translate }}</p>-->
<!--  <ul>-->
<!--    <li>{{ 'LEGAL.ABOUT.SECTIONS.6.CONTENT_2' | translate }}</li>-->
<!--    <li>{{ 'LEGAL.ABOUT.SECTIONS.6.CONTENT_3' | translate }}</li>-->
<!--    <li>{{ 'LEGAL.ABOUT.SECTIONS.6.CONTENT_4' | translate }}</li>-->
<!--  </ul>-->

<!--  <h3 class="mt-5 mb-4">{{ 'LEGAL.ABOUT.SECTIONS.7.TITLE' | translate | uppercase}}</h3>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.7.CONTENT_1' | translate | uppercase}}</p>-->
<!--  <p>{{ 'LEGAL.ABOUT.SECTIONS.7.CONTENT_2' | translate }}</p>-->
</section>
