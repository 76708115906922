<main class="container-xl pb-3 pb-lg-5">

  <!-- Promotions Slide-->
  <ng-container *ngIf="!user">
    <div *ngIf="showNewBanner" class="bg-darker rounded-2 text-center p-3 my-2">
      <div class="d-flex flex-column align-items-center">
        <img src="{{assetsPath}}/{{logoFileName}}" [alt]="logoAltText" style="width: 200px;"/>
        <h3 class="fw-bold">{{"HOME.NEW.TITLE" | translate}}</h3>
        <p class="">{{"HOME.NEW.TEXT" | translate}}</p>
        <button class="btn btn-primary" (click)="openLogin()">{{"HOME.NEW.BTN" | translate}}</button>
      </div>
    </div>
    <app-promo-slide></app-promo-slide>

  </ng-container>

  <div *ngIf="user">
   <app-recent-games></app-recent-games>
  </div>




  <section>
    <!-- searchbar-->
    <div class="my-4">
      <form class="input-group searchbar bg-darker">
        <span class="input-group-text" id="search-input">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </span>
        <input type="text" name="searchInput" class="form-control" [placeholder]=" 'HOME.SEARCHBAR_PLACEHOLDER' | translate" aria-label="searchbar" aria-describedby="search-input" [formControl]="searchTerm">
      </form>
    </div>

    <div id="category-nav" class="my-4">
      <div class="overflow-auto" id="pills-tab">
        <ul class="nav nav-pills flex-nowrap bg-darkest rounded-pill d-flex">
          <li class="nav-item" role="presentation" *ngFor="let category of displayedCategories; let i = index">
            <button class="nav-link rounded-pill d-flex flex-row text-nowrap py-2 ms-1" [class.active]="i == 0"
                    [id]="'pills-'+category.toLowerCase().split(' ').join('-')+'-tab'" data-bs-toggle="pill"
                    [attr.data-bs-target]="'#pills-'+category.toLowerCase().split(' ').join('-')" type="button" role="tab"
                     [attr.aria-controls]="'pills-'+category.toLowerCase().split(' ').join('-')" aria-selected="false" (click)="loadItems()" >
              <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
                <g [innerHTML]="getSanitizedSvgPath(category.toLowerCase().replace(' ', ''))"></g>
              </svg>
              <span>{{"CATEGORIES." + (category.split(' ').join('') | uppercase) | translate}}</span>
            </button>
          </li>
        </ul>
      </div>

      <!-- games-->
      <div class="tab-content" id="pills-tabContent">
        <ng-container *ngFor="let category of displayedCategories; let i = index">
          <div class="tab-pane fade show" [class.active]="i == 0"
               [id]="'pills-'+category.toLowerCase().split(' ').join('-')" role="tabpanel"
               [attr.aria-labelledby]="'pills-'+category.toLowerCase().split(' ').join('-')+'-tab'" tabindex="0">
            <div *ngIf="filteredGamesByCategory[i] && filteredGamesByCategory[i].length; else noGameBlock " class="pt-4">
              <div class="container-sm px-0 text-center" *ngFor="let chunk of (gamesToDisplayByCategory[i] | chunkify);">
                <div class="row g-2 mb-2">
                  <div class="col-12 col-md-6">
                    <div class="row g-2 mb-2">
                      <div class="col-8" *ngIf="chunk[0]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[0].enabled === false}" [src]="chunk[0].image" alt="{{chunk[0].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[0].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[0].category">{{chunk[0].category}}</div>
                          <div *ngIf="chunk[0].tag && chunk[0].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row g-2">
                          <div class="col-12" *ngIf="chunk[1]">
                            <div class="img-container clickable">
                              <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[1].enabled === false}" [src]="chunk[1].image" alt="{{chunk[1].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[1].id)" loading="lazy">
                              <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[1].category">{{chunk[1].category}}</div>
                              <div *ngIf="chunk[1].tag && chunk[1].tag.includes('beta')" class="ribbon">Beta</div>

                            </div>
                          </div>
                          <div class="col-12" *ngIf="chunk[2]">
                            <div class="img-container clickable">
                              <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[2].enabled === false}" [src]="chunk[2].image" alt="{{chunk[2].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[2].id)" loading="lazy">
                              <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[2].category">{{chunk[2].category}}</div>
                              <div *ngIf="chunk[2].tag && chunk[2].tag.includes('beta')" class="ribbon">Beta</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row g-2">
                      <div class="col-4" *ngIf="chunk[3]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[3].enabled === false}" [src]="chunk[3].image" alt="{{chunk[3].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[3].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[3].category">{{chunk[3].category}}</div>
                          <div *ngIf="chunk[3].tag && chunk[3].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                      <div class="col-4" *ngIf="chunk[4]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[4].enabled === false}" [src]="chunk[4].image" alt="{{chunk[4].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[4].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[4].category">{{chunk[4].category}}</div>
                          <div *ngIf="chunk[4].tag && chunk[4].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                      <div class="col-4" *ngIf="chunk[5]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[5].enabled === false}" [src]="chunk[5].image" alt="{{chunk[5].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[5].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[5].category">{{chunk[5].category}}</div>
                          <div *ngIf="chunk[5].tag && chunk[5].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="row g-2 mb-2">
                      <div class="col-4" *ngIf="chunk[6]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[6].enabled === false}" [src]="chunk[6].image" alt="{{chunk[6].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[6].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[6].category">{{chunk[6].category}}</div>
                          <div *ngIf="chunk[6].tag && chunk[6].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                      <div class="col-4" *ngIf="chunk[7]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[7].enabled === false}" [src]="chunk[7].image" alt="{{chunk[7].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[7].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[7].category">{{chunk[7].category}}</div>
                          <div *ngIf="chunk[7].tag && chunk[7].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                      <div class="col-4" *ngIf="chunk[8]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[8].enabled === false}" [src]="chunk[8].image" alt="{{chunk[8].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[8].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[8].category">{{chunk[8].category}}</div>
                          <div *ngIf="chunk[8].tag && chunk[8].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                    </div>
                    <div class="row g-2">
                      <div class="col-4">
                        <div class="row g-2">
                          <div class="col-12 " *ngIf="chunk[9]">
                            <div class="img-container clickable">
                              <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[9].enabled === false}" [src]="chunk[9].image" alt="{{chunk[9].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[9].id)" loading="lazy">
                              <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[9].category">{{chunk[9].category}}</div>
                              <div *ngIf="chunk[9].tag && chunk[9].tag.includes('beta')" class="ribbon">Beta</div>
                            </div>
                          </div>
                          <div class="col-12" *ngIf="chunk[10]">
                            <div class="img-container clickable">
                              <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[10].enable === false}" [src]="chunk[10].image" alt="{{chunk[10].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[10].id)" loading="lazy">
                              <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[10].category">{{chunk[10].category}}</div>
                              <div *ngIf="chunk[10].tag && chunk[10].tag.includes('beta')" class="ribbon">Beta</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8" *ngIf="chunk[11]">
                        <div class="img-container clickable">
                          <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[11].enable === false}" [src]="chunk[11].image" alt="{{chunk[11].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[11].id)" loading="lazy">
                          <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[11].category">{{chunk[11].category}}</div>
                          <div *ngIf="chunk[11].tag && chunk[11].tag.includes('beta')" class="ribbon">Beta</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center" *ngIf="category !== 'all'; else moreAll">
                <a *ngIf="filteredGamesByCategory[i].length" class="btn btn-outline-secondary rounded-pill mx-2" routerLink="/games/{{category.replace(' ', '')}}">
                 {{'HOME.ALL_LINK' | translate }} {{ gamesByDisplayedCategory[i].length }} {{ ('CATEGORIES.' + category.replace(" ", "").toUpperCase()) | translate }}
                </a>
              </div>

              <ng-template #moreAll>
                <app-item-showcase [totalItems]="filteredGamesByCategory[i].length" [itemsPerPage]="itemsPerPage" (itemsToLoad)="loadMore($event)"></app-item-showcase>
              </ng-template>

            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <ng-template #noGameBlock>
    <div class="container text-center pt-5">
      <div *ngIf="isLoading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <ng-container *ngIf="!isLoading && !games?.length">
        <h3>{{'HOME.NOGAMES_MESSAGE' | translate}}</h3>
        <h4>{{'HOME.NOGAMES_TIP' | translate}}</h4>
      </ng-container>
      <h3 *ngIf="!isLoading && searchTerm.value">{{'HOME.NOMATCH_MESSAGE' | translate}}</h3>
      <h4 *ngIf="searchTerm.value">{{'HOME.NOMATCH_TIP' | translate}}</h4>
    </div>
  </ng-template>


  <div class="mt-4">
    <app-carousel [dataList]="providersArray" [identifier]="'providerList'"></app-carousel>

    <div *ngIf="gamesByCategory.length">
      <app-carousel *ngFor="let category of randomGamesByCategoryForCarousels; let i = index;" [dataList]="category" [identifier]="categories[i].toLowerCase().replace(' ', '')"></app-carousel>
    </div>
  </div>

</main>






