<footer class="bg-darkest py-3 pt-lg-5">
  <section class="banner container">
    <div class="row">
      <div class="col">
        <div class="d-flex flex-column flex-wrap align-content-start align-content-sm-center">
          <h3 class="fs-6 text-uppercase">{{ 'FOOTER.CASINO_TITLE' | translate }}</h3>
          <ul class="list-unstyled fs-6">
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="">{{ 'CATEGORIES.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/about">{{ 'LEGAL.ABOUT.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/account/promotions">{{ 'PROMOTIONS.TITLE' | translate }}</a></li>
<!--            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/leaderboard">{{ 'LEADERBOARD.TITLE' | translate }}</a></li>-->
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="d-flex flex-column flex-wrap align-content-start align-content-sm-center">
          <h3 class="fs-6 text-uppercase">{{ 'FOOTER.SUPPORT_TITLE' | translate }}</h3>
          <ul class="list-unstyled fs-6">
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/responsible_gaming">{{ 'LEGAL.RESPONSIBLE.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/support">{{ 'LEGAL.SUPPORT.TITLE2' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/faq">{{ 'LEGAL.FAQ.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/bitcoin">{{ 'LEGAL.BITCOIN.TITLE' | translate }}</a></li>
          </ul>
        </div>
      </div>

      <div class="col">
        <div class="d-flex flex-column flex-wrap align-content-start align-content-sm-center">
          <h3 class="fs-6 text-uppercase">{{ 'FOOTER.LEGAL_TITLE' | translate }}</h3>
          <ul class="list-unstyled fs-6">
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/privacy">{{ 'LEGAL.PRIVACY.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/terms">{{ 'LEGAL.TERMS.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/aml">{{ 'LEGAL.AML.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/banking">{{ 'LEGAL.BANKING.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/cookie_policy">{{ 'LEGAL.COOKIE.TITLE' | translate }}</a></li>
            <li><a class="link-opacity-50 link-opacity-100-hover text-decoration-none" routerLink="/info/security">{{ 'LEGAL.SECURITY.TITLE' | translate }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="banner container py-3" *ngIf="providers$ | async as providers">
    <div class="row row-cols-auto justify-content-center align-items-center gy-2">
      <div class="col col-lg-2" *ngFor="let p of providers">
        <a routerLink="/providers/{{p.routerLink}}">
          <img [src]=p.imagePath class="emblem" loading="lazy" alt="{{p.name}} logo" style="width: 100px;">
        </a>
      </div>
    </div>
  </section>

  <section class="banner container">
    <div class="row row-cols-auto justify-content-center align-items-center p-3 gy-2">
      <div class="col mx-2">
        <img src="assets/emblems/18+.png" class="emblem" loading="lazy" alt="18+ logo" style="width: 40px;">
      </div>
      <div class="col mx-0">
        <img src="assets/emblems/resp_gambling.svg" class="emblem" loading="lazy" alt="responsible gambling logo" style="width: 160px;">
      </div>
      <div class="col mx-2">
        <img src="assets/emblems/visa_blue.png" class="emblem" loading="lazy" alt="visa logo" style="width: 100px;">
      </div>
      <div *ngIf="assetsSource.includes('pure')" class="col mx-2">
        <img src="assets/emblems/verified_by_visa.png" class="emblem" loading="lazy" alt="verified by visa logo" style="width: 100px;">
      </div>
      <div class="col mx-2">
        <img src="assets/emblems/ma_symbol_opt_73_3x.png" class="emblem" loading="lazy" alt="mastercard logo" style="width: 80px;">
      </div>
      <div *ngIf="assetsSource.includes('pure')" class="col mx-2">
        <img src="assets/emblems/master_card_secure_code.png" class="emblem" loading="lazy" alt="mastercard secure logo" style="width: 120px;">
      </div>
      <div class="col mx-2">
        <img src="assets/emblems/bitcoin.png" class="emblem" loading="lazy" alt="bitcoin logo" style="width: 140px;">
      </div>
      <div *ngIf="assetsSource.includes('pure')" class="col mx-2">
        <img src="assets/emblems/noda_identity_verification.png" class="emblem" loading="lazy" alt="noda identity verification logo" style="width: 140px;">
      </div>
      <div *ngIf="assetsSource.includes('pure')" class="col mx-2">
        <img src="assets/emblems/interac.png" class="emblem" loading="lazy" alt="interac logo" style="width: 100px;">
      </div>
    </div>
  </section>

  <section class="container p-3 pt-5">
    <div class="d-flex flex-column align-items-center">
<!--         <img src="{{assetsSource + (assetsSource.includes('slothorizon') || assetsSource.includes('jackpotnexus') ? '/logo-big.png' : '/logo.png') }}" alt="{{brandName}} logo" style="width: 300px;">-->
      <img src="{{assetsSource}}/{{logoFileName}}" [alt]="logoAltText" style="width: 300px;">
         <div class="p-3 text-secondary">&copy; {{year}} {{brandName}}
      </div>
    </div>
  </section>

<!--  pure footer license section-->
  <section *ngIf="assetsSource.includes('pure')" class="container p-3">
    <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
      <img class="mx-4 my-2" src="{{assetsSource}}/pan_de_bono.png" alt="Pan de Bono logo" style="width: 200px;">
      <a href='https://nam12.safelinks.protection.outlook.com/ap/b-59584e83/?url=https%3A%2F%2Fknoxxit2.sharepoint.com%2F%3Ab%3A%2Fs%2FCIL-Validations%2FEd3iHFgt3JdPu6e4rb_kQ2ABBde69HlDd9jMUHUoW1OLhQ%3Fe%3DR9Mhn8&data=05%7C02%7Cbryon.finies%40hbmgroup.com%7Cf73d721de4b64ab5b7c608dc3f0b53d9%7Cab6f126377d444e78de12c3ddcaa94ef%7C0%7C0%7C638454565086344840%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=UNg5XOyU8OZhHhQgPDppx8rNxQAEw%2FtsqoDUohoDu7U%3D&reserved=0' target="_blank" >
        <img class="mx-4 my-2" src="{{assetsSource}}/Curacao_Int_Lic.png" alt="Curacao licence logo" style="width: 120px;">
      </a>
    </div>
    <div class="p-3 text-primary text-center">
      <p class="mb-0">
        <span>{{'PURE.LICENSE_NAME' | translate: {name:licenseInfo?.pdb_name} }}</span>;
        <span>{{'PURE.LICENSE_ADDRESS' | translate: {address:licenseInfo?.pdb_address} }}</span>
      </p>
      <p class="mb-1">
        <span>{{'PURE.LICENSE_HOLDER' | translate: {name:licenseInfo?.lh_name} }}</span>;
        <span>{{'PURE.LICENSE_HOLDER_ADDRESS' | translate: {address:licenseInfo?.lh_address} }}</span>
      </p>
      <a class="link-primary link-opacity-100 fw-bold text-decoration-none" href="//www.truedynastyaffiliates.com/" title="Become a partner" target="blank">{{'PURE.LICENSE_LINK' | translate}}</a>
    </div>
  </section>

</footer>
