import { Component } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import {TitleService} from "../../../services/title.service";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-legal-section',
  templateUrl: './legal-section.component.html',
  styleUrls: ['./legal-section.component.scss']
})
export class LegalSectionComponent {
  selectedLegalSectionTitle!: string | null;
  routeSubs?: Subscription
  titleTranslateSubs?: Subscription

  constructor(
    private router: Router,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
    this.selectedLegalSectionTitle = this.router.url.split('/')[2].split("_")[0].toUpperCase();

    this.titleTranslateSubs?.unsubscribe();
    this.titleTranslateSubs = this.translate.stream("LEGAL." + this.selectedLegalSectionTitle + ".TITLE").subscribe(data => {
      this.titleService.setTitle(`Lobby / ${data}`);
    })

  }

  ngOnInit(){
    this.routeSubs = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.selectedLegalSectionTitle = event.url.split('/')[2].split("_")[0].toUpperCase();

      this.titleTranslateSubs?.unsubscribe();
      this.titleTranslateSubs = this.translate.stream("LEGAL." + this.selectedLegalSectionTitle + ".TITLE").subscribe(data => {
        this.titleService.setTitle(`Lobby / ${data}`);
      })

    });
  }

  ngOnDestroy(){
    this.routeSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();
  }
}
