import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  brandName:string;
  licenseN: string;

  legalSectionIndexes: any[] = [];
  legalSubs?: Subscription;

  constructor(
    private configs: ConfigService,
    private translate: TranslateService
  ) {
    this.brandName = this.configs.brandName;
    this.licenseN = this.configs.licenceNo;
    // get the content dynamically (!special elements, ex li, uppercase TODO style in html by index)
    this.translate.get('LEGAL.ABOUT.SECTIONS').subscribe(data => {
      if (Object.keys(data).length) {
        this.legalSectionIndexes = Array.from(Object.keys(data)).map(key => ({
          index: key,
          contentCount: Object.keys(data[key]).filter(k => k.startsWith('CONTENT_')).length
        }));
      }
      // console.log(this.legalSectionIndexes)
    });
  }

  ngOnDestroy(){
    this.legalSubs?.unsubscribe();
  }

}
