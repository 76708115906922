<div class="modal fade" id="signInModal" tabindex="-1" aria-labelledby="signInModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-6 ms-auto" id="signInModalLabel">{{ 'LOGIN.TITLE' | translate }}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeSignInButton></button>
      </div>
      <div class="modal-body">
        <form class="needs-validation" novalidate (ngSubmit)="submitLoginForm()" [formGroup]="loginForm">
          <div class="mb-4 d-flex flex-row justify-content-center">
            <input type="radio" class="btn-check" id="real" formControlName="mode" value="real">
            <label class="btn btn-outline-primary me-4" for="real">{{ 'LOGIN.REAL_MODE' | translate }}</label><br>

            <input type="radio" class="btn-check" id="fun" formControlName="mode" value="fun">
            <label class="btn btn-outline-primary" for="fun">{{ 'LOGIN.FUN_MODE' | translate }}</label><br>
          </div>
          <div class="mb-4">
            <label class="form-label mb-2" for="login">{{ 'LOGIN.LOGIN_LABEL' | translate }}</label>
            <input class="form-control" type="email" id="login" placeholder="{{ 'LOGIN.LOGIN_PLACEHOLDER' | translate }}" required formControlName="login"  autocomplete="login" [ngClass]="{'is-invalid': loginSubmit && logForm['login'].errors}">
            <div class="invalid-feedback">{{ 'LOGIN.LOGIN_ALERT' | translate }}</div>
          </div>
<!--          <div class="mb-4">-->
<!--            <div class="d-flex align-items-center justify-content-between mb-2">-->
<!--              <label class="form-label mb-0" for="siPassword">{{ 'LOGIN.PW_LABEL' | translate }}</label>-->
<!--            </div>-->
<!--            <div class="mb-4">-->
<!--              <input class="form-control" type="password" id="siPassword" placeholder="{{ 'LOGIN.PW_PLACEHOLDER' | translate }}" required formControlName="password" [ngClass]="{'is-invalid': loginSubmit && logForm['password'].errors}">-->
<!--              <div class="invalid-feedback">{{ 'LOGIN.PW_ALERT' | translate }}</div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="mb-4">
            <label class="form-label mb-2" for="password">{{ 'LOGIN.PW_LABEL' | translate }}</label>
            <div class="position-relative">
              <input class="form-control" [type]="fieldTextType ? 'text' : 'password'" id="password" required formControlName="password" autocomplete="password"
                     placeholder="{{ 'LOGIN.PW_PLACEHOLDER' | translate }}"
                     [ngClass]="{'is-invalid': loginSubmit && logForm['password'].errors}">
              <label class="password-toggle-btn" [ngClass]="{'invalid-toggle-btn': loginSubmit && logForm['password'].errors}" aria-label="Show/hide password">
                <input class="password-toggle-check" type="checkbox">
                <span class="password-toggle-indicator" [ngClass]="{'opacity-50': !fieldTextType, 'opacity-1': fieldTextType}" (click)="toggleFieldTextType()">
            <svg class="eye-off" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g><path d="M9.25,4.5A8.87,8.87,0,0,0,1,10.125a8.862,8.862,0,0,0,16.5,0A8.87,8.87,0,0,0,9.25,4.5Zm0,9.375A3.75,3.75,0,1,1,13,10.125,3.751,3.751,0,0,1,9.25,13.875Zm0-6a2.25,2.25,0,1,0,2.25,2.25A2.247,2.247,0,0,0,9.25,7.875Z"></path></g></svg>
          </span>
              </label>
              <div class="invalid-feedback">{{ 'LOGIN.PW_ALERT' | translate }}</div>
            </div>
          </div>

          <button *ngIf="!isLoading, else loadingSpinner"  class="btn btn-success btn-lg w-100 mb-4" type="submit" [disabled]="loginForm.invalid">
            <span >{{ 'LOGIN.TITLE' | translate }}</span>
          </button>
          <ng-template #loadingSpinner>
          <div *ngIf="isLoading" class="d-flex justify-content-center mb-4">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </ng-template>


        </form>
        <div class="text-center">
<!--          <a class="text-decoration-none" routerLink="/info/support" data-bs-dismiss="modal">{{ 'LOGIN.FORGOTTEN_LINK' | translate }}</a> on GPbrands-->
<!--          <a class="text-decoration-none" routerLink="/resetPassword" data-bs-dismiss="modal">{{ 'LOGIN.FORGOTTEN_LINK' | translate }}</a> oldRivalversion-->
          <a class="text-decoration-none" data-bs-target="#resetPwModal" data-bs-toggle="modal" href="#resetPwModal">{{ 'LOGIN.FORGOTTEN_LINK' | translate }}</a>
          <div class="mt-2">
           <p>{{ 'LOGIN.REG_LABEL' | translate }}
             <a class="text-decoration-none ms-2" (click)="onRegModalOpened()" data-bs-target="#signUpModal" data-bs-toggle="modal" href="#signUpModal">{{ 'REGISTER.BTN' | translate }}</a>
            </p>
          </div>
        </div>
        <!--        for social media login -->
        <!--        <div class="d-flex align-items-center py-3 mb-3">-->
        <!--              <hr class="w-100">-->
        <!--              <div class="px-3">Or</div>-->
        <!--              <hr class="w-100">-->
        <!--        </div>-->
      </div>

    </div>
  </div>
</div>
