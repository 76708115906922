import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-item-showcase',
  templateUrl: './item-showcase.component.html',
  styleUrls: ['./item-showcase.component.scss']
})
export class ItemShowcaseComponent implements OnChanges {

  @Input() itemsPerPage!: number;
  @Input() totalItems!: number;
  @Input() resetFlag?: boolean;

  @Output() itemsToLoad = new EventEmitter<number>();

  itemsToShow!: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['itemsPerPage'] || changes['resetFlag'] ){
    // itemsPerPage -> init in parent home (and anywhere else)
    // resetFlag -> change (init, filter or sort) happened in parent selectedCategory/selectedProvider component
      this.itemsToShow = this.itemsPerPage;
    }
  }


  loadItems(displaySelector: string) {
    if ( displaySelector === 'less'){
      this.itemsToShow = this.itemsPerPage;
    } else if (displaySelector === 'more'){
      this.itemsToShow += this.itemsPerPage;
    } else {
      //show all games
      this.itemsToShow = this.totalItems;
    }

    // console.log(this.itemsToShow)
    this.itemsToLoad.emit(this.itemsToShow);

  }

}
