import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserModel} from "../../models/user.model";
import { Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {Subscription} from "rxjs";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {TrackingHelperService} from "../../services/tracking-helper.service";
import {ConfigService} from "../../services/config.service";

declare var bootstrap: any;




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  brandName;
  assetsSource;
  logoFileName;
  logoAltText;
  isRival;

  user!: UserModel | null;
  userSubs: Subscription;


  @Output() sidebarToggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() sidebarToggled: boolean = false;


  constructor(
    private configs: ConfigService,
    private userService: UserService,
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private trackingService: TrackingHelperService
  ) {
    this.isRival = this.configs.isRivalCasino;
    this.brandName = this.configs.brandName;
    this.assetsSource = this.configs.assetsPath;
    this.logoFileName = this.configs.logoName;
    this.logoAltText = this.configs.logoAltText;

    this.userSubs = this.userService.userObject.subscribe( userData => {
      this.user = userData;
    })

  }

  ngOnInit(){
    //for landings - toggle registration in instantplay routes when they click on old landings
    const currentUrl = this.router.url;
    if(currentUrl.includes('instantplay') || currentUrl.includes('register') || currentUrl.includes('x=registration')){
      this.toggleSignUpModal();
    }

  }


  goToCashier(){
    this.router.navigate(['account', 'cashier'])
  }


  toggleSidebar(){
    this.sidebarToggled = !this.sidebarToggled;
    this.sidebarToggleEvent.emit(this.sidebarToggled);
  }



  toggleSignInModal(): void {
    //deactivate Bootstrap modals preventing text input fields focus
    bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };

    const signInModal = new bootstrap.Modal(document.getElementById('signInModal'), {})
    signInModal.show()
  }


  toggleSignUpModal(): void {
    //deactivate Bootstrap modals preventing text input fields focus
    bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };

    const signUpModal = new bootstrap.Modal(document.getElementById('signUpModal'), {})
    signUpModal.show()

    //send event to GA
    this.gaService.event('register_page_loaded', undefined, undefined, undefined, undefined,{play_mode: "real", ...this.trackingService.getAffiliateOptions() });
  }

  ngOnDestroy(){
    this.userSubs?.unsubscribe();
  }


}

