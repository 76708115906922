<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-4">{{ 'LEGAL.TERMS.TITLE' | translate }}</h1>

  <!--  Introduction -->
  <h3 class="h5 fw-bold mb-3">{{ 'LEGAL.TERMS.BASIC.INTRO.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.TERMS.BASIC.INTRO.CONTENT_1' | translate }}</p>
  <p class="text-darker">{{ 'LEGAL.TERMS.BASIC.INTRO.CONTENT_2' | translate }}</p>

  <div *ngIf="isAffTermsNeeded">
    <p class="text-darker">{{'LEGAL.TERMS.AFF.INTRO.CONTENT_3' | translate }}</p>
    <p class="text-darker">{{'LEGAL.TERMS.AFF.INTRO.CONTENT_4' | translate }}</p>
    <p class="text-darker">{{'LEGAL.TERMS.AFF.INTRO.CONTENT_5' | translate: {brandName} }}</p>
  </div>

  <div *ngIf="brandName.toLowerCase().includes('pure')">
    <p class="text-darker">
      <span>{{'PURE.LICENSE_NAME' | translate: {name:licenseInfo?.pdb_name} }}</span>;
      <span>{{'PURE.LICENSE_ADDRESS' | translate: {address:licenseInfo?.pdb_address} }}</span>
    </p>
    <p class="text-darker">
      <span>{{'PURE.LICENSE_HOLDER' | translate: {name:licenseInfo?.lh_name} }}</span>;
      <span>{{'PURE.LICENSE_HOLDER_ADDRESS' | translate: {address:licenseInfo?.lh_address} }}</span>
    </p>
  </div>

  <!--    Pure governing law section -->
  <div class="accordion mb-2" *ngIf="brandName.toLowerCase().includes('pure')">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGovLaw" aria-expanded="false" aria-controls="collapseGovLaw">
          <span class="fs-5 fw-bold">{{ 'PURE.TERMS.GOV_LAW.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseGovLaw" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'PURE.TERMS.GOV_LAW.P1' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <!--    Definitions -->
  <div class="accordion mb-2">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T1' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P1' | translate}}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T2' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P2' | translate:{brandName}  }}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T3' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P3' | translate }}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T4' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P4' | translate }}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T5' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P5' | translate }}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T6' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P6' | translate }}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T7' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P7' | translate }}</p>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.T8' | translate}}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DEFINITIONS.P8' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <!--    Disclaimer -->
  <div class="accordion mb-2" *ngIf="!isAffTermsNeeded">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDisclaimer" aria-expanded="false" aria-controls="collapseDisclaimer">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.DISCLAIMER.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseDisclaimer" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.BASIC.DISCLAIMER.CONTENT_1' | translate:{brandName}  }}</p>
          <ol>
            <li>{{ 'LEGAL.TERMS.BASIC.DISCLAIMER.LI_1' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.DISCLAIMER.LI_2' | translate }}</li>
          </ol>
          <p>{{ 'LEGAL.TERMS.BASIC.DISCLAIMER.CONTENT_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.DISCLAIMER.CONTENT_3' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isAffTermsNeeded">
    <!-- Website Content -->
    <div class="accordion mb-2" >
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWebsiteContent" aria-expanded="false" aria-controls="collapseWebsiteContent">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.AFF.WEBSITE_CONTENT.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapseWebsiteContent" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.TERMS.AFF.WEBSITE_CONTENT.CONTENT_1' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.WEBSITE_CONTENT.CONTENT_2' | translate  }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Player Obligation -->
    <div class="accordion mb-2" >
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlayerObligation" aria-expanded="false" aria-controls="collapsePlayerObligation">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapsePlayerObligation" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_1' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_2' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_3' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_4' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_5' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_6' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_7' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_8' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_9' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_10' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_11' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.PLAYER_OBLIGATIONS.CONTENT_12' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Accounts and passwords -->
    <div class="accordion mb-2" >
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAccounts" aria-expanded="false" aria-controls="collapseAccounts">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.AFF.ACCOUNTS_PASSWORDS.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapseAccounts" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.TERMS.AFF.ACCOUNTS_PASSWORDS.CONTENT_1' | translate: {brandName} }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.ACCOUNTS_PASSWORDS.CONTENT_2' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.ACCOUNTS_PASSWORDS.CONTENT_3' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.ACCOUNTS_PASSWORDS.CONTENT_4' | translate  }}</p>
            <p>{{ 'LEGAL.TERMS.AFF.ACCOUNTS_PASSWORDS.CONTENT_5' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="!isAffTermsNeeded">
    <!--    General Promotion Terms-->
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeneralPromoTerms" aria-expanded="false" aria-controls="collapseGeneralPromoTerms">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapseGeneralPromoTerms" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_1' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_2' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_3' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_4' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_5' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_6' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_7' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_8' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_9' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_10' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_11' | translate:{brandName}  }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_12' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_13' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_14' | translate }}</p>
            <p>{{ 'LEGAL.TERMS.BASIC.GENERAL_PROMO_TERMS.CONTENT_15' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <!--    Violations -->
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseViolations" aria-expanded="false" aria-controls="collapseViolations">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapseViolations" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>1. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_1' | translate }}</p>
            <ul>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_1' | translate: {brandName} }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_2' | translate: {brandName} }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_3' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_4' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_5' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_6' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_7' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_8' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_9' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_10' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_11' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_12' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_13' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_14' | translate }}</li>
            </ul>
            <p>2. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_2' | translate: {brandName} }}</p>
            <p>3. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_3' | translate: {brandName} }}</p>
            <p>4. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!--    General Terms-->
  <div class="accordion mb-2">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeneralTerms" aria-expanded="false" aria-controls="collapseGeneralTerms">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.GENERAL.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseGeneralTerms" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_0' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_1' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_3' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_4' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_5' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_6' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_7' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.GENERAL.CONTENT_8' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <!--    Promo and Bonus Terms-->
  <div class="accordion mb-2">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePromoBonusTerms" aria-expanded="false" aria-controls="collapsePromoBonusTerms">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapsePromoBonusTerms" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_1' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_3' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_4' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_5' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_5_1' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_6' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_7' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_8' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_9' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_10' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_11' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_12' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PROMO_TERMS.CONTENT_13' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <!--    Withdrawal Terms-->
  <div class="accordion mb-2">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWithdrawal" aria-expanded="false" aria-controls="collapseWithdrawal">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseWithdrawal" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_1' | translate }}</p>
          <p *ngIf="!withdrawalTime">{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_2' | translate }}</p>
          <p *ngIf="withdrawalTime">{{ 'LEGAL.TERMS.AFF.WITHDRAWAL_TERMS.CONTENT_2' | translate: {withdrawalTime} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_3' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_4' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_5' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_6' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_7' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_8' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_9_1' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_9_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_10' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_11' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_12' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.WITHDRAWAL_TERMS.CONTENT_13' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Prizes and Winnings -->
  <div class="accordion mb-2" *ngIf="isAffTermsNeeded">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrizesWinnings" aria-expanded="false" aria-controls="collapsePrizesWinnings">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapsePrizesWinnings" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_1' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_2' | translate: {withdrawalTime} }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_3' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_4' | translate  }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_5' | translate:{brandName}  }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_6' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_7' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_8' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_9' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_10' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_11' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.AFF.PRIZES_WINNINGS.CONTENT_12' | translate }}</p>
        </div>
      </div>
    </div>
  </div>


  <!--    Privacy-->
  <div class="accordion mb-2" *ngIf="!isAffTermsNeeded">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrivacy" aria-expanded="false" aria-controls="collapsePrivacy">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.PRIVACY.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapsePrivacy" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p class="fw-bold">{{ 'LEGAL.TERMS.BASIC.PRIVACY.SUBTITLE' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_1' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_3' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_4' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_5' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_6' | translate: {brandName} }}</p>
          <p class="fw-bold">{{ 'LEGAL.TERMS.BASIC.PRIVACY.SUBTITLE_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_7' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_8' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_9' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_10' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_11' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_12' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_13' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.PRIVACY.CONTENT_14' | translate: {brandName} }}</p>
        </div>
      </div>
    </div>
  </div>

  <!--  Refund Policy-->
  <div class="accordion mb-2">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRefund" aria-expanded="false" aria-controls="collapseRefund">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.REFUND.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseRefund" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_1' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_2' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_3' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_4' | translate }}</p>
          <ul>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_1' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_2' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_3' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_4' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_5' | translate }}</li>
          </ul>
          <p class="mb-0 fw-bold">{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_5' | translate }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_6' | translate }}</p>
          <ul>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_6' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_7' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_8' | translate }}</li>
          </ul>
          <p class="fw-bold">{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_7' | translate }}</p>
          <ul>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_9' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_10' | translate }}</li>
            <li>{{ 'LEGAL.TERMS.BASIC.REFUND.LI_11' | translate }}</li>
          </ul>
          <p>{{ 'LEGAL.TERMS.BASIC.REFUND.CONTENT_8' | translate }}</p>

        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="isAffTermsNeeded">
    <!-- Privacy on affiliated casinos -->
    <div class="accordion mb-2" >
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAffPrivacy" aria-expanded="false" aria-controls="collapseAffPrivacy">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.AFF.PRIVACY.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapseAffPrivacy" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.TERMS.AFF.PRIVACY.CONTENT_1' | translate:{brandName}  }}</p>
            <ul>
              <li>{{ 'LEGAL.TERMS.AFF.PRIVACY.LI_1' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.PRIVACY.LI_2' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.PRIVACY.LI_3' | translate }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Misuse of Account -->
    <div class="accordion mb-2" >
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMisuse" aria-expanded="false" aria-controls="collapseMisuse">
            <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.AFF.MISUSE.TITLE' | translate | uppercase}}</span>
          </button>
        </h2>
        <div id="collapseMisuse" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>1. {{ 'LEGAL.TERMS.AFF.MISUSE.CONTENT_1' | translate }}</p>
            <ul>
              <li>{{ 'LEGAL.TERMS.AFF.MISUSE.LI_1' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.MISUSE.LI_2' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.MISUSE.LI_3' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.MISUSE.LI_4' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.MISUSE.LI_5' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.AFF.MISUSE.LI_6' | translate }}</li>
            </ul>
            <p>2. {{ 'LEGAL.TERMS.AFF.MISUSE.CONTENT_2' | translate }}</p>
            <p>3. {{ 'LEGAL.TERMS.AFF.MISUSE.CONTENT_3' | translate }}</p>
            <!-- violation section-->
            <p>4. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_1' | translate }}</p>
            <ul>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_1' | translate: {brandName} }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_2' | translate: {brandName} }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_3' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_4' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_5' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_6' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_7' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_8' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_9' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_10' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_11' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_12' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_13' | translate }}</li>
              <li>{{ 'LEGAL.TERMS.BASIC.VIOLATIONS.LI_14' | translate }}</li>
            </ul>
            <p>5. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_2' | translate: {brandName} }}</p>
            <p>6. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_3' | translate: {brandName} }}</p>
            <p>7. {{ 'LEGAL.TERMS.BASIC.VIOLATIONS.CONTENT_4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <!--  Refer-a-friend-->
  <div class="accordion mb-2">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRefer" aria-expanded="false" aria-controls="collapseRefer">
          <span class="fs-5 fw-bold">{{ 'LEGAL.TERMS.BASIC.REFER.TITLE' | translate | uppercase}}</span>
        </button>
      </h2>
      <div id="collapseRefer" class="accordion-collapse collapse">
        <div class="accordion-body text-darker">
          <p>{{ 'LEGAL.TERMS.BASIC.REFER.CONTENT_1' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFER.CONTENT_2' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFER.CONTENT_3' | translate: {brandName} }}</p>
          <p>{{ 'LEGAL.TERMS.BASIC.REFER.CONTENT_4' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

<!--Closure-->
  <p class="mt-4 text-darker">{{ 'LEGAL.TERMS.BASIC.CLOSURE' | translate }}</p>


</section>
