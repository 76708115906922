import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ProviderModel} from "../../../models/provider.model";
import {GameService} from "../../../services/game.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Game, Games} from "../../../models/game.class";
import {Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {TitleService} from "../../../services/title.service";


@Component({
  selector: 'app-selected-provider',
  templateUrl: './selected-provider.component.html',
  styleUrls: ['./selected-provider.component.scss'],
})
export class SelectedProviderComponent implements OnInit, OnDestroy {
  categories: string[];
  svgPaths: { [key: string]: string };
  readonly itemsPerPage = 24;

  chosenProvider!: string | null;
  provider?: ProviderModel;
  providersArray: ProviderModel[];
  providerSubs?: Subscription;
  routeSubs?: Subscription;

  games?: Games;
  isLoading: boolean = true;

  gamesByCategory:Game[][] = []
  activeCategories: string[] = [];
  gamesByActiveCategory: Game[][] = [];
  filteredGamesByCategory: Game[][] = []
  displayedGamesByCategory: Game[][] = []

  searchTerm = new FormControl('');
  searchSubs?: Subscription;
  @ViewChildren('tab') tabs!: QueryList<ElementRef>

  selectedSorting: string;
  originalUnsortedGamesByCategory: Game[][] = []
  resetBySortOrFilterNeeded: boolean = false; //the change of value marks to itemsShowCase child component that initial value needed (init, filter or sort)

  titleTranslateSubs?: Subscription;



  constructor(
    private gameService: GameService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
    this.categories = this.gameService.categories;
    this.svgPaths = this.gameService.svgPaths;
    this.providersArray = this.gameService.providers;
    this.selectedSorting = "POPULAR";
  }

  ngOnInit(): void {
    this.routeSubs?.unsubscribe();
    this.routeSubs = this.activatedRoute.params.subscribe(params => {
      this.chosenProvider = params['provider'].replace('_','');

      // Resetting filters on route change
      this.isLoading = true;
      this.searchTerm.setValue('');
      this.selectedSorting = "POPULAR";
      if (this.tabs && this.tabs.length) {
        this.tabs.first.nativeElement.click();
      }

      if (this.chosenProvider){
        this.providerSubs?.unsubscribe();
        this.providerSubs = this.gameService.providers$.subscribe(providers => {
          this.provider = providers.find(p=>p.routerLink === this.chosenProvider);
          if (this.provider){
            this.providersArray = this.gameService.providers.filter(p=> p.gameList && p.gameList.length);

            //setting page title
            this.titleTranslateSubs?.unsubscribe();
            this.titleTranslateSubs = this.translate.stream('PROVIDERS.GAMES_TITLE').subscribe(data => {
              this.titleService.setTitle(`Lobby / ${data} ${this.provider?.name}`);
            })

            this.games = Games.fromGameArray(this.provider.gameList);
            // console.log(this.games)


            //grouping games into categories
            if (this.games && this.games.length) {
              this.gamesByCategory = this.gameService.categorizeGames(this.games);
              //show only categories that have games
              this.activeCategories = [];
              this.gamesByActiveCategory = [];
              this.categories.forEach((category, index) => {
                const categoryGames = this.gamesByCategory[index];
                if (categoryGames && categoryGames.length > 0) {
                  this.activeCategories.push(category);
                  this.gamesByActiveCategory.push(categoryGames);
                }
              });

              this.loadItems(undefined);
              this.isLoading = false;
            } else if (this.games) {
              //we could not fetch games in service
              this.isLoading = false;
            }
          }
        })
      }
    });

    this.searchSubs?.unsubscribe();
    this.searchSubs = this.searchTerm.valueChanges.subscribe(newValue => {
      this.loadItems(undefined)
    });
  }



  loadItems(itemsToShow: number | undefined) {
    //when itemsToShow is undefined, loadItems is called from parent, so initial itemsToShow value needed
    if (!itemsToShow){
      itemsToShow = this.itemsPerPage
    }
    if (itemsToShow === this.itemsPerPage){
      this.resetBySortOrFilterNeeded = !this.resetBySortOrFilterNeeded;
    }

    this.filterGames();
    // console.log(this.filteredGamesByCategory)

    this.originalUnsortedGamesByCategory = JSON.parse(JSON.stringify(this.filteredGamesByCategory));
    let sortedGamesByCategory = this.sortGames(this.selectedSorting);

    this.displayedGamesByCategory = sortedGamesByCategory.map(categorizedGames => {
      return categorizedGames.slice(0, itemsToShow);
    })

  }

  filterGames() {
    //filter by searchTerm, if any
    if (!this.searchTerm.value || this.searchTerm.value.trim() === '') {
      this.filteredGamesByCategory = JSON.parse(JSON.stringify(this.gamesByActiveCategory));
    }
    else {
      this.filteredGamesByCategory = this.gamesByActiveCategory.map(categoryGames => {
        return categoryGames.filter(game => game.name.toLowerCase().includes((this.searchTerm.value as string).toLowerCase()));
      });
    }
  }

  sortOptionSelected(value: string){
    this.selectedSorting = value;
    this.loadItems(undefined)
  }

  sortGames(value: string){
      // Clone the filtered games by category
      const clonedFilteredGames: Game[][] = this.filteredGamesByCategory;

      // Helper function to sort games by the selected option
      const sortByOption = (games: Game[], option: string) => {
        switch(option) {
          case 'AZ':
            return games.sort((a, b) => a.name.localeCompare(b.name));
          case 'ZA':
            return games.sort((a, b) => b.name.localeCompare(a.name));
          case 'FEATURED':
            return games.sort((a, b) => b.features.length - a.features.length);
          default:
            return games;
        }
      };

      // Process each category of games
      return clonedFilteredGames.map(categoryGames => {
        // Separate enabled and disabled games
        const enabledGames = categoryGames.filter(game => game.enabled);
        const disabledGames = categoryGames.filter(game => !game.enabled);

        // Sort enabled and disabled games separately based on the chosen option
        const sortedEnabledGames = sortByOption(enabledGames, value);
        const sortedDisabledGames = sortByOption(disabledGames, value);

        // Combine enabled games first, then disabled games
        return [...sortedEnabledGames, ...sortedDisabledGames];
      });

  }


  updateNotFoundImgUrl(event: Event) {
    (event.target as HTMLImageElement).src = `https://placehold.co/512x512/3b3b3b/F0F0F0/png?text=${(event.target as HTMLImageElement).alt}`;
  }

  launchGame(id: number): void {
    if(this.games){
      let chosenGame = this.games.all.find(game => game.id === id);
      if (chosenGame && chosenGame.enabled !== false){
        if(chosenGame.launchUrl){
          this.router.navigate(['play', chosenGame.id])
        } else {
          this.router.navigate(['details', chosenGame.id])
        }
      }
    }
  }


  getSanitizedSvgPath(category: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.svgPaths[category]);
  }

  ngOnDestroy() {
    this.providerSubs?.unsubscribe();
    this.searchSubs?.unsubscribe();
    this.routeSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();
  }


}
