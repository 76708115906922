import {Component, ElementRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {RegdataModel} from "../../../models/registration/regdata.model";
import {RegistrationService} from "../../../services/registration.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-reg-modal',
  templateUrl: './reg-modal.component.html',
  styleUrls: ['./reg-modal.component.scss']
})
export class RegModalComponent {

  currentStep = 1;
  stepSubs: Subscription;

  @ViewChild('closeSignUpButton') closeSignUpButton!: ElementRef;



  constructor(
    private regService: RegistrationService
  ) {
    this.stepSubs = this.regService.regStep.subscribe(step =>{
      this.currentStep = step
      if (this.currentStep === 5){
        //means that registration is done
        this.closeSignUpButton.nativeElement.click();
        this.regService.resetRegSession();
        this.currentStep = 1;
      }
    })
  }



  onCloseRegModal(){
    //when we click on close button, we loose filled in data - except from first step(that's stored in the form)
    this.regService.resetRegSession();


    // const myModalEl = document.getElementById('signUpModal')
    // if (myModalEl){
    //   myModalEl.addEventListener('hidden.bs.modal', event => {
    //     // reset form and regData if we close modal by clicking on btn
    //     if (this.currentStep === 1){
    //       // this.regService.resetRegSession();
    //     }
    //   })
    // }

  }

  ngOnDestroy(){
    this.stepSubs?.unsubscribe();
  }

}
