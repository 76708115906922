import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {catchError, map, of} from "rxjs";
import {UserService} from "../services/user.service";


declare var bootstrap: any;


export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.userObject.value !== null) {
    return true;
  }

  const token = localStorage.getItem('sessionToken');
  if (token) {
    return userService.getUserInfo().pipe(
      catchError((error) => {
        router.navigate(['']);

        //deactivate Bootstrap modals preventing text input fields focus
        bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };
        signInModal.show();

        return of(false);
      }),
      map(() => true)

    );
  }

  router.navigate(['']);

  //deactivate Bootstrap modals preventing text input fields focus
  bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };
  const signInModal = new bootstrap.Modal(document.getElementById('signInModal'), {})
  signInModal.show()

  return false;
};
