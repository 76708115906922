import {Component, ElementRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent {


  resetForm: UntypedFormGroup;
  resetSubmit: boolean = false;
  isLoading:boolean = false;

  @ViewChild('closeResetPwButton') closeResetPwButton!: ElementRef;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  submitResetForm(){
    this.resetSubmit = true;
    if (this.resetForm.valid){
      this.isLoading = true;

      this.authService.resetPassword(this.resetForm.value).subscribe({
        next: ( response ) => {
          this.isLoading = false;

          //close modal and resets form
          this.closeResetPwButton.nativeElement.click();

          this.toastr.success(`${this.translate.instant('RESET_PW.SUCCESS_MESSAGE')}`, this.translate.instant('RESET_PW.SUCCESS_H1'), {toastClass: 'ngx-toastr yourclass'})
        },
        error: (err) => {
          this.isLoading = false;
          // console.error(err)
          if (this.resetForm.controls['email']) {
            const formControlWithError = this.resetForm.get('email');
            if (formControlWithError){
              let errorMessage = `${this.translate.instant('RESET_PW.ERROR_MESSAGE')}`
              if(err.error.data){
                errorMessage = err.error.data['email'].message;
              }
              formControlWithError.setErrors({validationErrorFromServer: errorMessage})
            }
          }
        }
      })
    }
  }

  onCloseModal(){
    this.resetForm.reset();
    this.resetSubmit = false;
  }


}
