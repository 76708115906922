import { Component } from '@angular/core';
import {PromotionModel} from "../../../models/promotion.model";
import {PromotionService} from "../../../services/promotion.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {TitleService} from "../../../services/title.service";
import {UserModel} from "../../../models/user.model";
import {UserService} from "../../../services/user.service";
import {ConfigService} from "../../../services/config.service";

declare var bootstrap: any;


@Component({
  selector: 'app-promo-details',
  templateUrl: './promo-details.component.html',
  styleUrls: ['./promo-details.component.scss']
})
export class PromoDetailsComponent {

  brandName;
  titleTranslateSubs?: Subscription;

  promoIdentifier?: string | null;
  routeSubs?: Subscription;

  //static promo for non-logged in user
  promo?: PromotionModel;
  detailsSubs?: Subscription;
  promoDetailIndexes: number[] = [];

  user?: UserModel | null;
  userSubs?: Subscription;

  constructor(
    private configs: ConfigService,
    private promoService: PromotionService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private translate: TranslateService
  ) {
    this.brandName = this.configs.brandName;
    this.routeSubs = this.activatedRoute.paramMap.subscribe({
      next: (param) => {
        if (param.get('name')){
          this.promoIdentifier = param.get('name');
        }
      }
    })

    if(this.promoIdentifier){
      this.userSubs?.unsubscribe();
      this.userSubs = this.userService.userObject.subscribe(u=>{
        this.user = u;
        //if we have user, then they should see their promotions
        if (this.user){
          this.router.navigate(['account', 'promotions'])
        } else {
        //if not then we need the static promo chosen on promo listing site
          this.promo = this.promoService.staticPromotions.find(p => p.name === this.promoIdentifier)
          if (!this.promo) {
            this.router.navigate(["notFound"])
          }
        }
      })
    } else {
      this.router.navigate(["notFound"])
    }


  }


  ngOnInit(): void {
    // Get the translated promo details from the JSON file for dynamic translations
    if (this.promo){
      this.detailsSubs?.unsubscribe();
      this.detailsSubs = this.translate.get(`PROMOTIONS.${this.promo.id}_DETAILS`).subscribe( data => {
         if (data.length){
           this.promoDetailIndexes = Array.from(data.keys())
         }
      });

      this.titleTranslateSubs?.unsubscribe();
      this.titleTranslateSubs = this.translate.stream(["PROMOTIONS.TITLE", `PROMOTIONS.${this.promo.id}_NAME`]).subscribe(data => {
        let promoTitle = data['PROMOTIONS.TITLE'];
        let promoName = data[`PROMOTIONS.${this.promo!.id}_NAME`];
        this.titleService.setTitle(`Lobby / ${promoTitle} - ${promoName}`);
      })

    }
  }

  goToCashier(){
    if (this.user){
        this.router.navigate(['account', 'promotions'])
    } else {
      //needs login
      //deactivate Bootstrap modals preventing text input fields focus
      bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };
      const signInModal = new bootstrap.Modal(document.getElementById('signInModal'), {})
      signInModal.show()
    }

  }

  ngOnDestroy(){
    this.detailsSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();
    this.routeSubs?.unsubscribe();
    this.userSubs?.unsubscribe();
  }





}
