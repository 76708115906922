import {Component} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Subscription} from "rxjs";
import {RegdataModel} from "../../../../models/registration/regdata.model";
import {RegistrationService} from "../../../../services/registration.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {DetailsMapModel} from "../../../../models/registration/detailsMap.model";

@Component({
  selector: 'app-reg-step3',
  templateUrl: './reg-step3.component.html',
  styleUrls: ['./reg-step3.component.scss']
})
export class RegStep3Component {

  /** step 3 for security pw, question-answer
     - validation on form todo more precisely
     - ?? would be great to have username here - for that we have to change username in admin to optional and/or
     make a new call to step1 (create => having new regToken => sending all previous data again to validate (regData) - this is a longer task so moved it later
    - make server call to addDetails
  */

  regStep3Form!: UntypedFormGroup;
  regSubmit: boolean = false;
  regSubs?: Subscription;
  regObject?: RegdataModel | null;

  fieldTextType!: boolean;

  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private regService: RegistrationService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.regStep3Form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), this.noSpaceValidator]],
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.regSubs?.unsubscribe();
    this.regSubs = this.regService.regData.subscribe(regData => {
      this.regObject = regData
      // console.log(regData)

      if (regData) {
        this.regStep3Form.patchValue({
          question: regData.details.question ?? "",
          answer: regData.details.answer ?? ""
          // password: regData.details.password - no because we want to have the illusion that we dont store it
        })
      }
    })
  }

  goToPrevStep() {
    //save data in BS and go back to step 2
    const regData: RegdataModel = {
      email: this.regObject!.email,
      username: this.regObject!.username,
      locale: this.regObject!.locale,
      details: {
        ...this.regObject!.details,
        question: this.regStep3Form.value.question,
        answer: this.regStep3Form.value.answer,
        // password: this.regStep3Form.value.password - we are not saving for security illusion reasons
      }
    }
    // console.log(regData);
    this.regService.saveData(regData);
    this.regService.setStep(2);
  }


  submitRegForm() {
    this.regSubmit = true;
    if (this.regStep3Form.valid) {

      const regStep2Data: DetailsMapModel = {
          password: this.regStep3Form.value.password,
          question: this.regStep3Form.value.question,
          answer: this.regStep3Form.value.answer
      }
      // console.log(regData)

      this.isLoading = true;

      this.regService.addDetails(regStep2Data).subscribe({
        next: (response) => {
          // console.log(response); //result is empty
          //if OK -> save data to RegData BS, navigate to step 3

          const regData: RegdataModel = {
            email: this.regObject!.email,
            username: this.regObject!.username,
            locale: this.regObject!.locale,
            details: {
              ...this.regObject!.details,
              password: this.regStep3Form.value.password,
              question: this.regStep3Form.value.question,
              answer: this.regStep3Form.value.answer
            }
          }

          this.regStep3Form.reset();
          this.regSubmit = false;
          this.isLoading = false;

          this.regService.saveData(regData)
          this.regService.setStep(4)
        },
        error: (err) => {
          //error handling, validation errors from addDetails method
          this.isLoading = false;

          //invalid token
          if (err.error.message === 'Could not authorize request.' || err.status === 401){
            this.toastr.error(`${this.translate.instant('REGISTER.TOASTR_TOKEN_ERROR_MESSAGE')}`, this.translate.instant('REGISTER.TOASTR_TOKEN_ERROR_H1'), {toastClass: 'ngx-toastr yourclass'})
          }

          // todo: currently only for password but the API should check other fields as well (or us?)
          if (err.error && err.error.data){
            for (const key in err.error.data) {
              if (err.error.data.hasOwnProperty(key)) {
                let propName = key.split(".")[1]
                //set invalid fields invalid
                if (this.regStep3Form.controls[propName]) {
                  const formControlWithError = this.regStep3Form.get(propName);
                  if (formControlWithError) {
                    formControlWithError.setErrors({validationErrorFromServer: err.error.data[key].message})
                  }
                }
                //warn user about the error
                this.toastr.error(`${err.error.data[key].message}`, `${err.error.message}`, {toastClass: 'ngx-toastr yourclass'})
              }
            }
          }

          //todo handle other errors ex.network error

        }
      })

    }
  }

  get regForm() {
    return this.regStep3Form.controls;
  }

  noSpaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const hasSpace = control.value ? control.value.includes(" ") : false;
    return hasSpace ? {noSpace: true} : null;
  }


  //Hide/show password
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  ngOnDestroy() {
    this.regSubs?.unsubscribe();
  }


}

