<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.FAQ.TITLE2' | translate }}</h1>

  <!--  Introduction-->
  <p class="my-4 text-darker">{{ 'LEGAL.FAQ.INTRO' | translate }} <a class="link-primary link-underline-opacity-0 " routerLink="/info/support">{{ 'LEGAL.SUPPORT.LINK_TITLE' | translate }}</a> <span>{{ 'LEGAL.FAQ.INTRO_END' | translate }}</span> </p>

  <!--  Registration -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.REGISTRATION.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReg1" aria-expanded="false" aria-controls="collapseReg1">
            {{ 'LEGAL.FAQ.REGISTRATION.Q1' | translate | uppercase }}
          </button>
        </h2>
        <div id="collapseReg1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.REGISTRATION.A1' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReg2" aria-expanded="false" aria-controls="collapseReg2">
            {{ 'LEGAL.FAQ.REGISTRATION.Q2' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseReg2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.REGISTRATION.A2' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReg3" aria-expanded="false" aria-controls="collapseReg3">
            {{ 'LEGAL.FAQ.REGISTRATION.Q3' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseReg3" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.REGISTRATION.A3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReg4" aria-expanded="false" aria-controls="collapseReg4">
            {{ 'LEGAL.FAQ.REGISTRATION.Q4' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseReg4" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.REGISTRATION.A4' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!--  Connection -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.CONNECTION.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseConnect1" aria-expanded="false" aria-controls="collapseConnect1">
            {{ 'LEGAL.FAQ.CONNECTION.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseConnect1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.CONNECTION.A1' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseConnect2" aria-expanded="false" aria-controls="collapseConnect2">
            {{ 'LEGAL.FAQ.CONNECTION.Q2' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseConnect2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.CONNECTION.A2' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseConnect3" aria-expanded="false" aria-controls="collapseConnect3">
            {{ 'LEGAL.FAQ.CONNECTION.Q3' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseConnect3" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.CONNECTION.A3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseConnect4" aria-expanded="false" aria-controls="collapseConnect4">
            {{ 'LEGAL.FAQ.CONNECTION.Q4' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseConnect4" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.CONNECTION.A4' | translate:{brandName} }}</p>
            <ol>
              <li>{{ 'LEGAL.FAQ.CONNECTION.A4_LI1' | translate}}</li>
              <li>{{ 'LEGAL.FAQ.CONNECTION.A4_LI2' | translate}}</li>
              <li>{{ 'LEGAL.FAQ.CONNECTION.A4_LI3' | translate}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!-- Transactions -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.TRANSACTIONS.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTrans1" aria-expanded="false" aria-controls="collapseTrans1">
            {{ 'LEGAL.FAQ.TRANSACTIONS.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseTrans1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.TRANSACTIONS.A1' | translate: {brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTrans2" aria-expanded="false" aria-controls="collapseTrans2">
            {{ 'LEGAL.FAQ.TRANSACTIONS.Q2' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseTrans2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.TRANSACTIONS.A2' | translate: {brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTrans3" aria-expanded="false" aria-controls="collapseTrans3">
            {{ 'LEGAL.FAQ.TRANSACTIONS.Q3' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseTrans3" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p *ngIf="!withdrawalTime">{{ 'LEGAL.FAQ.TRANSACTIONS.A3_BASE' | translate}}</p>
            <p *ngIf="withdrawalTime">{{ 'LEGAL.FAQ.TRANSACTIONS.A3_AFF' | translate: {withdrawalTime} }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTrans4" aria-expanded="false" aria-controls="collapseTrans4">
            {{ 'LEGAL.FAQ.TRANSACTIONS.Q4' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseTrans4" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.TRANSACTIONS.A4' | translate}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTrans5" aria-expanded="false" aria-controls="collapseTrans5">
            {{ 'LEGAL.FAQ.TRANSACTIONS.Q5' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseTrans5" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.TRANSACTIONS.A5' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!--  Play -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.PLAY.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlay1" aria-expanded="false" aria-controls="collapsePlay1">
            {{ 'LEGAL.FAQ.PLAY.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePlay1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PLAY.A1' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlay2" aria-expanded="false" aria-controls="collapsePlay2">
            {{ 'LEGAL.FAQ.PLAY.Q2' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePlay2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PLAY.A2' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlay3" aria-expanded="false" aria-controls="collapsePlay3">
            {{ 'LEGAL.FAQ.PLAY.Q3' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePlay3" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PLAY.A3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlay4" aria-expanded="false" aria-controls="collapsePlay4">
            {{ 'LEGAL.FAQ.PLAY.Q4' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePlay4" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PLAY.A4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlay5" aria-expanded="false" aria-controls="collapsePlay5">
            {{ 'LEGAL.FAQ.PLAY.Q5' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePlay5" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PLAY.A5' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!--  Security -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.SECURITY.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecu1" aria-expanded="false" aria-controls="collapseSecu1">
            {{ 'LEGAL.FAQ.SECURITY.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseSecu1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.SECURITY.A1' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecu2" aria-expanded="false" aria-controls="collapseSecu2">
            {{ 'LEGAL.FAQ.SECURITY.Q2' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseSecu2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.SECURITY.A2_1' | translate:{brandName} }}</p>
            <p>{{ 'LEGAL.FAQ.SECURITY.A2_2' | translate:{brandName} }}</p>
            <p>{{ 'LEGAL.FAQ.SECURITY.A2_3' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecu3" aria-expanded="false" aria-controls="collapseSecu3">
            {{ 'LEGAL.FAQ.SECURITY.Q3' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseSecu3" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.SECURITY.A3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecu4" aria-expanded="false" aria-controls="collapseSecu4">
            {{ 'LEGAL.FAQ.SECURITY.Q4' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseSecu4" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.SECURITY.A4' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecu5" aria-expanded="false" aria-controls="collapseSecu5">
            {{ 'LEGAL.FAQ.SECURITY.Q5' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseSecu5" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.SECURITY.A5' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!--  Support -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.SUPPORT.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupport1" aria-expanded="false" aria-controls="collapseSupport1">
            {{ 'LEGAL.FAQ.SUPPORT.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseSupport1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.SUPPORT.A1' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!--  Legal -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.LEGAL.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLegal1" aria-expanded="false" aria-controls="collapseLegal1">
            {{ 'LEGAL.FAQ.LEGAL.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapseLegal1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.LEGAL.A1' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLegal2" aria-expanded="false" aria-controls="collapseLegal2">
            {{ 'LEGAL.FAQ.LEGAL.Q2' | translate:{brandName} | uppercase}}
          </button>
        </h2>
        <div id="collapseLegal2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.LEGAL.A2' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <!--  Promotion -->
  <article>
    <h3 class="my-4 h5 fw-bold">{{ 'LEGAL.FAQ.PROMO.TITLE' | translate | uppercase}}</h3>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePromo1" aria-expanded="false" aria-controls="collapsePromo1">
            {{ 'LEGAL.FAQ.PROMO.Q1' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePromo1" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PROMO.A1' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePromo2" aria-expanded="false" aria-controls="collapsePromo2">
            {{ 'LEGAL.FAQ.PROMO.Q2' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePromo2" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PROMO.A2' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion mb-2">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePromo3" aria-expanded="false" aria-controls="collapsePromo3">
            {{ 'LEGAL.FAQ.PROMO.Q3' | translate | uppercase}}
          </button>
        </h2>
        <div id="collapsePromo3" class="accordion-collapse collapse">
          <div class="accordion-body text-darker">
            <p>{{ 'LEGAL.FAQ.PROMO.A3' | translate:{brandName} }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>


</section>
