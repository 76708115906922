import {ComponentRef, Inject, Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {
  GoogleAnalyticsInitializer, GoogleAnalyticsRouterInitializer,
  GoogleAnalyticsService, GtagFn, IGoogleAnalyticsCommand, IGoogleAnalyticsRoutingSettings,
  IGoogleAnalyticsSettings, NGX_GOOGLE_ANALYTICS_ROUTING_SETTINGS_TOKEN,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN
} from "ngx-google-analytics";
import { initializeFaro } from '@grafana/faro-web-sdk';
import {DOCUMENT} from "@angular/common";
import {ConfigService} from "./config.service";


@Injectable({
  providedIn: 'root'
})
export class TrackingHelperService {

  private appComponentRef: ComponentRef<any> | null = null;

  tracker:string | undefined;
  btag: string | undefined;

  constructor(
    private configs: ConfigService,
    private cookieService: CookieService,
    private gaService: GoogleAnalyticsService,
    @Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN) private googleAnalyticsSettingsToken: IGoogleAnalyticsSettings,
    @Inject(NGX_GOOGLE_ANALYTICS_ROUTING_SETTINGS_TOKEN) private googleAnalyticsRoutingSettingsToken: IGoogleAnalyticsRoutingSettings,
    @Inject(NGX_GTAG_FN) private googleTagFunction: GtagFn,
    @Inject(DOCUMENT) private document: Document
  ) {
     // this was working since the first scroll but not for the first page_view (with basic setup in app.module.ts)
     // this.gaService.gtag('set', {...this.getAffiliateOptions()});


    //TODO something new from Ash - i need the collectors url + some type changes were needed in chat.service component's intervals
      // const faro = initializeFaro({
    //   url: 'https://collector-host:12345/collect',
    //   apiKey: 'secret',
    //   app: {
    //     name: 'frontend',
    //     version: '1.0.0',
    //   },
    // });

  }

  public setAppComponentRef(ref: ComponentRef<any>): void {
    this.appComponentRef = ref;
  }

  public initializeGoogleAnalytics(): void {
    if (this.appComponentRef) {

      const affiliateOptions = this.getAffiliateOptions();

      this.googleAnalyticsSettingsToken.initCommands = [
        {
          command: 'js',
          values: [new Date()]
        },
        {
          command: 'set',
          values: [affiliateOptions]
        },
        {
          command: 'config',
          values: [this.googleAnalyticsSettingsToken.trackingCode]
        }
      ];


      GoogleAnalyticsInitializer(this.googleAnalyticsSettingsToken, this.googleTagFunction, this.document)()
        .then(() => {
          if (this.appComponentRef) {
            GoogleAnalyticsRouterInitializer(this.googleAnalyticsRoutingSettingsToken, this.gaService)(this.appComponentRef);
          }
        })
        .catch((error: any) => {
          console.error('Error initializing Google Analytics', error);
        });
    }
  }


  setCookiesFromQueryParams(): void {
    const urlParams = new URLSearchParams(window.location.search);
    // btag=cx_35514_416668

    const strictCheck = (varName: string): boolean =>
      urlParams.has(varName) && urlParams.get(varName)?.trim() !== "";

    if (strictCheck("t")) {
      const paramValue = urlParams.get("t");
      if (paramValue !== null) {
        this.cookieService.set("tra_tracker", paramValue)
      }
    }
    if (strictCheck("id")) {
      const paramValue = urlParams.get("id");
      if (paramValue !== null) {
        this.cookieService.set("tra_tracker", "00000_" + paramValue, {path: "/", expires: 31536000} )
      }
    }
    if (strictCheck("tracker")) {
      const paramValue = urlParams.get("tracker");
      if (paramValue !== null) {
        this.cookieService.set("tra_tracker", paramValue, {path: "/", expires: 31536000} )
      }
    }
    if (strictCheck("btag")) {
      const paramValue = urlParams.get("btag");
      if (paramValue !== null) {
        this.cookieService.set("tra_btag", paramValue)
      }
    }

  }


  trackEventFromMessage(e: MessageEvent): void {
      switch (e.data?.action) {
        //embedded iframe messages from Rival
        case 'USER_UPDATE_BALANCE':
          //from rival games in iframes + also from keepAlive(balance)
          this.gaService.event('balance', undefined, undefined, undefined, undefined,{ user_balance: e.data?.balance, ...this.getAffiliateOptions() });
          break;

        case "GAMES_START_GAME":
          //in rival iframes when its loaded
          this.gaService.event('play', undefined, undefined, undefined, undefined,{ game_id: e.data?.id, ...this.getAffiliateOptions() });
          break;

        default:
          break;
      }
      //other events are sent in components
  }


  getTracker(): string | undefined {
    //TODO fix this tracker thing in db/with rival/ with marketing/google analytics
    //max 127 char long, domain at the very end
    // const domain = window.location.hostname;
    // const domainLength = domain.length;
    // const trackerInCookie = this.cookieService.get('tra_tracker');
    // if (trackerInCookie){
    //   if ((trackerInCookie.length + 1 + domainLength) > 127){
    //     let trimmedTracker = trackerInCookie.slice(0,(127-1-domainLength))
    //     this.tracker = `${trimmedTracker}_${domain}`
    //   } else {
    //     this.tracker = `${trackerInCookie}_${domain}`;
    //   }
    // } else {
    //   this.tracker = `00000_${domain}`
    // }

    //until marketing stuff get fixed in db and will be able to use domain
    //max 127 char long
    // const trackerInCookie = this.cookieService.get('tra_tracker');
    // if (trackerInCookie){
    //   if ((trackerInCookie.length) > 127){
    //     this.tracker = trackerInCookie.slice(0,(127))
    //   } else {
    //     this.tracker = trackerInCookie;
    //   }
    // } else {
    //   //no tracker that means its us
    //   this.tracker = '00000'
    // }

    //safety solution currently active:
    this.tracker = this.cookieService.get('tra_tracker');
    return this.tracker;
  }

  getBannerTag(): string | undefined {
    this.btag = this.cookieService.get('tra_btag');
    return this.btag;
  }

  getAffiliate(){
    const tracker = this.getTracker();
    return tracker ? tracker.split("_")[0] : undefined
  }

  getAffiliateOptions(){
    const affiliate = this.getAffiliate();
    return affiliate ? {campaign_id: affiliate, affiliate_id: affiliate} : {}
  }


}

