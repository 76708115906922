import {CanActivateFn, Router} from '@angular/router';
import {environment} from "../../environments/environment";
import {inject} from "@angular/core";

export const affiliateGuard: CanActivateFn = (route, state) => {

  const isAffiliatedBrand = environment.isAffiliated;
  const router = inject(Router);

  if(isAffiliatedBrand){
    return true;
  }

    router.navigate(['not-found']);

    return false;

};
