import { Component } from '@angular/core';
import {UserModel} from "../../models/user.model";
import {Subscription} from "rxjs";
import {PromotionModel} from "../../models/promotion.model";
import {UserService} from "../../services/user.service";
import {PromotionService} from "../../services/promotion.service";
import {TitleService} from "../../services/title.service";
import {TranslateService} from "@ngx-translate/core";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent {
  brandName:string;
  assetsSource:string;

  user!: UserModel | null;
  userSubs?: Subscription;
  publicPromotions: PromotionModel[] = [];
  userPromotions: PromotionModel[] = [];

  isLoading = true;
  promoSubs?: Subscription;
  titleTranslateSubs?: Subscription;


  constructor(
    private configs: ConfigService,
    private userService: UserService,
    private promoService: PromotionService,
    private titleService: TitleService,
    private translate: TranslateService
  ) {
    this.brandName = this.configs.brandName;
    this.assetsSource = this.configs.assetsPath;

  //todo: maybe clean code of html
    this.titleTranslateSubs?.unsubscribe();
    this.titleTranslateSubs = this.translate.stream("PROMOTIONS.TITLE").subscribe(data => {
      this.titleService.setTitle(`Lobby / ${data}`);
    })

    this.publicPromotions = this.promoService.staticPromotions.filter(p=>p.state === 'eligible');

    this.userSubs = this.userService.userObject.subscribe((u: UserModel | null) => {
      this.user = u;

      this.promoSubs?.unsubscribe();

      if (this.user) {
        this.promoSubs = this.promoService.getUserPromos().subscribe({
          next: data => {
            this.isLoading = false;
            this.userPromotions = data.result.promotions.filter(p=>p.state === 'eligible');
          },
          error: err => {
            this.isLoading = false;
          }
        })
      } else {
        this.isLoading = false;
      }

    })
  }


  ngOnDestroy(): void {
    this.userSubs?.unsubscribe();
    this.promoSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();

  }

}
