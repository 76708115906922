<form class="needs-validation" novalidate (ngSubmit)="submitRegForm()" [formGroup]="regStep4Form">

  <div class="terms-container">
    <div class="terms-scrollbox border p-3">
      <app-terms></app-terms>
    </div>
  </div>

  <div class="my-4">
    <div class="form-check my-3">
      <input class="form-check-input" type="checkbox" id="agree-to-terms" formControlName="agreement" [ngClass]="{'is-invalid': regSubmit && regForm['agreement'].errors}" required>
      <label class="form-check-label" for="agree-to-terms">{{ 'REGISTER.TERMS_LABEL' | translate }}
        <a class="text-decoration-none ms-1" routerLink="/info/terms" data-bs-dismiss="modal">{{ 'REGISTER.TERMS_LINK' | translate }}</a></label>
    </div>
  </div>
  <div class="row" *ngIf="!isLoading; else loadingSpinner">
    <div class="col">
      <button class="btn btn-outline-success btn-lg w-100" type="button" (click)="goToPrevStep()">{{ 'REGISTER.PREV_LABEL' | translate }}</button>
    </div>
    <div class="col">
      <button class="btn btn-success btn-lg w-100" type="submit">{{ 'REGISTER.FINALIZE_BTN' | translate }}</button>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</form>




