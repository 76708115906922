<section class="p-2 p-md-3">

  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.BANKING.TITLE' | translate }}</h1>

  <!--  Introduction-->
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.INTRO' | translate:{brandName} }} </p>

  <!--  Deposits -->
  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BANKING.DEPOSITS.TITLE' | translate | uppercase}}</h3>

  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.DEPOSITS.P1' | translate: {brandName}  }}</p>

  <p class="my-3 text-darker">
    <span>{{ 'LEGAL.BANKING.DEPOSITS.P2' | translate }}</span>
    <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }} </a>
    <span>{{ 'LEGAL.BANKING.DEPOSITS.P2_LINK_END' | translate }}</span>
  </p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.DEPOSITS.P3' | translate }}</p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.DEPOSITS.P4' | translate: {brandName} }}</p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.DEPOSITS.P5' | translate }}</p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.DEPOSITS.P6' | translate: {brandName} }}</p>


  <!--  Withdrawals-->
  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BANKING.WITHDRAWALS.TITLE' | translate | uppercase}}</h3>

  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.P1' | translate: {brandName}  }}</p>

  <p class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.P2' | translate }}</p>
  <ul>
    <li class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.LI1' | translate }} </li>
    <li class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.LI2' | translate }} </li>
    <li class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.LI3' | translate }} </li>
    <li class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.LI4' | translate }} <a class="link-primary link-underline-opacity-0" style="cursor: pointer;" (click)="downloadFile()">PDF</a></li>
  </ul>
  <p class="mt-3 mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.P3' | translate }}</p>

  <ul>
    <li class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.LI1' | translate }} </li>
    <li class="mb-2 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.LI2' | translate }} </li>
  </ul>

  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.P4' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a> {{  'LEGAL.BANKING.WITHDRAWALS.P4_LINK_END' | translate }}</p>

  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.WITHDRAWALS.P5' | translate }}</p>

<!--  Fraud prevention-->
  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BANKING.FRAUD.TITLE' | translate | uppercase}}</h3>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.FRAUD.P1' | translate }}</p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.FRAUD.P2' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a> {{  'LEGAL.BANKING.FRAUD.P2_LINK_END' | translate }} </p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.FRAUD.P3' | translate }}</p>
  <p class="my-3 text-darker">{{ 'LEGAL.BANKING.FRAUD.P4' | translate }}</p>

</section>


<ng-container #hiddenContainer></ng-container>
