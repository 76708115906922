<app-header (sidebarToggleEvent)="onSidebarToggle($event)" [sidebarToggled]="sidebarToggled"></app-header>
<!--this was the previous version of the header and sidebar - not used on our brands -->
<div *ngIf="isRival" class="main-section" [ngClass]="{'pushed-down': !token}" style="margin-top: 56px;">
  <div class="collapse shadow bg-darker" [ngClass]="{'show sticky-sidebar': sidebarToggled, 'with-token': token}">
    <app-sidebar style="overflow-y: auto;" (closeSidebarEvent)="onSidebarToggle(false)"></app-sidebar>
  </div>
  <div [ngClass]="{'sticky-main': sidebarToggled}">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>

<div *ngIf="!isRival">
  <div class="collapse shadow bg-darker" [ngClass]="{'show sticky-sidebar': sidebarToggled, 'with-token': token}" id="sidebar-wrapper">
    <app-sidebar style="overflow-y: auto;" (closeSidebarEvent)="onSidebarToggle(false)"></app-sidebar>
  </div>

  <div [ngClass]="{'sticky-main': sidebarToggled, 'with-token': token}" id="main-wrapper">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
