import { Component } from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import {GameModel} from "../../models/game.model";
import {GameService} from "../../services/game.service";
import {Subscription} from "rxjs";
import {GameStatService} from "../../services/game-stat.service";


@Component({
  selector: 'app-recent-games',
  templateUrl: './recent-games.component.html',
  styleUrls: ['./recent-games.component.scss']
})
export class RecentGamesComponent {

  recentGames: GameModel[] = [];
  recommendedGames: GameModel[] = [];
  recommendedSubs?: Subscription;

  constructor(
    private gameService: GameService,
    private gameStatService: GameStatService
  ) {
    this.gameService.getGames().subscribe(games=>{
      if (games && games.length){
        this.recentGames = this.gameService.getRecentGames(games);

        // this.recommendedGames = this.gameService.getRecommendedGames(games); //works

        // this.recommendedSubs?.unsubscribe();
        // this.recommendedSubs = this.gameService.getRecommendedGames(games).subscribe(data => {
        //    this.recommendedGames = data;
        // });

        // TODO
        this.gameService.getMostPlayedGames(games)
        this.recommendedGames = this.gameService.mostPlayedGames


        // this.recommendedSubs = this.gameStatService.getMostPlayedGamesJson().subscribe(data => {
        //   // console.log(data)
        //   let result: GameModel[] = [];
        //   const recentGameIds = localStorage.getItem('recentGames');
        //   if (recentGameIds){
        //     const gameIdArray = recentGameIds.split(",")
        //
        //     for (let i = 0; i < gameIdArray.length; i++) {
        //       const gameId = +gameIdArray[i];
        //       console.log(gameId)
        //       const gamesForGameById = data[gameId]; // Retrieve data from JSON
        //
        //       if (gamesForGameById) {
        //         // console.log(gamesForGameById)
        //         for (let j = 0; j < gamesForGameById.length; j++) {
        //           const game = games.find(g => g.id == gamesForGameById[j]);
        //           if (game && !result.includes(game) && !recentGameIds.includes(game.id.toString())) {
        //             result.push(game);
        //           }
        //         }
        //       }
        //     }
        //   console.log(result)
        //     // If less than 16 results, fill with exclusive games
        //   if (result.length < 16) {
        //       const instanetGames: GameModel[] = this.gameService.pickRandomGames(this.gameService.getGamesOfValidCategory('exclusive', games),16);
        //       for (let i = 0; i < instanetGames.length; i++) {
        //         if (!result.includes(instanetGames[i]) && !recentGameIds.includes(instanetGames[i].id.toString()) && result.length < 16) {
        //           result.push(instanetGames[i]);
        //         }
        //       }
        //   }
        //
        //   this.recommendedGames = result;
        //
        //   }
        //
        // });

      }
    })
  }

  ngOnDestroy(){
    this.recommendedSubs?.unsubscribe();
  }



}
