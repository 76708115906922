import { Component } from '@angular/core';
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-aml',
  templateUrl: './aml.component.html',
  styleUrls: ['./aml.component.scss']
})
export class AmlComponent {
  brandName: string;

  constructor(
    private configs: ConfigService,
  ) {
    this.brandName = this.configs.brandName;
  }

}
