import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {XmasWinnerModel} from "../models/xmasWinner.model";

@Injectable({
  providedIn: 'root'
})
export class XmasWinnersService {

  private jsonUrl = 'assets/landingPages/xmas/calendar/winners.json'; // Adjust the path as needed


  constructor(
    private http: HttpClient
  ) { }

  getWinners(): Observable<XmasWinnerModel> {
    return this.http.get<XmasWinnerModel>(this.jsonUrl);
  }
}
