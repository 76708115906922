import { Component } from '@angular/core';
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent {
  brandName: string;

  constructor(
    private configs: ConfigService,
  ) {
    this.brandName = this.configs.brandName;
  }
}
