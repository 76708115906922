import { Component } from '@angular/core';
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  constructor(
      private titleService: TitleService
  ) {
    this.titleService.resetTitle();
  }

}
