import { Component } from '@angular/core';
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  brandName: string;
  withdrawalTime: number | null;

  constructor(
    private configs: ConfigService,
  ) {
    this.brandName = this.configs.brandName;
    this.withdrawalTime = this.configs.withdrawalTime;
  }

}
