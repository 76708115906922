<section id="promos" #container>
  <!-- own-carousel version -->
  <owl-carousel-o [options]="customOptions" #owlCarousel>
    <ng-container *ngFor="let p of staticPromos; let i = index;">
      <ng-template carouselSlide>
        <div class="d-flex bg-darkest p-3 me-2 h-100">
          <div class="row w-100">
            <div class="col-8">
              <div class="h-100 d-flex flex-column justify-content-between">
                <div class="">
                  <span class="badge bg-lighter mb-2">{{ (p.details.includes('welcome') ? 'PROMOTIONS.WELCOME' : 'PROMOTIONS.WEEKLY') | translate }}</span>
                </div>
                <div>
                  <h3 class="fw-bold mb-0">{{ ('PROMOTIONS.' + p.id + '_NAME') | translate }}</h3>
                  <a owlRouterLink="/promotions/{{p.name}}">{{ 'PROMOTIONS.MORE_INFO_LINK' | translate}}</a>
                </div>
                <div>
                  <a class="btn btn-outline-secondary" type="button" routerLink="{{user ? '/account/promotions' : '/promotions'}}">{{ 'PROMOTIONS.GET_BTN' | translate }}</a>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="h-100 d-flex align-items-center">
                <img src="{{ p.imgPath ?? 'assets/icons/promotions.svg' }}" alt="promo image" style="height: auto;" loading="lazy">
              </div>
            </div>
          </div>

        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>

<!-- todo fix resizing of the owl-carousel: by a new one infinite scrolling, but not responsive yet, should implement mediaqueries-->
<!--<main class="vw-100 d-flex flex-row justify-content-center align-items-center">-->
<!--  <div class="logos overflow-hidden pt-0 pb-2 px-0 position-relative" style="white-space: nowrap;">-->
<!--    <div class="logos-slide d-inline-block">-->
<!--      &lt;!&ndash; Use *ngFor to loop through card data &ndash;&gt;-->
<!--      <div class="card d-inline-block" *ngFor="let card of staticPromos">-->
<!--        <div class="d-flex bg-darkest p-3 me-2 h-100">-->
<!--          <div class="row w-100">-->
<!--            <div class="col-8">-->
<!--              <div class="h-100 d-flex flex-column justify-content-between">-->
<!--                <div>-->
<!--                  <span class="badge bg-lighter mb-2">{{ (card.details.includes('welcome') ? 'PROMOTIONS.WELCOME' : 'PROMOTIONS.WEEKLY') | translate }}</span>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h3 class="fw-bold mb-0">{{ ('PROMOTIONS.' + card.id + '_NAME') | translate }}</h3>-->
<!--                  <a owlRouterLink="/promotions/{{card.name}}">{{ 'PROMOTIONS.MORE_INFO_LINK' | translate }}</a>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <a class="btn btn-outline-secondary" type="button" routerLink="{{user ? '/account/promotions' : '/promotions'}}">{{ 'PROMOTIONS.GET_BTN' | translate }}</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-4">-->
<!--              <div class="h-100 d-flex align-items-center">-->
<!--                <img src="{{ card.imgPath ?? 'assets/icons/promotions.svg' }}" alt="promo image">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card d-inline-block" *ngFor="let card of staticPromos">-->
<!--        <div class="d-flex bg-darkest p-3 me-2 h-100">-->
<!--          <div class="row w-100">-->
<!--            <div class="col-8">-->
<!--              <div class="h-100 d-flex flex-column justify-content-between">-->
<!--                <div>-->
<!--                  <span class="badge bg-lighter mb-2">{{ (card.details.includes('welcome') ? 'PROMOTIONS.WELCOME' : 'PROMOTIONS.WEEKLY') | translate }}</span>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h3 class="fw-bold mb-0">{{ ('PROMOTIONS.' + card.id + '_NAME') | translate }}</h3>-->
<!--                  <a owlRouterLink="/promotions/{{card.name}}">{{ 'PROMOTIONS.MORE_INFO_LINK' | translate }}</a>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <a class="btn btn-outline-secondary" type="button" routerLink="{{user ? '/account/promotions' : '/promotions'}}">{{ 'PROMOTIONS.GET_BTN' | translate }}</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-4">-->
<!--              <div class="h-100 d-flex align-items-center">-->
<!--                <img src="{{ card.imgPath ?? 'assets/icons/promotions.svg' }}" alt="promo image">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</main>-->

<!--same from this:-->
<!--https://codepen.io/ocxigin/pen/ExrpdVz-->
<!--https://codepen.io/ocxigin/pen/NWoBeGN-->


<!--other sliders-->
<!--https://github.com/rcbyr/keen-slider-->
<!--https://github.com/kenwheeler/slick-->
<!--https://github.com/nolimits4web/swiper-->

<!--https://codingyaar.com/bootstrap-carousel-multiple-items-increment-by-1/-->





