//currency data
const currencyData = [
  {code: "USD", name: "US Dollar"},
  {code: "EUR", name: "Euro"},
  {code: "GBP", name: "British Pounds"},
  {code: "ZAR", name: "South African Rand"},
  {code: "AUD", name: "Australian Dollar"},
  {code: "NZD", name: "New Zealand Dollar"},
]


//country data
const countryData = [
  //from RIVAL - id is a Rival id
    {
      "id": 1,
      "code": "AF",
      "name": "Afghanistan"
    },
    {
      "id": 2,
      "code": "AL",
      "name": "Albania"
    },
    {
      "id": 3,
      "code": "DZ",
      "name": "Algeria"
    },
    {
      "id": 4,
      "code": "AS",
      "name": "American Samoa"
    },
    {
      "id": 5,
      "code": "AD",
      "name": "Andorra"
    },
    {
      "id": 6,
      "code": "AO",
      "name": "Angola"
    },
    {
      "id": 7,
      "code": "AI",
      "name": "Anguilla"
    },
    {
      "id": 8,
      "code": "AG",
      "name": "Antigua and Barbuda"
    },
    {
      "id": 9,
      "code": "AR",
      "name": "Argentina"
    },
    {
      "id": 10,
      "code": "AM",
      "name": "Armenia"
    },
    {
      "id": 11,
      "code": "AW",
      "name": "Aruba"
    },
    {
      "id": 12,
      "code": "AU",
      "name": "Australia"
    },
    {
      "id": 13,
      "code": "AT",
      "name": "Austria"
    },
    {
      "id": 14,
      "code": "AZ",
      "name": "Azerbaijan"
    },
    {
      "id": 15,
      "code": "BS",
      "name": "Bahamas"
    },
    {
      "id": 16,
      "code": "BH",
      "name": "Bahrain"
    },
    {
      "id": 17,
      "code": "BD",
      "name": "Bangladesh"
    },
    {
      "id": 18,
      "code": "BB",
      "name": "Barbados"
    },
    {
      "id": 19,
      "code": "BY",
      "name": "Belarus"
    },
    {
      "id": 20,
      "code": "BE",
      "name": "Belgium"
    },
    {
      "id": 21,
      "code": "BZ",
      "name": "Belize"
    },
    {
      "id": 22,
      "code": "BJ",
      "name": "Benin"
    },
    {
      "id": 23,
      "code": "BM",
      "name": "Bermuda"
    },
    {
      "id": 24,
      "code": "BT",
      "name": "Bhutan"
    },
    {
      "id": 25,
      "code": "BO",
      "name": "Bolivia"
    },
    {
      "id": 26,
      "code": "BA",
      "name": "Bosnia and Herzegovina"
    },
    {
      "id": 27,
      "code": "BW",
      "name": "Botswana"
    },
    {
      "id": 28,
      "code": "BR",
      "name": "Brazil"
    },
    {
      "id": 29,
      "code": "VG",
      "name": "British Virgin Islands"
    },
    {
      "id": 30,
      "code": "BN",
      "name": "Brunei"
    },
    {
      "id": 31,
      "code": "BG",
      "name": "Bulgaria"
    },
    {
      "id": 32,
      "code": "BF",
      "name": "Burkina Faso"
    },
    {
      "id": 33,
      "code": "MM",
      "name": "Burma (Myanmar)"
    },
    {
      "id": 34,
      "code": "BI",
      "name": "Burundi"
    },
    {
      "id": 35,
      "code": "KH",
      "name": "Cambodia"
    },
    {
      "id": 36,
      "code": "CM",
      "name": "Cameroon"
    },
    {
      "id": 37,
      "code": "CA",
      "name": "Canada"
    },
    {
      "id": 38,
      "code": "CV",
      "name": "Cape Verde"
    },
    {
      "id": 39,
      "code": "KY",
      "name": "Cayman Islands"
    },
    {
      "id": 40,
      "code": "CF",
      "name": "Central African Republic"
    },
    {
      "id": 41,
      "code": "TD",
      "name": "Chad"
    },
    {
      "id": 42,
      "code": "CL",
      "name": "Chile"
    },
    {
      "id": 43,
      "code": "CN",
      "name": "China"
    },
    {
      "id": 44,
      "code": "CN",
      "name": "China (Hong Kong SAR)"
    },
    {
      "id": 45,
      "code": "CN",
      "name": "China (Macau SAR)"
    },
    {
      "id": 46,
      "code": "CX",
      "name": "Christmas Island"
    },
    {
      "id": 47,
      "code": "CC",
      "name": "Cocos Islands"
    },
    {
      "id": 48,
      "code": "CO",
      "name": "Colombia"
    },
    {
      "id": 49,
      "code": "KM",
      "name": "Comoros"
    },
    {
      "id": 50,
      "code": "CG",
      "name": "Congo (Dem. Rep. of the)"
    },
    {
      "id": 51,
      "code": "CG",
      "name": "Congo (Rep. of the)"
    },
    {
      "id": 52,
      "code": "CK",
      "name": "Cook Islands"
    },
    {
      "id": 53,
      "code": "CR",
      "name": "Costa Rica"
    },
    {
      "id": 54,
      "code": "CI",
      "name": "Cote d'Ivoire"
    },
    {
      "id": 55,
      "code": "HR",
      "name": "Croatia"
    },
    {
      "id": 56,
      "code": "CU",
      "name": "Cuba"
    },
    {
      "id": 57,
      "code": "CY",
      "name": "Cyprus"
    },
    {
      "id": 58,
      "code": "CZ",
      "name": "Czech Republic"
    },
    {
      "id": 59,
      "code": "DK",
      "name": "Denmark"
    },
    {
      "id": 60,
      "code": "DJ",
      "name": "Djibouti"
    },
    {
      "id": 61,
      "code": "DM",
      "name": "Dominica"
    },
    {
      "id": 62,
      "code": "DO",
      "name": "Dominican Republic"
    },
    {
      "id": 64,
      "code": "EC",
      "name": "Ecuador"
    },
    {
      "id": 65,
      "code": "EG",
      "name": "Egypt"
    },
    {
      "id": 66,
      "code": "SV",
      "name": "El Salvador"
    },
    {
      "id": 67,
      "code": "GQ",
      "name": "Equatorial Guinea"
    },
    {
      "id": 68,
      "code": "ER",
      "name": "Eritrea"
    },
    {
      "id": 69,
      "code": "EE",
      "name": "Estonia"
    },
    {
      "id": 70,
      "code": "ET",
      "name": "Ethiopia"
    },
    {
      "id": 71,
      "code": "FK",
      "name": "Falkland Islands (Islas Malvinas)"
    },
    {
      "id": 72,
      "code": "FO",
      "name": "Faroe Islands"
    },
    {
      "id": 73,
      "code": "FJ",
      "name": "Fiji"
    },
    {
      "id": 74,
      "code": "FI",
      "name": "Finland"
    },
    {
      "id": 75,
      "code": "FR",
      "name": "France"
    },
    {
      "id": 76,
      "code": "GF",
      "name": "French Guiana"
    },
    {
      "id": 77,
      "code": "PF",
      "name": "French Polynesia"
    },
    {
      "id": 78,
      "code": "GA",
      "name": "Gabon"
    },
    {
      "id": 79,
      "code": "GM",
      "name": "Gambia"
    },
    {
      "id": 80,
      "code": "GE",
      "name": "Georgia"
    },
    {
      "id": 81,
      "code": "DE",
      "name": "Germany"
    },
    {
      "id": 82,
      "code": "GH",
      "name": "Ghana"
    },
    {
      "id": 83,
      "code": "GI",
      "name": "Gibraltar"
    },
    {
      "id": 84,
      "code": "GR",
      "name": "Greece"
    },
    {
      "id": 85,
      "code": "GL",
      "name": "Greenland"
    },
    {
      "id": 86,
      "code": "GD",
      "name": "Grenada"
    },
    {
      "id": 87,
      "code": "GP",
      "name": "Guadeloupe"
    },
    {
      "id": 88,
      "code": "GU",
      "name": "Guam"
    },
    {
      "id": 89,
      "code": "GT",
      "name": "Guatemala"
    },
    {
      "id": 90,
      "code": "GG",
      "name": "Guernsey"
    },
    {
      "id": 91,
      "code": "GN",
      "name": "Guinea"
    },
    {
      "id": 92,
      "code": "GW",
      "name": "Guinea Bissau"
    },
    {
      "id": 93,
      "code": "GY",
      "name": "Guyana"
    },
    {
      "id": 94,
      "code": "HT",
      "name": "Haiti"
    },
    {
      "id": 95,
      "code": "HM",
      "name": "Heard Island and McDonald Island"
    },
    {
      "id": 96,
      "code": "VA",
      "name": "Holy See (Vatican City)"
    },
    {
      "id": 97,
      "code": "HN",
      "name": "Honduras"
    },
    {
      "id": 98,
      "code": "HU",
      "name": "Hungary"
    },
    {
      "id": 99,
      "code": "IS",
      "name": "Iceland"
    },
    {
      "id": 100,
      "code": "IN",
      "name": "India"
    },
    {
      "id": 102,
      "code": "IR",
      "name": "Iran"
    },
    {
      "id": 103,
      "code": "IQ",
      "name": "Iraq"
    },
    {
      "id": 104,
      "code": "IE",
      "name": "Ireland"
    },
    {
      "id": 105,
      "code": "IL",
      "name": "Israel"
    },
    {
      "id": 106,
      "code": "IT",
      "name": "Italy"
    },
    {
      "id": 107,
      "code": "JM",
      "name": "Jamaica"
    },
    {
      "id": 108,
      "code": "JP",
      "name": "Japan"
    },
    {
      "id": 109,
      "code": "JE",
      "name": "Jersey"
    },
    {
      "id": 110,
      "code": "JO",
      "name": "Jordan"
    },
    {
      "id": 111,
      "code": "KZ",
      "name": "Kazakhstan"
    },
    {
      "id": 112,
      "code": "KE",
      "name": "Kenya"
    },
    {
      "id": 113,
      "code": "KI",
      "name": "Kiribati"
    },
    {
      "id": 114,
      "code": "KP",
      "name": "Korea (North)"
    },
    {
      "id": 115,
      "code": "KR",
      "name": "Korea (South)"
    },
    {
      "id": 116,
      "code": "KW",
      "name": "Kuwait"
    },
    {
      "id": 117,
      "code": "KG",
      "name": "Kyrgyzstan"
    },
    {
      "id": 118,
      "code": "LA",
      "name": "Laos"
    },
    {
      "id": 119,
      "code": "LV",
      "name": "Latvia"
    },
    {
      "id": 120,
      "code": "LB",
      "name": "Lebanon"
    },
    {
      "id": 121,
      "code": "LS",
      "name": "Lesotho"
    },
    {
      "id": 122,
      "code": "LR",
      "name": "Liberia"
    },
    {
      "id": 123,
      "code": "LY",
      "name": "Libya"
    },
    {
      "id": 124,
      "code": "LI",
      "name": "Liechtenstein"
    },
    {
      "id": 125,
      "code": "LT",
      "name": "Lithuania"
    },
    {
      "id": 126,
      "code": "LU",
      "name": "Luxembourg"
    },
    {
      "id": 127,
      "code": "MK",
      "name": "Macedonia"
    },
    {
      "id": 128,
      "code": "MG",
      "name": "Madagascar"
    },
    {
      "id": 129,
      "code": "MW",
      "name": "Malawi"
    },
    {
      "id": 130,
      "code": "MY",
      "name": "Malaysia"
    },
    {
      "id": 131,
      "code": "MV",
      "name": "Maldives"
    },
    {
      "id": 132,
      "code": "ML",
      "name": "Mali"
    },
    {
      "id": 133,
      "code": "MT",
      "name": "Malta"
    },
    {
      "id": 134,
      "code": "IM",
      "name": "Isle of Man"
    },
    {
      "id": 135,
      "code": "MH",
      "name": "Marshall Islands"
    },
    {
      "id": 136,
      "code": "MQ",
      "name": "Martinique"
    },
    {
      "id": 137,
      "code": "MR",
      "name": "Mauritania"
    },
    {
      "id": 138,
      "code": "MU",
      "name": "Mauritius"
    },
    {
      "id": 139,
      "code": "YT",
      "name": "Mayotte"
    },
    {
      "id": 140,
      "code": "MX",
      "name": "Mexico"
    },
    {
      "id": 141,
      "code": "FM",
      "name": "Micronesia (Federated States of)"
    },
    {
      "id": 142,
      "code": "MD",
      "name": "Moldova"
    },
    {
      "id": 143,
      "code": "MC",
      "name": "Monaco"
    },
    {
      "id": 144,
      "code": "MN",
      "name": "Mongolia"
    },
    {
      "id": 145,
      "code": "MS",
      "name": "Montserrat"
    },
    {
      "id": 146,
      "code": "MA",
      "name": "Morocco"
    },
    {
      "id": 147,
      "code": "MZ",
      "name": "Mozambique"
    },
    {
      "id": 148,
      "code": "NA",
      "name": "Namibia"
    },
    {
      "id": 149,
      "code": "NR",
      "name": "Nauru"
    },
    {
      "id": 150,
      "code": "NP",
      "name": "Nepal"
    },
    {
      "id": 151,
      "code": "NL",
      "name": "Netherlands"
    },
    {
      "id": 152,
      "code": "AN",
      "name": "Netherlands Antilles"
    },
    {
      "id": 153,
      "code": "NC",
      "name": "New Caledonia"
    },
    {
      "id": 154,
      "code": "NZ",
      "name": "New Zealand"
    },
    {
      "id": 155,
      "code": "NI",
      "name": "Nicaragua"
    },
    {
      "id": 156,
      "code": "NE",
      "name": "Niger"
    },
    {
      "id": 157,
      "code": "NG",
      "name": "Nigeria"
    },
    {
      "id": 158,
      "code": "NU",
      "name": "Niue"
    },
    {
      "id": 159,
      "code": "NF",
      "name": "Norfolk Island"
    },
    {
      "id": 160,
      "code": "MP",
      "name": "Northern Mariana Islands"
    },
    {
      "id": 161,
      "code": "NO",
      "name": "Norway"
    },
    {
      "id": 163,
      "code": "OM",
      "name": "Oman"
    },
    {
      "id": 164,
      "code": "PK",
      "name": "Pakistan"
    },
    {
      "id": 165,
      "code": "PW",
      "name": "Palau"
    },
    {
      "id": 166,
      "code": "PA",
      "name": "Panama"
    },
    {
      "id": 167,
      "code": "PG",
      "name": "Papua New Guinea"
    },
    {
      "id": 168,
      "code": "PY",
      "name": "Paraguay"
    },
    {
      "id": 169,
      "code": "PE",
      "name": "Peru"
    },
    {
      "id": 170,
      "code": "PH",
      "name": "Philippines"
    },
    {
      "id": 63,
      "code": "TL",
      "name": "East Timor"
    },
    {
      "id": 171,
      "code": "PN",
      "name": "Pitcairn Islands"
    },
    {
      "id": 172,
      "code": "PL",
      "name": "Poland"
    },
    {
      "id": 173,
      "code": "PT",
      "name": "Portugal"
    },
    {
      "id": 174,
      "code": "PR",
      "name": "Puerto Rico"
    },
    {
      "id": 175,
      "code": "QA",
      "name": "Qatar"
    },
    {
      "id": 176,
      "code": "RE",
      "name": "Reunion"
    },
    {
      "id": 177,
      "code": "RO",
      "name": "Romania"
    },
    {
      "id": 178,
      "code": "RU",
      "name": "Russia"
    },
    {
      "id": 179,
      "code": "RW",
      "name": "Rwanda"
    },
    {
      "id": 180,
      "code": "SH",
      "name": "Saint Helena"
    },
    {
      "id": 181,
      "code": "KN",
      "name": "Saint Kitts and Nevis"
    },
    {
      "id": 182,
      "code": "LC",
      "name": "Saint Lucia"
    },
    {
      "id": 183,
      "code": "PM",
      "name": "Saint Pierre and Miquelon"
    },
    {
      "id": 184,
      "code": "VC",
      "name": "Saint Vincent and the Grenadines"
    },
    {
      "id": 185,
      "code": "WS",
      "name": "Samoa"
    },
    {
      "id": 186,
      "code": "SM",
      "name": "San Marino"
    },
    {
      "id": 187,
      "code": "ST",
      "name": "Sao Tome and Principe"
    },
    {
      "id": 188,
      "code": "SA",
      "name": "Saudi Arabia"
    },
    {
      "id": 189,
      "code": "SN",
      "name": "Senegal"
    },
    {
      "id": 190,
      "code": "SC",
      "name": "Seychelles"
    },
    {
      "id": 191,
      "code": "SL",
      "name": "Sierra Leone"
    },
    {
      "id": 192,
      "code": "SG",
      "name": "Singapore"
    },
    {
      "id": 193,
      "code": "SK",
      "name": "Slovakia"
    },
    {
      "id": 194,
      "code": "SI",
      "name": "Slovenia"
    },
    {
      "id": 195,
      "code": "SB",
      "name": "Solomon Islands"
    },
    {
      "id": 196,
      "code": "SO",
      "name": "Somalia"
    },
    {
      "id": 197,
      "code": "ZA",
      "name": "South Africa"
    },
    {
      "id": 198,
      "code": "ES",
      "name": "Spain"
    },
    {
      "id": 199,
      "code": "LK",
      "name": "Sri Lanka"
    },
    {
      "id": 200,
      "code": "SD",
      "name": "Sudan"
    },
    {
      "id": 201,
      "code": "SR",
      "name": "Suriname"
    },
    {
      "id": 202,
      "code": "SJ",
      "name": "Svalbard"
    },
    {
      "id": 203,
      "code": "SZ",
      "name": "Swaziland"
    },
    {
      "id": 204,
      "code": "SE",
      "name": "Sweden"
    },
    {
      "id": 205,
      "code": "CH",
      "name": "Switzerland"
    },
    {
      "id": 206,
      "code": "SY",
      "name": "Syria"
    },
    {
      "id": 207,
      "code": "TW",
      "name": "Taiwan"
    },
    {
      "id": 208,
      "code": "TJ",
      "name": "Tajikistan"
    },
    {
      "id": 209,
      "code": "TZ",
      "name": "Tanzania"
    },
    {
      "id": 210,
      "code": "TH",
      "name": "Thailand"
    },
    {
      "id": 211,
      "code": "TG",
      "name": "Togo"
    },
    {
      "id": 212,
      "code": "TO",
      "name": "Tonga"
    },
    {
      "id": 213,
      "code": "TT",
      "name": "Trinidad and Tobago"
    },
    {
      "id": 214,
      "code": "TN",
      "name": "Tunisia"
    },
    {
      "id": 215,
      "code": "TR",
      "name": "Turkey"
    },
    {
      "id": 216,
      "code": "TM",
      "name": "Turkmenistan"
    },
    {
      "id": 217,
      "code": "TC",
      "name": "Turks and Caicos Islands"
    },
    {
      "id": 218,
      "code": "TV",
      "name": "Tuvalu"
    },
    {
      "id": 219,
      "code": "UG",
      "name": "Uganda"
    },
    {
      "id": 220,
      "code": "UA",
      "name": "Ukraine"
    },
    {
      "id": 222,
      "code": "GB",
      "name": "United Kingdom"
    },
    {
      "id": 223,
      "code": "US",
      "name": "United States"
    },
    {
      "id": 224,
      "code": "UY",
      "name": "Uruguay"
    },
    {
      "id": 225,
      "code": "UZ",
      "name": "Uzbekistan"
    },
    {
      "id": 226,
      "code": "VU",
      "name": "Vanuatu"
    },
    {
      "id": 227,
      "code": "VE",
      "name": "Venezuela"
    },
    {
      "id": 228,
      "code": "VN",
      "name": "Vietnam"
    },
    {
      "id": 229,
      "code": "VI",
      "name": "Virgin Islands (U.S.)"
    },
    {
      "id": 231,
      "code": "WF",
      "name": "Wallis and Futuna"
    },
    {
      "id": 232,
      "code": "YE",
      "name": "Yemen"
    },
    {
      "id": 233,
      "code": "ZM",
      "name": "Zambia"
    },
    {
      "id": 234,
      "code": "ZW",
      "name": "Zimbabwe"
    },
    {
      "id": 235,
      "code": "RS",
      "name": "Serbia"
    },
    {
      "id": 236,
      "code": "ME",
      "name": "Montenegro"
    },
    {
      "id": 101,
      "code": "ID",
      "name": "Indonesia"
    },
    {
      "id": 221,
      "code": "AE",
      "name": "United Arab Emirates"
    }

  //from the internet -
  // {"code": "AF", "name": "Afghanistan"},
  // {"code": "AX", "name": "Aland Islands"}, //invalid on lust
  // {"code": "AL", "name": "Albania"},
  // {"code": "DZ", "name": "Algeria"},
  // {"code": "AS", "name": "American Samoa"},
  // {"code": "AD", "name": "Andorra"},
  // {"code": "AO", "name": "Angola"},
  // {"code": "AI", "name": "Anguilla"},
  // {"code": "AQ", "name": "Antarctica"}, //invalid on lust
  // {"code": "AG", "name": "Antigua and Barbuda"},
  // {"code": "AR", "name": "Argentina"},
  // {"code": "AM", "name": "Armenia"},
  // {"code": "AW", "name": "Aruba"},
  // {"code": "AU", "name": "Australia"},
  // {"code": "AT", "name": "Austria"},
  // {"code": "AZ", "name": "Azerbaijan"},
  // {"code": "BS", "name": "Bahamas"},
  // {"code": "BH", "name": "Bahrain"},
  // {"code": "BD", "name": "Bangladesh"},
  // {"code": "BB", "name": "Barbados"},
  // {"code": "BY", "name": "Belarus"},
  // {"code": "BE", "name": "Belgium"},
  // {"code": "BZ", "name": "Belize"},
  // {"code": "BJ", "name": "Benin"},
  // {"code": "BM", "name": "Bermuda"},
  // {"code": "BT", "name": "Bhutan"},
  // {"code": "BO", "name": "Bolivia, Plurinational State of"},
  // {"code": "BQ", "name": "Bonaire, Sint Eustatius and Saba"},
  // {"code": "BA", "name": "Bosnia and Herzegovina"},
  // {"code": "BW", "name": "Botswana"},
  // {"code": "BV", "name": "Bouvet Island"},
  // {"code": "BR", "name": "Brazil"},
  // {"code": "IO", "name": "British Indian Ocean Territory"},
  // {"code": "BN", "name": "Brunei Darussalam"},
  // {"code": "BG", "name": "Bulgaria"},
  // {"code": "BF", "name": "Burkina Faso"},
  // {"code": "BI", "name": "Burundi"},
  // {"code": "KH", "name": "Cambodia"},
  // {"code": "CM", "name": "Cameroon"},
  // {"code": "CA", "name": "Canada"},
  // {"code": "CV", "name": "Cape Verde"},
  // {"code": "KY", "name": "Cayman Islands"},
  // {"code": "CF", "name": "Central African Republic"},
  // {"code": "TD", "name": "Chad"},
  // {"code": "CL", "name": "Chile"},
  // {"code": "CN", "name": "China"},
  // {"code": "CX", "name": "Christmas Island"},
  // {"code": "CC", "name": "Cocos (Keeling) Islands"},
  // {"code": "CO", "name": "Colombia"},
  // {"code": "KM", "name": "Comoros"},
  // {"code": "CG", "name": "Congo"},
  // {"code": "CD", "name": "Congo, the Democratic Republic of the"},
  // {"code": "CK", "name": "Cook Islands"},
  // {"code": "CR", "name": "Costa Rica"},
  // {"code": "CI", "name": "Cote d'Ivoire"},
  // {"code": "HR", "name": "Croatia"},
  // {"code": "CU", "name": "Cuba"},
  // {"code": "CW", "name": "Curacao"},
  // {"code": "CY", "name": "Cyprus"},
  // {"code": "CZ", "name": "Czech Republic"},
  // {"code": "DK", "name": "Denmark"},
  // {"code": "DJ", "name": "Djibouti"},
  // {"code": "DM", "name": "Dominica"},
  // {"code": "DO", "name": "Dominican Republic"},
  // {"code": "EC", "name": "Ecuador"},
  // {"code": "EG", "name": "Egypt"},
  // {"code": "SV", "name": "El Salvador"},
  // {"code": "GQ", "name": "Equatorial Guinea"},
  // {"code": "ER", "name": "Eritrea"},
  // {"code": "EE", "name": "Estonia"},
  // {"code": "ET", "name": "Ethiopia"},
  // {"code": "FK", "name": "Falkland Islands (Malvinas)"},
  // {"code": "FO", "name": "Faroe Islands"},
  // {"code": "FJ", "name": "Fiji"},
  // {"code": "FI", "name": "Finland"},
  // {"code": "FR", "name": "France"},
  // {"code": "GF", "name": "French Guiana"},
  // {"code": "PF", "name": "French Polynesia"},
  // {"code": "TF", "name": "French Southern Territories"},
  // {"code": "GA", "name": "Gabon"},
  // {"code": "GM", "name": "Gambia"},
  // {"code": "GE", "name": "Georgia"},
  // {"code": "DE", "name": "Germany"},
  // {"code": "GH", "name": "Ghana"},
  // {"code": "GI", "name": "Gibraltar"},
  // {"code": "GR", "name": "Greece"},
  // {"code": "GL", "name": "Greenland"},
  // {"code": "GD", "name": "Grenada"},
  // {"code": "GP", "name": "Guadeloupe"},
  // {"code": "GU", "name": "Guam"},
  // {"code": "GT", "name": "Guatemala"},
  // {"code": "GG", "name": "Guernsey"},
  // {"code": "GN", "name": "Guinea"},
  // {"code": "GW", "name": "Guinea-Bissau"},
  // {"code": "GY", "name": "Guyana"},
  // {"code": "HT", "name": "Haiti"},
  // {"code": "HM", "name": "Heard Island and McDonald Islands"},
  // {"code": "VA", "name": "Holy See (Vatican City State)"},
  // {"code": "HN", "name": "Honduras"},
  // {"code": "HK", "name": "Hong Kong"},
  // {"code": "HU", "name": "Hungary"},
  // {"code": "IS", "name": "Iceland"},
  // {"code": "IN", "name": "India"},
  // {"code": "ID", "name": "Indonesia"},
  // {"code": "IR", "name": "Iran, Islamic Republic of"},
  // {"code": "IQ", "name": "Iraq"},
  // {"code": "IE", "name": "Ireland"},
  // {"code": "IM", "name": "Isle of Man"},
  // {"code": "IL", "name": "Israel"},
  // {"code": "IT", "name": "Italy"},
  // {"code": "JM", "name": "Jamaica"},
  // {"code": "JP", "name": "Japan"},
  // {"code": "JE", "name": "Jersey"},
  // {"code": "JO", "name": "Jordan"},
  // {"code": "KZ", "name": "Kazakhstan"},
  // {"code": "KE", "name": "Kenya"},
  // {"code": "KI", "name": "Kiribati"},
  // {"code": "KP", "name": "Korea, Democratic People's Republic of"},
  // {"code": "KR", "name": "Korea, Republic of"},
  // {"code": "KW", "name": "Kuwait"},
  // {"code": "KG", "name": "Kyrgyzstan"},
  // {"code": "LA", "name": "Lao People's Democratic Republic"},
  // {"code": "LV", "name": "Latvia"},
  // {"code": "LB", "name": "Lebanon"},
  // {"code": "LS", "name": "Lesotho"},
  // {"code": "LR", "name": "Liberia"},
  // {"code": "LY", "name": "Libya"},
  // {"code": "LI", "name": "Liechtenstein"},
  // {"code": "LT", "name": "Lithuania"},
  // {"code": "LU", "name": "Luxembourg"},
  // {"code": "MO", "name": "Macao"},
  // {"code": "MK", "name": "Macedonia, the Former Yugoslav Republic of"},
  // {"code": "MG", "name": "Madagascar"},
  // {"code": "MW", "name": "Malawi"},
  // {"code": "MY", "name": "Malaysia"},
  // {"code": "MV", "name": "Maldives"},
  // {"code": "ML", "name": "Mali"},
  // {"code": "MT", "name": "Malta"},
  // {"code": "MH", "name": "Marshall Islands"},
  // {"code": "MQ", "name": "Martinique"},
  // {"code": "MR", "name": "Mauritania"},
  // {"code": "MU", "name": "Mauritius"},
  // {"code": "YT", "name": "Mayotte"},
  // {"code": "MX", "name": "Mexico"},
  // {"code": "FM", "name": "Micronesia, Federated States of"},
  // {"code": "MD", "name": "Moldova, Republic of"},
  // {"code": "MC", "name": "Monaco"},
  // {"code": "MN", "name": "Mongolia"},
  // {"code": "ME", "name": "Montenegro"},
  // {"code": "MS", "name": "Montserrat"},
  // {"code": "MA", "name": "Morocco"},
  // {"code": "MZ", "name": "Mozambique"},
  // {"code": "MM", "name": "Myanmar"},
  // {"code": "NA", "name": "Namibia"},
  // {"code": "NR", "name": "Nauru"},
  // {"code": "NP", "name": "Nepal"},
  // {"code": "NL", "name": "Netherlands"},
  // {"code": "NC", "name": "New Caledonia"},
  // {"code": "NZ", "name": "New Zealand"},
  // {"code": "NI", "name": "Nicaragua"},
  // {"code": "NE", "name": "Niger"},
  // {"code": "NG", "name": "Nigeria"},
  // {"code": "NU", "name": "Niue"},
  // {"code": "NF", "name": "Norfolk Island"},
  // {"code": "MP", "name": "Northern Mariana Islands"},
  // {"code": "NO", "name": "Norway"},
  // {"code": "OM", "name": "Oman"},
  // {"code": "PK", "name": "Pakistan"},
  // {"code": "PW", "name": "Palau"},
  // {"code": "PS", "name": "Palestine, State of"},
  // {"code": "PA", "name": "Panama"},
  // {"code": "PG", "name": "Papua New Guinea"},
  // {"code": "PY", "name": "Paraguay"},
  // {"code": "PE", "name": "Peru"},
  // {"code": "PH", "name": "Philippines"},
  // {"code": "PN", "name": "Pitcairn"},
  // {"code": "PL", "name": "Poland"},
  // {"code": "PT", "name": "Portugal"},
  // {"code": "PR", "name": "Puerto Rico"},
  // {"code": "QA", "name": "Qatar"},
  // {"code": "RE", "name": "Reunion"},
  // {"code": "RO", "name": "Romania"},
  // {"code": "RU", "name": "Russian Federation"},
  // {"code": "RW", "name": "Rwanda"},
  // {"code": "BL", "name": "Saint Barthelemy"},
  // {"code": "SH", "name": "Saint Helena, Ascension and Tristan da Cunha"},
  // {"code": "KN", "name": "Saint Kitts and Nevis"},
  // {"code": "LC", "name": "Saint Lucia"},
  // {"code": "MF", "name": "Saint Martin (French part)"},
  // {"code": "PM", "name": "Saint Pierre and Miquelon"},
  // {"code": "VC", "name": "Saint Vincent and the Grenadines"},
  // {"code": "WS", "name": "Samoa"},
  // {"code": "SM", "name": "San Marino"},
  // {"code": "ST", "name": "Sao Tome and Principe"},
  // {"code": "SA", "name": "Saudi Arabia"},
  // {"code": "SN", "name": "Senegal"},
  // {"code": "RS", "name": "Serbia"},
  // {"code": "SC", "name": "Seychelles"},
  // {"code": "SL", "name": "Sierra Leone"},
  // {"code": "SG", "name": "Singapore"},
  // {"code": "SX", "name": "Sint Maarten (Dutch part)"},
  // {"code": "SK", "name": "Slovakia"},
  // {"code": "SI", "name": "Slovenia"},
  // {"code": "SB", "name": "Solomon Islands"},
  // {"code": "SO", "name": "Somalia"},
  // {"code": "ZA", "name": "South Africa"},
  // {"code": "GS", "name": "South Georgia and the South Sandwich Islands"},
  // {"code": "SS", "name": "South Sudan"},
  // {"code": "ES", "name": "Spain"},
  // {"code": "LK", "name": "Sri Lanka"},
  // {"code": "SD", "name": "Sudan"},
  // {"code": "SR", "name": "Suriname"},
  // {"code": "SJ", "name": "Svalbard and Jan Mayen"},
  // {"code": "SZ", "name": "Swaziland"},
  // {"code": "SE", "name": "Sweden"},
  // {"code": "CH", "name": "Switzerland"},
  // {"code": "SY", "name": "Syrian Arab Republic"},
  // {"code": "TW", "name": "Taiwan, Province of China"},
  // {"code": "TJ", "name": "Tajikistan"},
  // {"code": "TZ", "name": "Tanzania, United Republic of"},
  // {"code": "TH", "name": "Thailand"},
  // {"code": "TL", "name": "Timor-Leste"},
  // {"code": "TG", "name": "Togo"},
  // {"code": "TK", "name": "Tokelau"},
  // {"code": "TO", "name": "Tonga"},
  // {"code": "TT", "name": "Trinidad and Tobago"},
  // {"code": "TN", "name": "Tunisia"},
  // {"code": "TR", "name": "Turkey"},
  // {"code": "TM", "name": "Turkmenistan"},
  // {"code": "TC", "name": "Turks and Caicos Islands"},
  // {"code": "TV", "name": "Tuvalu"},
  // {"code": "UG", "name": "Uganda"},
  // {"code": "UA", "name": "Ukraine"},
  // {"code": "AE", "name": "United Arab Emirates"},
  // {"code": "GB", "name": "United Kingdom"},
  // {"code": "US", "name": "United States"},
  // {"code": "UM", "name": "United States Minor Outlying Islands"},
  // {"code": "UY", "name": "Uruguay"},
  // {"code": "UZ", "name": "Uzbekistan"},
  // {"code": "VU", "name": "Vanuatu"},
  // {"code": "VE", "name": "Venezuela, Bolivarian Republic of"},
  // {"code": "VN", "name": "Viet Nam"},
  // {"code": "VG", "name": "Virgin Islands, British"},
  // {"code": "VI", "name": "Virgin Islands, U.S."},
  // {"code": "WF", "name": "Wallis and Futuna"},
  // {"code": "EH", "name": "Western Sahara"},
  // {"code": "YE", "name": "Yemen"},
  // {"code": "ZM", "name": "Zambia"},
  // {"code": "ZW", "name": "Zimbabwe"}
]


const subdivisionData = [
  {
    "country_code": "AD",
    "subdivision_name": "Andorra la Vella",
    "code": "07"
  },
  {
    "country_code": "AD",
    "subdivision_name": "Canillo",
    "code": "02"
  },
  {
    "country_code": "AD",
    "subdivision_name": "Encamp",
    "code": "03"
  },
  {
    "country_code": "AD",
    "subdivision_name": "Escaldes-Engordany",
    "code": "08"
  },
  {
    "country_code": "AD",
    "subdivision_name": "La Massana",
    "code": "04"
  },
  {
    "country_code": "AD",
    "subdivision_name": "Ordino",
    "code": "05"
  },
  {
    "country_code": "AD",
    "subdivision_name": "Sant Julia de Loria",
    "code": "06"
  },
  {
    "country_code": "AE",
    "subdivision_name": "'Ajman",
    "code": "AJ"
  },
  {
    "country_code": "AE",
    "subdivision_name": "Abu Zaby",
    "code": "AZ"
  },
  {
    "country_code": "AE",
    "subdivision_name": "Al Fujayrah",
    "code": "FU"
  },
  {
    "country_code": "AE",
    "subdivision_name": "Ash Shariqah",
    "code": "SH"
  },
  {
    "country_code": "AE",
    "subdivision_name": "Dubayy",
    "code": "DU"
  },
  {
    "country_code": "AE",
    "subdivision_name": "Ra's al Khaymah",
    "code": "RK"
  },
  {
    "country_code": "AE",
    "subdivision_name": "Umm al Qaywayn",
    "code": "UQ"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Badghis",
    "code": "BDG"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Baghlan",
    "code": "BGL"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Balkh",
    "code": "BAL"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Bamyan",
    "code": "BAM"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Daykundi",
    "code": "DAY"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Farah",
    "code": "FRA"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Faryab",
    "code": "FYB"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Ghazni",
    "code": "GHA"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Ghor",
    "code": "GHO"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Helmand",
    "code": "HEL"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Herat",
    "code": "HER"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Jowzjan",
    "code": "JOW"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Kabul",
    "code": "KAB"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Kandahar",
    "code": "KAN"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Khost",
    "code": "KHO"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Kunar",
    "code": "KNR"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Kunduz",
    "code": "KDZ"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Laghman",
    "code": "LAG"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Logar",
    "code": "LOG"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Nangarhar",
    "code": "NAN"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Nimroz",
    "code": "NIM"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Paktika",
    "code": "PKA"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Paktiya",
    "code": "PIA"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Parwan",
    "code": "PAR"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Samangan",
    "code": "SAM"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Sar-e Pul",
    "code": "SAR"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Takhar",
    "code": "TAK"
  },
  {
    "country_code": "AF",
    "subdivision_name": "Uruzgan",
    "code": "URU"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Barbuda",
    "code": "10"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Redonda",
    "code": "11"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Saint George",
    "code": "03"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Saint John",
    "code": "04"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Saint Mary",
    "code": "05"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Saint Paul",
    "code": "06"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Saint Peter",
    "code": "07"
  },
  {
    "country_code": "AG",
    "subdivision_name": "Saint Philip",
    "code": "08"
  },
  {
    "country_code": "AI",
    "subdivision_name": "Anguilla",
    "code": "-"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Berat",
    "code": "01"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Diber",
    "code": "09"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Durres",
    "code": "02"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Elbasan",
    "code": "03"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Fier",
    "code": "04"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Gjirokaster",
    "code": "05"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Korce",
    "code": "06"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Kukes",
    "code": "07"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Lezhe",
    "code": "08"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Shkoder",
    "code": "10"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Tirane",
    "code": "11"
  },
  {
    "country_code": "AL",
    "subdivision_name": "Vlore",
    "code": "12"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Aragacotn",
    "code": "AG"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Ararat",
    "code": "AR"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Armavir",
    "code": "AV"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Erevan",
    "code": "ER"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Gegark'unik'",
    "code": "GR"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Kotayk'",
    "code": "KT"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Lori",
    "code": "LO"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Sirak",
    "code": "SH"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Syunik'",
    "code": "SU"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Tavus",
    "code": "TV"
  },
  {
    "country_code": "AM",
    "subdivision_name": "Vayoc Jor",
    "code": "VD"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Bengo",
    "code": "BGO"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Benguela",
    "code": "BGU"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Bie",
    "code": "BIE"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Cabinda",
    "code": "CAB"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Cuando Cubango",
    "code": "CCU"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Cuanza-Norte",
    "code": "CNO"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Cuanza-Sul",
    "code": "CUS"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Huambo",
    "code": "HUA"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Huila",
    "code": "HUI"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Luanda",
    "code": "LUA"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Lunda-Norte",
    "code": "LNO"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Lunda-Sul",
    "code": "LSU"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Malange",
    "code": "MAL"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Moxico",
    "code": "MOX"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Namibe",
    "code": "NAM"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Uige",
    "code": "UIG"
  },
  {
    "country_code": "AO",
    "subdivision_name": "Zaire",
    "code": "ZAI"
  },
  {
    "country_code": "AQ",
    "subdivision_name": "Antarctica",
    "code": "-"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Buenos Aires",
    "code": "B"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Catamarca",
    "code": "K"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Chaco",
    "code": "H"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Chubut",
    "code": "U"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Ciudad Autonoma de Buenos Aires",
    "code": "C"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Cordoba",
    "code": "X"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Corrientes",
    "code": "W"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Entre Rios",
    "code": "E"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Formosa",
    "code": "P"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Jujuy",
    "code": "Y"
  },
  {
    "country_code": "AR",
    "subdivision_name": "La Pampa",
    "code": "L"
  },
  {
    "country_code": "AR",
    "subdivision_name": "La Rioja",
    "code": "F"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Mendoza",
    "code": "M"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Misiones",
    "code": "N"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Neuquen",
    "code": "Q"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Rio Negro",
    "code": "R"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Salta",
    "code": "A"
  },
  {
    "country_code": "AR",
    "subdivision_name": "San Juan",
    "code": "J"
  },
  {
    "country_code": "AR",
    "subdivision_name": "San Luis",
    "code": "D"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Santa Cruz",
    "code": "Z"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Santa Fe",
    "code": "S"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Santiago del Estero",
    "code": "G"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Tierra del Fuego",
    "code": "V"
  },
  {
    "country_code": "AR",
    "subdivision_name": "Tucuman",
    "code": "T"
  },
  {
    "country_code": "AS",
    "subdivision_name": "Eastern District",
    "code": "-"
  },
  {
    "country_code": "AS",
    "subdivision_name": "Western District",
    "code": "-"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Burgenland",
    "code": "1"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Karnten",
    "code": "2"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Niederosterreich",
    "code": "3"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Oberosterreich",
    "code": "4"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Salzburg",
    "code": "5"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Steiermark",
    "code": "6"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Tirol",
    "code": "7"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Vorarlberg",
    "code": "8"
  },
  {
    "country_code": "AT",
    "subdivision_name": "Wien",
    "code": "9"
  },
  {
    "country_code": "AU",
    "subdivision_name": "Australian Capital Territory",
    "code": "ACT"
  },
  {
    "country_code": "AU",
    "subdivision_name": "New South Wales",
    "code": "NSW"
  },
  {
    "country_code": "AU",
    "subdivision_name": "Northern Territory",
    "code": "NT"
  },
  {
    "country_code": "AU",
    "subdivision_name": "Queensland",
    "code": "QLD"
  },
  {
    "country_code": "AU",
    "subdivision_name": "South Australia",
    "code": "SA"
  },
  {
    "country_code": "AU",
    "subdivision_name": "Tasmania",
    "code": "TAS"
  },
  {
    "country_code": "AU",
    "subdivision_name": "Victoria",
    "code": "VIC"
  },
  {
    "country_code": "AU",
    "subdivision_name": "Western Australia",
    "code": "WA"
  },
  {
    "country_code": "AW",
    "subdivision_name": "Aruba",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Eckeroe",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Finstroem",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Hammarland",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Jomala",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Lemland",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Mariehamn",
    "code": "-"
  },
  {
    "country_code": "AX",
    "subdivision_name": "Saltvik",
    "code": "-"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Abseron",
    "code": "ABS"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Agcabadi",
    "code": "AGC"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Agdas",
    "code": "AGS"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Agstafa",
    "code": "AGA"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Agsu",
    "code": "AGU"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Astara",
    "code": "AST"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Baki",
    "code": "BA"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Balakan",
    "code": "BAL"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Barda",
    "code": "BAR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Beylaqan",
    "code": "BEY"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Calilabad",
    "code": "CAL"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Gadabay",
    "code": "GAD"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Ganca",
    "code": "GA"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Goranboy",
    "code": "GOR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Goycay",
    "code": "GOY"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Goygol",
    "code": "GYG"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Imisli",
    "code": "IMI"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Ismayilli",
    "code": "ISM"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Kurdamir",
    "code": "KUR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Lankaran",
    "code": "LA"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Masalli",
    "code": "MAS"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Mingacevir",
    "code": "MI"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Naxcivan",
    "code": "NX"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Neftcala",
    "code": "NEF"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Oguz",
    "code": "OGU"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Qabala",
    "code": "QAB"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Qazax",
    "code": "QAZ"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Quba",
    "code": "QBA"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Qusar",
    "code": "QUS"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Saatli",
    "code": "SAT"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Sabirabad",
    "code": "SAB"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Saki",
    "code": "SAK"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Salyan",
    "code": "SAL"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Samaxi",
    "code": "SMI"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Samkir",
    "code": "SKR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Samux",
    "code": "SMX"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Sirvan",
    "code": "SR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Siyazan",
    "code": "SIY"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Sumqayit",
    "code": "SM"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Tartar",
    "code": "TAR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Tovuz",
    "code": "TOV"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Ucar",
    "code": "UCA"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Xacmaz",
    "code": "XAC"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Xizi",
    "code": "XIZ"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Yardimli",
    "code": "YAR"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Yevlax",
    "code": "YEV"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Zaqatala",
    "code": "ZAQ"
  },
  {
    "country_code": "AZ",
    "subdivision_name": "Zardab",
    "code": "ZAR"
  },
  {
    "country_code": "BA",
    "subdivision_name": "Brcko distrikt",
    "code": "BRC"
  },
  {
    "country_code": "BA",
    "subdivision_name": "Federacija Bosne i Hercegovine",
    "code": "BIH"
  },
  {
    "country_code": "BA",
    "subdivision_name": "Republika Srpska",
    "code": "SRP"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Christ Church",
    "code": "01"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Andrew",
    "code": "02"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint George",
    "code": "03"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint James",
    "code": "04"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint John",
    "code": "05"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Joseph",
    "code": "06"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Lucy",
    "code": "07"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Michael",
    "code": "08"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Peter",
    "code": "09"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Philip",
    "code": "10"
  },
  {
    "country_code": "BB",
    "subdivision_name": "Saint Thomas",
    "code": "11"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Barishal",
    "code": "A"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Chattogram",
    "code": "B"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Dhaka",
    "code": "C"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Khulna",
    "code": "D"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Rajshahi",
    "code": "E"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Rangpur",
    "code": "F"
  },
  {
    "country_code": "BD",
    "subdivision_name": "Sylhet",
    "code": "G"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Antwerpen",
    "code": "VAN"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Brabant wallon",
    "code": "WBR"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Brussels Hoofdstedelijk Gewest",
    "code": "BRU"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Hainaut",
    "code": "WHT"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Liege",
    "code": "WLG"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Limburg",
    "code": "VLI"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Luxembourg",
    "code": "WLX"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Namur",
    "code": "WNA"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Oost-Vlaanderen",
    "code": "VOV"
  },
  {
    "country_code": "BE",
    "subdivision_name": "Vlaams-Brabant",
    "code": "VBR"
  },
  {
    "country_code": "BE",
    "subdivision_name": "West-Vlaanderen",
    "code": "VWV"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Bale",
    "code": "BAL"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Bam",
    "code": "BAM"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Banwa",
    "code": "BAN"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Bazega",
    "code": "BAZ"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Bougouriba",
    "code": "BGR"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Boulgou",
    "code": "BLG"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Boulkiemde",
    "code": "BLK"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Comoe",
    "code": "COM"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Ganzourgou",
    "code": "GAN"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Gnagna",
    "code": "GNA"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Gourma",
    "code": "GOU"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Houet",
    "code": "HOU"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Kadiogo",
    "code": "KAD"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Kenedougou",
    "code": "KEN"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Kompienga",
    "code": "KMP"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Kossi",
    "code": "KOS"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Kouritenga",
    "code": "KOT"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Kourweogo",
    "code": "KOW"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Leraba",
    "code": "LER"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Loroum",
    "code": "LOR"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Mouhoun",
    "code": "MOU"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Nahouri",
    "code": "NAO"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Namentenga",
    "code": "NAM"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Nayala",
    "code": "NAY"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Oubritenga",
    "code": "OUB"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Oudalan",
    "code": "OUD"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Passore",
    "code": "PAS"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Sanmatenga",
    "code": "SMT"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Seno",
    "code": "SEN"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Sissili",
    "code": "SIS"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Soum",
    "code": "SOM"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Sourou",
    "code": "SOR"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Tapoa",
    "code": "TAP"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Tuy",
    "code": "TUI"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Yatenga",
    "code": "YAT"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Ziro",
    "code": "ZIR"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Zondoma",
    "code": "ZON"
  },
  {
    "country_code": "BF",
    "subdivision_name": "Zoundweogo",
    "code": "ZOU"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Blagoevgrad",
    "code": "01"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Burgas",
    "code": "02"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Dobrich",
    "code": "08"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Gabrovo",
    "code": "07"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Haskovo",
    "code": "26"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Kardzhali",
    "code": "09"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Kyustendil",
    "code": "10"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Lovech",
    "code": "11"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Montana",
    "code": "12"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Pazardzhik",
    "code": "13"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Pernik",
    "code": "14"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Pleven",
    "code": "15"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Plovdiv",
    "code": "16"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Razgrad",
    "code": "17"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Ruse",
    "code": "18"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Shumen",
    "code": "27"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Silistra",
    "code": "19"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Sliven",
    "code": "20"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Smolyan",
    "code": "21"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Sofia",
    "code": "23"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Sofia (stolitsa)",
    "code": "22"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Stara Zagora",
    "code": "24"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Targovishte",
    "code": "25"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Varna",
    "code": "03"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Veliko Tarnovo",
    "code": "04"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Vidin",
    "code": "05"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Vratsa",
    "code": "06"
  },
  {
    "country_code": "BG",
    "subdivision_name": "Yambol",
    "code": "28"
  },
  {
    "country_code": "BH",
    "subdivision_name": "Al 'Asimah",
    "code": "13"
  },
  {
    "country_code": "BH",
    "subdivision_name": "Al Janubiyah",
    "code": "14"
  },
  {
    "country_code": "BH",
    "subdivision_name": "Al Muharraq",
    "code": "15"
  },
  {
    "country_code": "BH",
    "subdivision_name": "Ash Shamaliyah",
    "code": "17"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Bujumbura Mairie",
    "code": "BM"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Bururi",
    "code": "BR"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Cibitoke",
    "code": "CI"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Gitega",
    "code": "GI"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Kirundo",
    "code": "KI"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Mwaro",
    "code": "MW"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Ngozi",
    "code": "NG"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Rumonge",
    "code": "RM"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Rutana",
    "code": "RT"
  },
  {
    "country_code": "BI",
    "subdivision_name": "Ruyigi",
    "code": "RY"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Atacora",
    "code": "AK"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Atlantique",
    "code": "AQ"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Borgou",
    "code": "BO"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Collines",
    "code": "CO"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Littoral",
    "code": "LI"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Mono",
    "code": "MO"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Oueme",
    "code": "OU"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Plateau",
    "code": "PL"
  },
  {
    "country_code": "BJ",
    "subdivision_name": "Zou",
    "code": "ZO"
  },
  {
    "country_code": "BL",
    "subdivision_name": "Saint Barthelemy",
    "code": "-"
  },
  {
    "country_code": "BM",
    "subdivision_name": "Hamilton",
    "code": "-"
  },
  {
    "country_code": "BM",
    "subdivision_name": "Saint George",
    "code": "-"
  },
  {
    "country_code": "BN",
    "subdivision_name": "Belait",
    "code": "BE"
  },
  {
    "country_code": "BN",
    "subdivision_name": "Brunei-Muara",
    "code": "BM"
  },
  {
    "country_code": "BN",
    "subdivision_name": "Temburong",
    "code": "TE"
  },
  {
    "country_code": "BN",
    "subdivision_name": "Tutong",
    "code": "TU"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Chuquisaca",
    "code": "H"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Cochabamba",
    "code": "C"
  },
  {
    "country_code": "BO",
    "subdivision_name": "El Beni",
    "code": "B"
  },
  {
    "country_code": "BO",
    "subdivision_name": "La Paz",
    "code": "L"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Oruro",
    "code": "O"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Pando",
    "code": "N"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Potosi",
    "code": "P"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Santa Cruz",
    "code": "S"
  },
  {
    "country_code": "BO",
    "subdivision_name": "Tarija",
    "code": "T"
  },
  {
    "country_code": "BQ",
    "subdivision_name": "Bonaire",
    "code": "BO"
  },
  {
    "country_code": "BQ",
    "subdivision_name": "Saba",
    "code": "SA"
  },
  {
    "country_code": "BQ",
    "subdivision_name": "Sint Eustatius",
    "code": "SE"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Acre",
    "code": "AC"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Alagoas",
    "code": "AL"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Amapa",
    "code": "AP"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Amazonas",
    "code": "AM"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Bahia",
    "code": "BA"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Ceara",
    "code": "CE"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Distrito Federal",
    "code": "DF"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Espirito Santo",
    "code": "ES"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Goias",
    "code": "GO"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Maranhao",
    "code": "MA"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Mato Grosso",
    "code": "MT"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Mato Grosso do Sul",
    "code": "MS"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Minas Gerais",
    "code": "MG"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Para",
    "code": "PA"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Paraiba",
    "code": "PB"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Parana",
    "code": "PR"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Pernambuco",
    "code": "PE"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Piaui",
    "code": "PI"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Rio Grande do Norte",
    "code": "RN"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Rio Grande do Sul",
    "code": "RS"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Rio de Janeiro",
    "code": "RJ"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Rondonia",
    "code": "RO"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Roraima",
    "code": "RR"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Santa Catarina",
    "code": "SC"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Sao Paulo",
    "code": "SP"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Sergipe",
    "code": "SE"
  },
  {
    "country_code": "BR",
    "subdivision_name": "Tocantins",
    "code": "TO"
  },
  {
    "country_code": "BS",
    "subdivision_name": "Black Point",
    "code": "BP"
  },
  {
    "country_code": "BS",
    "subdivision_name": "Central Abaco",
    "code": "CO"
  },
  {
    "country_code": "BS",
    "subdivision_name": "City of Freeport",
    "code": "FP"
  },
  {
    "country_code": "BS",
    "subdivision_name": "East Grand Bahama",
    "code": "EG"
  },
  {
    "country_code": "BS",
    "subdivision_name": "Harbour Island",
    "code": "HI"
  },
  {
    "country_code": "BS",
    "subdivision_name": "Long Island",
    "code": "LI"
  },
  {
    "country_code": "BS",
    "subdivision_name": "Moore's Island",
    "code": "MI"
  },
  {
    "country_code": "BS",
    "subdivision_name": "New Providence",
    "code": "NP"
  },
  {
    "country_code": "BS",
    "subdivision_name": "North Andros",
    "code": "NS"
  },
  {
    "country_code": "BS",
    "subdivision_name": "North Eleuthera",
    "code": "NE"
  },
  {
    "country_code": "BS",
    "subdivision_name": "San Salvador",
    "code": "SS"
  },
  {
    "country_code": "BS",
    "subdivision_name": "South Eleuthera",
    "code": "SE"
  },
  {
    "country_code": "BS",
    "subdivision_name": "West Grand Bahama",
    "code": "WG"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Bumthang",
    "code": "33"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Chhukha",
    "code": "12"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Dagana",
    "code": "22"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Gasa",
    "code": "GA"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Lhuentse",
    "code": "44"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Monggar",
    "code": "42"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Paro",
    "code": "11"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Pema Gatshel",
    "code": "43"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Punakha",
    "code": "23"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Samdrup Jongkhar",
    "code": "45"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Samtse",
    "code": "14"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Sarpang",
    "code": "31"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Thimphu",
    "code": "15"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Trashigang",
    "code": "41"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Trongsa",
    "code": "32"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Tsirang",
    "code": "21"
  },
  {
    "country_code": "BT",
    "subdivision_name": "Wangdue Phodrang",
    "code": "24"
  },
  {
    "country_code": "BV",
    "subdivision_name": "Bouvet Island",
    "code": "-"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Central",
    "code": "CE"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Chobe",
    "code": "CH"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Ghanzi",
    "code": "GH"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Kgalagadi",
    "code": "KG"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Kgatleng",
    "code": "KL"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Kweneng",
    "code": "KW"
  },
  {
    "country_code": "BW",
    "subdivision_name": "North East",
    "code": "NE"
  },
  {
    "country_code": "BW",
    "subdivision_name": "North West",
    "code": "NW"
  },
  {
    "country_code": "BW",
    "subdivision_name": "South East",
    "code": "SE"
  },
  {
    "country_code": "BW",
    "subdivision_name": "Southern",
    "code": "SO"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Brestskaya voblasts'",
    "code": "BR"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Homyel'skaya voblasts'",
    "code": "HO"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Horad Minsk",
    "code": "HM"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Hrodzyenskaya voblasts'",
    "code": "HR"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Mahilyowskaya voblasts'",
    "code": "MA"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Minskaya voblasts'",
    "code": "MI"
  },
  {
    "country_code": "BY",
    "subdivision_name": "Vitsyebskaya voblasts'",
    "code": "VI"
  },
  {
    "country_code": "BZ",
    "subdivision_name": "Belize",
    "code": "BZ"
  },
  {
    "country_code": "BZ",
    "subdivision_name": "Cayo",
    "code": "CY"
  },
  {
    "country_code": "BZ",
    "subdivision_name": "Corozal",
    "code": "CZL"
  },
  {
    "country_code": "BZ",
    "subdivision_name": "Orange Walk",
    "code": "OW"
  },
  {
    "country_code": "BZ",
    "subdivision_name": "Stann Creek",
    "code": "SC"
  },
  {
    "country_code": "BZ",
    "subdivision_name": "Toledo",
    "code": "TOL"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Alberta",
    "code": "AB"
  },
  {
    "country_code": "CA",
    "subdivision_name": "British Columbia",
    "code": "BC"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Manitoba",
    "code": "MB"
  },
  {
    "country_code": "CA",
    "subdivision_name": "New Brunswick",
    "code": "NB"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Newfoundland and Labrador",
    "code": "NL"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Northwest Territories",
    "code": "NT"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Nova Scotia",
    "code": "NS"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Nunavut",
    "code": "NU"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Ontario",
    "code": "ON"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Prince Edward Island",
    "code": "PE"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Quebec",
    "code": "QC"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Saskatchewan",
    "code": "SK"
  },
  {
    "country_code": "CA",
    "subdivision_name": "Yukon",
    "code": "YT"
  },
  {
    "country_code": "CC",
    "subdivision_name": "Cocos (Keeling) Islands",
    "code": "-"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Equateur",
    "code": "EQ"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Haut-Katanga",
    "code": "HK"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Ituri",
    "code": "IT"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Kasai",
    "code": "KS"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Kasai Central",
    "code": "KC"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Kasai Oriental",
    "code": "KE"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Kinshasa",
    "code": "KN"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Kongo Central",
    "code": "BC"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Lomami",
    "code": "LO"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Lualaba",
    "code": "LU"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Maniema",
    "code": "MA"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Nord-Kivu",
    "code": "NK"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Nord-Ubangi",
    "code": "NU"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Sankuru",
    "code": "SA"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Sud-Kivu",
    "code": "SK"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Tanganyika",
    "code": "TA"
  },
  {
    "country_code": "CD",
    "subdivision_name": "Tshopo",
    "code": "TO"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Bamingui-Bangoran",
    "code": "BB"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Bangui",
    "code": "BGF"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Gribingui",
    "code": "KB"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Haut-Mbomou",
    "code": "HM"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Haute-Kotto",
    "code": "HK"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Kemo-Gribingui",
    "code": "KG"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Lobaye",
    "code": "LB"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Mambere-Kadei",
    "code": "HS"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Nana-Mambere",
    "code": "NM"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Ouaka",
    "code": "UK"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Ouham",
    "code": "AC"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Ouham-Pende",
    "code": "OP"
  },
  {
    "country_code": "CF",
    "subdivision_name": "Vakaga",
    "code": "VK"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Bouenza",
    "code": "11"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Brazzaville",
    "code": "BZV"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Cuvette",
    "code": "8"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Likouala",
    "code": "7"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Niari",
    "code": "9"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Pointe-Noire",
    "code": "16"
  },
  {
    "country_code": "CG",
    "subdivision_name": "Sangha",
    "code": "13"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Aargau",
    "code": "AG"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Appenzell Ausserrhoden",
    "code": "AR"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Appenzell Innerrhoden",
    "code": "AI"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Basel-Landschaft",
    "code": "BL"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Basel-Stadt",
    "code": "BS"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Bern",
    "code": "BE"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Fribourg",
    "code": "FR"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Geneve",
    "code": "GE"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Glarus",
    "code": "GL"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Graubunden",
    "code": "GR"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Jura",
    "code": "JU"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Luzern",
    "code": "LU"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Neuchatel",
    "code": "NE"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Nidwalden",
    "code": "NW"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Obwalden",
    "code": "OW"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Sankt Gallen",
    "code": "SG"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Schaffhausen",
    "code": "SH"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Schwyz",
    "code": "SZ"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Solothurn",
    "code": "SO"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Thurgau",
    "code": "TG"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Ticino",
    "code": "TI"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Uri",
    "code": "UR"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Valais",
    "code": "VS"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Vaud",
    "code": "VD"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Zug",
    "code": "ZG"
  },
  {
    "country_code": "CH",
    "subdivision_name": "Zurich",
    "code": "ZH"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Abidjan",
    "code": "AB"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Bas-Sassandra",
    "code": "BS"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Comoe",
    "code": "CM"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Denguele",
    "code": "DN"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Goh-Djiboua",
    "code": "GD"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Lacs",
    "code": "LC"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Lagunes",
    "code": "LG"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Montagnes",
    "code": "MG"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Sassandra-Marahoue",
    "code": "SM"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Savanes",
    "code": "SV"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Vallee du Bandama",
    "code": "VB"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Woroba",
    "code": "WR"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Yamoussoukro",
    "code": "YM"
  },
  {
    "country_code": "CI",
    "subdivision_name": "Zanzan",
    "code": "ZZ"
  },
  {
    "country_code": "CK",
    "subdivision_name": "Cook Islands",
    "code": "-"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Aisen del General Carlos Ibanez del Campo",
    "code": "AI"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Antofagasta",
    "code": "AN"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Arica y Parinacota",
    "code": "AP"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Atacama",
    "code": "AT"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Biobio",
    "code": "BI"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Coquimbo",
    "code": "CO"
  },
  {
    "country_code": "CL",
    "subdivision_name": "La Araucania",
    "code": "AR"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Libertador General Bernardo O'Higgins",
    "code": "LI"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Los Lagos",
    "code": "LL"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Los Rios",
    "code": "LR"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Magallanes",
    "code": "MA"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Maule",
    "code": "ML"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Nuble",
    "code": "NB"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Region Metropolitana de Santiago",
    "code": "RM"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Tarapaca",
    "code": "TA"
  },
  {
    "country_code": "CL",
    "subdivision_name": "Valparaiso",
    "code": "VS"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Adamaoua",
    "code": "AD"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Centre",
    "code": "CE"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Est",
    "code": "ES"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Extreme-Nord",
    "code": "EN"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Littoral",
    "code": "LT"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Nord",
    "code": "NO"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Nord-Ouest",
    "code": "NW"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Ouest",
    "code": "OU"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Sud",
    "code": "SU"
  },
  {
    "country_code": "CM",
    "subdivision_name": "Sud-Ouest",
    "code": "SW"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Anhui",
    "code": "AH"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Beijing",
    "code": "BJ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Chongqing",
    "code": "CQ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Fujian",
    "code": "FJ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Gansu",
    "code": "GS"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Guangdong",
    "code": "GD"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Guangxi Zhuangzu",
    "code": "GX"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Guizhou",
    "code": "GZ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Hainan",
    "code": "HI"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Hebei",
    "code": "HE"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Heilongjiang",
    "code": "HL"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Henan",
    "code": "HA"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Hubei",
    "code": "HB"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Hunan",
    "code": "HN"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Jiangsu",
    "code": "JS"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Jiangxi",
    "code": "JX"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Jilin",
    "code": "JL"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Liaoning",
    "code": "LN"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Nei Mongol",
    "code": "NM"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Ningxia Huizu",
    "code": "NX"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Qinghai",
    "code": "QH"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Shaanxi",
    "code": "SN"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Shandong",
    "code": "SD"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Shanghai",
    "code": "SH"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Shanxi",
    "code": "SX"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Sichuan",
    "code": "SC"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Tianjin",
    "code": "TJ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Xinjiang Uygur",
    "code": "XJ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Xizang",
    "code": "XZ"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Yunnan",
    "code": "YN"
  },
  {
    "country_code": "CN",
    "subdivision_name": "Zhejiang",
    "code": "ZJ"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Amazonas",
    "code": "AMA"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Antioquia",
    "code": "ANT"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Arauca",
    "code": "ARA"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Atlantico",
    "code": "ATL"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Bolivar",
    "code": "BOL"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Boyaca",
    "code": "BOY"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Caldas",
    "code": "CAL"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Caqueta",
    "code": "CAQ"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Casanare",
    "code": "CAS"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Cauca",
    "code": "CAU"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Cesar",
    "code": "CES"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Choco",
    "code": "CHO"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Cordoba",
    "code": "COR"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Cundinamarca",
    "code": "CUN"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Distrito Capital de Bogota",
    "code": "DC"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Guainia",
    "code": "GUA"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Guaviare",
    "code": "GUV"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Huila",
    "code": "HUI"
  },
  {
    "country_code": "CO",
    "subdivision_name": "La Guajira",
    "code": "LAG"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Magdalena",
    "code": "MAG"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Meta",
    "code": "MET"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Narino",
    "code": "NAR"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Norte de Santander",
    "code": "NSA"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Putumayo",
    "code": "PUT"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Quindio",
    "code": "QUI"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Risaralda",
    "code": "RIS"
  },
  {
    "country_code": "CO",
    "subdivision_name": "San Andres, Providencia y Santa Catalina",
    "code": "SAP"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Santander",
    "code": "SAN"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Sucre",
    "code": "SUC"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Tolima",
    "code": "TOL"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Valle del Cauca",
    "code": "VAC"
  },
  {
    "country_code": "CO",
    "subdivision_name": "Vichada",
    "code": "VID"
  },
  {
    "country_code": "CR",
    "subdivision_name": "Alajuela",
    "code": "A"
  },
  {
    "country_code": "CR",
    "subdivision_name": "Cartago",
    "code": "C"
  },
  {
    "country_code": "CR",
    "subdivision_name": "Guanacaste",
    "code": "G"
  },
  {
    "country_code": "CR",
    "subdivision_name": "Heredia",
    "code": "H"
  },
  {
    "country_code": "CR",
    "subdivision_name": "Limon",
    "code": "L"
  },
  {
    "country_code": "CR",
    "subdivision_name": "Puntarenas",
    "code": "P"
  },
  {
    "country_code": "CR",
    "subdivision_name": "San Jose",
    "code": "SJ"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Artemisa",
    "code": "15"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Camaguey",
    "code": "09"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Ciego de Avila",
    "code": "08"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Cienfuegos",
    "code": "06"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Granma",
    "code": "12"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Guantanamo",
    "code": "14"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Holguin",
    "code": "11"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Isla de la Juventud",
    "code": "99"
  },
  {
    "country_code": "CU",
    "subdivision_name": "La Habana",
    "code": "03"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Las Tunas",
    "code": "10"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Matanzas",
    "code": "04"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Mayabeque",
    "code": "16"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Pinar del Rio",
    "code": "01"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Sancti Spiritus",
    "code": "07"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Santiago de Cuba",
    "code": "13"
  },
  {
    "country_code": "CU",
    "subdivision_name": "Villa Clara",
    "code": "05"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Boa Vista",
    "code": "BV"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Paul",
    "code": "PA"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Porto Novo",
    "code": "PN"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Praia",
    "code": "PR"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Ribeira Grande",
    "code": "RG"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Ribeira Grande de Santiago",
    "code": "RS"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Sal",
    "code": "SL"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Sao Domingos",
    "code": "SD"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Sao Filipe",
    "code": "SF"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Sao Salvador do Mundo",
    "code": "SS"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Sao Vicente",
    "code": "SV"
  },
  {
    "country_code": "CV",
    "subdivision_name": "Tarrafal",
    "code": "TA"
  },
  {
    "country_code": "CW",
    "subdivision_name": "Curacao",
    "code": "-"
  },
  {
    "country_code": "CX",
    "subdivision_name": "Christmas Island",
    "code": "-"
  },
  {
    "country_code": "CY",
    "subdivision_name": "Ammochostos",
    "code": "04"
  },
  {
    "country_code": "CY",
    "subdivision_name": "Keryneia",
    "code": "06"
  },
  {
    "country_code": "CY",
    "subdivision_name": "Larnaka",
    "code": "03"
  },
  {
    "country_code": "CY",
    "subdivision_name": "Lefkosia",
    "code": "01"
  },
  {
    "country_code": "CY",
    "subdivision_name": "Lemesos",
    "code": "02"
  },
  {
    "country_code": "CY",
    "subdivision_name": "Pafos",
    "code": "05"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Jihocesky kraj",
    "code": "31"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Jihomoravsky kraj",
    "code": "64"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Karlovarsky kraj",
    "code": "41"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Kraj Vysocina",
    "code": "63"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Kralovehradecky kraj",
    "code": "52"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Liberecky kraj",
    "code": "51"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Moravskoslezsky kraj",
    "code": "80"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Olomoucky kraj",
    "code": "71"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Pardubicky kraj",
    "code": "53"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Plzensky kraj",
    "code": "32"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Praha, Hlavni mesto",
    "code": "10"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Stredocesky kraj",
    "code": "20"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Ustecky kraj",
    "code": "42"
  },
  {
    "country_code": "CZ",
    "subdivision_name": "Zlinsky kraj",
    "code": "72"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Baden-Wurttemberg",
    "code": "BW"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Bayern",
    "code": "BY"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Berlin",
    "code": "BE"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Brandenburg",
    "code": "BB"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Bremen",
    "code": "HB"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Hamburg",
    "code": "HH"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Hessen",
    "code": "HE"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Mecklenburg-Vorpommern",
    "code": "MV"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Niedersachsen",
    "code": "NI"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Nordrhein-Westfalen",
    "code": "NW"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Rheinland-Pfalz",
    "code": "RP"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Saarland",
    "code": "SL"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Sachsen",
    "code": "SN"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Sachsen-Anhalt",
    "code": "ST"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Schleswig-Holstein",
    "code": "SH"
  },
  {
    "country_code": "DE",
    "subdivision_name": "Thuringen",
    "code": "TH"
  },
  {
    "country_code": "DJ",
    "subdivision_name": "Arta",
    "code": "AR"
  },
  {
    "country_code": "DJ",
    "subdivision_name": "Dikhil",
    "code": "DI"
  },
  {
    "country_code": "DJ",
    "subdivision_name": "Djibouti",
    "code": "DJ"
  },
  {
    "country_code": "DK",
    "subdivision_name": "Hovedstaden",
    "code": "84"
  },
  {
    "country_code": "DK",
    "subdivision_name": "Midtjylland",
    "code": "82"
  },
  {
    "country_code": "DK",
    "subdivision_name": "Nordjylland",
    "code": "81"
  },
  {
    "country_code": "DK",
    "subdivision_name": "Sjaelland",
    "code": "85"
  },
  {
    "country_code": "DK",
    "subdivision_name": "Syddanmark",
    "code": "83"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint Andrew",
    "code": "02"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint George",
    "code": "04"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint John",
    "code": "05"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint Joseph",
    "code": "06"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint Luke",
    "code": "07"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint Patrick",
    "code": "09"
  },
  {
    "country_code": "DM",
    "subdivision_name": "Saint Paul",
    "code": "10"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Azua",
    "code": "02"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Baoruco",
    "code": "03"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Barahona",
    "code": "04"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Dajabon",
    "code": "05"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Distrito Nacional (Santo Domingo)",
    "code": "01"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Duarte",
    "code": "06"
  },
  {
    "country_code": "DO",
    "subdivision_name": "El Seibo",
    "code": "08"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Elias Pina",
    "code": "07"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Espaillat",
    "code": "09"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Hato Mayor",
    "code": "30"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Hermanas Mirabal",
    "code": "19"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Independencia",
    "code": "10"
  },
  {
    "country_code": "DO",
    "subdivision_name": "La Altagracia",
    "code": "11"
  },
  {
    "country_code": "DO",
    "subdivision_name": "La Romana",
    "code": "12"
  },
  {
    "country_code": "DO",
    "subdivision_name": "La Vega",
    "code": "13"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Maria Trinidad Sanchez",
    "code": "14"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Monsenor Nouel",
    "code": "28"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Monte Cristi",
    "code": "15"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Monte Plata",
    "code": "29"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Peravia",
    "code": "17"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Puerto Plata",
    "code": "18"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Samana",
    "code": "20"
  },
  {
    "country_code": "DO",
    "subdivision_name": "San Cristobal",
    "code": "21"
  },
  {
    "country_code": "DO",
    "subdivision_name": "San Jose de Ocoa",
    "code": "31"
  },
  {
    "country_code": "DO",
    "subdivision_name": "San Juan",
    "code": "22"
  },
  {
    "country_code": "DO",
    "subdivision_name": "San Pedro de Macoris",
    "code": "23"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Sanchez Ramirez",
    "code": "24"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Santiago",
    "code": "25"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Santiago Rodriguez",
    "code": "26"
  },
  {
    "country_code": "DO",
    "subdivision_name": "Valverde",
    "code": "27"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Adrar",
    "code": "01"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Ain Defla",
    "code": "44"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Ain Temouchent",
    "code": "46"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Alger",
    "code": "16"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Annaba",
    "code": "23"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Batna",
    "code": "05"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Bechar",
    "code": "08"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Bejaia",
    "code": "06"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Biskra",
    "code": "07"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Blida",
    "code": "09"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Bordj Bou Arreridj",
    "code": "34"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Bouira",
    "code": "10"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Boumerdes",
    "code": "35"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Chlef",
    "code": "02"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Constantine",
    "code": "25"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Djanet",
    "code": "56"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Djelfa",
    "code": "17"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "El Bayadh",
    "code": "32"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "El Meghaier",
    "code": "57"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "El Oued",
    "code": "39"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "El Tarf",
    "code": "36"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Ghardaia",
    "code": "47"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Guelma",
    "code": "24"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Illizi",
    "code": "33"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "In Salah",
    "code": "53"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Jijel",
    "code": "18"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Khenchela",
    "code": "40"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Laghouat",
    "code": "03"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "M'sila",
    "code": "28"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Mascara",
    "code": "29"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Medea",
    "code": "26"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Mila",
    "code": "43"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Mostaganem",
    "code": "27"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Naama",
    "code": "45"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Oran",
    "code": "31"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Ouargla",
    "code": "30"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Ouled Djellal",
    "code": "51"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Oum el Bouaghi",
    "code": "04"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Relizane",
    "code": "48"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Saida",
    "code": "20"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Setif",
    "code": "19"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Sidi Bel Abbes",
    "code": "22"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Skikda",
    "code": "21"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Souk Ahras",
    "code": "41"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tamanrasset",
    "code": "11"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tebessa",
    "code": "12"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tiaret",
    "code": "14"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Timimoun",
    "code": "49"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tindouf",
    "code": "37"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tipaza",
    "code": "42"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tissemsilt",
    "code": "38"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tizi Ouzou",
    "code": "15"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Tlemcen",
    "code": "13"
  },
  {
    "country_code": "DZ",
    "subdivision_name": "Touggourt",
    "code": "55"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Azuay",
    "code": "A"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Bolivar",
    "code": "B"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Canar",
    "code": "F"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Carchi",
    "code": "C"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Chimborazo",
    "code": "H"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Cotopaxi",
    "code": "X"
  },
  {
    "country_code": "EC",
    "subdivision_name": "El Oro",
    "code": "O"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Esmeraldas",
    "code": "E"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Galapagos",
    "code": "W"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Guayas",
    "code": "G"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Imbabura",
    "code": "I"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Loja",
    "code": "L"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Los Rios",
    "code": "R"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Manabi",
    "code": "M"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Morona Santiago",
    "code": "S"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Napo",
    "code": "N"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Orellana",
    "code": "D"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Pastaza",
    "code": "Y"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Pichincha",
    "code": "P"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Santa Elena",
    "code": "SE"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Santo Domingo de los Tsachilas",
    "code": "SD"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Sucumbios",
    "code": "U"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Tungurahua",
    "code": "T"
  },
  {
    "country_code": "EC",
    "subdivision_name": "Zamora Chinchipe",
    "code": "Z"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Harjumaa",
    "code": "37"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Hiiumaa",
    "code": "39"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Ida-Virumaa",
    "code": "45"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Jarvamaa",
    "code": "52"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Jogevamaa",
    "code": "50"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Laane-Virumaa",
    "code": "60"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Laanemaa",
    "code": "56"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Parnumaa",
    "code": "68"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Polvamaa",
    "code": "64"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Raplamaa",
    "code": "71"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Saaremaa",
    "code": "74"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Tartumaa",
    "code": "79"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Valgamaa",
    "code": "81"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Viljandimaa",
    "code": "84"
  },
  {
    "country_code": "EE",
    "subdivision_name": "Vorumaa",
    "code": "87"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Ad Daqahliyah",
    "code": "DK"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Bahr al Ahmar",
    "code": "BA"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Buhayrah",
    "code": "BH"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Fayyum",
    "code": "FYM"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Gharbiyah",
    "code": "GH"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Iskandariyah",
    "code": "ALX"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Isma'iliyah",
    "code": "IS"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Jizah",
    "code": "GZ"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Minufiyah",
    "code": "MNF"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Minya",
    "code": "MN"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Qahirah",
    "code": "C"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Qalyubiyah",
    "code": "KB"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Uqsur",
    "code": "LX"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Al Wadi al Jadid",
    "code": "WAD"
  },
  {
    "country_code": "EG",
    "subdivision_name": "As Suways",
    "code": "SUZ"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Ash Sharqiyah",
    "code": "SHR"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Aswan",
    "code": "ASN"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Asyut",
    "code": "AST"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Bani Suwayf",
    "code": "BNS"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Bur Sa'id",
    "code": "PTS"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Dumyat",
    "code": "DT"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Janub Sina'",
    "code": "JS"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Kafr ash Shaykh",
    "code": "KFS"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Matruh",
    "code": "MT"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Qina",
    "code": "KN"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Shamal Sina'",
    "code": "SIN"
  },
  {
    "country_code": "EG",
    "subdivision_name": "Suhaj",
    "code": "SHG"
  },
  {
    "country_code": "EH",
    "subdivision_name": "Western Sahara",
    "code": "-"
  },
  {
    "country_code": "ER",
    "subdivision_name": "Al Awsat",
    "code": "MA"
  },
  {
    "country_code": "ER",
    "subdivision_name": "Qash-Barkah",
    "code": "GB"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Andalucia",
    "code": "AN"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Aragon",
    "code": "AR"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Asturias, Principado de",
    "code": "AS"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Canarias",
    "code": "CN"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Cantabria",
    "code": "CB"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Castilla y Leon",
    "code": "CL"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Castilla-La Mancha",
    "code": "CM"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Catalunya",
    "code": "CT"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Ceuta",
    "code": "CE"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Extremadura",
    "code": "EX"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Galicia",
    "code": "GA"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Illes Balears",
    "code": "IB"
  },
  {
    "country_code": "ES",
    "subdivision_name": "La Rioja",
    "code": "RI"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Madrid, Comunidad de",
    "code": "MD"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Melilla",
    "code": "ML"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Murcia, Region de",
    "code": "MC"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Navarra, Comunidad Foral de",
    "code": "NC"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Pais Vasco",
    "code": "PV"
  },
  {
    "country_code": "ES",
    "subdivision_name": "Valenciana, Comunidad",
    "code": "VC"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Addis Ababa",
    "code": "AA"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Afar",
    "code": "AF"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Amara",
    "code": "AM"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Benshangul-Gumaz",
    "code": "BE"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Dire Dawa",
    "code": "DD"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Harari People",
    "code": "HA"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Oromia",
    "code": "OR"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Somali",
    "code": "SO"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Southern Nations, Nationalities and Peoples",
    "code": "SN"
  },
  {
    "country_code": "ET",
    "subdivision_name": "Tigrai",
    "code": "TI"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Etela-Karjala",
    "code": "02"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Etela-Pohjanmaa",
    "code": "03"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Etela-Savo",
    "code": "04"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Kainuu",
    "code": "05"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Kanta-Hame",
    "code": "06"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Keski-Pohjanmaa",
    "code": "07"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Keski-Suomi",
    "code": "08"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Kymenlaakso",
    "code": "09"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Lappi",
    "code": "10"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Paijat-Hame",
    "code": "16"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Pirkanmaa",
    "code": "11"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Pohjanmaa",
    "code": "12"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Pohjois-Karjala",
    "code": "13"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Pohjois-Pohjanmaa",
    "code": "14"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Pohjois-Savo",
    "code": "15"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Satakunta",
    "code": "17"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Uusimaa",
    "code": "18"
  },
  {
    "country_code": "FI",
    "subdivision_name": "Varsinais-Suomi",
    "code": "19"
  },
  {
    "country_code": "FJ",
    "subdivision_name": "Central",
    "code": "C"
  },
  {
    "country_code": "FJ",
    "subdivision_name": "Eastern",
    "code": "E"
  },
  {
    "country_code": "FJ",
    "subdivision_name": "Northern",
    "code": "N"
  },
  {
    "country_code": "FJ",
    "subdivision_name": "Rotuma",
    "code": "R"
  },
  {
    "country_code": "FJ",
    "subdivision_name": "Western",
    "code": "W"
  },
  {
    "country_code": "FK",
    "subdivision_name": "Falkland Islands (Malvinas)",
    "code": "-"
  },
  {
    "country_code": "FM",
    "subdivision_name": "Chuuk",
    "code": "TRK"
  },
  {
    "country_code": "FM",
    "subdivision_name": "Kosrae",
    "code": "KSA"
  },
  {
    "country_code": "FM",
    "subdivision_name": "Pohnpei",
    "code": "PNI"
  },
  {
    "country_code": "FM",
    "subdivision_name": "Yap",
    "code": "YAP"
  },
  {
    "country_code": "FO",
    "subdivision_name": "Eysturoy",
    "code": "-"
  },
  {
    "country_code": "FO",
    "subdivision_name": "Nordoyar",
    "code": "-"
  },
  {
    "country_code": "FO",
    "subdivision_name": "Streymoy",
    "code": "-"
  },
  {
    "country_code": "FO",
    "subdivision_name": "Suduroy",
    "code": "-"
  },
  {
    "country_code": "FO",
    "subdivision_name": "Vagar",
    "code": "-"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Auvergne-Rhone-Alpes",
    "code": "ARA"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Bourgogne-Franche-Comte",
    "code": "BFC"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Bretagne",
    "code": "BRE"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Centre-Val de Loire",
    "code": "CVL"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Corse",
    "code": "20R"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Grand-Est",
    "code": "GES"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Hauts-de-France",
    "code": "HDF"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Ile-de-France",
    "code": "IDF"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Normandie",
    "code": "NOR"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Nouvelle-Aquitaine",
    "code": "NAQ"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Occitanie",
    "code": "OCC"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Pays-de-la-Loire",
    "code": "PDL"
  },
  {
    "country_code": "FR",
    "subdivision_name": "Provence-Alpes-Cote-d'Azur",
    "code": "PAC"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Estuaire",
    "code": "1"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Haut-Ogooue",
    "code": "2"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Moyen-Ogooue",
    "code": "3"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Ngounie",
    "code": "4"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Nyanga",
    "code": "5"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Ogooue-Lolo",
    "code": "7"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Ogooue-Maritime",
    "code": "8"
  },
  {
    "country_code": "GA",
    "subdivision_name": "Woleu-Ntem",
    "code": "9"
  },
  {
    "country_code": "GB",
    "subdivision_name": "England",
    "code": "ENG"
  },
  {
    "country_code": "GB",
    "subdivision_name": "Northern Ireland",
    "code": "NIR"
  },
  {
    "country_code": "GB",
    "subdivision_name": "Scotland",
    "code": "SCT"
  },
  {
    "country_code": "GB",
    "subdivision_name": "Wales",
    "code": "WLS"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Saint Andrew",
    "code": "01"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Saint David",
    "code": "02"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Saint George",
    "code": "03"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Saint John",
    "code": "04"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Saint Mark",
    "code": "05"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Saint Patrick",
    "code": "06"
  },
  {
    "country_code": "GD",
    "subdivision_name": "Southern Grenadine Islands",
    "code": "10"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Abkhazia",
    "code": "AB"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Ajaria",
    "code": "AJ"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Guria",
    "code": "GU"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Imereti",
    "code": "IM"
  },
  {
    "country_code": "GE",
    "subdivision_name": "K'akheti",
    "code": "KA"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Kvemo Kartli",
    "code": "KK"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Mtskheta-Mtianeti",
    "code": "MM"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Rach'a-Lechkhumi-Kvemo Svaneti",
    "code": "RL"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Samegrelo-Zemo Svaneti",
    "code": "SZ"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Samtskhe-Javakheti",
    "code": "SJ"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Shida Kartli",
    "code": "SK"
  },
  {
    "country_code": "GE",
    "subdivision_name": "Tbilisi",
    "code": "TB"
  },
  {
    "country_code": "GF",
    "subdivision_name": "Guyane",
    "code": "-"
  },
  {
    "country_code": "GG",
    "subdivision_name": "Guernsey",
    "code": "-"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Ahafo",
    "code": "AF"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Ashanti",
    "code": "AH"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Bono",
    "code": "BO"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Bono East",
    "code": "BE"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Central",
    "code": "CP"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Eastern",
    "code": "EP"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Greater Accra",
    "code": "AA"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Northern",
    "code": "NP"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Upper East",
    "code": "UE"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Upper West",
    "code": "UW"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Volta",
    "code": "TV"
  },
  {
    "country_code": "GH",
    "subdivision_name": "Western",
    "code": "WP"
  },
  {
    "country_code": "GI",
    "subdivision_name": "Gibraltar",
    "code": "-"
  },
  {
    "country_code": "GL",
    "subdivision_name": "Avannaata Kommunia",
    "code": "AV"
  },
  {
    "country_code": "GL",
    "subdivision_name": "Kommune Kujalleq",
    "code": "KU"
  },
  {
    "country_code": "GL",
    "subdivision_name": "Kommune Qeqertalik",
    "code": "QT"
  },
  {
    "country_code": "GL",
    "subdivision_name": "Kommuneqarfik Sermersooq",
    "code": "SM"
  },
  {
    "country_code": "GL",
    "subdivision_name": "Qeqqata Kommunia",
    "code": "QE"
  },
  {
    "country_code": "GM",
    "subdivision_name": "Banjul",
    "code": "B"
  },
  {
    "country_code": "GM",
    "subdivision_name": "Central River",
    "code": "M"
  },
  {
    "country_code": "GM",
    "subdivision_name": "Lower River",
    "code": "L"
  },
  {
    "country_code": "GM",
    "subdivision_name": "North Bank",
    "code": "N"
  },
  {
    "country_code": "GM",
    "subdivision_name": "Upper River",
    "code": "U"
  },
  {
    "country_code": "GM",
    "subdivision_name": "Western",
    "code": "W"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Beyla",
    "code": "BE"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Boffa",
    "code": "BF"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Boke",
    "code": "BK"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Conakry",
    "code": "C"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Dabola",
    "code": "DB"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Dinguiraye",
    "code": "DI"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Dubreka",
    "code": "DU"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Fria",
    "code": "FR"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Kankan",
    "code": "KA"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Kouroussa",
    "code": "KO"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Labe",
    "code": "LA"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Mamou",
    "code": "MM"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Pita",
    "code": "PI"
  },
  {
    "country_code": "GN",
    "subdivision_name": "Siguiri",
    "code": "SI"
  },
  {
    "country_code": "GP",
    "subdivision_name": "Guadeloupe",
    "code": "-"
  },
  {
    "country_code": "GQ",
    "subdivision_name": "Bioko Norte",
    "code": "BN"
  },
  {
    "country_code": "GQ",
    "subdivision_name": "Centro Sur",
    "code": "CS"
  },
  {
    "country_code": "GQ",
    "subdivision_name": "Kie-Ntem",
    "code": "KN"
  },
  {
    "country_code": "GQ",
    "subdivision_name": "Litoral",
    "code": "LI"
  },
  {
    "country_code": "GQ",
    "subdivision_name": "Wele-Nzas",
    "code": "WN"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Anatoliki Makedonia kai Thraki",
    "code": "A"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Attiki",
    "code": "I"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Dytiki Ellada",
    "code": "G"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Dytiki Makedonia",
    "code": "C"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Ionia Nisia",
    "code": "F"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Ipeiros",
    "code": "D"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Kentriki Makedonia",
    "code": "B"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Kriti",
    "code": "M"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Notio Aigaio",
    "code": "L"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Peloponnisos",
    "code": "J"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Sterea Ellada",
    "code": "H"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Thessalia",
    "code": "E"
  },
  {
    "country_code": "GR",
    "subdivision_name": "Voreio Aigaio",
    "code": "K"
  },
  {
    "country_code": "GS",
    "subdivision_name": "South Georgia and the South Sandwich Islands",
    "code": "-"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Alta Verapaz",
    "code": "16"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Baja Verapaz",
    "code": "15"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Chimaltenango",
    "code": "04"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Chiquimula",
    "code": "20"
  },
  {
    "country_code": "GT",
    "subdivision_name": "El Progreso",
    "code": "02"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Escuintla",
    "code": "05"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Guatemala",
    "code": "01"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Huehuetenango",
    "code": "13"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Izabal",
    "code": "18"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Jalapa",
    "code": "21"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Jutiapa",
    "code": "22"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Peten",
    "code": "17"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Quetzaltenango",
    "code": "09"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Quiche",
    "code": "14"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Retalhuleu",
    "code": "11"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Sacatepequez",
    "code": "03"
  },
  {
    "country_code": "GT",
    "subdivision_name": "San Marcos",
    "code": "12"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Santa Rosa",
    "code": "06"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Solola",
    "code": "07"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Suchitepequez",
    "code": "10"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Totonicapan",
    "code": "08"
  },
  {
    "country_code": "GT",
    "subdivision_name": "Zacapa",
    "code": "19"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Agana Heights",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Agat",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Barrigada",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Chalan Pago-Ordot",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Dededo",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Hagatna",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Inarajan",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Mangilao",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Mongmong-Toto-Maite",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Piti",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Santa Rita",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Sinajana",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Talofofo",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Tamuning-Tumon-Harmon",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Yigo",
    "code": "-"
  },
  {
    "country_code": "GU",
    "subdivision_name": "Yona",
    "code": "-"
  },
  {
    "country_code": "GW",
    "subdivision_name": "Bafata",
    "code": "BA"
  },
  {
    "country_code": "GW",
    "subdivision_name": "Bissau",
    "code": "BS"
  },
  {
    "country_code": "GW",
    "subdivision_name": "Cacheu",
    "code": "CA"
  },
  {
    "country_code": "GW",
    "subdivision_name": "Gabu",
    "code": "GA"
  },
  {
    "country_code": "GW",
    "subdivision_name": "Oio",
    "code": "OI"
  },
  {
    "country_code": "GW",
    "subdivision_name": "Quinara",
    "code": "QU"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Barima-Waini",
    "code": "BA"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Cuyuni-Mazaruni",
    "code": "CU"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Demerara-Mahaica",
    "code": "DE"
  },
  {
    "country_code": "GY",
    "subdivision_name": "East Berbice-Corentyne",
    "code": "EB"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Essequibo Islands-West Demerara",
    "code": "ES"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Mahaica-Berbice",
    "code": "MA"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Pomeroon-Supenaam",
    "code": "PM"
  },
  {
    "country_code": "GY",
    "subdivision_name": "Upper Demerara-Berbice",
    "code": "UD"
  },
  {
    "country_code": "HK",
    "subdivision_name": "Hong Kong",
    "code": "-"
  },
  {
    "country_code": "HM",
    "subdivision_name": "Heard Island and McDonald Islands",
    "code": "-"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Atlantida",
    "code": "AT"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Choluteca",
    "code": "CH"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Colon",
    "code": "CL"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Comayagua",
    "code": "CM"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Copan",
    "code": "CP"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Cortes",
    "code": "CR"
  },
  {
    "country_code": "HN",
    "subdivision_name": "El Paraiso",
    "code": "EP"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Francisco Morazan",
    "code": "FM"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Intibuca",
    "code": "IN"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Islas de la Bahia",
    "code": "IB"
  },
  {
    "country_code": "HN",
    "subdivision_name": "La Paz",
    "code": "LP"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Lempira",
    "code": "LE"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Ocotepeque",
    "code": "OC"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Olancho",
    "code": "OL"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Santa Barbara",
    "code": "SB"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Valle",
    "code": "VA"
  },
  {
    "country_code": "HN",
    "subdivision_name": "Yoro",
    "code": "YO"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Bjelovarsko-bilogorska zupanija",
    "code": "07"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Brodsko-posavska zupanija",
    "code": "12"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Dubrovacko-neretvanska zupanija",
    "code": "19"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Grad Zagreb",
    "code": "21"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Istarska zupanija",
    "code": "18"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Karlovacka zupanija",
    "code": "04"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Koprivnicko-krizevacka zupanija",
    "code": "06"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Krapinsko-zagorska zupanija",
    "code": "02"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Licko-senjska zupanija",
    "code": "09"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Medimurska zupanija",
    "code": "20"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Osjecko-baranjska zupanija",
    "code": "14"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Pozesko-slavonska zupanija",
    "code": "11"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Primorsko-goranska zupanija",
    "code": "08"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Sibensko-kninska zupanija",
    "code": "15"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Sisacko-moslavacka zupanija",
    "code": "03"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Splitsko-dalmatinska zupanija",
    "code": "17"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Varazdinska zupanija",
    "code": "05"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Viroviticko-podravska zupanija",
    "code": "10"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Vukovarsko-srijemska zupanija",
    "code": "16"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Zadarska zupanija",
    "code": "13"
  },
  {
    "country_code": "HR",
    "subdivision_name": "Zagrebacka zupanija",
    "code": "01"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Artibonite",
    "code": "AR"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Centre",
    "code": "CE"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Grande'Anse",
    "code": "GA"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Nord",
    "code": "ND"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Nord-Ouest",
    "code": "NO"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Ouest",
    "code": "OU"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Sud",
    "code": "SD"
  },
  {
    "country_code": "HT",
    "subdivision_name": "Sud-Est",
    "code": "SE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Bacs-Kiskun",
    "code": "BK"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Baranya",
    "code": "BA"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Bekes",
    "code": "BE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Borsod-Abauj-Zemplen",
    "code": "BZ"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Budapest",
    "code": "BU"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Csongrad-Csanad",
    "code": "CS"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Fejer",
    "code": "FE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Gyor-Moson-Sopron",
    "code": "GS"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Hajdu-Bihar",
    "code": "HB"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Heves",
    "code": "HE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Jasz-Nagykun-Szolnok",
    "code": "JN"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Komarom-Esztergom",
    "code": "KE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Nograd",
    "code": "NO"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Pest",
    "code": "PE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Somogy",
    "code": "SO"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Szabolcs-Szatmar-Bereg",
    "code": "SZ"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Tolna",
    "code": "TO"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Vas",
    "code": "VA"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Veszprem",
    "code": "VE"
  },
  {
    "country_code": "HU",
    "subdivision_name": "Zala",
    "code": "ZA"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Aceh",
    "code": "AC"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Bali",
    "code": "BA"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Banten",
    "code": "BT"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Bengkulu",
    "code": "BE"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Gorontalo",
    "code": "GO"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Jakarta Raya",
    "code": "JK"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Jambi",
    "code": "JA"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Jawa Barat",
    "code": "JB"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Jawa Tengah",
    "code": "JT"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Jawa Timur",
    "code": "JI"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kalimantan Barat",
    "code": "KB"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kalimantan Selatan",
    "code": "KS"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kalimantan Tengah",
    "code": "KT"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kalimantan Timur",
    "code": "KI"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kalimantan Utara",
    "code": "KU"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kepulauan Bangka Belitung",
    "code": "BB"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Kepulauan Riau",
    "code": "KR"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Lampung",
    "code": "LA"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Maluku",
    "code": "ML"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Maluku Utara",
    "code": "MU"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Nusa Tenggara Barat",
    "code": "NB"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Nusa Tenggara Timur",
    "code": "NT"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Papua",
    "code": "PP"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Papua Barat",
    "code": "PB"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Papua Barat Daya",
    "code": "PD"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Papua Pengunungan",
    "code": "PE"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Papua Selatan",
    "code": "PS"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Papua Tengah",
    "code": "PT"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Riau",
    "code": "RI"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sulawesi Barat",
    "code": "SR"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sulawesi Selatan",
    "code": "SN"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sulawesi Tengah",
    "code": "ST"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sulawesi Tenggara",
    "code": "SG"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sulawesi Utara",
    "code": "SA"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sumatera Barat",
    "code": "SB"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sumatera Selatan",
    "code": "SS"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Sumatera Utara",
    "code": "SU"
  },
  {
    "country_code": "ID",
    "subdivision_name": "Yogyakarta",
    "code": "YO"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Carlow",
    "code": "CW"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Cavan",
    "code": "CN"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Clare",
    "code": "CE"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Cork",
    "code": "CO"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Donegal",
    "code": "DL"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Dublin",
    "code": "D"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Galway",
    "code": "G"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Kerry",
    "code": "KY"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Kildare",
    "code": "KE"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Kilkenny",
    "code": "KK"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Laois",
    "code": "LS"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Leitrim",
    "code": "LM"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Limerick",
    "code": "LK"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Longford",
    "code": "LD"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Louth",
    "code": "LH"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Mayo",
    "code": "MO"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Meath",
    "code": "MH"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Monaghan",
    "code": "MN"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Offaly",
    "code": "OY"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Roscommon",
    "code": "RN"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Sligo",
    "code": "SO"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Tipperary",
    "code": "TA"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Waterford",
    "code": "WD"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Westmeath",
    "code": "WH"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Wexford",
    "code": "WX"
  },
  {
    "country_code": "IE",
    "subdivision_name": "Wicklow",
    "code": "WW"
  },
  {
    "country_code": "IL",
    "subdivision_name": "HaDarom",
    "code": "D"
  },
  {
    "country_code": "IL",
    "subdivision_name": "HaMerkaz",
    "code": "M"
  },
  {
    "country_code": "IL",
    "subdivision_name": "HaTsafon",
    "code": "Z"
  },
  {
    "country_code": "IL",
    "subdivision_name": "Hefa",
    "code": "HA"
  },
  {
    "country_code": "IL",
    "subdivision_name": "Tel Aviv",
    "code": "TA"
  },
  {
    "country_code": "IL",
    "subdivision_name": "Yerushalayim",
    "code": "JM"
  },
  {
    "country_code": "IM",
    "subdivision_name": "Isle of Man",
    "code": "-"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Andaman and Nicobar Islands",
    "code": "AN"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Andhra Pradesh",
    "code": "AP"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Arunachal Pradesh",
    "code": "AR"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Assam",
    "code": "AS"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Bihar",
    "code": "BR"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Chandigarh",
    "code": "CH"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Chhattisgarh",
    "code": "CG"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Dadra and Nagar Haveli and Daman and Diu",
    "code": "DH"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Delhi",
    "code": "DL"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Goa",
    "code": "GA"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Gujarat",
    "code": "GJ"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Haryana",
    "code": "HR"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Himachal Pradesh",
    "code": "HP"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Jammu and Kashmir",
    "code": "JK"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Jharkhand",
    "code": "JH"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Karnataka",
    "code": "KA"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Kerala",
    "code": "KL"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Lakshadweep",
    "code": "LD"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Madhya Pradesh",
    "code": "MP"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Maharashtra",
    "code": "MH"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Manipur",
    "code": "MN"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Meghalaya",
    "code": "ML"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Mizoram",
    "code": "MZ"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Nagaland",
    "code": "NL"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Odisha",
    "code": "OD"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Puducherry",
    "code": "PY"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Punjab",
    "code": "PB"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Rajasthan",
    "code": "RJ"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Sikkim",
    "code": "SK"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Tamil Nadu",
    "code": "TN"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Telangana",
    "code": "TS"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Tripura",
    "code": "TR"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Uttar Pradesh",
    "code": "UP"
  },
  {
    "country_code": "IN",
    "subdivision_name": "Uttarakhand",
    "code": "UK"
  },
  {
    "country_code": "IN",
    "subdivision_name": "West Bengal",
    "code": "WB"
  },
  {
    "country_code": "IO",
    "subdivision_name": "British Indian Ocean Territory",
    "code": "-"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Al Anbar",
    "code": "AN"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Al Basrah",
    "code": "BA"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Al Muthanna",
    "code": "MU"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Al Qadisiyah",
    "code": "QA"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "An Najaf",
    "code": "NA"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Arbil",
    "code": "AR"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "As Sulaymaniyah",
    "code": "SU"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Babil",
    "code": "BB"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Baghdad",
    "code": "BG"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Dahuk",
    "code": "DA"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Dhi Qar",
    "code": "DQ"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Diyala",
    "code": "DI"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Karbala'",
    "code": "KA"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Kirkuk",
    "code": "KI"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Maysan",
    "code": "MA"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Ninawa",
    "code": "NI"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Salah ad Din",
    "code": "SD"
  },
  {
    "country_code": "IQ",
    "subdivision_name": "Wasit",
    "code": "WA"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Alborz",
    "code": "30"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Ardabil",
    "code": "24"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Azarbayjan-e Gharbi",
    "code": "04"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Azarbayjan-e Sharqi",
    "code": "03"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Bushehr",
    "code": "18"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Chahar Mahal va Bakhtiari",
    "code": "14"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Esfahan",
    "code": "10"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Fars",
    "code": "07"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Gilan",
    "code": "01"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Golestan",
    "code": "27"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Hamadan",
    "code": "13"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Hormozgan",
    "code": "22"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Ilam",
    "code": "16"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Kerman",
    "code": "08"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Kermanshah",
    "code": "05"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Khorasan-e Jonubi",
    "code": "29"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Khorasan-e Razavi",
    "code": "09"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Khorasan-e Shomali",
    "code": "28"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Khuzestan",
    "code": "06"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Kohgiluyeh va Bowyer Ahmad",
    "code": "17"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Kordestan",
    "code": "12"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Lorestan",
    "code": "15"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Markazi",
    "code": "00"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Mazandaran",
    "code": "02"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Qazvin",
    "code": "26"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Qom",
    "code": "25"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Semnan",
    "code": "20"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Sistan va Baluchestan",
    "code": "11"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Tehran",
    "code": "23"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Yazd",
    "code": "21"
  },
  {
    "country_code": "IR",
    "subdivision_name": "Zanjan",
    "code": "19"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Austurland",
    "code": "7"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Hofudborgarsvaedi",
    "code": "1"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Nordurland eystra",
    "code": "6"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Nordurland vestra",
    "code": "5"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Sudurland",
    "code": "8"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Sudurnes",
    "code": "2"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Vestfirdir",
    "code": "4"
  },
  {
    "country_code": "IS",
    "subdivision_name": "Vesturland",
    "code": "3"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Abruzzo",
    "code": "65"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Basilicata",
    "code": "77"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Calabria",
    "code": "78"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Campania",
    "code": "72"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Emilia-Romagna",
    "code": "45"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Friuli-Venezia Giulia",
    "code": "36"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Lazio",
    "code": "62"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Liguria",
    "code": "42"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Lombardia",
    "code": "25"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Marche",
    "code": "57"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Molise",
    "code": "67"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Piemonte",
    "code": "21"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Puglia",
    "code": "75"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Sardegna",
    "code": "88"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Sicilia",
    "code": "82"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Toscana",
    "code": "52"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Trentino-Alto Adige",
    "code": "32"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Umbria",
    "code": "55"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Valle d'Aosta",
    "code": "23"
  },
  {
    "country_code": "IT",
    "subdivision_name": "Veneto",
    "code": "34"
  },
  {
    "country_code": "JE",
    "subdivision_name": "Jersey",
    "code": "-"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Clarendon",
    "code": "13"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Hanover",
    "code": "09"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Kingston",
    "code": "01"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Manchester",
    "code": "12"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Portland",
    "code": "04"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint Andrew",
    "code": "02"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint Ann",
    "code": "06"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint Catherine",
    "code": "14"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint Elizabeth",
    "code": "11"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint James",
    "code": "08"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint Mary",
    "code": "05"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Saint Thomas",
    "code": "03"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Trelawny",
    "code": "07"
  },
  {
    "country_code": "JM",
    "subdivision_name": "Westmoreland",
    "code": "10"
  },
  {
    "country_code": "JO",
    "subdivision_name": "'Ajlun",
    "code": "AJ"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Al 'Aqabah",
    "code": "AQ"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Al 'Asimah",
    "code": "AM"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Al Balqa'",
    "code": "BA"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Al Karak",
    "code": "KA"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Al Mafraq",
    "code": "MA"
  },
  {
    "country_code": "JO",
    "subdivision_name": "At Tafilah",
    "code": "AT"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Az Zarqa'",
    "code": "AZ"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Irbid",
    "code": "IR"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Jarash",
    "code": "JA"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Ma'an",
    "code": "MN"
  },
  {
    "country_code": "JO",
    "subdivision_name": "Madaba",
    "code": "MD"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Aichi",
    "code": "23"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Akita",
    "code": "05"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Aomori",
    "code": "02"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Chiba",
    "code": "12"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Ehime",
    "code": "38"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Fukui",
    "code": "18"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Fukuoka",
    "code": "40"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Fukushima",
    "code": "07"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Gifu",
    "code": "21"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Gunma",
    "code": "10"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Hiroshima",
    "code": "34"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Hokkaido",
    "code": "01"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Hyogo",
    "code": "28"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Ibaraki",
    "code": "08"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Ishikawa",
    "code": "17"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Iwate",
    "code": "03"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Kagawa",
    "code": "37"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Kagoshima",
    "code": "46"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Kanagawa",
    "code": "14"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Kochi",
    "code": "39"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Kumamoto",
    "code": "43"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Kyoto",
    "code": "26"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Mie",
    "code": "24"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Miyagi",
    "code": "04"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Miyazaki",
    "code": "45"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Nagano",
    "code": "20"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Nagasaki",
    "code": "42"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Nara",
    "code": "29"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Niigata",
    "code": "15"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Oita",
    "code": "44"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Okayama",
    "code": "33"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Okinawa",
    "code": "47"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Osaka",
    "code": "27"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Saga",
    "code": "41"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Saitama",
    "code": "11"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Shiga",
    "code": "25"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Shimane",
    "code": "32"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Shizuoka",
    "code": "22"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Tochigi",
    "code": "09"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Tokushima",
    "code": "36"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Tokyo",
    "code": "13"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Tottori",
    "code": "31"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Toyama",
    "code": "16"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Wakayama",
    "code": "30"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Yamagata",
    "code": "06"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Yamaguchi",
    "code": "35"
  },
  {
    "country_code": "JP",
    "subdivision_name": "Yamanashi",
    "code": "19"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Baringo",
    "code": "01"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Bomet",
    "code": "02"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Bungoma",
    "code": "03"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Busia",
    "code": "04"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Elgeyo/Marakwet",
    "code": "05"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Embu",
    "code": "06"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Garissa",
    "code": "07"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Homa Bay",
    "code": "08"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Isiolo",
    "code": "09"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kajiado",
    "code": "10"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kakamega",
    "code": "11"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kericho",
    "code": "12"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kiambu",
    "code": "13"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kilifi",
    "code": "14"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kirinyaga",
    "code": "15"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kisii",
    "code": "16"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kisumu",
    "code": "17"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kitui",
    "code": "18"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Kwale",
    "code": "19"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Laikipia",
    "code": "20"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Lamu",
    "code": "21"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Machakos",
    "code": "22"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Makueni",
    "code": "23"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Mandera",
    "code": "24"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Marsabit",
    "code": "25"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Meru",
    "code": "26"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Migori",
    "code": "27"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Mombasa",
    "code": "28"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Murang'a",
    "code": "29"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Nairobi City",
    "code": "30"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Nakuru",
    "code": "31"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Nandi",
    "code": "32"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Narok",
    "code": "33"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Nyamira",
    "code": "34"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Nyandarua",
    "code": "35"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Nyeri",
    "code": "36"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Samburu",
    "code": "37"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Siaya",
    "code": "38"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Taita/Taveta",
    "code": "39"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Tana River",
    "code": "40"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Tharaka-Nithi",
    "code": "41"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Trans Nzoia",
    "code": "42"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Turkana",
    "code": "43"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Uasin Gishu",
    "code": "44"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Vihiga",
    "code": "45"
  },
  {
    "country_code": "KE",
    "subdivision_name": "Wajir",
    "code": "46"
  },
  {
    "country_code": "KE",
    "subdivision_name": "West Pokot",
    "code": "47"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Batken",
    "code": "B"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Bishkek Shaary",
    "code": "GB"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Chuy",
    "code": "C"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Jalal-Abad",
    "code": "J"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Naryn",
    "code": "N"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Osh Shaary",
    "code": "GO"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Talas",
    "code": "T"
  },
  {
    "country_code": "KG",
    "subdivision_name": "Ysyk-Kol",
    "code": "Y"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Baat Dambang",
    "code": "2"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Banteay Mean Choay",
    "code": "1"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kaeb",
    "code": "23"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kampong Chaam",
    "code": "3"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kampong Chhnang",
    "code": "4"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kampong Spueu",
    "code": "5"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kampong Thum",
    "code": "6"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kampot",
    "code": "7"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kandaal",
    "code": "8"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kaoh Kong",
    "code": "9"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Kracheh",
    "code": "10"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Mondol Kiri",
    "code": "11"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Otdar Mean Chey",
    "code": "22"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Pailin",
    "code": "24"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Phnom Penh",
    "code": "12"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Preah Sihanouk",
    "code": "18"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Preah Vihear",
    "code": "13"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Prey Veaeng",
    "code": "14"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Rotanak Kiri",
    "code": "16"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Siem Reab",
    "code": "17"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Stueng Traeng",
    "code": "19"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Svaay Rieng",
    "code": "20"
  },
  {
    "country_code": "KH",
    "subdivision_name": "Taakaev",
    "code": "21"
  },
  {
    "country_code": "KI",
    "subdivision_name": "Gilbert Islands",
    "code": "G"
  },
  {
    "country_code": "KI",
    "subdivision_name": "Line Islands",
    "code": "L"
  },
  {
    "country_code": "KM",
    "subdivision_name": "Grande Comore",
    "code": "G"
  },
  {
    "country_code": "KM",
    "subdivision_name": "Moheli",
    "code": "M"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Christ Church Nichola Town",
    "code": "01"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Anne Sandy Point",
    "code": "02"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint George Basseterre",
    "code": "03"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint James Windward",
    "code": "05"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint John Capisterre",
    "code": "06"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint John Figtree",
    "code": "07"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Mary Cayon",
    "code": "08"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Paul Capisterre",
    "code": "09"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Paul Charlestown",
    "code": "10"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Peter Basseterre",
    "code": "11"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Thomas Lowland",
    "code": "12"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Saint Thomas Middle Island",
    "code": "13"
  },
  {
    "country_code": "KN",
    "subdivision_name": "Trinity Palmetto Point",
    "code": "15"
  },
  {
    "country_code": "KP",
    "subdivision_name": "P'yongyang",
    "code": "01"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Busan-gwangyeoksi",
    "code": "26"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Chungcheongbuk-do",
    "code": "43"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Chungcheongnam-do",
    "code": "44"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Daegu-gwangyeoksi",
    "code": "27"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Daejeon-gwangyeoksi",
    "code": "30"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Gangwon-teukbyeoljachido",
    "code": "42"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Gwangju-gwangyeoksi",
    "code": "29"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Gyeonggi-do",
    "code": "41"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Gyeongsangbuk-do",
    "code": "47"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Gyeongsangnam-do",
    "code": "48"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Incheon-gwangyeoksi",
    "code": "28"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Jeju-teukbyeoljachido",
    "code": "49"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Jeollabuk-do",
    "code": "45"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Jeollanam-do",
    "code": "46"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Sejong",
    "code": "50"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Seoul-teukbyeolsi",
    "code": "11"
  },
  {
    "country_code": "KR",
    "subdivision_name": "Ulsan-gwangyeoksi",
    "code": "31"
  },
  {
    "country_code": "KW",
    "subdivision_name": "Al 'Asimah",
    "code": "KU"
  },
  {
    "country_code": "KW",
    "subdivision_name": "Al Ahmadi",
    "code": "AH"
  },
  {
    "country_code": "KW",
    "subdivision_name": "Al Farwaniyah",
    "code": "FA"
  },
  {
    "country_code": "KW",
    "subdivision_name": "Al Jahra'",
    "code": "JA"
  },
  {
    "country_code": "KW",
    "subdivision_name": "Hawalli",
    "code": "HA"
  },
  {
    "country_code": "KW",
    "subdivision_name": "Mubarak al Kabir",
    "code": "MU"
  },
  {
    "country_code": "KY",
    "subdivision_name": "Cayman Islands",
    "code": "-"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Abay oblysy",
    "code": "10"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Almaty",
    "code": "75"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Almaty oblysy",
    "code": "19"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Aqmola oblysy",
    "code": "11"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Aqtobe oblysy",
    "code": "15"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Astana",
    "code": "71"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Atyrau oblysy",
    "code": "23"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Batys Qazaqstan oblysy",
    "code": "27"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Mangghystau oblysy",
    "code": "47"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Pavlodar oblysy",
    "code": "55"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Qaraghandy oblysy",
    "code": "35"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Qostanay oblysy",
    "code": "39"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Qyzylorda oblysy",
    "code": "43"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Shyghys Qazaqstan oblysy",
    "code": "63"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Shymkent",
    "code": "79"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Soltustik Qazaqstan oblysy",
    "code": "59"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Turkistan oblysy",
    "code": "61"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Ulytau oblysy",
    "code": "62"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Zhambyl oblysy",
    "code": "31"
  },
  {
    "country_code": "KZ",
    "subdivision_name": "Zhetisu oblysy",
    "code": "33"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Attapu",
    "code": "AT"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Bokeo",
    "code": "BK"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Bolikhamxai",
    "code": "BL"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Champasak",
    "code": "CH"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Khammouan",
    "code": "KH"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Louang Namtha",
    "code": "LM"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Louangphabang",
    "code": "LP"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Oudomxai",
    "code": "OU"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Phongsali",
    "code": "PH"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Savannakhet",
    "code": "SV"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Viangchan",
    "code": "VI"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Xaignabouli",
    "code": "XA"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Xekong",
    "code": "XE"
  },
  {
    "country_code": "LA",
    "subdivision_name": "Xiangkhouang",
    "code": "XI"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Aakkar",
    "code": "AK"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Baalbek-Hermel",
    "code": "BH"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Beqaa",
    "code": "BI"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Beyrouth",
    "code": "BA"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Liban-Nord",
    "code": "AS"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Liban-Sud",
    "code": "JA"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Mont-Liban",
    "code": "JL"
  },
  {
    "country_code": "LB",
    "subdivision_name": "Nabatiye",
    "code": "NA"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Anse la Raye",
    "code": "01"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Castries",
    "code": "02"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Choiseul",
    "code": "03"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Dennery",
    "code": "05"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Gros Islet",
    "code": "06"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Laborie",
    "code": "07"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Micoud",
    "code": "08"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Soufriere",
    "code": "10"
  },
  {
    "country_code": "LC",
    "subdivision_name": "Vieux Fort",
    "code": "11"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Balzers",
    "code": "01"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Eschen",
    "code": "02"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Gamprin",
    "code": "03"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Mauren",
    "code": "04"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Ruggell",
    "code": "06"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Schaan",
    "code": "07"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Triesen",
    "code": "09"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Triesenberg",
    "code": "10"
  },
  {
    "country_code": "LI",
    "subdivision_name": "Vaduz",
    "code": "11"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Central Province",
    "code": "2"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Eastern Province",
    "code": "5"
  },
  {
    "country_code": "LK",
    "subdivision_name": "North Central Province",
    "code": "7"
  },
  {
    "country_code": "LK",
    "subdivision_name": "North Western Province",
    "code": "6"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Northern Province",
    "code": "4"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Sabaragamuwa Province",
    "code": "9"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Southern Province",
    "code": "3"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Uva Province",
    "code": "8"
  },
  {
    "country_code": "LK",
    "subdivision_name": "Western Province",
    "code": "1"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Bomi",
    "code": "BM"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Bong",
    "code": "BG"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Grand Cape Mount",
    "code": "CM"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Grand Gedeh",
    "code": "GG"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Margibi",
    "code": "MG"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Montserrado",
    "code": "MO"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Nimba",
    "code": "NI"
  },
  {
    "country_code": "LR",
    "subdivision_name": "River Cess",
    "code": "RI"
  },
  {
    "country_code": "LR",
    "subdivision_name": "River Gee",
    "code": "RG"
  },
  {
    "country_code": "LR",
    "subdivision_name": "Sinoe",
    "code": "SI"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Berea",
    "code": "D"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Botha-Bothe",
    "code": "B"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Leribe",
    "code": "C"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Mafeteng",
    "code": "E"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Maseru",
    "code": "A"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Mohale's Hoek",
    "code": "F"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Mokhotlong",
    "code": "J"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Qacha's Nek",
    "code": "H"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Quthing",
    "code": "G"
  },
  {
    "country_code": "LS",
    "subdivision_name": "Thaba-Tseka",
    "code": "K"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Alytaus apskritis",
    "code": "AL"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Kauno apskritis",
    "code": "KU"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Klaipedos apskritis",
    "code": "KL"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Marijampoles apskritis",
    "code": "MR"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Panevezio apskritis",
    "code": "PN"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Siauliu apskritis",
    "code": "SA"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Taurages apskritis",
    "code": "TA"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Telsiu apskritis",
    "code": "TE"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Utenos apskritis",
    "code": "UT"
  },
  {
    "country_code": "LT",
    "subdivision_name": "Vilniaus apskritis",
    "code": "VL"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Capellen",
    "code": "CA"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Clervaux",
    "code": "CL"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Diekirch",
    "code": "DI"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Echternach",
    "code": "EC"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Esch-sur-Alzette",
    "code": "ES"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Grevenmacher",
    "code": "GR"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Luxembourg",
    "code": "LU"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Mersch",
    "code": "ME"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Redange",
    "code": "RD"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Remich",
    "code": "RM"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Vianden",
    "code": "VD"
  },
  {
    "country_code": "LU",
    "subdivision_name": "Wiltz",
    "code": "WI"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Adazu novads",
    "code": "011"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Aizkraukles novads",
    "code": "002"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Aluksnes novads",
    "code": "007"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Augsdaugavas novads",
    "code": "111"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Balvu novads",
    "code": "015"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Bauskas novads",
    "code": "016"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Cesu novads",
    "code": "022"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Daugavpils",
    "code": "DGV"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Dienvidkurzemes novads",
    "code": "112"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Dobeles novads",
    "code": "026"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Gulbenes novads",
    "code": "033"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Jekabpils novads",
    "code": "042"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Jelgava",
    "code": "JEL"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Jelgavas novads",
    "code": "041"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Jurmala",
    "code": "JUR"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Kekavas novads",
    "code": "052"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Kraslavas novads",
    "code": "047"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Kuldigas novads",
    "code": "050"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Liepaja",
    "code": "LPX"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Limbazu novads",
    "code": "054"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Livanu novads",
    "code": "056"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Ludzas novads",
    "code": "058"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Madonas novads",
    "code": "059"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Marupes novads",
    "code": "062"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Ogres novads",
    "code": "067"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Olaines novads",
    "code": "068"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Preilu novads",
    "code": "073"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Rezeknes novads",
    "code": "077"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Riga",
    "code": "RIX"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Ropazu novads",
    "code": "080"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Salaspils novads",
    "code": "087"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Saldus novads",
    "code": "088"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Saulkrastu novads",
    "code": "089"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Siguldas novads",
    "code": "091"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Smiltenes novads",
    "code": "094"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Talsu novads",
    "code": "097"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Tukuma novads",
    "code": "099"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Valkas novads",
    "code": "101"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Valmieras novads",
    "code": "113"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Varaklanu novads",
    "code": "102"
  },
  {
    "country_code": "LV",
    "subdivision_name": "Ventspils novads",
    "code": "106"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Butnan",
    "code": "BU"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Jabal al Akhdar",
    "code": "JA"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Jabal al Gharbi",
    "code": "JG"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Jafarah",
    "code": "JI"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Jufrah",
    "code": "JU"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Kufrah",
    "code": "KF"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Marj",
    "code": "MJ"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Marqab",
    "code": "MB"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Al Wahat",
    "code": "WA"
  },
  {
    "country_code": "LY",
    "subdivision_name": "An Nuqat al Khams",
    "code": "NQ"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Az Zawiyah",
    "code": "ZA"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Banghazi",
    "code": "BA"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Darnah",
    "code": "DR"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Misratah",
    "code": "MI"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Murzuq",
    "code": "MQ"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Nalut",
    "code": "NL"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Sabha",
    "code": "SB"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Surt",
    "code": "SR"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Tarabulus",
    "code": "TB"
  },
  {
    "country_code": "LY",
    "subdivision_name": "Wadi ash Shati'",
    "code": "WS"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Beni-Mellal-Khenifra",
    "code": "05"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Casablanca-Settat",
    "code": "06"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Draa-Tafilalet",
    "code": "08"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Fes- Meknes",
    "code": "03"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Guelmim-Oued Noun (EH-partial)",
    "code": "10"
  },
  {
    "country_code": "MA",
    "subdivision_name": "L'Oriental",
    "code": "02"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Laayoune-Sakia El Hamra (EH-partial)",
    "code": "11"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Marrakech-Safi",
    "code": "07"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Rabat-Sale-Kenitra",
    "code": "04"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Souss-Massa",
    "code": "09"
  },
  {
    "country_code": "MA",
    "subdivision_name": "Tanger-Tetouan-Al Hoceima",
    "code": "01"
  },
  {
    "country_code": "MC",
    "subdivision_name": "Fontvieille",
    "code": "FO"
  },
  {
    "country_code": "MC",
    "subdivision_name": "La Condamine",
    "code": "CO"
  },
  {
    "country_code": "MC",
    "subdivision_name": "Monaco-Ville",
    "code": "MO"
  },
  {
    "country_code": "MC",
    "subdivision_name": "Moneghetti",
    "code": "MG"
  },
  {
    "country_code": "MC",
    "subdivision_name": "Monte-Carlo",
    "code": "MC"
  },
  {
    "country_code": "MC",
    "subdivision_name": "Saint-Roman",
    "code": "SR"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Anenii Noi",
    "code": "AN"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Balti",
    "code": "BA"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Basarabeasca",
    "code": "BS"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Bender",
    "code": "BD"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Briceni",
    "code": "BR"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Cahul",
    "code": "CA"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Calarasi",
    "code": "CL"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Cantemir",
    "code": "CT"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Causeni",
    "code": "CS"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Chisinau",
    "code": "CU"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Cimislia",
    "code": "CM"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Criuleni",
    "code": "CR"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Donduseni",
    "code": "DO"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Drochia",
    "code": "DR"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Dubasari",
    "code": "DU"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Edinet",
    "code": "ED"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Falesti",
    "code": "FA"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Floresti",
    "code": "FL"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Gagauzia, Unitatea teritoriala autonoma",
    "code": "GA"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Glodeni",
    "code": "GL"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Hincesti",
    "code": "HI"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Ialoveni",
    "code": "IA"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Leova",
    "code": "LE"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Nisporeni",
    "code": "NI"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Ocnita",
    "code": "OC"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Orhei",
    "code": "OR"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Rezina",
    "code": "RE"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Riscani",
    "code": "RI"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Singerei",
    "code": "SI"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Soldanesti",
    "code": "SD"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Soroca",
    "code": "SO"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Stefan Voda",
    "code": "SV"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Stinga Nistrului, unitatea teritoriala din",
    "code": "SN"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Straseni",
    "code": "ST"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Taraclia",
    "code": "TA"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Telenesti",
    "code": "TE"
  },
  {
    "country_code": "MD",
    "subdivision_name": "Ungheni",
    "code": "UN"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Andrijevica",
    "code": "01"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Bar",
    "code": "02"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Berane",
    "code": "03"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Bijelo Polje",
    "code": "04"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Budva",
    "code": "05"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Cetinje",
    "code": "06"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Danilovgrad",
    "code": "07"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Herceg-Novi",
    "code": "08"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Kolasin",
    "code": "09"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Kotor",
    "code": "10"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Niksic",
    "code": "12"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Plav",
    "code": "13"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Pljevlja",
    "code": "14"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Pluzine",
    "code": "15"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Podgorica",
    "code": "16"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Rozaje",
    "code": "17"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Tivat",
    "code": "19"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Tuzi",
    "code": "24"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Ulcinj",
    "code": "20"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Zabljak",
    "code": "21"
  },
  {
    "country_code": "ME",
    "subdivision_name": "Zeta",
    "code": "25"
  },
  {
    "country_code": "MF",
    "subdivision_name": "Saint Martin (French Part)",
    "code": "-"
  },
  {
    "country_code": "MG",
    "subdivision_name": "Antananarivo",
    "code": "T"
  },
  {
    "country_code": "MG",
    "subdivision_name": "Antsiranana",
    "code": "D"
  },
  {
    "country_code": "MG",
    "subdivision_name": "Fianarantsoa",
    "code": "F"
  },
  {
    "country_code": "MG",
    "subdivision_name": "Mahajanga",
    "code": "M"
  },
  {
    "country_code": "MG",
    "subdivision_name": "Toamasina",
    "code": "A"
  },
  {
    "country_code": "MG",
    "subdivision_name": "Toliara",
    "code": "U"
  },
  {
    "country_code": "MH",
    "subdivision_name": "Kwajalein",
    "code": "KWA"
  },
  {
    "country_code": "MH",
    "subdivision_name": "Majuro",
    "code": "MAJ"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Berovo",
    "code": "201"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Bitola",
    "code": "501"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Bogdanci",
    "code": "401"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Bogovinje",
    "code": "601"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Bosilovo",
    "code": "402"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Brvenica",
    "code": "602"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Butel",
    "code": "803"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Caska",
    "code": "109"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Centar",
    "code": "814"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Centar Zupa",
    "code": "313"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Cucer Sandevo",
    "code": "816"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Debar",
    "code": "303"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Delcevo",
    "code": "203"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Demir Hisar",
    "code": "502"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Dojran",
    "code": "406"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Dolneni",
    "code": "503"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Gazi Baba",
    "code": "804"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Gevgelija",
    "code": "405"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Gostivar",
    "code": "604"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Ilinden",
    "code": "807"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Jegunovce",
    "code": "606"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Karbinci",
    "code": "205"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kavadarci",
    "code": "104"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kicevo",
    "code": "307"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kisela Voda",
    "code": "809"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kocani",
    "code": "206"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Konce",
    "code": "407"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kratovo",
    "code": "701"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kriva Palanka",
    "code": "702"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Krivogastani",
    "code": "504"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Kumanovo",
    "code": "703"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Lipkovo",
    "code": "704"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Makedonska Kamenica",
    "code": "207"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Makedonski Brod",
    "code": "308"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Mavrovo i Rostusa",
    "code": "607"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Mogila",
    "code": "506"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Negotino",
    "code": "106"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Novaci",
    "code": "507"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Novo Selo",
    "code": "408"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Ohrid",
    "code": "310"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Petrovec",
    "code": "810"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Plasnica",
    "code": "311"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Prilep",
    "code": "508"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Probistip",
    "code": "209"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Radovis",
    "code": "409"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Rankovce",
    "code": "705"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Resen",
    "code": "509"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Rosoman",
    "code": "107"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Saraj",
    "code": "811"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Sopiste",
    "code": "812"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Stip",
    "code": "211"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Struga",
    "code": "312"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Strumica",
    "code": "410"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Studenicani",
    "code": "813"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Sveti Nikole",
    "code": "108"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Tearce",
    "code": "608"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Tetovo",
    "code": "609"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Valandovo",
    "code": "403"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Vasilevo",
    "code": "404"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Veles",
    "code": "101"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Vevcani",
    "code": "301"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Vinica",
    "code": "202"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Vrapciste",
    "code": "603"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Zelenikovo",
    "code": "806"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Zelino",
    "code": "605"
  },
  {
    "country_code": "MK",
    "subdivision_name": "Zrnovci",
    "code": "204"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Bamako",
    "code": "BKO"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Gao",
    "code": "7"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Kayes",
    "code": "1"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Kidal",
    "code": "8"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Koulikoro",
    "code": "2"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Mopti",
    "code": "5"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Segou",
    "code": "4"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Sikasso",
    "code": "3"
  },
  {
    "country_code": "ML",
    "subdivision_name": "Tombouctou",
    "code": "6"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Ayeyarwady",
    "code": "07"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Bago",
    "code": "02"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Chin",
    "code": "14"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Kachin",
    "code": "11"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Kayah",
    "code": "12"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Kayin",
    "code": "13"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Magway",
    "code": "03"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Mandalay",
    "code": "04"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Mon",
    "code": "15"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Nay Pyi Taw",
    "code": "18"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Rakhine",
    "code": "16"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Sagaing",
    "code": "01"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Shan",
    "code": "17"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Tanintharyi",
    "code": "05"
  },
  {
    "country_code": "MM",
    "subdivision_name": "Yangon",
    "code": "06"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Bayan-Olgiy",
    "code": "071"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Bayanhongor",
    "code": "069"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Darhan uul",
    "code": "037"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Dornod",
    "code": "061"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Dundgovi",
    "code": "059"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Dzavhan",
    "code": "057"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Govi-Altay",
    "code": "065"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Hovd",
    "code": "043"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Hovsgol",
    "code": "041"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Omnogovi",
    "code": "053"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Orhon",
    "code": "035"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Ovorhangay",
    "code": "055"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Selenge",
    "code": "049"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Tov",
    "code": "047"
  },
  {
    "country_code": "MN",
    "subdivision_name": "Ulaanbaatar",
    "code": "1"
  },
  {
    "country_code": "MO",
    "subdivision_name": "Macao",
    "code": "-"
  },
  {
    "country_code": "MP",
    "subdivision_name": "Northern Mariana Islands",
    "code": "-"
  },
  {
    "country_code": "MQ",
    "subdivision_name": "Martinique",
    "code": "-"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Adrar",
    "code": "07"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Assaba",
    "code": "03"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Brakna",
    "code": "05"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Dakhlet Nouadhibou",
    "code": "08"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Gorgol",
    "code": "04"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Guidimaka",
    "code": "10"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Hodh el Gharbi",
    "code": "02"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Inchiri",
    "code": "12"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Nouakchott Ouest",
    "code": "13"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Tagant",
    "code": "09"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Tiris Zemmour",
    "code": "11"
  },
  {
    "country_code": "MR",
    "subdivision_name": "Trarza",
    "code": "06"
  },
  {
    "country_code": "MS",
    "subdivision_name": "Saint Anthony",
    "code": "-"
  },
  {
    "country_code": "MS",
    "subdivision_name": "Saint Peter",
    "code": "-"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Attard",
    "code": "01"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Balzan",
    "code": "02"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Birgu",
    "code": "03"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Birkirkara",
    "code": "04"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Birzebbuga",
    "code": "05"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Bormla",
    "code": "06"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Dingli",
    "code": "07"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Fgura",
    "code": "08"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Floriana",
    "code": "09"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Fontana",
    "code": "10"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Ghajnsielem",
    "code": "13"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Gharb",
    "code": "14"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Gharghur",
    "code": "15"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Ghasri",
    "code": "16"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Ghaxaq",
    "code": "17"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Gudja",
    "code": "11"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Gzira",
    "code": "12"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Hamrun",
    "code": "18"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Iklin",
    "code": "19"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Isla",
    "code": "20"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Kalkara",
    "code": "21"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Kirkop",
    "code": "23"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Lija",
    "code": "24"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Luqa",
    "code": "25"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Marsa",
    "code": "26"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Marsaskala",
    "code": "27"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Marsaxlokk",
    "code": "28"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Mdina",
    "code": "29"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Mellieha",
    "code": "30"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Mgarr",
    "code": "31"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Mosta",
    "code": "32"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Mqabba",
    "code": "33"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Msida",
    "code": "34"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Mtarfa",
    "code": "35"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Munxar",
    "code": "36"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Nadur",
    "code": "37"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Naxxar",
    "code": "38"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Paola",
    "code": "39"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Pembroke",
    "code": "40"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Pieta",
    "code": "41"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Qala",
    "code": "42"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Qormi",
    "code": "43"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Qrendi",
    "code": "44"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Rabat Gozo",
    "code": "45"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Rabat Malta",
    "code": "46"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Safi",
    "code": "47"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Saint John",
    "code": "49"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Saint Julian's",
    "code": "48"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Saint Lawrence",
    "code": "50"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Saint Lucia's",
    "code": "53"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Saint Paul's Bay",
    "code": "51"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Sannat",
    "code": "52"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Santa Venera",
    "code": "54"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Siggiewi",
    "code": "55"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Sliema",
    "code": "56"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Swieqi",
    "code": "57"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Ta' Xbiex",
    "code": "58"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Tarxien",
    "code": "59"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Valletta",
    "code": "60"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Xaghra",
    "code": "61"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Xewkija",
    "code": "62"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Xghajra",
    "code": "63"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Zabbar",
    "code": "64"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Zebbug Gozo",
    "code": "65"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Zejtun",
    "code": "67"
  },
  {
    "country_code": "MT",
    "subdivision_name": "Zurrieq",
    "code": "68"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Black River",
    "code": "BL"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Flacq",
    "code": "FL"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Grand Port",
    "code": "GP"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Moka",
    "code": "MO"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Pamplemousses",
    "code": "PA"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Plaines Wilhems",
    "code": "PW"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Port Louis",
    "code": "PL"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Riviere du Rempart",
    "code": "RR"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Rodrigues Islands",
    "code": "RO"
  },
  {
    "country_code": "MU",
    "subdivision_name": "Savanne",
    "code": "SA"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Addu City",
    "code": "01"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Faadhippolhu",
    "code": "03"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Felidhu Atoll",
    "code": "04"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Fuvammulah",
    "code": "29"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Hahdhunmathi",
    "code": "05"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Male",
    "code": "MLE"
  },
  {
    "country_code": "MV",
    "subdivision_name": "Mulaku Atoll",
    "code": "12"
  },
  {
    "country_code": "MV",
    "subdivision_name": "North Ari Atoll",
    "code": "02"
  },
  {
    "country_code": "MV",
    "subdivision_name": "North Huvadhu Atoll",
    "code": "27"
  },
  {
    "country_code": "MV",
    "subdivision_name": "North Maalhosmadulu",
    "code": "13"
  },
  {
    "country_code": "MV",
    "subdivision_name": "North Miladhunmadulu",
    "code": "24"
  },
  {
    "country_code": "MV",
    "subdivision_name": "North Nilandhe Atoll",
    "code": "14"
  },
  {
    "country_code": "MV",
    "subdivision_name": "North Thiladhunmathi",
    "code": "07"
  },
  {
    "country_code": "MV",
    "subdivision_name": "South Ari Atoll",
    "code": "00"
  },
  {
    "country_code": "MV",
    "subdivision_name": "South Huvadhu Atoll",
    "code": "28"
  },
  {
    "country_code": "MV",
    "subdivision_name": "South Maalhosmadulu",
    "code": "20"
  },
  {
    "country_code": "MV",
    "subdivision_name": "South Miladhunmadulu",
    "code": "25"
  },
  {
    "country_code": "MV",
    "subdivision_name": "South Nilandhe Atoll",
    "code": "17"
  },
  {
    "country_code": "MV",
    "subdivision_name": "South Thiladhunmathi",
    "code": "23"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Balaka",
    "code": "BA"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Blantyre",
    "code": "BL"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Chikwawa",
    "code": "CK"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Chiradzulu",
    "code": "CR"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Dedza",
    "code": "DE"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Dowa",
    "code": "DO"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Karonga",
    "code": "KR"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Lilongwe",
    "code": "LI"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Machinga",
    "code": "MH"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Mangochi",
    "code": "MG"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Mwanza",
    "code": "MW"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Mzimba",
    "code": "MZ"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Neno",
    "code": "NE"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Nkhotakota",
    "code": "NK"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Ntchisi",
    "code": "NI"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Salima",
    "code": "SA"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Thyolo",
    "code": "TH"
  },
  {
    "country_code": "MW",
    "subdivision_name": "Zomba",
    "code": "ZO"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Aguascalientes",
    "code": "AGU"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Baja California",
    "code": "BCN"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Baja California Sur",
    "code": "BCS"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Campeche",
    "code": "CAM"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Chiapas",
    "code": "CHP"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Chihuahua",
    "code": "CHH"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Ciudad de Mexico",
    "code": "CMX"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Coahuila de Zaragoza",
    "code": "COA"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Colima",
    "code": "COL"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Durango",
    "code": "DUR"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Guanajuato",
    "code": "GUA"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Guerrero",
    "code": "GRO"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Hidalgo",
    "code": "HID"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Jalisco",
    "code": "JAL"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Mexico",
    "code": "MEX"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Michoacan de Ocampo",
    "code": "MIC"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Morelos",
    "code": "MOR"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Nayarit",
    "code": "NAY"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Nuevo Leon",
    "code": "NLE"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Oaxaca",
    "code": "OAX"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Puebla",
    "code": "PUE"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Queretaro",
    "code": "QUE"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Quintana Roo",
    "code": "ROO"
  },
  {
    "country_code": "MX",
    "subdivision_name": "San Luis Potosi",
    "code": "SLP"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Sinaloa",
    "code": "SIN"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Sonora",
    "code": "SON"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Tabasco",
    "code": "TAB"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Tamaulipas",
    "code": "TAM"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Tlaxcala",
    "code": "TLA"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Veracruz de Ignacio de la Llave",
    "code": "VER"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Yucatan",
    "code": "YUC"
  },
  {
    "country_code": "MX",
    "subdivision_name": "Zacatecas",
    "code": "ZAC"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Johor",
    "code": "01"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Kedah",
    "code": "02"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Kelantan",
    "code": "03"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Melaka",
    "code": "04"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Negeri Sembilan",
    "code": "05"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Pahang",
    "code": "06"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Perak",
    "code": "08"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Perlis",
    "code": "09"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Pulau Pinang",
    "code": "07"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Sabah",
    "code": "12"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Sarawak",
    "code": "13"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Selangor",
    "code": "10"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Terengganu",
    "code": "11"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Wilayah Persekutuan Kuala Lumpur",
    "code": "14"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Wilayah Persekutuan Labuan",
    "code": "15"
  },
  {
    "country_code": "MY",
    "subdivision_name": "Wilayah Persekutuan Putrajaya",
    "code": "16"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Cabo Delgado",
    "code": "P"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Gaza",
    "code": "G"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Inhambane",
    "code": "I"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Manica",
    "code": "B"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Maputo",
    "code": "L"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Nampula",
    "code": "N"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Niassa",
    "code": "A"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Sofala",
    "code": "S"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Tete",
    "code": "T"
  },
  {
    "country_code": "MZ",
    "subdivision_name": "Zambezia",
    "code": "Q"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Erongo",
    "code": "ER"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Hardap",
    "code": "HA"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Karas",
    "code": "KA"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Kavango East",
    "code": "KE"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Kavango West",
    "code": "KW"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Khomas",
    "code": "KH"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Kunene",
    "code": "KU"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Ohangwena",
    "code": "OW"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Omaheke",
    "code": "OH"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Omusati",
    "code": "OS"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Oshana",
    "code": "ON"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Oshikoto",
    "code": "OT"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Otjozondjupa",
    "code": "OD"
  },
  {
    "country_code": "NA",
    "subdivision_name": "Zambezi",
    "code": "CA"
  },
  {
    "country_code": "NC",
    "subdivision_name": "Province Nord",
    "code": "-"
  },
  {
    "country_code": "NC",
    "subdivision_name": "Province Sud",
    "code": "-"
  },
  {
    "country_code": "NC",
    "subdivision_name": "Province des iles Loyaute",
    "code": "-"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Agadez",
    "code": "1"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Diffa",
    "code": "2"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Dosso",
    "code": "3"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Maradi",
    "code": "4"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Niamey",
    "code": "8"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Tahoua",
    "code": "5"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Tillaberi",
    "code": "6"
  },
  {
    "country_code": "NE",
    "subdivision_name": "Zinder",
    "code": "7"
  },
  {
    "country_code": "NF",
    "subdivision_name": "Norfolk Island",
    "code": "-"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Abia",
    "code": "AB"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Abuja Federal Capital Territory",
    "code": "FC"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Adamawa",
    "code": "AD"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Akwa Ibom",
    "code": "AK"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Anambra",
    "code": "AN"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Bauchi",
    "code": "BA"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Bayelsa",
    "code": "BY"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Benue",
    "code": "BE"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Borno",
    "code": "BO"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Cross River",
    "code": "CR"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Delta",
    "code": "DE"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Edo",
    "code": "ED"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Ekiti",
    "code": "EK"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Enugu",
    "code": "EN"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Gombe",
    "code": "GO"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Imo",
    "code": "IM"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Jigawa",
    "code": "JI"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Kaduna",
    "code": "KD"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Kano",
    "code": "KN"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Katsina",
    "code": "KT"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Kebbi",
    "code": "KE"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Kogi",
    "code": "KO"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Kwara",
    "code": "KW"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Lagos",
    "code": "LA"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Nasarawa",
    "code": "NA"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Niger",
    "code": "NI"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Ogun",
    "code": "OG"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Ondo",
    "code": "ON"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Osun",
    "code": "OS"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Oyo",
    "code": "OY"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Plateau",
    "code": "PL"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Rivers",
    "code": "RI"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Sokoto",
    "code": "SO"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Taraba",
    "code": "TA"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Yobe",
    "code": "YO"
  },
  {
    "country_code": "NG",
    "subdivision_name": "Zamfara",
    "code": "ZA"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Boaco",
    "code": "BO"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Carazo",
    "code": "CA"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Chinandega",
    "code": "CI"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Chontales",
    "code": "CO"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Costa Caribe Norte",
    "code": "AN"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Costa Caribe Sur",
    "code": "AS"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Esteli",
    "code": "ES"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Granada",
    "code": "GR"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Jinotega",
    "code": "JI"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Leon",
    "code": "LE"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Madriz",
    "code": "MD"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Managua",
    "code": "MN"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Masaya",
    "code": "MS"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Matagalpa",
    "code": "MT"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Nueva Segovia",
    "code": "NS"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Rio San Juan",
    "code": "SJ"
  },
  {
    "country_code": "NI",
    "subdivision_name": "Rivas",
    "code": "RI"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Drenthe",
    "code": "DR"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Flevoland",
    "code": "FL"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Fryslan",
    "code": "FR"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Gelderland",
    "code": "GE"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Groningen",
    "code": "GR"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Limburg",
    "code": "LI"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Noord-Brabant",
    "code": "NB"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Noord-Holland",
    "code": "NH"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Overijssel",
    "code": "OV"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Utrecht",
    "code": "UT"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Zeeland",
    "code": "ZE"
  },
  {
    "country_code": "NL",
    "subdivision_name": "Zuid-Holland",
    "code": "ZH"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Agder",
    "code": "42"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Innlandet",
    "code": "34"
  },
  {
    "country_code": "NO",
    "subdivision_name": "More og Romsdal",
    "code": "15"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Nordland",
    "code": "18"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Oslo",
    "code": "03"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Rogaland",
    "code": "11"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Troms og Finnmark",
    "code": "54"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Trondelag",
    "code": "50"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Vestfold og Telemark",
    "code": "38"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Vestland",
    "code": "46"
  },
  {
    "country_code": "NO",
    "subdivision_name": "Viken",
    "code": "30"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Bagmati",
    "code": "P3"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Gandaki",
    "code": "P4"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Karnali",
    "code": "P6"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Koshi",
    "code": "P1"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Lumbini",
    "code": "P5"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Madhesh",
    "code": "P2"
  },
  {
    "country_code": "NP",
    "subdivision_name": "Sudurpashchim",
    "code": "P7"
  },
  {
    "country_code": "NR",
    "subdivision_name": "Aiwo",
    "code": "01"
  },
  {
    "country_code": "NR",
    "subdivision_name": "Anetan",
    "code": "03"
  },
  {
    "country_code": "NR",
    "subdivision_name": "Yaren",
    "code": "14"
  },
  {
    "country_code": "NU",
    "subdivision_name": "Niue",
    "code": "-"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Auckland",
    "code": "AUK"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Bay of Plenty",
    "code": "BOP"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Canterbury",
    "code": "CAN"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Chatham Islands Territory",
    "code": "CIT"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Gisborne",
    "code": "GIS"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Greater Wellington",
    "code": "WGN"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Hawke's Bay",
    "code": "HKB"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Manawatu-Whanganui",
    "code": "MWT"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Marlborough",
    "code": "MBH"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Nelson",
    "code": "NSN"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Northland",
    "code": "NTL"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Otago",
    "code": "OTA"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Southland",
    "code": "STL"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Taranaki",
    "code": "TKI"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Tasman",
    "code": "TAS"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "Waikato",
    "code": "WKO"
  },
  {
    "country_code": "NZ",
    "subdivision_name": "West Coast",
    "code": "WTC"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Ad Dakhiliyah",
    "code": "DA"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Al Buraymi",
    "code": "BU"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Al Wusta",
    "code": "WU"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Az Zahirah",
    "code": "ZA"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Janub al Batinah",
    "code": "BJ"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Janub ash Sharqiyah",
    "code": "SJ"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Masqat",
    "code": "MA"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Musandam",
    "code": "MU"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Shamal al Batinah",
    "code": "BS"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Shamal ash Sharqiyah",
    "code": "SS"
  },
  {
    "country_code": "OM",
    "subdivision_name": "Zufar",
    "code": "ZU"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Bocas del Toro",
    "code": "1"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Chiriqui",
    "code": "4"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Cocle",
    "code": "2"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Colon",
    "code": "3"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Darien",
    "code": "5"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Herrera",
    "code": "6"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Los Santos",
    "code": "7"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Ngobe-Bugle",
    "code": "NB"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Panama",
    "code": "8"
  },
  {
    "country_code": "PA",
    "subdivision_name": "Veraguas",
    "code": "9"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Amazonas",
    "code": "AMA"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Ancash",
    "code": "ANC"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Apurimac",
    "code": "APU"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Arequipa",
    "code": "ARE"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Ayacucho",
    "code": "AYA"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Cajamarca",
    "code": "CAJ"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Cusco",
    "code": "CUS"
  },
  {
    "country_code": "PE",
    "subdivision_name": "El Callao",
    "code": "CAL"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Huancavelica",
    "code": "HUV"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Huanuco",
    "code": "HUC"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Ica",
    "code": "ICA"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Junin",
    "code": "JUN"
  },
  {
    "country_code": "PE",
    "subdivision_name": "La Libertad",
    "code": "LAL"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Lambayeque",
    "code": "LAM"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Lima",
    "code": "LIM"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Loreto",
    "code": "LOR"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Madre de Dios",
    "code": "MDD"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Moquegua",
    "code": "MOQ"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Pasco",
    "code": "PAS"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Piura",
    "code": "PIU"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Puno",
    "code": "PUN"
  },
  {
    "country_code": "PE",
    "subdivision_name": "San Martin",
    "code": "SAM"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Tacna",
    "code": "TAC"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Tumbes",
    "code": "TUM"
  },
  {
    "country_code": "PE",
    "subdivision_name": "Ucayali",
    "code": "UCA"
  },
  {
    "country_code": "PF",
    "subdivision_name": "Iles Australes",
    "code": "-"
  },
  {
    "country_code": "PF",
    "subdivision_name": "Iles Marquises",
    "code": "-"
  },
  {
    "country_code": "PF",
    "subdivision_name": "Iles Sous-le-Vent",
    "code": "-"
  },
  {
    "country_code": "PF",
    "subdivision_name": "Iles Tuamotu-Gambier",
    "code": "-"
  },
  {
    "country_code": "PF",
    "subdivision_name": "Iles du Vent",
    "code": "-"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Bougainville",
    "code": "NSB"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Central",
    "code": "CPM"
  },
  {
    "country_code": "PG",
    "subdivision_name": "East New Britain",
    "code": "EBR"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Eastern Highlands",
    "code": "EHG"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Enga",
    "code": "EPW"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Madang",
    "code": "MPM"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Manus",
    "code": "MRL"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Milne Bay",
    "code": "MBA"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Morobe",
    "code": "MPL"
  },
  {
    "country_code": "PG",
    "subdivision_name": "National Capital District (Port Moresby)",
    "code": "NCD"
  },
  {
    "country_code": "PG",
    "subdivision_name": "New Ireland",
    "code": "NIK"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Northern",
    "code": "NPP"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Southern Highlands",
    "code": "SHM"
  },
  {
    "country_code": "PG",
    "subdivision_name": "West New Britain",
    "code": "WBK"
  },
  {
    "country_code": "PG",
    "subdivision_name": "West Sepik",
    "code": "SAN"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Western",
    "code": "WPD"
  },
  {
    "country_code": "PG",
    "subdivision_name": "Western Highlands",
    "code": "WHM"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Abra",
    "code": "ABR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Agusan del Norte",
    "code": "AGN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Agusan del Sur",
    "code": "AGS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Aklan",
    "code": "AKL"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Albay",
    "code": "ALB"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Antique",
    "code": "ANT"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Apayao",
    "code": "APA"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Aurora",
    "code": "AUR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Basilan",
    "code": "BAS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Bataan",
    "code": "BAN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Batanes",
    "code": "BTN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Batangas",
    "code": "BTG"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Benguet",
    "code": "BEN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Biliran",
    "code": "BIL"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Bohol",
    "code": "BOH"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Bukidnon",
    "code": "BUK"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Bulacan",
    "code": "BUL"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Cagayan",
    "code": "CAG"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Camarines Norte",
    "code": "CAN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Camarines Sur",
    "code": "CAS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Camiguin",
    "code": "CAM"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Capiz",
    "code": "CAP"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Catanduanes",
    "code": "CAT"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Cavite",
    "code": "CAV"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Cebu",
    "code": "CEB"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Cotabato",
    "code": "NCO"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Davao Oriental",
    "code": "DAO"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Davao de Oro",
    "code": "COM"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Davao del Norte",
    "code": "DAV"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Davao del Sur",
    "code": "DAS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Dinagat Islands",
    "code": "DIN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Eastern Samar",
    "code": "EAS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Guimaras",
    "code": "GUI"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Ifugao",
    "code": "IFU"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Ilocos Norte",
    "code": "ILN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Ilocos Sur",
    "code": "ILS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Iloilo",
    "code": "ILI"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Isabela",
    "code": "ISA"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Kalinga",
    "code": "KAL"
  },
  {
    "country_code": "PH",
    "subdivision_name": "La Union",
    "code": "LUN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Laguna",
    "code": "LAG"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Lanao del Norte",
    "code": "LAN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Lanao del Sur",
    "code": "LAS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Leyte",
    "code": "LEY"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Maguindanao",
    "code": "MAG"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Marinduque",
    "code": "MAD"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Masbate",
    "code": "MAS"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Mindoro Occidental",
    "code": "MDC"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Mindoro Oriental",
    "code": "MDR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Misamis Occidental",
    "code": "MSC"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Misamis Oriental",
    "code": "MSR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Mountain Province",
    "code": "MOU"
  },
  {
    "country_code": "PH",
    "subdivision_name": "National Capital Region",
    "code": "00"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Negros Occidental",
    "code": "NEC"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Negros Oriental",
    "code": "NER"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Northern Samar",
    "code": "NSA"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Nueva Ecija",
    "code": "NUE"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Nueva Vizcaya",
    "code": "NUV"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Palawan",
    "code": "PLW"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Pampanga",
    "code": "PAM"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Pangasinan",
    "code": "PAN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Quezon",
    "code": "QUE"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Quirino",
    "code": "QUI"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Rizal",
    "code": "RIZ"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Romblon",
    "code": "ROM"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Samar",
    "code": "WSA"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Sarangani",
    "code": "SAR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Siquijor",
    "code": "SIG"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Sorsogon",
    "code": "SOR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "South Cotabato",
    "code": "SCO"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Southern Leyte",
    "code": "SLE"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Sultan Kudarat",
    "code": "SUK"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Sulu",
    "code": "SLU"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Surigao del Norte",
    "code": "SUN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Surigao del Sur",
    "code": "SUR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Tarlac",
    "code": "TAR"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Tawi-Tawi",
    "code": "TAW"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Zambales",
    "code": "ZMB"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Zamboanga Sibugay",
    "code": "ZSI"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Zamboanga del Norte",
    "code": "ZAN"
  },
  {
    "country_code": "PH",
    "subdivision_name": "Zamboanga del Sur",
    "code": "ZAS"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Azad Jammu and Kashmir",
    "code": "JK"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Balochistan",
    "code": "BA"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Gilgit-Baltistan",
    "code": "GB"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Islamabad",
    "code": "IS"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Khyber Pakhtunkhwa",
    "code": "KP"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Punjab",
    "code": "PB"
  },
  {
    "country_code": "PK",
    "subdivision_name": "Sindh",
    "code": "SD"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Dolnoslaskie",
    "code": "02"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Kujawsko-Pomorskie",
    "code": "04"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Lodzkie",
    "code": "10"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Lubelskie",
    "code": "06"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Lubuskie",
    "code": "08"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Malopolskie",
    "code": "12"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Mazowieckie",
    "code": "14"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Opolskie",
    "code": "16"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Podkarpackie",
    "code": "18"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Podlaskie",
    "code": "20"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Pomorskie",
    "code": "22"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Slaskie",
    "code": "24"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Swietokrzyskie",
    "code": "26"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Warminsko-Mazurskie",
    "code": "28"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Wielkopolskie",
    "code": "30"
  },
  {
    "country_code": "PL",
    "subdivision_name": "Zachodniopomorskie",
    "code": "32"
  },
  {
    "country_code": "PM",
    "subdivision_name": "Saint Pierre and Miquelon",
    "code": "-"
  },
  {
    "country_code": "PN",
    "subdivision_name": "Pitcairn",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Adjuntas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Aguada",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Aguadilla",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Aguas Buenas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Aibonito",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Anasco",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Arecibo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Arroyo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Barceloneta",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Barranquitas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Bayamon",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Cabo Rojo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Caguas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Camuy",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Canovanas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Carolina",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Catano",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Cayey",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Ceiba",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Ciales",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Cidra",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Coamo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Comerio",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Corozal",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Culebra",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Dorado",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Fajardo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Florida",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Guanica",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Guayama",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Guayanilla",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Guaynabo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Gurabo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Hatillo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Hormigueros",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Humacao",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Isabela",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Juana Diaz",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Lajas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Lares",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Las Piedras",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Loiza",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Luquillo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Manati",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Maunabo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Mayaguez",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Moca",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Morovis",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Municipio de Jayuya",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Municipio de Juncos",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Naguabo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Naranjito",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Patillas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Penuelas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Ponce",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Quebradillas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Rincon",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Rio Grande",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Sabana Grande",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Salinas",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "San German",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "San Juan",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "San Lorenzo",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "San Sebastian",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Santa Isabel Municipio",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Toa Alta",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Toa Baja",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Trujillo Alto",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Utuado",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Vega Alta",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Vega Baja",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Vieques",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Villalba",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Yabucoa",
    "code": "-"
  },
  {
    "country_code": "PR",
    "subdivision_name": "Yauco",
    "code": "-"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Bethlehem",
    "code": "BTH"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Deir El Balah",
    "code": "DEB"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Gaza",
    "code": "GZA"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Hebron",
    "code": "HBN"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Jenin",
    "code": "JEN"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Jericho and Al Aghwar",
    "code": "JRH"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Jerusalem",
    "code": "JEM"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Khan Yunis",
    "code": "KYS"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Nablus",
    "code": "NBS"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Qalqilya",
    "code": "QQA"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Rafah",
    "code": "RFH"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Ramallah",
    "code": "RBH"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Salfit",
    "code": "SLT"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Tubas",
    "code": "TBS"
  },
  {
    "country_code": "PS",
    "subdivision_name": "Tulkarm",
    "code": "TKM"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Aveiro",
    "code": "01"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Beja",
    "code": "02"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Braga",
    "code": "03"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Braganca",
    "code": "04"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Castelo Branco",
    "code": "05"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Coimbra",
    "code": "06"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Evora",
    "code": "07"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Faro",
    "code": "08"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Guarda",
    "code": "09"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Leiria",
    "code": "10"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Lisboa",
    "code": "11"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Portalegre",
    "code": "12"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Porto",
    "code": "13"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Regiao Autonoma da Madeira",
    "code": "30"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Regiao Autonoma dos Acores",
    "code": "20"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Santarem",
    "code": "14"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Setubal",
    "code": "15"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Viana do Castelo",
    "code": "16"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Vila Real",
    "code": "17"
  },
  {
    "country_code": "PT",
    "subdivision_name": "Viseu",
    "code": "18"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Airai",
    "code": "004"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Koror",
    "code": "150"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Melekeok",
    "code": "212"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Ngaraard",
    "code": "214"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Ngardmau",
    "code": "222"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Ngatpang",
    "code": "224"
  },
  {
    "country_code": "PW",
    "subdivision_name": "Sonsorol",
    "code": "370"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Alto Parana",
    "code": "10"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Amambay",
    "code": "13"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Asuncion",
    "code": "ASU"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Boqueron",
    "code": "19"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Caaguazu",
    "code": "5"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Caazapa",
    "code": "6"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Canindeyu",
    "code": "14"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Central",
    "code": "11"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Concepcion",
    "code": "1"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Cordillera",
    "code": "3"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Guaira",
    "code": "4"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Itapua",
    "code": "7"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Misiones",
    "code": "8"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Neembucu",
    "code": "12"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Paraguari",
    "code": "9"
  },
  {
    "country_code": "PY",
    "subdivision_name": "Presidente Hayes",
    "code": "15"
  },
  {
    "country_code": "PY",
    "subdivision_name": "San Pedro",
    "code": "2"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Ad Dawhah",
    "code": "DA"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Al Khawr wa adh Dhakhirah",
    "code": "KH"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Al Wakrah",
    "code": "WA"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Ar Rayyan",
    "code": "RA"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Ash Shamal",
    "code": "MS"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Az Za'ayin",
    "code": "ZA"
  },
  {
    "country_code": "QA",
    "subdivision_name": "Umm Salal",
    "code": "US"
  },
  {
    "country_code": "RE",
    "subdivision_name": "Reunion",
    "code": "-"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Alba",
    "code": "AB"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Arad",
    "code": "AR"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Arges",
    "code": "AG"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Bacau",
    "code": "BC"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Bihor",
    "code": "BH"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Bistrita-Nasaud",
    "code": "BN"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Botosani",
    "code": "BT"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Braila",
    "code": "BR"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Brasov",
    "code": "BV"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Bucuresti",
    "code": "B"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Buzau",
    "code": "BZ"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Calarasi",
    "code": "CL"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Caras-Severin",
    "code": "CS"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Cluj",
    "code": "CJ"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Constanta",
    "code": "CT"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Covasna",
    "code": "CV"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Dambovita",
    "code": "DB"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Dolj",
    "code": "DJ"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Galati",
    "code": "GL"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Giurgiu",
    "code": "GR"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Gorj",
    "code": "GJ"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Harghita",
    "code": "HR"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Hunedoara",
    "code": "HD"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Ialomita",
    "code": "IL"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Iasi",
    "code": "IS"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Ilfov",
    "code": "IF"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Maramures",
    "code": "MM"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Mehedinti",
    "code": "MH"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Mures",
    "code": "MS"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Neamt",
    "code": "NT"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Olt",
    "code": "OT"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Prahova",
    "code": "PH"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Salaj",
    "code": "SJ"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Satu Mare",
    "code": "SM"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Sibiu",
    "code": "SB"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Suceava",
    "code": "SV"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Teleorman",
    "code": "TR"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Timis",
    "code": "TM"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Tulcea",
    "code": "TL"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Valcea",
    "code": "VL"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Vaslui",
    "code": "VS"
  },
  {
    "country_code": "RO",
    "subdivision_name": "Vrancea",
    "code": "VN"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Beograd",
    "code": "00"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Borski okrug",
    "code": "14"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Branicevski okrug",
    "code": "11"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Jablanicki okrug",
    "code": "23"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Juznobacki okrug",
    "code": "06"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Juznobanatski okrug",
    "code": "04"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Kolubarski okrug",
    "code": "09"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Kosovsko-Mitrovacki okrug",
    "code": "28"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Macvanski okrug",
    "code": "08"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Moravicki okrug",
    "code": "17"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Nisavski okrug",
    "code": "20"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Pcinjski okrug",
    "code": "24"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Pecki okrug",
    "code": "26"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Pirotski okrug",
    "code": "22"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Podunavski okrug",
    "code": "10"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Pomoravski okrug",
    "code": "13"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Prizrenski okrug",
    "code": "27"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Rasinski okrug",
    "code": "19"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Raski okrug",
    "code": "18"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Severnobacki okrug",
    "code": "01"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Severnobanatski okrug",
    "code": "03"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Srednjebanatski okrug",
    "code": "02"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Sremski okrug",
    "code": "07"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Sumadijski okrug",
    "code": "12"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Toplicki okrug",
    "code": "21"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Zajecarski okrug",
    "code": "15"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Zapadnobacki okrug",
    "code": "05"
  },
  {
    "country_code": "RS",
    "subdivision_name": "Zlatiborski okrug",
    "code": "16"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Adygeya, Respublika",
    "code": "AD"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Altay, Respublika",
    "code": "AL"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Altayskiy kray",
    "code": "ALT"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Amurskaya oblast'",
    "code": "AMU"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Arkhangel'skaya oblast'",
    "code": "ARK"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Astrakhanskaya oblast'",
    "code": "AST"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Bashkortostan, Respublika",
    "code": "BA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Belgorodskaya oblast'",
    "code": "BEL"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Bryanskaya oblast'",
    "code": "BRY"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Buryatiya, Respublika",
    "code": "BU"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Chechenskaya Respublika",
    "code": "CE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Chelyabinskaya oblast'",
    "code": "CHE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Chukotskiy avtonomnyy okrug",
    "code": "CHU"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Chuvashskaya Respublika",
    "code": "CU"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Dagestan, Respublika",
    "code": "DA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Ingushetiya, Respublika",
    "code": "IN"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Irkutskaya oblast'",
    "code": "IRK"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Ivanovskaya oblast'",
    "code": "IVA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kabardino-Balkarskaya Respublika",
    "code": "KB"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kaliningradskaya oblast'",
    "code": "KGD"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kalmykiya, Respublika",
    "code": "KL"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kaluzhskaya oblast'",
    "code": "KLU"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kamchatskiy kray",
    "code": "KAM"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Karachayevo-Cherkesskaya Respublika",
    "code": "KC"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kareliya, Respublika",
    "code": "KR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kemerovskaya oblast'",
    "code": "KEM"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Khabarovskiy kray",
    "code": "KHA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Khakasiya, Respublika",
    "code": "KK"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Khanty-Mansiyskiy avtonomnyy okrug",
    "code": "KHM"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kirovskaya oblast'",
    "code": "KIR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Komi, Respublika",
    "code": "KO"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kostromskaya oblast'",
    "code": "KOS"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Krasnodarskiy kray",
    "code": "KDA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Krasnoyarskiy kray",
    "code": "KYA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kurganskaya oblast'",
    "code": "KGN"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Kurskaya oblast'",
    "code": "KRS"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Leningradskaya oblast'",
    "code": "LEN"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Lipetskaya oblast'",
    "code": "LIP"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Magadanskaya oblast'",
    "code": "MAG"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Mariy El, Respublika",
    "code": "ME"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Mordoviya, Respublika",
    "code": "MO"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Moskovskaya oblast'",
    "code": "MOS"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Moskva",
    "code": "MOW"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Murmanskaya oblast'",
    "code": "MUR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Nenetskiy avtonomnyy okrug",
    "code": "NEN"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Nizhegorodskaya oblast'",
    "code": "NIZ"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Novgorodskaya oblast'",
    "code": "NGR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Novosibirskaya oblast'",
    "code": "NVS"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Omskaya oblast'",
    "code": "OMS"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Orenburgskaya oblast'",
    "code": "ORE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Orlovskaya oblast'",
    "code": "ORL"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Penzenskaya oblast'",
    "code": "PNZ"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Permskiy kray",
    "code": "PER"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Primorskiy kray",
    "code": "PRI"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Pskovskaya oblast'",
    "code": "PSK"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Rostovskaya oblast'",
    "code": "ROS"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Ryazanskaya oblast'",
    "code": "RYA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Saha, Respublika",
    "code": "SA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Sakhalinskaya oblast'",
    "code": "SAK"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Samarskaya oblast'",
    "code": "SAM"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Sankt-Peterburg",
    "code": "SPE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Saratovskaya oblast'",
    "code": "SAR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Severnaya Osetiya, Respublika",
    "code": "SE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Smolenskaya oblast'",
    "code": "SMO"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Stavropol'skiy kray",
    "code": "STA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Sverdlovskaya oblast'",
    "code": "SVE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tambovskaya oblast'",
    "code": "TAM"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tatarstan, Respublika",
    "code": "TA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tomskaya oblast'",
    "code": "TOM"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tul'skaya oblast'",
    "code": "TUL"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tverskaya oblast'",
    "code": "TVE"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tyumenskaya oblast'",
    "code": "TYU"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Tyva, Respublika",
    "code": "TY"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Udmurtskaya Respublika",
    "code": "UD"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Ul'yanovskaya oblast'",
    "code": "ULY"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Vladimirskaya oblast'",
    "code": "VLA"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Volgogradskaya oblast'",
    "code": "VGG"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Vologodskaya oblast'",
    "code": "VLG"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Voronezhskaya oblast'",
    "code": "VOR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Yamalo-Nenetskiy avtonomnyy okrug",
    "code": "YAN"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Yaroslavskaya oblast'",
    "code": "YAR"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Yevreyskaya avtonomnaya oblast'",
    "code": "YEV"
  },
  {
    "country_code": "RU",
    "subdivision_name": "Zabaykal'skiy kray",
    "code": "ZAB"
  },
  {
    "country_code": "RW",
    "subdivision_name": "Est",
    "code": "02"
  },
  {
    "country_code": "RW",
    "subdivision_name": "Nord",
    "code": "03"
  },
  {
    "country_code": "RW",
    "subdivision_name": "Ouest",
    "code": "04"
  },
  {
    "country_code": "RW",
    "subdivision_name": "Sud",
    "code": "05"
  },
  {
    "country_code": "RW",
    "subdivision_name": "Ville de Kigali",
    "code": "01"
  },
  {
    "country_code": "SA",
    "subdivision_name": "'Asir",
    "code": "14"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Al Bahah",
    "code": "11"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Al Hudud ash Shamaliyah",
    "code": "08"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Al Jawf",
    "code": "12"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Al Madinah al Munawwarah",
    "code": "03"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Al Qasim",
    "code": "05"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Ar Riyad",
    "code": "01"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Ash Sharqiyah",
    "code": "04"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Ha'il",
    "code": "06"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Jazan",
    "code": "09"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Makkah al Mukarramah",
    "code": "02"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Najran",
    "code": "10"
  },
  {
    "country_code": "SA",
    "subdivision_name": "Tabuk",
    "code": "07"
  },
  {
    "country_code": "SB",
    "subdivision_name": "Guadalcanal",
    "code": "GU"
  },
  {
    "country_code": "SB",
    "subdivision_name": "Makira-Ulawa",
    "code": "MK"
  },
  {
    "country_code": "SB",
    "subdivision_name": "Western",
    "code": "WE"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Anse Boileau",
    "code": "02"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Anse Royale",
    "code": "05"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Anse aux Pins",
    "code": "01"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Baie Lazare",
    "code": "06"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Baie Sainte Anne",
    "code": "07"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Beau Vallon",
    "code": "08"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Bel Ombre",
    "code": "10"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Cascade",
    "code": "11"
  },
  {
    "country_code": "SC",
    "subdivision_name": "English River",
    "code": "16"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Grand Anse Mahe",
    "code": "13"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Grand Anse Praslin",
    "code": "14"
  },
  {
    "country_code": "SC",
    "subdivision_name": "La Digue",
    "code": "15"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Pointe Larue",
    "code": "20"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Port Glaud",
    "code": "21"
  },
  {
    "country_code": "SC",
    "subdivision_name": "Takamaka",
    "code": "23"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Blue Nile",
    "code": "NB"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Central Darfur",
    "code": "DC"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Gedaref",
    "code": "GD"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Gezira",
    "code": "GZ"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Kassala",
    "code": "KA"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Khartoum",
    "code": "KH"
  },
  {
    "country_code": "SD",
    "subdivision_name": "North Darfur",
    "code": "DN"
  },
  {
    "country_code": "SD",
    "subdivision_name": "North Kordofan",
    "code": "KN"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Northern",
    "code": "NO"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Red Sea",
    "code": "RS"
  },
  {
    "country_code": "SD",
    "subdivision_name": "River Nile",
    "code": "NR"
  },
  {
    "country_code": "SD",
    "subdivision_name": "Sennar",
    "code": "SI"
  },
  {
    "country_code": "SD",
    "subdivision_name": "South Darfur",
    "code": "DS"
  },
  {
    "country_code": "SD",
    "subdivision_name": "South Kordofan",
    "code": "KS"
  },
  {
    "country_code": "SD",
    "subdivision_name": "West Darfur",
    "code": "DW"
  },
  {
    "country_code": "SD",
    "subdivision_name": "West Kordofan",
    "code": "GK"
  },
  {
    "country_code": "SD",
    "subdivision_name": "White Nile",
    "code": "NW"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Blekinge lan",
    "code": "K"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Dalarnas lan",
    "code": "W"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Gavleborgs lan",
    "code": "X"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Gotlands lan",
    "code": "I"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Hallands lan",
    "code": "N"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Jamtlands lan",
    "code": "Z"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Jonkopings lan",
    "code": "F"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Kalmar lan",
    "code": "H"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Kronobergs lan",
    "code": "G"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Norrbottens lan",
    "code": "BD"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Orebro lan",
    "code": "T"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Ostergotlands lan",
    "code": "E"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Skane lan",
    "code": "M"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Sodermanlands lan",
    "code": "D"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Stockholms lan",
    "code": "AB"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Uppsala lan",
    "code": "C"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Varmlands lan",
    "code": "S"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Vasterbottens lan",
    "code": "AC"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Vasternorrlands lan",
    "code": "Y"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Vastmanlands lan",
    "code": "U"
  },
  {
    "country_code": "SE",
    "subdivision_name": "Vastra Gotalands lan",
    "code": "O"
  },
  {
    "country_code": "SG",
    "subdivision_name": "Singapore",
    "code": "-"
  },
  {
    "country_code": "SH",
    "subdivision_name": "Saint Helena",
    "code": "HL"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ajdovscina",
    "code": "001"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ankaran",
    "code": "213"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Apace",
    "code": "195"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Beltinci",
    "code": "002"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Benedikt",
    "code": "148"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Bistrica ob Sotli",
    "code": "149"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Bled",
    "code": "003"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Bloke",
    "code": "150"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Bohinj",
    "code": "004"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Borovnica",
    "code": "005"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Bovec",
    "code": "006"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Braslovce",
    "code": "151"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Brda",
    "code": "007"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Brezice",
    "code": "009"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Brezovica",
    "code": "008"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Cankova",
    "code": "152"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Celje",
    "code": "011"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Cerklje na Gorenjskem",
    "code": "012"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Cerknica",
    "code": "013"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Cerkno",
    "code": "014"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Cirkulane",
    "code": "196"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Crensovci",
    "code": "015"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Crnomelj",
    "code": "017"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Destrnik",
    "code": "018"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Divaca",
    "code": "019"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dobje",
    "code": "154"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dobrepolje",
    "code": "020"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dobrna",
    "code": "155"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dobrova-Polhov Gradec",
    "code": "021"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dobrovnik",
    "code": "156"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Domzale",
    "code": "023"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dornava",
    "code": "024"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Dravograd",
    "code": "025"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Duplek",
    "code": "026"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Gorje",
    "code": "207"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Gornja Radgona",
    "code": "029"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Gornji Petrovci",
    "code": "031"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Grad",
    "code": "158"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Grosuplje",
    "code": "032"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Hajdina",
    "code": "159"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Hoce-Slivnica",
    "code": "160"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Hodos",
    "code": "161"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Horjul",
    "code": "162"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Hrastnik",
    "code": "034"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Hrpelje-Kozina",
    "code": "035"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Idrija",
    "code": "036"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ig",
    "code": "037"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ilirska Bistrica",
    "code": "038"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ivancna Gorica",
    "code": "039"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Izola",
    "code": "040"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Jesenice",
    "code": "041"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Jursinci",
    "code": "042"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kamnik",
    "code": "043"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kanal",
    "code": "044"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kidricevo",
    "code": "045"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kobarid",
    "code": "046"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kobilje",
    "code": "047"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kocevje",
    "code": "048"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Komen",
    "code": "049"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Komenda",
    "code": "164"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Koper",
    "code": "050"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kosanjevica na Krki",
    "code": "197"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kostel",
    "code": "165"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kranj",
    "code": "052"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kranjska Gora",
    "code": "053"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Krizevci",
    "code": "166"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Krsko",
    "code": "054"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kungota",
    "code": "055"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Kuzma",
    "code": "056"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Lasko",
    "code": "057"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Lenart",
    "code": "058"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Lendava",
    "code": "059"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Litija",
    "code": "060"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ljubljana",
    "code": "061"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ljutomer",
    "code": "063"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Log-Dragomer",
    "code": "208"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Logatec",
    "code": "064"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Loska dolina",
    "code": "065"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Loski Potok",
    "code": "066"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Lovrenc na Pohorju",
    "code": "167"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Luce",
    "code": "067"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Lukovica",
    "code": "068"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Majsperk",
    "code": "069"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Makole",
    "code": "198"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Maribor",
    "code": "070"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Markovci",
    "code": "168"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Medvode",
    "code": "071"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Menges",
    "code": "072"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Metlika",
    "code": "073"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Mezica",
    "code": "074"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Miklavz na Dravskem polju",
    "code": "169"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Miren-Kostanjevica",
    "code": "075"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Mirna",
    "code": "212"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Mirna Pec",
    "code": "170"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Mislinja",
    "code": "076"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Mokronog-Trebelno",
    "code": "199"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Moravce",
    "code": "077"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Mozirje",
    "code": "079"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Murska Sobota",
    "code": "080"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Muta",
    "code": "081"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Naklo",
    "code": "082"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Nazarje",
    "code": "083"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Nova Gorica",
    "code": "084"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Novo Mesto",
    "code": "085"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Odranci",
    "code": "086"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Oplotnica",
    "code": "171"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ormoz",
    "code": "087"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Piran",
    "code": "090"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Pivka",
    "code": "091"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Podcetrtek",
    "code": "092"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Podlehnik",
    "code": "172"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Poljcane",
    "code": "200"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Polzela",
    "code": "173"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Postojna",
    "code": "094"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Prebold",
    "code": "174"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Preddvor",
    "code": "095"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Prevalje",
    "code": "175"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ptuj",
    "code": "096"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Puconci",
    "code": "097"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Race-Fram",
    "code": "098"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Radece",
    "code": "099"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Radenci",
    "code": "100"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Radlje ob Dravi",
    "code": "101"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Radovljica",
    "code": "102"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ravne na Koroskem",
    "code": "103"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Razkrizje",
    "code": "176"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Recica ob Savinji",
    "code": "209"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Rence-Vogrsko",
    "code": "201"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ribnica",
    "code": "104"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Rogaska Slatina",
    "code": "106"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Rogasovci",
    "code": "105"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ruse",
    "code": "108"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Salovci",
    "code": "033"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Semic",
    "code": "109"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sempeter-Vrtojba",
    "code": "183"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sencur",
    "code": "117"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sentilj",
    "code": "118"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sentjernej",
    "code": "119"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sentjur",
    "code": "120"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sentrupert",
    "code": "211"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sevnica",
    "code": "110"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sezana",
    "code": "111"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Skocjan",
    "code": "121"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Skofja Loka",
    "code": "122"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Skofljica",
    "code": "123"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Slovenj Gradec",
    "code": "112"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Slovenska Bistrica",
    "code": "113"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Slovenske Konjice",
    "code": "114"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Smarje pri Jelsah",
    "code": "124"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Smarjeske Toplice",
    "code": "206"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Smartno ob Paki",
    "code": "125"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Smartno pri Litiji",
    "code": "194"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sodrazica",
    "code": "179"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Solcava",
    "code": "180"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sostanj",
    "code": "126"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Starse",
    "code": "115"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Store",
    "code": "127"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Straza",
    "code": "203"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sveta Trojica v Slovenskih goricah",
    "code": "204"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sveti Andraz v Slovenskih Goricah",
    "code": "182"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sveti Jurij ob Scavnici",
    "code": "116"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sveti Jurij v Slovenskih goricah",
    "code": "210"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Sveti Tomaz",
    "code": "205"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Tabor",
    "code": "184"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Tisina",
    "code": "010"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Tolmin",
    "code": "128"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Trbovlje",
    "code": "129"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Trebnje",
    "code": "130"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Trnovska Vas",
    "code": "185"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Trzic",
    "code": "131"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Trzin",
    "code": "186"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Turnisce",
    "code": "132"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Velenje",
    "code": "133"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Velika Polana",
    "code": "187"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Velike Lasce",
    "code": "134"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Verzej",
    "code": "188"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Videm",
    "code": "135"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vipava",
    "code": "136"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vitanje",
    "code": "137"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vodice",
    "code": "138"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vojnik",
    "code": "139"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vransko",
    "code": "189"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vrhnika",
    "code": "140"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Vuzenica",
    "code": "141"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zagorje ob Savi",
    "code": "142"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zalec",
    "code": "190"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zavrc",
    "code": "143"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zelezniki",
    "code": "146"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zetale",
    "code": "191"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Ziri",
    "code": "147"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zrece",
    "code": "144"
  },
  {
    "country_code": "SI",
    "subdivision_name": "Zuzemberk",
    "code": "193"
  },
  {
    "country_code": "SJ",
    "subdivision_name": "Svalbard and Jan Mayen",
    "code": "-"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Banskobystricky kraj",
    "code": "BC"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Bratislavsky kraj",
    "code": "BL"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Kosicky kraj",
    "code": "KI"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Nitriansky kraj",
    "code": "NI"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Presovsky kraj",
    "code": "PV"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Trenciansky kraj",
    "code": "TC"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Trnavsky kraj",
    "code": "TA"
  },
  {
    "country_code": "SK",
    "subdivision_name": "Zilinsky kraj",
    "code": "ZI"
  },
  {
    "country_code": "SL",
    "subdivision_name": "Eastern",
    "code": "E"
  },
  {
    "country_code": "SL",
    "subdivision_name": "North Western",
    "code": "NW"
  },
  {
    "country_code": "SL",
    "subdivision_name": "Northern",
    "code": "N"
  },
  {
    "country_code": "SL",
    "subdivision_name": "Southern",
    "code": "S"
  },
  {
    "country_code": "SL",
    "subdivision_name": "Western Area",
    "code": "W"
  },
  {
    "country_code": "SM",
    "subdivision_name": "Chiesanuova",
    "code": "02"
  },
  {
    "country_code": "SM",
    "subdivision_name": "Citta di San Marino",
    "code": "07"
  },
  {
    "country_code": "SM",
    "subdivision_name": "Faetano",
    "code": "04"
  },
  {
    "country_code": "SM",
    "subdivision_name": "Serravalle",
    "code": "09"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Dakar",
    "code": "DK"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Diourbel",
    "code": "DB"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Fatick",
    "code": "FK"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Kaffrine",
    "code": "KA"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Kaolack",
    "code": "KL"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Kedougou",
    "code": "KE"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Kolda",
    "code": "KD"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Louga",
    "code": "LG"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Matam",
    "code": "MT"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Saint-Louis",
    "code": "SL"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Tambacounda",
    "code": "TC"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Thies",
    "code": "TH"
  },
  {
    "country_code": "SN",
    "subdivision_name": "Ziguinchor",
    "code": "ZG"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Awdal",
    "code": "AW"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Banaadir",
    "code": "BN"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Bari",
    "code": "BR"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Bay",
    "code": "BY"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Galguduud",
    "code": "GA"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Hiiraan",
    "code": "HI"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Jubbada Hoose",
    "code": "JH"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Mudug",
    "code": "MU"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Nugaal",
    "code": "NU"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Sanaag",
    "code": "SA"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Sool",
    "code": "SO"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Togdheer",
    "code": "TO"
  },
  {
    "country_code": "SO",
    "subdivision_name": "Woqooyi Galbeed",
    "code": "WO"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Brokopondo",
    "code": "BR"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Commewijne",
    "code": "CM"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Coronie",
    "code": "CR"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Nickerie",
    "code": "NI"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Para",
    "code": "PR"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Paramaribo",
    "code": "PM"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Saramacca",
    "code": "SA"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Sipaliwini",
    "code": "SI"
  },
  {
    "country_code": "SR",
    "subdivision_name": "Wanica",
    "code": "WA"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Central Equatoria",
    "code": "EC"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Eastern Equatoria",
    "code": "EE"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Jonglei",
    "code": "JG"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Northern Bahr el Ghazal",
    "code": "BN"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Unity",
    "code": "UY"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Upper Nile",
    "code": "NU"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Warrap",
    "code": "WR"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Western Bahr el Ghazal",
    "code": "BW"
  },
  {
    "country_code": "SS",
    "subdivision_name": "Western Equatoria",
    "code": "EW"
  },
  {
    "country_code": "ST",
    "subdivision_name": "Agua Grande",
    "code": "01"
  },
  {
    "country_code": "ST",
    "subdivision_name": "Principe",
    "code": "P"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Ahuachapan",
    "code": "AH"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Cabanas",
    "code": "CA"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Chalatenango",
    "code": "CH"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Cuscatlan",
    "code": "CU"
  },
  {
    "country_code": "SV",
    "subdivision_name": "La Libertad",
    "code": "LI"
  },
  {
    "country_code": "SV",
    "subdivision_name": "La Paz",
    "code": "PA"
  },
  {
    "country_code": "SV",
    "subdivision_name": "La Union",
    "code": "UN"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Morazan",
    "code": "MO"
  },
  {
    "country_code": "SV",
    "subdivision_name": "San Miguel",
    "code": "SM"
  },
  {
    "country_code": "SV",
    "subdivision_name": "San Salvador",
    "code": "SS"
  },
  {
    "country_code": "SV",
    "subdivision_name": "San Vicente",
    "code": "SV"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Santa Ana",
    "code": "SA"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Sonsonate",
    "code": "SO"
  },
  {
    "country_code": "SV",
    "subdivision_name": "Usulutan",
    "code": "US"
  },
  {
    "country_code": "SX",
    "subdivision_name": "Sint Maarten (Dutch Part)",
    "code": "-"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Al Hasakah",
    "code": "HA"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Al Ladhiqiyah",
    "code": "LA"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Al Qunaytirah",
    "code": "QU"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Ar Raqqah",
    "code": "RA"
  },
  {
    "country_code": "SY",
    "subdivision_name": "As Suwayda'",
    "code": "SU"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Dar'a",
    "code": "DR"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Dayr az Zawr",
    "code": "DY"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Dimashq",
    "code": "DI"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Halab",
    "code": "HL"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Hamah",
    "code": "HM"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Hims",
    "code": "HI"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Idlib",
    "code": "ID"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Rif Dimashq",
    "code": "RD"
  },
  {
    "country_code": "SY",
    "subdivision_name": "Tartus",
    "code": "TA"
  },
  {
    "country_code": "SZ",
    "subdivision_name": "Hhohho",
    "code": "HH"
  },
  {
    "country_code": "SZ",
    "subdivision_name": "Lubombo",
    "code": "LU"
  },
  {
    "country_code": "SZ",
    "subdivision_name": "Manzini",
    "code": "MA"
  },
  {
    "country_code": "TC",
    "subdivision_name": "Turks and Caicos Islands",
    "code": "-"
  },
  {
    "country_code": "TD",
    "subdivision_name": "Bahr el Ghazal",
    "code": "BG"
  },
  {
    "country_code": "TD",
    "subdivision_name": "Chari-Baguirmi",
    "code": "CB"
  },
  {
    "country_code": "TD",
    "subdivision_name": "Guera",
    "code": "GR"
  },
  {
    "country_code": "TD",
    "subdivision_name": "Lac",
    "code": "LC"
  },
  {
    "country_code": "TD",
    "subdivision_name": "Sila",
    "code": "SI"
  },
  {
    "country_code": "TD",
    "subdivision_name": "Ville de Ndjamena",
    "code": "ND"
  },
  {
    "country_code": "TF",
    "subdivision_name": "French Southern Territories",
    "code": "-"
  },
  {
    "country_code": "TG",
    "subdivision_name": "Centrale",
    "code": "C"
  },
  {
    "country_code": "TG",
    "subdivision_name": "Kara",
    "code": "K"
  },
  {
    "country_code": "TG",
    "subdivision_name": "Maritime",
    "code": "M"
  },
  {
    "country_code": "TG",
    "subdivision_name": "Plateaux",
    "code": "P"
  },
  {
    "country_code": "TG",
    "subdivision_name": "Savanes",
    "code": "S"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Amnat Charoen",
    "code": "37"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Ang Thong",
    "code": "15"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Bueng Kan",
    "code": "38"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Buri Ram",
    "code": "31"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chachoengsao",
    "code": "24"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chai Nat",
    "code": "18"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chaiyaphum",
    "code": "36"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chanthaburi",
    "code": "22"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chiang Mai",
    "code": "50"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chiang Rai",
    "code": "57"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chon Buri",
    "code": "20"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Chumphon",
    "code": "86"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Kalasin",
    "code": "46"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Kamphaeng Phet",
    "code": "62"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Kanchanaburi",
    "code": "71"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Khon Kaen",
    "code": "40"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Krabi",
    "code": "81"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Krung Thep Maha Nakhon",
    "code": "10"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Lampang",
    "code": "52"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Lamphun",
    "code": "51"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Loei",
    "code": "42"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Lop Buri",
    "code": "16"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Mae Hong Son",
    "code": "58"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Maha Sarakham",
    "code": "44"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Mukdahan",
    "code": "49"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nakhon Nayok",
    "code": "26"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nakhon Pathom",
    "code": "73"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nakhon Phanom",
    "code": "48"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nakhon Ratchasima",
    "code": "30"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nakhon Sawan",
    "code": "60"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nakhon Si Thammarat",
    "code": "80"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nan",
    "code": "55"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Narathiwat",
    "code": "96"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nong Bua Lam Phu",
    "code": "39"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nong Khai",
    "code": "43"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Nonthaburi",
    "code": "12"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Pathum Thani",
    "code": "13"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Pattani",
    "code": "94"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phangnga",
    "code": "82"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phatthalung",
    "code": "93"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phayao",
    "code": "56"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phetchabun",
    "code": "67"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phetchaburi",
    "code": "76"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phichit",
    "code": "66"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phitsanulok",
    "code": "65"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phra Nakhon Si Ayutthaya",
    "code": "14"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phrae",
    "code": "54"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Phuket",
    "code": "83"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Prachin Buri",
    "code": "25"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Prachuap Khiri Khan",
    "code": "77"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Ranong",
    "code": "85"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Ratchaburi",
    "code": "70"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Rayong",
    "code": "21"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Roi Et",
    "code": "45"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Sa Kaeo",
    "code": "27"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Sakon Nakhon",
    "code": "47"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Samut Prakan",
    "code": "11"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Samut Sakhon",
    "code": "74"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Samut Songkhram",
    "code": "75"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Saraburi",
    "code": "19"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Satun",
    "code": "91"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Si Sa Ket",
    "code": "33"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Sing Buri",
    "code": "17"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Songkhla",
    "code": "90"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Sukhothai",
    "code": "64"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Suphan Buri",
    "code": "72"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Surat Thani",
    "code": "84"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Surin",
    "code": "32"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Tak",
    "code": "63"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Trang",
    "code": "92"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Trat",
    "code": "23"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Ubon Ratchathani",
    "code": "34"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Udon Thani",
    "code": "41"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Uthai Thani",
    "code": "61"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Uttaradit",
    "code": "53"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Yala",
    "code": "95"
  },
  {
    "country_code": "TH",
    "subdivision_name": "Yasothon",
    "code": "35"
  },
  {
    "country_code": "TJ",
    "subdivision_name": "Dushanbe",
    "code": "DU"
  },
  {
    "country_code": "TJ",
    "subdivision_name": "Khatlon",
    "code": "KT"
  },
  {
    "country_code": "TJ",
    "subdivision_name": "Kuhistoni Badakhshon",
    "code": "GB"
  },
  {
    "country_code": "TJ",
    "subdivision_name": "Nohiyahoi Tobei Jumhuri",
    "code": "RA"
  },
  {
    "country_code": "TJ",
    "subdivision_name": "Sughd",
    "code": "SU"
  },
  {
    "country_code": "TK",
    "subdivision_name": "Tokelau",
    "code": "-"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Ainaro",
    "code": "AN"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Bobonaro",
    "code": "BO"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Dili",
    "code": "DI"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Ermera",
    "code": "ER"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Liquica",
    "code": "LI"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Manatuto",
    "code": "MT"
  },
  {
    "country_code": "TL",
    "subdivision_name": "Viqueque",
    "code": "VI"
  },
  {
    "country_code": "TM",
    "subdivision_name": "Ahal",
    "code": "A"
  },
  {
    "country_code": "TM",
    "subdivision_name": "Balkan",
    "code": "B"
  },
  {
    "country_code": "TM",
    "subdivision_name": "Dasoguz",
    "code": "D"
  },
  {
    "country_code": "TM",
    "subdivision_name": "Lebap",
    "code": "L"
  },
  {
    "country_code": "TM",
    "subdivision_name": "Mary",
    "code": "M"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Beja",
    "code": "31"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Ben Arous",
    "code": "13"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Bizerte",
    "code": "23"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Gabes",
    "code": "81"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Gafsa",
    "code": "71"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Jendouba",
    "code": "32"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Kairouan",
    "code": "41"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Kasserine",
    "code": "42"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Kebili",
    "code": "73"
  },
  {
    "country_code": "TN",
    "subdivision_name": "L'Ariana",
    "code": "12"
  },
  {
    "country_code": "TN",
    "subdivision_name": "La Manouba",
    "code": "14"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Le Kef",
    "code": "33"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Mahdia",
    "code": "53"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Medenine",
    "code": "82"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Monastir",
    "code": "52"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Nabeul",
    "code": "21"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Sfax",
    "code": "61"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Sidi Bouzid",
    "code": "43"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Siliana",
    "code": "34"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Sousse",
    "code": "51"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Tataouine",
    "code": "83"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Tozeur",
    "code": "72"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Tunis",
    "code": "11"
  },
  {
    "country_code": "TN",
    "subdivision_name": "Zaghouan",
    "code": "22"
  },
  {
    "country_code": "TO",
    "subdivision_name": "'Eua",
    "code": "01"
  },
  {
    "country_code": "TO",
    "subdivision_name": "Ha'apai",
    "code": "02"
  },
  {
    "country_code": "TO",
    "subdivision_name": "Niuas",
    "code": "03"
  },
  {
    "country_code": "TO",
    "subdivision_name": "Tongatapu",
    "code": "04"
  },
  {
    "country_code": "TO",
    "subdivision_name": "Vava'u",
    "code": "05"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Adana",
    "code": "01"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Adiyaman",
    "code": "02"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Afyonkarahisar",
    "code": "03"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Agri",
    "code": "04"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Aksaray",
    "code": "68"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Amasya",
    "code": "05"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Ankara",
    "code": "06"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Antalya",
    "code": "07"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Ardahan",
    "code": "75"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Artvin",
    "code": "08"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Aydin",
    "code": "09"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Balikesir",
    "code": "10"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bartin",
    "code": "74"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Batman",
    "code": "72"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bayburt",
    "code": "69"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bilecik",
    "code": "11"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bingol",
    "code": "12"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bitlis",
    "code": "13"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bolu",
    "code": "14"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Burdur",
    "code": "15"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Bursa",
    "code": "16"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Canakkale",
    "code": "17"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Cankiri",
    "code": "18"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Corum",
    "code": "19"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Denizli",
    "code": "20"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Diyarbakir",
    "code": "21"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Duzce",
    "code": "81"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Edirne",
    "code": "22"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Elazig",
    "code": "23"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Erzincan",
    "code": "24"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Erzurum",
    "code": "25"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Eskisehir",
    "code": "26"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Gaziantep",
    "code": "27"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Giresun",
    "code": "28"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Gumushane",
    "code": "29"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Hakkari",
    "code": "30"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Hatay",
    "code": "31"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Igdir",
    "code": "76"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Isparta",
    "code": "32"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Istanbul",
    "code": "34"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Izmir",
    "code": "35"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kahramanmaras",
    "code": "46"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Karabuk",
    "code": "78"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Karaman",
    "code": "70"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kars",
    "code": "36"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kastamonu",
    "code": "37"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kayseri",
    "code": "38"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kilis",
    "code": "79"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kirikkale",
    "code": "71"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kirklareli",
    "code": "39"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kirsehir",
    "code": "40"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kocaeli",
    "code": "41"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Konya",
    "code": "42"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Kutahya",
    "code": "43"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Malatya",
    "code": "44"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Manisa",
    "code": "45"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Mardin",
    "code": "47"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Mersin",
    "code": "33"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Mugla",
    "code": "48"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Mus",
    "code": "49"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Nevsehir",
    "code": "50"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Nigde",
    "code": "51"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Ordu",
    "code": "52"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Osmaniye",
    "code": "80"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Rize",
    "code": "53"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Sakarya",
    "code": "54"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Samsun",
    "code": "55"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Sanliurfa",
    "code": "63"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Siirt",
    "code": "56"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Sinop",
    "code": "57"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Sirnak",
    "code": "73"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Sivas",
    "code": "58"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Tekirdag",
    "code": "59"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Tokat",
    "code": "60"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Trabzon",
    "code": "61"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Tunceli",
    "code": "62"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Usak",
    "code": "64"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Van",
    "code": "65"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Yalova",
    "code": "77"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Yozgat",
    "code": "66"
  },
  {
    "country_code": "TR",
    "subdivision_name": "Zonguldak",
    "code": "67"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Arima",
    "code": "ARI"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Chaguanas",
    "code": "CHA"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Couva-Tabaquite-Talparo",
    "code": "CTT"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Diego Martin",
    "code": "DMN"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Mayaro-Rio Claro",
    "code": "MRC"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Penal-Debe",
    "code": "PED"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Point Fortin",
    "code": "PTF"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Port of Spain",
    "code": "POS"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Princes Town",
    "code": "PRT"
  },
  {
    "country_code": "TT",
    "subdivision_name": "San Fernando",
    "code": "SFO"
  },
  {
    "country_code": "TT",
    "subdivision_name": "San Juan-Laventille",
    "code": "SJL"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Sangre Grande",
    "code": "SGE"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Siparia",
    "code": "SIP"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Tobago",
    "code": "TOB"
  },
  {
    "country_code": "TT",
    "subdivision_name": "Tunapuna-Piarco",
    "code": "TUP"
  },
  {
    "country_code": "TV",
    "subdivision_name": "Funafuti",
    "code": "FUN"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Changhua",
    "code": "CHA"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Chiayi",
    "code": "CYQ"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Hsinchu",
    "code": "HSQ"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Hualien",
    "code": "HUA"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Kaohsiung",
    "code": "KHH"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Keelung",
    "code": "KEE"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Kinmen",
    "code": "KIN"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Lienchiang",
    "code": "LIE"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Miaoli",
    "code": "MIA"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Nantou",
    "code": "NAN"
  },
  {
    "country_code": "TW",
    "subdivision_name": "New Taipei",
    "code": "NWT"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Penghu",
    "code": "PEN"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Pingtung",
    "code": "PIF"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Taichung",
    "code": "TXG"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Tainan",
    "code": "TNN"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Taipei",
    "code": "TPE"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Taitung",
    "code": "TTT"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Taoyuan",
    "code": "TAO"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Yilan",
    "code": "ILA"
  },
  {
    "country_code": "TW",
    "subdivision_name": "Yunlin",
    "code": "YUN"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Arusha",
    "code": "01"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Dar es Salaam",
    "code": "02"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Dodoma",
    "code": "03"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Geita",
    "code": "27"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Iringa",
    "code": "04"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kagera",
    "code": "05"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kaskazini Pemba",
    "code": "06"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kaskazini Unguja",
    "code": "07"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Katavi",
    "code": "28"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kigoma",
    "code": "08"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kilimanjaro",
    "code": "09"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kusini Pemba",
    "code": "10"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Kusini Unguja",
    "code": "11"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Lindi",
    "code": "12"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Manyara",
    "code": "26"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Mara",
    "code": "13"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Mbeya",
    "code": "14"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Mjini Magharibi",
    "code": "15"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Morogoro",
    "code": "16"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Mtwara",
    "code": "17"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Mwanza",
    "code": "18"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Njombe",
    "code": "29"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Pwani",
    "code": "19"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Rukwa",
    "code": "20"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Ruvuma",
    "code": "21"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Shinyanga",
    "code": "22"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Simiyu",
    "code": "30"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Singida",
    "code": "23"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Songwe",
    "code": "31"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Tabora",
    "code": "24"
  },
  {
    "country_code": "TZ",
    "subdivision_name": "Tanga",
    "code": "25"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Avtonomna Respublika Krym",
    "code": "43"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Cherkaska oblast",
    "code": "71"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Chernihivska oblast",
    "code": "74"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Chernivetska oblast",
    "code": "77"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Dnipropetrovska oblast",
    "code": "12"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Donetska oblast",
    "code": "14"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Ivano-Frankivska oblast",
    "code": "26"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Kharkivska oblast",
    "code": "63"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Khersonska oblast",
    "code": "65"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Khmelnytska oblast",
    "code": "68"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Kirovohradska oblast",
    "code": "35"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Kyiv",
    "code": "30"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Kyivska oblast",
    "code": "32"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Luhanska oblast",
    "code": "09"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Lvivska oblast",
    "code": "46"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Mykolaivska oblast",
    "code": "48"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Odeska oblast",
    "code": "51"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Poltavska oblast",
    "code": "53"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Rivnenska oblast",
    "code": "56"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Sevastopol",
    "code": "40"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Sumska oblast",
    "code": "59"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Ternopilska oblast",
    "code": "61"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Vinnytska oblast",
    "code": "05"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Volynska oblast",
    "code": "07"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Zakarpatska oblast",
    "code": "21"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Zaporizka oblast",
    "code": "23"
  },
  {
    "country_code": "UA",
    "subdivision_name": "Zhytomyrska oblast",
    "code": "18"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Abim",
    "code": "314"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Adjumani",
    "code": "301"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Agago",
    "code": "322"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Alebtong",
    "code": "323"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Apac",
    "code": "302"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Arua",
    "code": "303"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bududa",
    "code": "218"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bugiri",
    "code": "201"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Buhweju",
    "code": "420"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Buikwe",
    "code": "117"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bukedea",
    "code": "219"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bukomansibi",
    "code": "118"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bulambuli",
    "code": "225"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Buliisa",
    "code": "416"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bundibugyo",
    "code": "401"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Bushenyi",
    "code": "402"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Busia",
    "code": "202"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Buvuma",
    "code": "120"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Buyende",
    "code": "226"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Gomba",
    "code": "121"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Gulu",
    "code": "304"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Hoima",
    "code": "403"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Ibanda",
    "code": "417"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Iganga",
    "code": "203"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Isingiro",
    "code": "418"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Jinja",
    "code": "204"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kaabong",
    "code": "318"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kabale",
    "code": "404"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kabarole",
    "code": "405"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kaberamaido",
    "code": "213"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kalangala",
    "code": "101"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kaliro",
    "code": "222"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kalungu",
    "code": "122"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kampala",
    "code": "102"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kamuli",
    "code": "205"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kamwenge",
    "code": "413"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kanungu",
    "code": "414"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kapchorwa",
    "code": "206"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kasese",
    "code": "406"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Katakwi",
    "code": "207"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kayunga",
    "code": "112"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kibaale",
    "code": "407"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kiboga",
    "code": "103"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kibuku",
    "code": "227"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kiruhura",
    "code": "419"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kiryandongo",
    "code": "421"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kisoro",
    "code": "408"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kitgum",
    "code": "305"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Koboko",
    "code": "319"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kole",
    "code": "325"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kumi",
    "code": "208"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kween",
    "code": "228"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kyankwanzi",
    "code": "123"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kyegegwa",
    "code": "422"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Kyenjojo",
    "code": "415"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Lamwo",
    "code": "326"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Lira",
    "code": "307"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Luuka",
    "code": "229"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Luwero",
    "code": "104"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Lwengo",
    "code": "124"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Lyantonde",
    "code": "114"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Manafwa",
    "code": "223"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Maracha",
    "code": "320"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Masaka",
    "code": "105"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Masindi",
    "code": "409"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mayuge",
    "code": "214"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mbale",
    "code": "209"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mbarara",
    "code": "410"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mitooma",
    "code": "423"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mityana",
    "code": "115"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Moroto",
    "code": "308"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Moyo",
    "code": "309"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mpigi",
    "code": "106"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mubende",
    "code": "107"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Mukono",
    "code": "108"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Nakapiripirit",
    "code": "311"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Nakaseke",
    "code": "116"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Nakasongola",
    "code": "109"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Namayingo",
    "code": "230"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Napak",
    "code": "327"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Nebbi",
    "code": "310"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Ntoroko",
    "code": "424"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Ntungamo",
    "code": "411"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Nwoya",
    "code": "328"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Oyam",
    "code": "321"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Pader",
    "code": "312"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Rakai",
    "code": "110"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Rubirizi",
    "code": "425"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Rukungiri",
    "code": "412"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Sembabule",
    "code": "111"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Sheema",
    "code": "426"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Sironko",
    "code": "215"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Soroti",
    "code": "211"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Tororo",
    "code": "212"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Wakiso",
    "code": "113"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Yumbe",
    "code": "313"
  },
  {
    "country_code": "UG",
    "subdivision_name": "Zombo",
    "code": "330"
  },
  {
    "country_code": "UM",
    "subdivision_name": "Palmyra Atoll",
    "code": "95"
  },
  {
    "country_code": "US",
    "subdivision_name": "Alabama",
    "code": "AL"
  },
  {
    "country_code": "US",
    "subdivision_name": "Alaska",
    "code": "AK"
  },
  {
    "country_code": "US",
    "subdivision_name": "Arizona",
    "code": "AZ"
  },
  {
    "country_code": "US",
    "subdivision_name": "Arkansas",
    "code": "AR"
  },
  {
    "country_code": "US",
    "subdivision_name": "California",
    "code": "CA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Colorado",
    "code": "CO"
  },
  {
    "country_code": "US",
    "subdivision_name": "Connecticut",
    "code": "CT"
  },
  {
    "country_code": "US",
    "subdivision_name": "Delaware",
    "code": "DE"
  },
  {
    "country_code": "US",
    "subdivision_name": "District of Columbia",
    "code": "DC"
  },
  {
    "country_code": "US",
    "subdivision_name": "Florida",
    "code": "FL"
  },
  {
    "country_code": "US",
    "subdivision_name": "Georgia",
    "code": "GA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Hawaii",
    "code": "HI"
  },
  {
    "country_code": "US",
    "subdivision_name": "Idaho",
    "code": "ID"
  },
  {
    "country_code": "US",
    "subdivision_name": "Illinois",
    "code": "IL"
  },
  {
    "country_code": "US",
    "subdivision_name": "Indiana",
    "code": "IN"
  },
  {
    "country_code": "US",
    "subdivision_name": "Iowa",
    "code": "IA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Kansas",
    "code": "KS"
  },
  {
    "country_code": "US",
    "subdivision_name": "Kentucky",
    "code": "KY"
  },
  {
    "country_code": "US",
    "subdivision_name": "Louisiana",
    "code": "LA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Maine",
    "code": "ME"
  },
  {
    "country_code": "US",
    "subdivision_name": "Maryland",
    "code": "MD"
  },
  {
    "country_code": "US",
    "subdivision_name": "Massachusetts",
    "code": "MA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Michigan",
    "code": "MI"
  },
  {
    "country_code": "US",
    "subdivision_name": "Minnesota",
    "code": "MN"
  },
  {
    "country_code": "US",
    "subdivision_name": "Mississippi",
    "code": "MS"
  },
  {
    "country_code": "US",
    "subdivision_name": "Missouri",
    "code": "MO"
  },
  {
    "country_code": "US",
    "subdivision_name": "Montana",
    "code": "MT"
  },
  {
    "country_code": "US",
    "subdivision_name": "Nebraska",
    "code": "NE"
  },
  {
    "country_code": "US",
    "subdivision_name": "Nevada",
    "code": "NV"
  },
  {
    "country_code": "US",
    "subdivision_name": "New Hampshire",
    "code": "NH"
  },
  {
    "country_code": "US",
    "subdivision_name": "New Jersey",
    "code": "NJ"
  },
  {
    "country_code": "US",
    "subdivision_name": "New Mexico",
    "code": "NM"
  },
  {
    "country_code": "US",
    "subdivision_name": "New York",
    "code": "NY"
  },
  {
    "country_code": "US",
    "subdivision_name": "North Carolina",
    "code": "NC"
  },
  {
    "country_code": "US",
    "subdivision_name": "North Dakota",
    "code": "ND"
  },
  {
    "country_code": "US",
    "subdivision_name": "Ohio",
    "code": "OH"
  },
  {
    "country_code": "US",
    "subdivision_name": "Oklahoma",
    "code": "OK"
  },
  {
    "country_code": "US",
    "subdivision_name": "Oregon",
    "code": "OR"
  },
  {
    "country_code": "US",
    "subdivision_name": "Pennsylvania",
    "code": "PA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Rhode Island",
    "code": "RI"
  },
  {
    "country_code": "US",
    "subdivision_name": "South Carolina",
    "code": "SC"
  },
  {
    "country_code": "US",
    "subdivision_name": "South Dakota",
    "code": "SD"
  },
  {
    "country_code": "US",
    "subdivision_name": "Tennessee",
    "code": "TN"
  },
  {
    "country_code": "US",
    "subdivision_name": "Texas",
    "code": "TX"
  },
  {
    "country_code": "US",
    "subdivision_name": "Utah",
    "code": "UT"
  },
  {
    "country_code": "US",
    "subdivision_name": "Vermont",
    "code": "VT"
  },
  {
    "country_code": "US",
    "subdivision_name": "Virginia",
    "code": "VA"
  },
  {
    "country_code": "US",
    "subdivision_name": "Washington",
    "code": "WA"
  },
  {
    "country_code": "US",
    "subdivision_name": "West Virginia",
    "code": "WV"
  },
  {
    "country_code": "US",
    "subdivision_name": "Wisconsin",
    "code": "WI"
  },
  {
    "country_code": "US",
    "subdivision_name": "Wyoming",
    "code": "WY"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Artigas",
    "code": "AR"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Canelones",
    "code": "CA"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Cerro Largo",
    "code": "CL"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Colonia",
    "code": "CO"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Durazno",
    "code": "DU"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Flores",
    "code": "FS"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Florida",
    "code": "FD"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Lavalleja",
    "code": "LA"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Maldonado",
    "code": "MA"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Montevideo",
    "code": "MO"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Paysandu",
    "code": "PA"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Rio Negro",
    "code": "RN"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Rivera",
    "code": "RV"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Rocha",
    "code": "RO"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Salto",
    "code": "SA"
  },
  {
    "country_code": "UY",
    "subdivision_name": "San Jose",
    "code": "SJ"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Soriano",
    "code": "SO"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Tacuarembo",
    "code": "TA"
  },
  {
    "country_code": "UY",
    "subdivision_name": "Treinta y Tres",
    "code": "TT"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Andijon",
    "code": "AN"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Buxoro",
    "code": "BU"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Farg'ona",
    "code": "FA"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Jizzax",
    "code": "JI"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Namangan",
    "code": "NG"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Navoiy",
    "code": "NW"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Qashqadaryo",
    "code": "QA"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Qoraqalpog'iston Respublikasi",
    "code": "QR"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Samarqand",
    "code": "SA"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Sirdaryo",
    "code": "SI"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Surxondaryo",
    "code": "SU"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Toshkent",
    "code": "TK"
  },
  {
    "country_code": "UZ",
    "subdivision_name": "Xorazm",
    "code": "XO"
  },
  {
    "country_code": "VA",
    "subdivision_name": "Vatican City",
    "code": "-"
  },
  {
    "country_code": "VC",
    "subdivision_name": "Charlotte",
    "code": "01"
  },
  {
    "country_code": "VC",
    "subdivision_name": "Grenadines",
    "code": "06"
  },
  {
    "country_code": "VC",
    "subdivision_name": "Saint George",
    "code": "04"
  },
  {
    "country_code": "VC",
    "subdivision_name": "Saint Patrick",
    "code": "05"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Amazonas",
    "code": "Z"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Anzoategui",
    "code": "B"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Apure",
    "code": "C"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Aragua",
    "code": "D"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Barinas",
    "code": "E"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Bolivar",
    "code": "F"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Carabobo",
    "code": "G"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Cojedes",
    "code": "H"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Delta Amacuro",
    "code": "Y"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Dependencias Federales",
    "code": "W"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Distrito Capital",
    "code": "A"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Falcon",
    "code": "I"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Guarico",
    "code": "J"
  },
  {
    "country_code": "VE",
    "subdivision_name": "La Guaira",
    "code": "X"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Lara",
    "code": "K"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Merida",
    "code": "L"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Miranda",
    "code": "M"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Monagas",
    "code": "N"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Nueva Esparta",
    "code": "O"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Portuguesa",
    "code": "P"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Sucre",
    "code": "R"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Tachira",
    "code": "S"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Trujillo",
    "code": "T"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Yaracuy",
    "code": "U"
  },
  {
    "country_code": "VE",
    "subdivision_name": "Zulia",
    "code": "V"
  },
  {
    "country_code": "VG",
    "subdivision_name": "Virgin Islands, British",
    "code": "-"
  },
  {
    "country_code": "VI",
    "subdivision_name": "Virgin Islands, U.S.",
    "code": "-"
  },
  {
    "country_code": "VN",
    "subdivision_name": "An Giang",
    "code": "44"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ba Ria - Vung Tau",
    "code": "43"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Bac Giang",
    "code": "54"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Bac Kan",
    "code": "53"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Bac Lieu",
    "code": "55"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Bac Ninh",
    "code": "56"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ben Tre",
    "code": "50"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Binh Dinh",
    "code": "31"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Binh Duong",
    "code": "57"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Binh Phuoc",
    "code": "58"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Binh Thuan",
    "code": "40"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ca Mau",
    "code": "59"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Can Tho",
    "code": "CT"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Cao Bang",
    "code": "04"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Da Nang",
    "code": "DN"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Dak Lak",
    "code": "33"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Dak Nong",
    "code": "72"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Dien Bien",
    "code": "71"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Dong Nai",
    "code": "39"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Dong Thap",
    "code": "45"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Gia Lai",
    "code": "30"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ha Giang",
    "code": "03"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ha Nam",
    "code": "63"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ha Noi",
    "code": "HN"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ha Tinh",
    "code": "23"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Hai Duong",
    "code": "61"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Hai Phong",
    "code": "HP"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Hau Giang",
    "code": "73"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ho Chi Minh",
    "code": "SG"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Hoa Binh",
    "code": "14"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Hung Yen",
    "code": "66"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Khanh Hoa",
    "code": "34"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Kien Giang",
    "code": "47"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Kon Tum",
    "code": "28"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Lai Chau",
    "code": "01"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Lam Dong",
    "code": "35"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Lang Son",
    "code": "09"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Lao Cai",
    "code": "02"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Long An",
    "code": "41"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Nam Dinh",
    "code": "67"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Nghe An",
    "code": "22"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ninh Binh",
    "code": "18"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Ninh Thuan",
    "code": "36"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Phu Tho",
    "code": "68"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Phu Yen",
    "code": "32"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Quang Binh",
    "code": "24"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Quang Nam",
    "code": "27"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Quang Ngai",
    "code": "29"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Quang Ninh",
    "code": "13"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Quang Tri",
    "code": "25"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Soc Trang",
    "code": "52"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Son La",
    "code": "05"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Tay Ninh",
    "code": "37"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Thai Binh",
    "code": "20"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Thai Nguyen",
    "code": "69"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Thanh Hoa",
    "code": "21"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Thua Thien-Hue",
    "code": "26"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Tien Giang",
    "code": "46"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Tra Vinh",
    "code": "51"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Tuyen Quang",
    "code": "07"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Vinh Long",
    "code": "49"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Vinh Phuc",
    "code": "70"
  },
  {
    "country_code": "VN",
    "subdivision_name": "Yen Bai",
    "code": "06"
  },
  {
    "country_code": "VU",
    "subdivision_name": "Sanma",
    "code": "SAM"
  },
  {
    "country_code": "VU",
    "subdivision_name": "Shefa",
    "code": "SEE"
  },
  {
    "country_code": "VU",
    "subdivision_name": "Tafea",
    "code": "TAE"
  },
  {
    "country_code": "WF",
    "subdivision_name": "Sigave",
    "code": "SG"
  },
  {
    "country_code": "WF",
    "subdivision_name": "Uvea",
    "code": "UV"
  },
  {
    "country_code": "WS",
    "subdivision_name": "Atua",
    "code": "AT"
  },
  {
    "country_code": "WS",
    "subdivision_name": "Fa'asaleleaga",
    "code": "FA"
  },
  {
    "country_code": "WS",
    "subdivision_name": "Tuamasaga",
    "code": "TU"
  },
  {
    "country_code": "YE",
    "subdivision_name": "'Adan",
    "code": "AD"
  },
  {
    "country_code": "YE",
    "subdivision_name": "'Amran",
    "code": "AM"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Ad Dali'",
    "code": "DA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Al Bayda'",
    "code": "BA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Al Hudaydah",
    "code": "HU"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Al Jawf",
    "code": "JA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Al Mahrah",
    "code": "MR"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Al Mahwit",
    "code": "MW"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Amanat al 'Asimah",
    "code": "SA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Dhamar",
    "code": "DH"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Hadramawt",
    "code": "HD"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Ibb",
    "code": "IB"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Lahij",
    "code": "LA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Ma'rib",
    "code": "MA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Raymah",
    "code": "RA"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Sa'dah",
    "code": "SD"
  },
  {
    "country_code": "YE",
    "subdivision_name": "San'a'",
    "code": "SN"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Shabwah",
    "code": "SH"
  },
  {
    "country_code": "YE",
    "subdivision_name": "Ta'izz",
    "code": "TA"
  },
  {
    "country_code": "YT",
    "subdivision_name": "Bandraboua",
    "code": "-"
  },
  {
    "country_code": "YT",
    "subdivision_name": "Bandrele",
    "code": "-"
  },
  {
    "country_code": "YT",
    "subdivision_name": "Kani-Keli",
    "code": "-"
  },
  {
    "country_code": "YT",
    "subdivision_name": "Mamoudzou",
    "code": "-"
  },
  {
    "country_code": "YT",
    "subdivision_name": "Pamandzi",
    "code": "-"
  },
  {
    "country_code": "YT",
    "subdivision_name": "Tsingoni",
    "code": "-"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Eastern Cape",
    "code": "EC"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Free State",
    "code": "FS"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Gauteng",
    "code": "GP"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Kwazulu-Natal",
    "code": "KZN"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Limpopo",
    "code": "LP"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Mpumalanga",
    "code": "MP"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "North-West",
    "code": "NW"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Northern Cape",
    "code": "NC"
  },
  {
    "country_code": "ZA",
    "subdivision_name": "Western Cape",
    "code": "WC"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Central",
    "code": "02"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Copperbelt",
    "code": "08"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Eastern",
    "code": "03"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Luapula",
    "code": "04"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Lusaka",
    "code": "09"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Muchinga",
    "code": "10"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "North-Western",
    "code": "06"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Northern",
    "code": "05"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Southern",
    "code": "07"
  },
  {
    "country_code": "ZM",
    "subdivision_name": "Western",
    "code": "01"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Bulawayo",
    "code": "BU"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Harare",
    "code": "HA"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Manicaland",
    "code": "MA"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Mashonaland Central",
    "code": "MC"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Mashonaland East",
    "code": "ME"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Mashonaland West",
    "code": "MW"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Masvingo",
    "code": "MV"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Matabeleland North",
    "code": "MN"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Matabeleland South",
    "code": "MS"
  },
  {
    "country_code": "ZW",
    "subdivision_name": "Midlands",
    "code": "MI"
  }
]


export { countryData, currencyData, subdivisionData };
