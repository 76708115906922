<aside class="w-100 p-2">
<!--  new style logo on our brands-->
  <div class="logo-and-nav-in-sidebar mb-2" *ngIf="!isRival">
<!--    <div class="close-btn">-->
<!--      <button class="navbar-toggler" type="button" (click)="toggleSidebar()">-->
<!--&lt;!&ndash;        <span class="navbar-toggler-icon"></span>&ndash;&gt;-->
<!--        <svg viewBox="0 0 64 64"> <title></title> <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path></svg>-->
<!--      </button>-->
<!--    </div>-->
<!--    <a class="navbar-brand text-center" routerLink=""><img src="{{assetsSource}}/logo.png" alt="{{brandName}} logo"></a>-->
    <a class="navbar-brand text-center" routerLink=""><img src="{{assetsSource}}/{{logoFileNameForAILogo ?? logoFileName}}" [alt]="logoAltText"></a>
  </div>



  <div *ngIf="user" class="p-2 pb-0 d-flex justify-content-center d-sm-none mb-2 flex-grow-1 mt-2 row gx-2">
    <div class="col">
      <button class="btn btn-outline-primary w-100" disabled><span class="fw-bold">{{user.balance | currency: user.currency }}</span></button>
    </div>
    <div class="col">
      <button class="btn btn-primary w-100" type="button" (click)="goToCashier(); decideTogglingSidebar()">{{ "ACCOUNT.BTN_TO_CASHIER" | translate }}</button>
    </div>
  </div>


  <div class="mb-2 p-2 py-0 rounded-2 bg-main">
    <div class="navbar d-block">
      <ul class="navbar-nav">
                  <li class="nav-item dropend">
                    <button class="nav-link dropdown-toggle d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                           viewBox="0 0 120 120">
                        <g>
                          <path style="fill:#FFFFFF;" d="M64.8,111.8c0.4,0,0.7-0.1,1.1-0.1c0.4,0,0.8-0.1,1.1-0.1c0.4-0.1,0.9-0.1,1.3-0.2
                                   		c0.2,0,0.4-0.1,0.6-0.1c1.9-0.3,3.8-0.8,5.6-1.3c0,0,0,0,0,0C96.2,103.6,112,83.7,112,60S96.2,16.4,74.6,10.1c0,0,0,0,0,0
                                   		c-1.8-0.5-3.7-1-5.6-1.3c-0.2,0-0.4-0.1-0.6-0.1c-0.4-0.1-0.9-0.1-1.3-0.2c-0.4-0.1-0.8-0.1-1.1-0.1c-0.4,0-0.7-0.1-1.1-0.1
                                   		c-0.5,0-1-0.1-1.5-0.1c-0.3,0-0.6,0-0.9-0.1C61.6,8,60.8,8,60,8s-1.6,0-2.4,0.1c-0.3,0-0.6,0-0.9,0.1c-0.5,0-1,0.1-1.5,0.1
                                   		c-0.4,0-0.7,0.1-1.1,0.1c-0.4,0-0.8,0.1-1.1,0.1c-0.4,0.1-0.9,0.1-1.3,0.2c-0.2,0-0.4,0.1-0.6,0.1c-1.9,0.3-3.8,0.8-5.6,1.3
                                   		c0,0,0,0,0,0C23.8,16.4,8,36.3,8,60s15.8,43.6,37.4,49.9c0,0,0,0,0,0c1.8,0.5,3.7,1,5.6,1.3c0.2,0,0.4,0.1,0.6,0.1
                                   		c0.4,0.1,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.1,1.1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.5,0,1,0.1,1.5,0.1c0.3,0,0.6,0,0.9,0.1
                                   		c0.8,0,1.6,0.1,2.4,0.1s1.6,0,2.4-0.1c0.3,0,0.6,0,0.9-0.1C63.8,111.9,64.3,111.8,64.8,111.8z M46.7,98.9
                                   		c-2.6-5.4-4.6-11.9-5.9-18.9H52c2.2,0,4,1.8,4,4v19.8c-2.5-0.2-5-0.7-7.4-1.3C47.9,101.4,47.3,100.2,46.7,98.9z M16,60
                                   		c0-4.2,0.6-8.2,1.7-12h13.9c-0.4,3.9-0.6,7.9-0.6,12s0.2,8.1,0.6,12H17.7C16.6,68.2,16,64.2,16,60z M39,60c0-4.1,0.2-8.1,0.6-12H56
                                   		v24H39.6C39.2,68.1,39,64.1,39,60z M48.6,17.5c2.4-0.6,4.9-1.1,7.4-1.3V36c0,2.2-1.8,4-4,4H40.8c1.3-7.1,3.3-13.6,5.9-18.9
                                   		C47.3,19.8,47.9,18.6,48.6,17.5z M73.3,21.1c2.6,5.4,4.6,11.9,5.9,18.9H68c-2.2,0-4-1.8-4-4V16.2c2.5,0.2,5,0.7,7.4,1.3
                                   		C72.1,18.6,72.7,19.8,73.3,21.1z M104,60c0,4.2-0.6,8.2-1.7,12H88.4c0.4-3.9,0.6-7.9,0.6-12s-0.2-8.1-0.6-12h13.9
                                   		C103.4,51.8,104,55.8,104,60z M81,60c0,4.1-0.2,8.1-0.6,12H64V48h16.4C80.8,51.9,81,55.9,81,60z M68,80h11.2
                                   		c-1.3,7.1-3.3,13.6-5.9,18.9c-0.6,1.3-1.3,2.4-1.9,3.6c-2.4,0.6-4.9,1.1-7.4,1.3V84C64,81.8,65.8,80,68,80z M82.5,97.8
                                   		c1.7-4.4,3.2-9.3,4.2-14.6c0.4-1.9,2-3.2,3.9-3.2h8.5C95.4,87.4,89.6,93.5,82.5,97.8z M99.2,40h-8.5c-1.9,0-3.5-1.3-3.9-3.2
                                   		c-1.1-5.3-2.5-10.2-4.2-14.6C89.6,26.5,95.4,32.6,99.2,40z M37.5,22.2c-1.7,4.4-3.2,9.3-4.2,14.6c-0.4,1.9-2,3.2-3.9,3.2h-8.5
                                   		C24.6,32.6,30.4,26.5,37.5,22.2z M20.8,80h8.5c1.9,0,3.5,1.3,3.9,3.2c1.1,5.3,2.5,10.2,4.2,14.6C30.4,93.5,24.6,87.4,20.8,80z"/>
                        </g>
                      </svg>
                      <span class="fw-bold">{{'LANG' | translate}}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-dark dropdown-bg border-0">

                      <li *ngFor="let option of languageOptions" (click)="switchLang(option.value)">
                        <button class="dropdown-item" type="button">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <img class="me-2" [ngSrc]="'assets/lang_images/' + option.value + '.svg'" width="20" [height]="option.height" [alt]="option.label">
                              <label class="fw-bold form-check-label">{{option.label}}</label>
                            </div>
                            <input class="form-check-input mt-0" type="radio" name="lang" id="lang{{ option.value | titlecase }}" [checked]="selectedLang === option.value">
                          </div>
                        </button>
                      </li>

                    </ul>
                  </li>
      </ul>
    </div>
  </div>






    <div class="p-2 pb-0 rounded-2 bg-main" *ngIf="user">

<!--      <div *ngIf="user" class="d-flex justify-content-center d-sm-none mb-4 ">-->
<!--        <div class="btn-group w-100">-->
<!--          <button id="balanceInBtn" class="btn btn-outline-primary" disabled><span class="fw-bold">{{user.balance | currency: user.currency }}</span></button>-->
<!--          <button class="btn btn-primary" type="button" (click)="goToCashier(); decideTogglingSidebar()">{{ "ACCOUNT.BALANCE" | translate }}</button>-->
<!--        </div>-->
<!--      </div>-->



      <h6 class="fw-bold mb-0">{{ 'ACCOUNT.TITLE' | translate}}</h6>
      <div class="navbar pt-0">
        <ul class="navbar-nav">

          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="account/cashier" routerLinkActive="active-link" (click)="decideTogglingSidebar()">
            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 120 120">
              <path style="fill:#FFFFFF;" d="M96.6,68L96.6,68c-0.2-0.6-0.4-1.1-0.6-1.7c0,0,0,0,0-0.1c-0.5-1.1-1-2.1-1.7-3.1c0,0,0-0.1-0.1-0.1
              	c-0.6-1-1.3-1.9-2.1-2.8c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-0.8-0.9-1.2-1.3c0,0,0,0-0.1-0.1c-0.4-0.4-0.8-0.8-1.3-1.1c0,0,0,0-0.1-0.1
              	c-0.4-0.4-0.9-0.7-1.3-1c-0.1,0-0.1-0.1-0.2-0.1C87,55.8,86,55.2,85,54.7c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.2-1-0.5-1.5-0.7
              	c0,0-0.1,0-0.1-0.1c-0.5-0.2-1-0.4-1.5-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.2-1-0.3-1.6-0.5c-0.1,0-0.2,0-0.3-0.1
              	c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.5-0.1-1.1-0.2-1.7-0.2c-0.1,0-0.3,0-0.4,0C75.2,52,74.6,52,74,52h0h-5h-1V32
              	c0-1.1,0.9-2,2-2h5h1c2.2,0,4,1.8,4,4v3c0,2.2,1.8,4,4,4h8h2c0.8,0,1.5-0.5,1.8-1.2C95.9,38.9,96,38,96,37c0-4.3-1.2-8.3-3.2-11.7h0
              	c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.4-0.5-0.6
              	c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6-0.6-1.2-1.3-1.8-1.8c0,0-0.1-0.1-0.2-0.1
              	c-0.3-0.2-0.6-0.5-0.8-0.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.2-0.5-0.4-0.8-0.6c0,0-0.1-0.1-0.1-0.1h0c-2.4-1.7-5.2-2.9-8.1-3.5
              	C77,14.3,76,15,76,16c0,0,0,0,0,0v1.8c0,0.1,0,0.2,0,0.2h0c0,0.7-0.3,1.4-0.6,2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
              	c-0.1,0.2-0.3,0.3-0.5,0.5c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.3-1.1,0.6-1.7,0.6C70,22.2,68,20.3,68,18v-4V4H52v10v3.8V18
              	c0,1.5-0.9,2.8-2.1,3.5c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1
              	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.1,0-0.2,0
              	c-0.2,0-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1v0c-1.2-0.7-2-2-2-3.4v-2.8c0-0.6-0.4-1-1-1c0,0,0,0-0.1,0
              	l0,0l-0.1,0c-0.1,0-0.3,0-0.4,0.1c-1.5,0.3-3.7,0.8-5.1,1.3C28.4,19.1,22,27.8,22,38v6c0,2.8,0.5,5.5,1.4,8h0
              	c0.2,0.6,0.4,1.1,0.7,1.7c0,0,0,0,0,0.1c0.5,1.1,1,2.1,1.7,3.1c0,0,0,0.1,0.1,0.1c0.6,1,1.3,1.9,2.1,2.8c0,0,0.1,0.1,0.1,0.1
              	c0.4,0.4,0.8,0.9,1.2,1.3c0,0,0,0,0.1,0.1c0.4,0.4,0.8,0.8,1.3,1.1c0,0,0,0,0.1,0.1c0.4,0.4,0.9,0.7,1.3,1c0.1,0,0.1,0.1,0.2,0.1
              	c0.9,0.6,1.9,1.2,2.9,1.8c0.1,0,0.2,0.1,0.3,0.1c0.5,0.2,1,0.5,1.5,0.7c0,0,0.1,0,0.1,0.1c0.5,0.2,1,0.4,1.5,0.5
              	c0.1,0,0.2,0.1,0.3,0.1c0.5,0.2,1,0.3,1.6,0.5c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.3c0.1,0,0.2,0,0.2,0
              	c0.5,0.1,1.1,0.2,1.7,0.2c0.1,0,0.3,0,0.4,0c0.6,0,1.1,0.1,1.7,0.1h0h5h1v20c0,1.1-0.9,2-2,2h-5h-1c-2.2,0-4-1.8-4-4v-3
              	c0-2.2-1.8-4-4-4h-8h-2c-0.8,0-1.5,0.5-1.8,1.2C24,81.1,24,82,24,83c0,4.3,1.2,8.3,3.2,11.7h0c0,0.1,0.1,0.1,0.1,0.2
              	c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.3,0.3,0.4
              	c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.1,0.2,0.2,0.2,0.3c0.6,0.6,1.2,1.3,1.8,1.8c0,0,0.1,0.1,0.2,0.1c0.3,0.2,0.6,0.5,0.8,0.7
              	c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.2,0.5,0.4,0.8,0.6c0,0,0.1,0.1,0.1,0.1h0c2.4,1.7,5.2,2.9,8.1,3.5c0.9,0.2,1.9-0.5,1.9-1.4
              	c0,0,0,0,0,0v-1.8c0-0.1,0-0.2,0-0.2h0c0-0.7,0.3-1.4,0.6-2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.3-0.3,0.5-0.5
              	c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.3c0.5-0.3,1.1-0.6,1.7-0.6c2.4-0.2,4.4,1.6,4.4,4v4v10h16v-10v-3.8V102c0-1.5,0.9-2.8,2.1-3.5
              	c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
              	c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.5,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.2,0.1,0.3,0.1
              	c0.1,0,0.2,0.1,0.2,0.1v0c1.2,0.7,2,2,2,3.4v2.8c0,0.6,0.4,1,1,1c0,0,0,0,0.1,0l0,0l0.1,0c0.1,0,0.3,0,0.4-0.1
              	c1.5-0.3,3.7-0.8,5.1-1.3C91.6,100.9,98,92.2,98,82v-6C98,73.2,97.5,70.5,96.6,68z M47,52h-1h0h-2h-2c-2.2,0-4-1.8-4-4V34
              	c0-2.2,1.8-4,4-4h4h4c1.1,0,2,0.9,2,2v20h0H47z M82,86c0,2.2-1.8,4-4,4h-4h-4c-1.1,0-2-0.9-2-2V68h0h5h1h0h2h2c2.2,0,4,1.8,4,4V86z"/>
              </svg>
            <span class="fw-bold">{{ 'ACCOUNT.CASHIER' | translate}}</span>
          </a></li>

          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="account/promotions" routerLinkActive="active-link" (click)="decideTogglingSidebar()">
            <svg class="casino-icon"  id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 120 120">
              <path style="fill:#FFFFFF;" d="M103.1,85.9l-8.9-14c-0.7-1.2-2-1.9-3.4-1.9H86l-5.8,0c-2.1,0-4-1.5-4.2-3.6C75.8,64,77.7,62,80,62h2
                   	c2.2,0,4-1.8,4-4V44c0-10.4-6.1-19.3-14.8-23.5c-1.6-0.7-2.5-2.4-2.3-4.1c0.1-0.5,0.1-1,0.1-1.5c-0.1-4.8-4-8.8-8.8-8.9
                   	c-5.1-0.1-9.2,4-9.2,9c0,0.5,0,0.9,0.1,1.4c0.3,1.7-0.7,3.4-2.3,4.1C40.1,24.7,34,33.6,34,44v14c0,2.2,1.8,4,4,4l1.8,0
                   	c2.1,0,4,1.5,4.2,3.6c0.2,2.4-1.6,4.4-4,4.4h-6h-4.8c-1.4,0-2.6,0.7-3.4,1.9l-8.9,14c-1.7,2.7,0.2,6.1,3.4,6.1h33.1h13.3h33.1
                   	C102.9,92,104.8,88.5,103.1,85.9z"/>
              <path style="fill:#FFFFFF;" d="M48.2,100c-0.1,0.7-0.2,1.3-0.2,2c0,6.6,5.4,12,12,12s12-5.4,12-12c0-0.7-0.1-1.3-0.2-2H48.2z"/>
            </svg>
            <span class="fw-bold">{{ 'ACCOUNT.PROMO.TITLE' | translate}}</span>
          </a></li>

          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="account/profile" routerLinkActive="active-link"  (click)="decideTogglingSidebar()">
            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 120 120">
                <path style="fill:#FFFFFF;" d="M84.2,62.4c-1.4-1.2-3.4-1.4-4.9-0.4c-1.8,1.3-3.8,2.4-5.9,3.3c-0.5,0.2-1,0.3-1.6,0.3
                	c-1.7,0-3.3-1.1-3.8-2.8c-0.6-2,0.5-4.1,2.4-4.9c1.6-0.7,3-1.5,4.4-2.5c0.2-0.1,0.4-0.2,0.5-0.4c0.4-0.3,0.9-0.7,1.3-1
                	c0.2-0.2,0.4-0.4,0.7-0.6c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.4,0.7-0.7c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.4,0.6-0.7
                	c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.2,0.4-0.4,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.2,0.3-0.4,0.4-0.6c0.3-0.4,0.5-0.8,0.7-1.2
                	c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.5,0.6-1.1,0.8-1.6c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.4-0.9,0.6-1.4c0.1-0.1,0.1-0.3,0.2-0.4
                	c0.2-0.4,0.3-0.8,0.4-1.3c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.8,0.3-1.2c0-0.2,0.1-0.4,0.1-0.5c0.1-0.4,0.2-0.8,0.2-1.3
                	c0-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0.1-0.9,0.1-1.3c0-0.2,0-0.3,0-0.5c0-0.6,0.1-1.2,0.1-1.8C86,19.6,74.4,8,60,8S34,19.6,34,34
                	c0,0.6,0,1.2,0.1,1.8c0,0.2,0,0.3,0,0.5c0,0.4,0.1,0.9,0.1,1.3c0,0.2,0.1,0.4,0.1,0.6c0.1,0.4,0.1,0.8,0.2,1.3
                	c0,0.2,0.1,0.4,0.1,0.5c0.1,0.4,0.2,0.8,0.3,1.2c0,0.2,0.1,0.3,0.2,0.5c0.1,0.4,0.3,0.8,0.4,1.3c0.1,0.1,0.1,0.3,0.2,0.4
                	c0.2,0.5,0.4,0.9,0.6,1.4c0,0.1,0.1,0.2,0.1,0.3c0.3,0.5,0.5,1.1,0.8,1.6c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.8,0.7,1.2
                	c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.3,0.4,0.5,0.6,0.8c0.2,0.2,0.3,0.5,0.5,0.7c0.2,0.2,0.4,0.4,0.5,0.7c0.2,0.2,0.4,0.5,0.6,0.7
                	c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.7,0.6c0.4,0.4,0.9,0.7,1.3,1
                	c0.2,0.1,0.4,0.2,0.5,0.4c1.4,0.9,2.8,1.8,4.4,2.5c1.9,0.8,3.1,3,2.4,4.9c-0.6,1.7-2.1,2.8-3.8,2.8c-0.5,0-1.1-0.1-1.6-0.3
                	c-2.1-0.9-4.1-2-5.9-3.3c-1.5-1-3.5-0.9-4.9,0.4C27.4,69.9,22,81.3,22,94v14c0,2.2,1.8,4,4,4h68c2.2,0,4-1.8,4-4V94
                	C98,81.3,92.6,69.9,84.2,62.4z"/>
            </svg>
            <span class="fw-bold">{{ 'ACCOUNT.INFO.TITLE' | translate}}</span>
          </a></li>

<!--          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="account/jackpots" routerLinkActive="active-link"  (click)="decideTogglingSidebar()">-->
<!--            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"-->
<!--                 viewBox="0 0 120 120">-->
<!--              <g>-->
<!--                <path style="fill:#FFFFFF;" d="M41.8,50.6L59,103.4c0.3,0.9,1.6,0.9,1.9,0l17.2-52.8c0.4-1.3-0.5-2.6-1.9-2.6H43.7C42.4,48,41.4,49.3,41.8,50.6z"/>-->
<!--                <path style="fill:#FFFFFF;" d="M46.2,29.7C46.2,29.7,46.2,29.7,46.2,29.7l-3.5,7.4c-0.6,1.3,0.3,2.8,1.8,2.8h31c1.5,0,2.4-1.5,1.8-2.8l-3.5-7.4c0,0,0,0,0,0l-5.3-11.4c-0.7-1.4-2.1-2.3-3.6-2.3H60h-4.9c-1.6,0-3,0.9-3.6,2.3L46.2,29.7z"/>-->
<!--                <path style="fill:#FFFFFF;" d="M113.5,41.4L89.6,16.6c-0.4-0.4-0.9-0.6-1.4-0.6h-8.8c-1.5,0-2.4,1.5-1.8,2.8l8.3,17.7l0.5,1.1C87,39.1,88.4,40,90,40l8.8,0c2.1,0,4,1.5,4.2,3.6c0.2,2.4-1.6,4.4-4,4.4h-8.7c-1.7,0-3.3,1.1-3.8,2.8l-15,46c-0.1,0.4,0.4,0.7,0.7,0.4l41.5-50.4C115,45.2,114.9,42.9,113.5,41.4z"/>-->
<!--                <path style="fill:#FFFFFF;" d="M29.7,48l-8.5,0c-2.1,0-4-1.5-4.2-3.6C16.8,42,18.7,40,21,40h9c1.6,0,3-0.9,3.6-2.3l0.5-1l8.3-17.9c0.6-1.3-0.3-2.8-1.8-2.8h-8.8c-0.5,0-1.1,0.2-1.4,0.6L6.5,41.4c-1.4,1.5-1.5,3.8-0.2,5.3l41.5,50.4c0.3,0.3,0.8,0,0.7-0.4l-15-46C32.9,49.1,31.4,48,29.7,48z"/>-->
<!--              </g>-->
<!--            </svg>-->
<!--            <span class="fw-bold"> {{ 'ACCOUNT.JACKPOT.TITLE' | translate}}</span>-->
<!--           </a></li>-->

          <li class="nav-item"><a class="nav-link d-flex align-items-center" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#logoutModal" (click)="decideTogglingSidebar()">
            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 120 120">
              <g>
                <path style="fill:#FFFFFF;" d="M58,92H26c-1.1,0-2-0.9-2-2V30c0-1.1,0.9-2,2-2h32c1.1,0,2-0.9,2-2v-6c0-1.1-0.9-2-2-2H24h-8c-1.1,0-2,0.9-2,2v8v64v8c0,1.1,0.9,2,2,2h8h34c1.1,0,2-0.9,2-2v-6C60,92.9,59.1,92,58,92z"/>
                <path style="fill:#FFFFFF;" d="M110.7,57.5L81.2,30.6C80,29.4,78,30.2,78,31.8V48H48c-2.2,0-4,1.8-4,4v16c0,2.2,1.8,4,4,4h30v16.2c0,1.6,2,2.3,3.2,1.3l29.5-26.9C112.3,61.1,112.3,58.9,110.7,57.5z"/>
              </g>
            </svg>
            <span class="fw-bold"> {{ 'LOGOUT.TITLE' | translate}} </span>
          </a></li>


        </ul>
      </div>
    </div>

    <div class="p-2 pb-0 rounded-2 bg-main" [ngClass]="{'mt-2': user}">
      <h6 class="fw-bold mb-0">{{ 'CATEGORIES.TITLE' | translate}}</h6>
      <div class="navbar pt-0">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let category of categories; let i = index">
            <a class="nav-link d-flex align-items-center" routerLink="/games/{{category.replace(' ', '')}}"  routerLinkActive="active-link" [class.active-link]="i === 0 && router.url.includes('/details')"
               (click)="decideTogglingSidebar()">
              <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
                <g [innerHTML]="getSanitizedSvgPath(category.toLowerCase().replace(' ', ''))"></g>
              </svg>
              <span class="fw-bold">{{"CATEGORIES." + (category.split(' ').join('') | uppercase) | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="mt-2 p-2 rounded-2 bg-main">
      <a class="fs-6 fw-bold nav-link d-flex align-items-center" routerLink="providers"  routerLinkActive="active-link"  (click)="decideTogglingSidebar()">
        <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
          <g [innerHTML]="getSanitizedSvgPath('provider')"></g>
        </svg>
        <span class="fw-bold">{{'PROVIDERS.TITLE' | translate}}</span>
      </a>
    </div>

    <div class="mt-2 p-2 rounded-2 bg-main" *ngIf="!user">
      <a class="fs-6 fw-bold nav-link d-flex align-items-center" routerLink="/promotions"  routerLinkActive="active-link"  (click)="decideTogglingSidebar()">
        <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 120 120">
              <g>
              	<g>
              		<path style="fill:#FFFFFF;" d="M52,30H19.2c-2.3,0-4.2,1.9-4.2,4.2v13.6c0,2.3,1.9,4.2,4.2,4.2H23h11.8c2.1,0,4,1.5,4.2,3.6
              			c0.2,2.4-1.6,4.4-4,4.4h-8c-2.2,0-4,1.8-4,4v40c0,4.4,3.6,8,8,8h21V30z"/>
                  <path style="fill:#FFFFFF;" d="M100.8,30H68v82h21c4.4,0,8-3.6,8-8V64c0-2.2-1.8-4-4-4h-8c-2.3,0-4.2-2-4-4.4
              			c0.2-2.1,2.1-3.6,4.2-3.6H97h3.8c2.3,0,4.2-1.9,4.2-4.2V34.2C105,31.9,103.1,30,100.8,30z"/>
              	</g>
                <path style="fill:#FFFFFF;" d="M80,6c-1.6,0-3.2,0.5-4.4,1.3l0,0l0,0c0,0-0.1,0-0.1,0.1l-13.2,9c-1.4,0.9-3.2,0.9-4.5,0l-13.2-9
              		c0,0-0.1,0-0.1-0.1l0,0l0,0C43.2,6.5,41.6,6,40,6c-4.4,0-8,3.6-8,8s3.6,8,8,8h20h20c4.4,0,8-3.6,8-8S84.4,6,80,6z"/>
              </g>
              </svg>
        <span class="fw-bold">{{'PROMOTIONS.TITLE' | translate}}</span>
      </a>
    </div>

<!--    <div class="mt-2 p-2 rounded-2 bg-main">-->
<!--      <a class="fs-6 fw-bold nav-link d-flex align-items-center" routerLink="leaderboard"  routerLinkActive="active-link"  (click)="decideTogglingSidebar()">-->
<!--        <svg class="casino-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">-->
<!--          <path style="fill:#FFFFFF;" d="M114.1,21.9c-3.6-3.6-9.6-3.3-13.5,0.6c-3.9,3.9-4.2,9.9-0.6,13.5c0.7,0.7,1.5,1.3,2.4,1.7L93.5,54-->
<!--          	c-4.5,8.3-16.5,7.7-20.2-0.9L64,31.3c4.1-1.5,7-5.1,7-9.3c0-5.5-4.9-10-11-10s-11,4.5-11,10c0,4.2,2.9,7.9,7,9.3l-9.3,21.8-->
<!--          	C43,61.8,30.9,62.3,26.5,54l-8.8-16.3c0.9-0.4,1.7-1,2.4-1.7c3.6-3.6,3.3-9.6-0.6-13.5c-3.9-3.9-9.9-4.2-13.5-0.6-->
<!--          	c-3.6,3.6-3.3,9.6,0.6,13.5c2,2,4.5,3,7,3.1l5.8,36.1c0.3,1.9,2,3.4,3.9,3.4l8.7,0c2.1,0,4,1.5,4.2,3.6c0.2,2.4-1.6,4.4-4,4.4h-8-->
<!--          	l0,0c-3.1,0-6.5,4-7.7,9.5c-1.4,6,0.2,11.6,3.5,12.4c0.3,0.1,0.6,0.1,0.9,0.1l0,0H60h39.1l0,0c0.3,0,0.6,0,0.9-0.1-->
<!--          	c3.4-0.8,5-6.4,3.5-12.4c-1.3-5.5-4.6-9.5-7.7-9.5l0,0L88,86c-2.1,0-4-1.5-4.2-3.6c-0.2-2.4,1.6-4.4,4-4.4h8.9c2,0,3.6-1.4,3.9-3.4-->
<!--          	l5.8-36.1c2.5-0.1,5-1.1,7-3.1C117.3,31.5,117.6,25.5,114.1,21.9z"/>-->
<!--        </svg>-->
<!--        <span class="fw-bold">{{'LEADERBOARD.TITLE' | translate}}</span>-->
<!--      </a>-->
<!--    </div>-->

    <div class="mt-2 p-2 py-0 rounded-2 bg-main">
      <div class="navbar d-block">
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="info/about" [class.active-link]="router.url.includes('/info/') && !router.url.includes('/support') && !router.url.includes('/terms')"  (click)="decideTogglingSidebar()">
            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 120 120">
              <path style="fill:#FFFFFF;" d="M60,10c-27.6,0-50,22.4-50,50s22.4,50,50,50s50-22.4,50-50S87.6,10,60,10z M61.9,21.3
                     	c5.5,0,10,4.5,10,10c0,5.5-4.5,10-10,10s-10-4.5-10-10C51.9,25.8,56.4,21.3,61.9,21.3z M74.3,87.6l-0.4,2.1c-0.3,1.5-1.5,2.7-3,3.1
                     	l-14.4,2.5c-5.6,1-10.4-4-9.2-9.5l2.1-10.1l2.9-14.2l-7.9,1.4c-2.8,0.5-5.2-2-4.6-4.7l0.4-2.1c0.3-1.6,1.6-2.9,3.2-3.1l11-1.9
                     	l3.2-0.6c5.6-1,10.4,4,9.2,9.5l-0.1,0.6l-4.9,23.7l7.9-1.4C72.4,82.3,74.8,84.8,74.3,87.6z"/>
            </svg>
            <span class="fw-bold">{{ 'LEGAL.ABOUT.TITLE' | translate}}</span></a></li>

          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="info/terms"  routerLinkActive="active-link"  (click)="decideTogglingSidebar()">
            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 120 120">
                <path style="fill:#FFFFFF;" d="M75,32c-2.2,0-4-1.8-4-4V8H29c-2.2,0-4,1.8-4,4v96c0,2.2,1.8,4,4,4h62c2.2,0,4-1.8,4-4V32H75z M56,93
                	H38c-2.2,0-4-1.8-4-4s1.8-4,4-4h18c2.2,0,4,1.8,4,4S58.2,93,56,93z M82,73H38c-2.2,0-4-1.8-4-4s1.8-4,4-4h44c2.2,0,4,1.8,4,4
                	S84.2,73,82,73z M82,53H38c-2.2,0-4-1.8-4-4s1.8-4,4-4h44c2.2,0,4,1.8,4,4S84.2,53,82,53z"/>
                </svg>
            <span class="fw-bold">{{ 'LEGAL.TERMS.TITLE' | translate}}</span></a></li>

          <li class="nav-item"><a class="nav-link d-flex align-items-center" routerLink="info/support"  routerLinkActive="active-link"  (click)="decideTogglingSidebar()">
              <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background:new 0 0 120 120;" xml:space="preserve">
                <g><path style="fill:#FFFFFF;" d="M106,49.3c-3.4,1.9-7.9,4.5-12.7,7.3c-2.7,1.5-2.7,5.4,0,6.9l9.8,5.6c1.9,1.1,2.6,3.5,1.5,5.5s-3.5,2.6-5.5,1.5l0,0v0l-18-10.3c-1.2-0.7-2.7-0.7-4,0c-5.7,3.2-9.7,5.6-9.7,5.6l-0.2,0.1l-0.3,0.1c-2.1,1-4.5,1.6-6.9,1.6s-4.8-0.5-6.9-1.6l-0.3-0.1l-0.2-0.1c0,0-4.1-2.3-9.7-5.6c-1.2-0.7-2.7-0.7-4,0l-2.3,1.3l0,0l-3.9,2.3L20.8,76v0l0,0c-1.9,1.1-4.4,0.4-5.5-1.5h0c-1.1-1.9-0.4-4.4,1.5-5.5l4.9-2.8c1.1-0.6,2.3-1.3,3.5-2l1.5-0.8c2.7-1.5,2.7-5.4,0-6.9c-4.8-2.7-9.3-5.3-12.7-7.3c-2.7-1.5-6,0.4-6,3.5c0,4.4,0,10.1,0,14.5c0,0,0,0,0,0v20.9h0c0,0.5,0.1,1.1,0.2,1.6C9,93.5,12.5,96,16.3,96h87.4c3.8,0,7.3-2.5,8.1-6.3c0.1-0.5,0.2-1.1,0.2-1.6h0V67.3c0,0,0,0,0,0c0-4.4,0-10.1,0-14.5C112,49.7,108.7,47.7,106,49.3z"/>
                <path style="fill:#FFFFFF;" d="M12.5,39.2l44,25.2c1.1,0.5,2.3,0.8,3.5,0.8s2.4-0.3,3.5-0.8l44-25.2c2.8-1.3,4.5-4.1,4.5-7.2c0-4.4-3.6-8-8-8H16c-4.4,0-8,3.6-8,8C8,35.1,9.8,37.9,12.5,39.2z"/></g>
               </svg>
               <span class="fw-bold">{{ 'LEGAL.SUPPORT.TITLE' | translate}}</span>
          </a></li>

          <li class="nav-item"><button class="btn btn-link nav-link d-flex align-items-center" (click)="decideTogglingSidebar(); openChat()">
            <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
              <path style="fill:#FFFFFF;" d="M60,17C31.3,17,8,34.5,8,56c0,10.4,5.4,19.9,14.3,26.8c1.1,0.8,1.6,2.2,1.5,3.5
            	  c-0.8,8.1-4,14.9-8.3,18.6c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.9,0.8,1.6,1.6,1.5c9-1,17.1-3.6,23.3-7.2c-1.9-0.5-3.8-1.1-5.7-1.8
            	  c-2.1-0.8-3.1-3.1-2.4-5.1c0.8-2.1,3.1-3.1,5.1-2.4c4.2,1.6,8.5,2.6,13,3.3c0.7,0.1,1.4,0.2,2.1,0.3c0.2,0,0.4,0,0.7,0.1
            	  c0.6,0.1,1.2,0.1,1.8,0.2c0.4,0,0.8,0.1,1.2,0.1c0.5,0,0.9,0.1,1.4,0.1c0.9,0,1.8,0.1,2.7,0.1v0c28.7,0,52-17.5,52-39S88.7,17,60,17
            	  z M30,66c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10S35.5,66,30,66z M60,66c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10
            	  S65.5,66,60,66z M90,66c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10S95.5,66,90,66z"/>
            </svg>
            <span class="fw-bold">{{ 'CHAT.TITLE' | translate}}</span>
          </button></li>

        </ul>
      </div>
    </div>

  <div class="logo-and-nav-in-sidebar mb-2">
        <div class="close-btn">
          <button class="navbar-toggler mt-2" type="button" (click)="toggleSidebar()" aria-label="Toggle navigation">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
              <g><path d="M4.3,43.3l28.9,31.3c2.9,3.1,8.1,1.1,8.1-3.2V58.1h31.6c3,0,5.4-2.4,5.4-5.4V27.4c0-3-2.4-5.4-5.4-5.4H41.3V8.7c0-4.2-5.2-6.3-8.1-3.2l-29,31.3C2.6,38.6,2.6,41.4,4.3,43.3z"/></g>
            </svg>
          </button>
        </div>
  </div>


</aside>
