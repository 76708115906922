<!DOCTYPE html>
<html>
<head>
  <style>
    .content{
      margin-top: 30px;
      padding: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .line{
      margin: 10px 0 10px 0;
      width: 80%;
      height: 1px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.336);
    }
    .bdd{
      margin-top: 10px;
      border-radius: 15px;
      padding: 20px;
      width: 80%;
      height: fit-content;
      border: 1px solid black;
    }
    .cardH{
      margin-top: 10px;
      border-radius: 15px;
      padding: 20px;
      width: 80%;
      height: fit-content;
      border: 1px solid black;
      display: flex;
      flex-direction: column;

    }
    .cardH > div {
      display: flex;
      margin: 8px 0 8px 0;
    }
    .cardH > div > div {
      width: 300px;
      height: 16px;
      margin-left: 5px;
      border-bottom: 1px solid black;
    }
    ul > li {
      margin-bottom: 8px;
    }
    .copy{
      font-size: 15px;
      width: 90%;
    }
    .copy > ul > li {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  </style>
</head>
<body style="background: #FFFFFF; color: #000000;">
<div class="content">
  <div class="header">
    <h2 style="margin: 0px 0 8px 0;">{{brandName}} {{ 'LEGAL.BANKING.CAF.TITLE' | translate }}</h2>
  </div>
  <div class='line'></div>
  <div>
    Email: {{contactEmail}}
  </div>
  <div class='line'></div>
  <div class="bdd">
    <p style="font-weight:bold;">{{ 'LEGAL.BANKING.CAF.P1' | translate }}</p>
    <div>
      <ul style="margin-bottom: 0;">
        <li>{{ 'LEGAL.BANKING.CAF.LI1' | translate }}</li>
        <li>{{ 'LEGAL.BANKING.CAF.LI2' | translate }}</li>
        <li>{{ 'LEGAL.BANKING.CAF.LI3' | translate }}</li>
      </ul>
    </div>
  </div>
  <div class="cardH">
    <div>{{ 'LEGAL.BANKING.CAF.NAME' | translate }}: <div></div> </div>
    <div>{{ 'LEGAL.BANKING.CAF.PHONE' | translate }}: <div></div> </div>
    <div>{{ 'LEGAL.BANKING.CAF.CARD_TYPE' | translate }}:&nbsp;&nbsp; Visa  &nbsp;&nbsp;  Master Card &nbsp;&nbsp; Amex </div>
    <div>{{ 'LEGAL.BANKING.CAF.CARD_NUMBER' | translate }}: _ &nbsp;_ &nbsp;_ &nbsp;_ - X X X X - X X X X - _&nbsp; _ &nbsp;_&nbsp; _</div>
    <div>{{'LEGAL.BANKING.CAF.CARD_EXP' | translate}}: _ &nbsp; _ &nbsp; / &nbsp; _ &nbsp; _ &nbsp; ({{'LEGAL.BANKING.CAF.CARD_EXP_INFO' | translate}})</div>
    <p style="opacity:0.7;font-style: italic; margin-bottom: 0;">* {{ 'LEGAL.BANKING.CAF.CARD_WARNING' | translate }}</p>
  </div>
  <p style="font-weight: 600;margin-bottom: 0; margin-top: 10px;">{{ 'LEGAL.BANKING.CAF.COPY_P' | translate }}</p>
  <div class="copy">
    <ul>
      <li>{{'LEGAL.BANKING.CAF.COPY_LI1' | translate}}</li>
      <li>{{'LEGAL.BANKING.CAF.COPY_LI2' | translate}}</li>
      <li>{{'LEGAL.BANKING.CAF.COPY_LI3' | translate}}</li>
    </ul>
  </div>
  <div class='line'></div>
  <p style="margin-top:0 ; font-weight: 600; text-align: -webkit-center; width: 90%;">{{ 'LEGAL.BANKING.CAF.SIGN_P' | translate }}</p>
  <div style="display:flex; justify-content: space-around; width: 100%; margin-top: 15px;">
    <div>
      {{ 'LEGAL.BANKING.CAF.SIGN_DATE' | translate }}: ________________________
    </div>
    <div>
      {{ 'LEGAL.BANKING.CAF.SIGN' | translate }}: ________________________
    </div>
  </div>

</div>
</body>
</html>
