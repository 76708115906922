import { Component } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {RegistrationService} from "../../../../services/registration.service";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {RegdataModel} from "../../../../models/registration/regdata.model";
import {ToastrService} from "ngx-toastr";
import {DetailsMapModel} from "../../../../models/registration/detailsMap.model";

@Component({
  selector: 'app-reg-step1',
  templateUrl: './reg-step1.component.html',
  styleUrls: ['./reg-step1.component.scss']
})
export class RegStep1Component {

  /**
   * get user email  +  username? (if needed)  +  set locale ==> send it to server (create method/step1)
   * get first name  +  last name  +  birthdate => send to server (addDetails/step2)
   * expected API working:
   * create: if the data is valid, we will get a regToken ==> addDetails: if the data is correct, we save it and
   * we can move on to regStep 2,
   * if not : error will contain the validation problems - show on form fields
   *
   * TODO check all this with the server Rival API once it will be available in PROD
   * todo validation on frontend
   * !!! if user comes back here and modifies email/username, that's gonna be a new registration
   * */


  regStep1Form!: UntypedFormGroup;
  regSubmit: boolean = false;
  regSubs?: Subscription;
  regObject?: RegdataModel | null;

  //birth date string
  currentYear = new Date().getFullYear();
  startYear = 1900;
  yearOptions: number[] = [];
  monthOptions: string[] = [];
  dayOptions: number[] = [];
  formSubs?: Subscription;
  previousBirthYear: number | null = null;
  previousBirthMonth: number | null = null;

  isLoading: boolean = false;



  constructor(
    private formBuilder: UntypedFormBuilder,
    private regService: RegistrationService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    for (let year = this.currentYear-18; year >= this.startYear; year--) {
      this.yearOptions.push(year)
    }
    for (let i = 1; i<= 12; i++) {
      const month = i<10 ? `0${i}` : `${i}`
      this.monthOptions.push(month)
    }
    for (let day = 1; day<= 31; day++) {
      this.dayOptions.push(day)
    }


    this.regStep1Form = this.formBuilder.group({
      //todo - not real validation needed on serverside ==> our Validators
      email: ['', [Validators.required, Validators.email]],
      username: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(12),this.noSpaceValidator]],
      fname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), this.notOnlySpaceValidator]],
      lname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), this.notOnlySpaceValidator]],
      birthYear: ['', [Validators.required]],
      birthMonth: ['', [Validators.required]],
      birthDay: ['', [Validators.required]]
    }, {validators: this.ageValidator});
  }

  ngOnInit(){
    this.formSubs?.unsubscribe();
    this.formSubs = this.regStep1Form.valueChanges.subscribe( values => {

      //reset day field if year or month value changes
      if (values.birthYear || values.birthMonth){
        const year = +values.birthYear;
        const month = +values.birthMonth;

        if (year && month && this.previousBirthYear && this.previousBirthMonth && (year !== this.previousBirthYear || month !== this.previousBirthMonth)){
          const daysInMonth = new Date(year, month, 0).getDate();
          if (values.birthDay){
            this.regStep1Form.patchValue({ birthDay: '' });
          }
          this.dayOptions = [];
          for (let day = 1; day<= daysInMonth; day++) {
            this.dayOptions.push(day)
          }
        }

        this.previousBirthYear = +year;
        this.previousBirthMonth = +month;
      }

    })


    this.regSubs?.unsubscribe();
    this.regSubs = this.regService.regData.subscribe(regData => {
      this.regObject = regData;
      if (regData){
        this.regStep1Form.patchValue({
          email: regData.email,
          username: regData.username,
          fname: regData.details.fname ?? "",
          lname: regData.details.lname ?? "",
          birthYear: regData.details.birthDate ? regData.details.birthDate.split('-')[0] : "",
          birthMonth: regData.details.birthDate ? regData.details.birthDate.split('-')[1] : "",
          birthDay: regData.details.birthDate ? this.getBirthDay(regData.details.birthDate) : "",
        })
      }

      //reset regSubmit
      this.regSubmit = false;
    })

  }

  submitForm() {
    this.regSubmit = true;
    if (this.regStep1Form.valid) {

      //first step of registration (create method to get the token)
      let selectedLang = 'en_US'
      const currentLang = this.translate.currentLang;
      if (currentLang){
        if (currentLang !== 'en'){
          selectedLang = `${currentLang}_${currentLang.toUpperCase()}`
        }
      } else {
        if (navigator.language) {
          const lang = navigator.language.split("-")[0]
          const supportedLanguages = ['en', 'es', 'de', 'fr', 'pt', 'it']
          if (supportedLanguages.includes(lang) && lang !=='en'){
            selectedLang = `${lang}_${lang.toUpperCase()}`
          }
        }
      }

      const regStep1Data =  {
        email: this.regStep1Form.value.email,
        username: this.regStep1Form.value.username,
        locale: selectedLang
      }

      this.isLoading = true;

      this.regService.create(regStep1Data).subscribe({
        next: ( response ) => {
          // console.log("Response from create:", response); //result with the regToken, saved in regService

          //second step of registration (addDetails method first chunk)
          const regStep2Data: DetailsMapModel =  {
              fname: this.regStep1Form.value.fname,
              lname: this.regStep1Form.value.lname,
              birthDate: `${this.regStep1Form.value.birthYear}-${this.regStep1Form.value.birthMonth}-${this.regStep1Form.value.birthDay < 10 ? '0'+ this.regStep1Form.value.birthDay : this.regStep1Form.value.birthDay}`,
          }

          this.regService.addDetails(regStep2Data).subscribe({
            next: ( response ) => {
              // console.log("response from step1 component addDetails subs:", response); //result is empty = has no errors in validated data

              //if OK -> save data to RegData BS, navigate to step 2
              this.isLoading = false;
              this.regSubmit = false;

              const regDataToSave: RegdataModel = {
                ...regStep1Data,
                details: {
                  ...this.regObject?.details, //this wont be saved as this will be a new registration on the server
                  fname: this.regStep1Form.value.fname,
                  lname: this.regStep1Form.value.lname,
                  birthDate: `${this.regStep1Form.value.birthYear}-${this.regStep1Form.value.birthMonth}-${this.regStep1Form.value.birthDay < 10 ? '0'+ this.regStep1Form.value.birthDay : this.regStep1Form.value.birthDay}`,
                }
              }
              // console.log("sent to service from step1:", regDataToSave);
              this.regService.saveData(regDataToSave)
              this.regService.setStep(2)

            },
            error: (err) => {
              //errors from addDetails method for firstName, lastName and birthDate
              // todo: addDetails does not throw errors currently - only finalize, so maybe we should send everything at the very end ???
              this.isLoading = false;

              // console.log(err.error)
              if (err.error && err.error.data){
                for (const key in err.error.data) {
                  if (err.error.data.hasOwnProperty(key)) {
                    let propName = key.split(".")[1]
                    //set invalid fields invalid
                    if (this.regStep1Form.controls[propName]) {
                      const formControlWithError = this.regStep1Form.get(propName);
                      if (formControlWithError){
                        formControlWithError.setErrors({validationErrorFromServer: err.error.data[key].message})
                      }
                    } else if (propName === 'birthDate'){
                      const birthYear = this.regStep1Form.get('birthYear');
                      const birthMonth = this.regStep1Form.get('birthMonth');
                      const birthDay = this.regStep1Form.get('birthDay');
                      birthYear?.setErrors({validationErrorFromServer: 'invalid birthDate'})
                      birthMonth?.setErrors({validationErrorFromServer: 'invalid birthDate'})
                      birthDay?.setErrors({validationErrorFromServer: 'invalid birthDate'})
                    }
                    //warn user about the error
                    this.toastr.error(`${err.error.data[key].message}`, `${err.error.message}`, {toastClass: 'ngx-toastr yourclass'})
                  }
                }
              }

              //todo handle other errors ex.network error

            }
          })


        },
        error: (err) => {
          // console.log(err.error)
          //error from create method for email and username
          this.isLoading = false;

          for (const key in err.error.data) {
            if (err.error.data.hasOwnProperty(key)) {
              //set invalid fields invalid
              if (this.regStep1Form.controls[key]) {
                const formControlWithError = this.regStep1Form.get(key);
                if (formControlWithError){
                  formControlWithError.setErrors({validationErrorFromServer: err.error.data[key].message})
                }
              }
              //warn user about the error
              this.toastr.error(`${err.error.data[key].message}`, `${err.error.message}`, {toastClass: 'ngx-toastr yourclass'})
            }
          }

          if (err.status === 400){
            //bad request on gaminpodium (already existing email/username) - TODO translation of error messages
            if(err.error && err.error.jsonrpc_err && err.error.jsonrpc_err.message){
              if (err.error.jsonrpc_err.message.includes('email')){
                this.regStep1Form.controls['email'].setErrors({invalid: true})
              }
              if (err.error.jsonrpc_err.message.includes('login')){
                this.regStep1Form.controls['username'].setErrors({invalid: true})
              }
            }
            //warn user about the error
            this.toastr.error(`${err.error.jsonrpc_err.message}`, 'Validation error', {toastClass: 'ngx-toastr yourclass'})
          }
        }
      })

    }
  }

  get regForm() {
    return this.regStep1Form.controls;
  }

  noSpaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const hasSpace = control.value ? control.value.includes(" ") : false;
    return hasSpace ? {noSpace : true} : null;
  }

  notOnlySpaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const onlySpace = !control.value || !control.value.trim();
    return onlySpace ? { onlySpaces: true } : null;
  }

  ageValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    //user has to be 18+
    const birthYear = control.get('birthYear')?.value;
    const birthMonth = control.get('birthMonth')?.value;
    const birthDay = control.get('birthDay')?.value;

    if (!birthYear || !birthMonth || !birthDay) {
      return null; // No value to validate
    }

    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    return (age > 18 || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) ? null : { ageInvalid: true };
  }


  getBirthDay(birthDate: string){
    if (birthDate){
      const birthDay = +(birthDate.split("-")[2]);
      if (birthDay && birthDay > 0){
        return birthDay
      }
    }
    return ""
  }

  ngOnDestroy(){
    this.formSubs?.unsubscribe();
    this.regSubs?.unsubscribe();
  }

}

