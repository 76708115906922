import { Component } from '@angular/core';
import {ConfigService} from "../../../../services/config.service";

@Component({
  selector: 'app-caf',
  templateUrl: './caf.component.html',
  styleUrls: ['./caf.component.scss']
})
export class CafComponent {
  brandName: string;
  contactEmail: string;

  constructor(
    private configs: ConfigService,
  ) {
    this.brandName = this.configs.brandName;
    this.contactEmail = this.configs.contactEmail;
  }

}
