import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../services/config.service";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent {

  contactEmail: string;

  constructor(
    private configs: ConfigService,
    private route: ActivatedRoute,
    private titleService: TitleService
  ) {
    this.contactEmail = this.configs.contactEmail;
    this.titleService.setTitle(`Lobby / Unsubscribe`)
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const email = params['email'];
      // console.log('Email:', email);
      // TODO - unsubscribe from mail list ?
    });
  }


}
