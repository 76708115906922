<main class="container-lg px-2 pb-3 pb-lg-5">
  <section *ngIf="game; else noGamesBlock" class="p-2 bg-darkest rounded-2 my-3 mt-md-0 mb-mt-5">
    <h3 class="fw-bold fs-6 d-sm-none">{{game.name}}</h3>

    <div class="d-block d-sm-flex">
      <div class="col-12 col-sm-4 play-game-image-container position-relative overflow-hidden" (click)="launchGame()">
        <img class="rounded-2 w-100 h-auto d-block mx-auto mx-sm-0" [src]="game.image" [alt]="game.name" (error)="updateNotFoundImgUrl($event)">
        <div *ngIf="game.tag && game.tag.includes('beta')" class="ribbon">Beta</div>
        <div *ngIf="!(!isLoggingIn && isReloading)" class="overlay position-absolute top-0 start-0 w-100 h-100">
          <img src="assets/icons/play_button.svg" alt="play button" class="position-absolute top-50 start-50 translate-middle">
        </div>
      </div>

      <div class="col-12 col-sm-8 d-flex flex-column flex-grow-1 position-relative" *ngIf="game">
        <div class="d-flex flex-column flex-grow-1 justify-content-between">
          <div class="mt-2 mt-sm-0 ms-0 ms-sm-2 flex-sm-grow-1">
            <div *ngIf="badges && badges.length" class="d-flex justify-content-center justify-content-sm-start flex-wrap gap-2">
              <a *ngFor="let badge of badges" class="text-decoration-none badge text-wrap link-badge" [routerLink]="badge.routerLink">{{badge.text}}</a>
            </div>
            <h3 class="fw-bold text-primary my-1 d-none d-sm-block">{{game.name}}</h3>
            <p class="my-2 my-sm-0">{{ 'SELECTED_GAME.DESC' | translate : {gameName: game.name, gameCat: game.category ? game.category : '', gameProv: game.publisher} }}</p>
            <ng-container *ngIf="game.features && game.features.length">
                <h5 class="fs-6 my-1">{{ 'SELECTED_GAME.FEATURES.TITLE' | translate }}</h5>
                <ul class="mb-1">
                  <li *ngFor="let feature of game.features"> {{ ('SELECTED_GAME.FEATURES.' + (feature | uppercase)) | translate}}</li>
                </ul>
            </ng-container>
          </div>
          <div class="d-flex justify-content-center mb-2 mb-lg-4">
            <button class="btn btn-primary my-0" (click)="launchGame()" [disabled]="!isLoggingIn && isReloading">
              <span *ngIf="!isLoggingIn && isReloading" class="spinner-border spinner-border-sm me-1" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              {{ 'ACCOUNT.JACKPOT.LAUNCH_GAME_BTN' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="jackpot" class="jackpot-overlay">
          <div class="text-center text-sm-end">
            <svg class="colored-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
              <g>
                <path style="fill:#FFFFFF;" d="M41.8,50.6L59,103.4c0.3,0.9,1.6,0.9,1.9,0l17.2-52.8c0.4-1.3-0.5-2.6-1.9-2.6H43.7C42.4,48,41.4,49.3,41.8,50.6z"/>
                <path style="fill:#FFFFFF;" d="M46.2,29.7C46.2,29.7,46.2,29.7,46.2,29.7l-3.5,7.4c-0.6,1.3,0.3,2.8,1.8,2.8h31c1.5,0,2.4-1.5,1.8-2.8l-3.5-7.4c0,0,0,0,0,0l-5.3-11.4c-0.7-1.4-2.1-2.3-3.6-2.3H60h-4.9c-1.6,0-3,0.9-3.6,2.3L46.2,29.7z"/>
                <path style="fill:#FFFFFF;" d="M113.5,41.4L89.6,16.6c-0.4-0.4-0.9-0.6-1.4-0.6h-8.8c-1.5,0-2.4,1.5-1.8,2.8l8.3,17.7l0.5,1.1C87,39.1,88.4,40,90,40l8.8,0c2.1,0,4,1.5,4.2,3.6c0.2,2.4-1.6,4.4-4,4.4h-8.7c-1.7,0-3.3,1.1-3.8,2.8l-15,46c-0.1,0.4,0.4,0.7,0.7,0.4l41.5-50.4C115,45.2,114.9,42.9,113.5,41.4z"/>
                <path style="fill:#FFFFFF;" d="M29.7,48l-8.5,0c-2.1,0-4-1.5-4.2-3.6C16.8,42,18.7,40,21,40h9c1.6,0,3-0.9,3.6-2.3l0.5-1l8.3-17.9c0.6-1.3-0.3-2.8-1.8-2.8h-8.8c-0.5,0-1.1,0.2-1.4,0.6L6.5,41.4c-1.4,1.5-1.5,3.8-0.2,5.3l41.5,50.4c0.3,0.3,0.8,0,0.7-0.4l-15-46C32.9,49.1,31.4,48,29.7,48z"/>
              </g>
            </svg>
            <small class="d-block text-primary mb-0"> {{jackpot.value}} {{jackpot.currency}} <br>Jackpot!</small>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ng-template #noGamesBlock>
    <div class="container-lg text-center pt-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

  </ng-template>

  <div *ngIf="user">
    <app-recent-games></app-recent-games>
  </div>


  <section *ngIf="gamesSelectionAll && gamesSelectionAll.length && !isReloading">
    <app-carousel *ngIf="game" [dataList]="gamesSelectionByCategory" [identifier]="categoryToNavigate"></app-carousel>

    <app-carousel *ngIf="game && game.name.includes('Live')" [dataList]="gamesSelectionLive" [identifier]="'live'"></app-carousel>
    <app-carousel *ngIf="game && game.tag && game.tag === 'NEW'" [dataList]="gamesSelectionNew" [identifier]="'new'"></app-carousel>
    <app-carousel *ngIf="game && game.tag && game.tag === 'HOT'" [dataList]="gamesSelectionTop" [identifier]="'top'"></app-carousel>
    <app-carousel *ngIf="game && game.tag && game.tag === 'FEATURED'" [dataList]="gamesSelectionFeatured" [identifier]="'featured'"></app-carousel>

    <app-carousel *ngIf="game" [dataList]="gamesSelectionByProvider" [identifier]="'provider'"></app-carousel>

    <app-carousel [dataList]="gamesSelectionAll" [identifier]="'all'"></app-carousel>

    <app-carousel [dataList]="providers" [identifier]="'providerList'"></app-carousel>

  </section>


</main>

