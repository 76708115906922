import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable} from "rxjs";
import {GameModel} from "../models/game.model";
import {JackpotModel} from "../models/jackpot.model";
import {ProviderModel} from "../models/provider.model";
import {ToastrService} from "ngx-toastr";
import {ResponseModel} from "../models/response.model";

import {AuthService} from "./auth.service";
import {TrackingHelperService} from "./tracking-helper.service";
import {mostPlayedGamesData} from "../shared/recent-games/data";
import {GameStatService} from "./game-stat.service";
import {Game, Games} from "../models/game.class";
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class GameService {
  BASE_URL:string;
  CASINO_ID: number;

  readonly categories = ['all', 'exclusive', 'new', 'slots', 'table', 'top', 'live', 'video poker', 'featured', 'specialty'];
  readonly svgPaths: { [key: string]: string } = {
    'all': '<path class="st0" d="M92.3,19.6c1.7-0.7,3.2-1.4,4.5-1.9c2-0.9,2.9-3.2,2.1-5.2l-0.8-2c-0.8-2-3-3-5-2.3C89,9.6,83,11.5,77,13\n' +
      '\tc-2.3,0.6-3.6,3.1-2.7,5.3c0.4,0.9,0.7,1.9,1.1,2.9c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.2,0.2,0.5,0.3,0.7\n' +
      '\tc0.1,0.4,0.3,0.8,0.4,1.2c3.8,10.9,7.7,24.9,7.7,36.5C84.5,80.2,69.5,84,60,84s-24.5-3.8-24.5-22.2c0-11.6,3.9-25.6,7.7-36.5\n' +
      '\tc0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.3,0.2-0.6,0.3-0.9c0.4-1,0.7-2,1.1-2.9\n' +
      '\tc0.9-2.2-0.4-4.7-2.7-5.3c-6-1.6-12-3.5-16.1-4.9c-2-0.7-4.2,0.3-5,2.3l-0.8,2c-0.8,2,0.1,4.3,2.1,5.2c1.3,0.6,2.8,1.2,4.5,1.9\n' +
      '\tC21.7,32.2,14,50.2,14,66c0,23.3,14.6,33.9,28.6,38.3l0.1,0.9c0.2,1.8,1.7,3.7,3.4,4.2c3,1,6.7,1.6,14,1.6s11-0.6,14-1.6\n' +
      '\tc1.7-0.6,3.2-2.4,3.4-4.2l0.1-0.9c14-4.4,28.6-15,28.6-38.3C106,50.2,98.3,32.2,92.3,19.6z M33,90c-2.8,0-5-2.3-5-5s2.3-5,5-5\n' +
      '\ts5,2.3,5,5S35.8,90,33,90z M60,100c-2.8,0-5-2.3-5-5s2.3-5,5-5s5,2.3,5,5S62.8,100,60,100z M87,90c-2.8,0-5-2.3-5-5s2.3-5,5-5\n' +
      '\ts5,2.3,5,5S89.8,90,87,90z"/>',
    'exclusive': '<path style="fill:#FFFFFF;" d="M102.2,22.7L61,18.9l6-6L71.8,8h0L17.5,22.5c-0.9,0.2-1.5,1-1.5,1.9V42v36v17.3c0,1,0.8,1.9,1.8,2\n' +
      '\tl41.2,3.9l-6,6l-4.8,4.9h0l54.3-14.5c0.9-0.2,1.5-1,1.5-1.9V78V42V24.7C104,23.7,103.2,22.8,102.2,22.7z M42,79.7H29.1V40.3H42V79.7\n' +
      '\tz M90.9,80.3v1.5L63.2,62.2v17.6H50.3v-40v-1.5L78,57.7V40.3h12.9V80.3z"/>',
    'new': '<path class="st0" d="M116,31c0,0-16-19-38-19c-15.6,0-28.3,12.3-40.1,31.5C25,30.6,10.5,40.1,5.4,54.9C0.1,70,7.7,86.4,22.3,91.5\n' +
      '\tc3.1,1.1,6.3,1.6,9.5,1.5c-0.8-2.4-1.4-5-1.8-7.6c-0.3-2.2,1.3-4.2,3.5-4.5c2.2-0.3,4.2,1.3,4.5,3.5c1.3,10.4,8.1,19.5,18.3,23.1\n' +
      '\tc14.6,5.1,30.7-3.1,36-18.2c5.1-14.7-0.2-31-18-29.2c2.8-9,2.2-17.4-1.8-25.8C79,37.9,86.8,41,94,41C107,41,116,31,116,31z M71.4,59\n' +
      '\tc-10.9-10.3-22.7-5-29.1,5.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.4-0.4,0.7-0.6,1.1c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.3-0.3,0.7-0.5,1\n' +
      '\tc-0.1,0.1-0.1,0.2-0.2,0.4c-0.9,2-3.3,2.9-5.3,2c-2-0.9-2.9-3.3-2-5.3c4.2-9.2,11.2-15.7,19-18.1c-3.1-1.9-7.1-2.8-12.1-2.3\n' +
      '\tC43.1,39.9,50.6,29.9,60,26C60,26,75,38.2,71.4,59z"/>',
    'slots': '<path style="fill:#FFFFFF;" d="M107.8,14.8l-5.7-5.7c-1.6-1.6-4.1-1.6-5.7,0C92.6,13.1,85.9,14,82.1,14c-10.5,0-14.7-6-29.2-6\n' +
      '\tc-4.9,0-9.8,0.9-14,2.1C37.2,10.6,36,12.2,36,14v1.9c0,2.1-1.5,4-3.6,4.2C30,20.2,28,18.3,28,16v-1.9V12c0-2.2-1.8-4-4-4h-8\n' +
      '\tc-2.2,0-4,1.8-4,4v35c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4c0-3.5,2.7-6.4,11.3-6.4c12,0,13.9,4.6,24.1,4.6c4.2,0,11.3-2.2,16.4-5.8\n' +
      '\tC62.3,65.5,35,63.8,35,108c0,2.2,1.8,4,4,4l34,0c2.2,0,4-1.8,4-4c0-32.1,9.7-50.3,17.2-63.4c7.2-12.4,10.4-18.1,14.3-25.1\n' +
      '\tc0,0,0,0,0,0C109.3,18,109.1,16.1,107.8,14.8z"/>',
    'table': '<path class="st0" d="M91,8H29c-2.2,0-4,1.8-4,4v96c0,2.2,1.8,4,4,4h62c2.2,0,4-1.8,4-4V12C95,9.8,93.2,8,91,8z M33,21\n' +
      '\tc0-2.8,2.3-5,5-5s5,2.3,5,5s-2.3,5-5,5S33,23.8,33,21z M60,82H49c3.5-3.5,9-6.3,9-14v-0.9c-2,2.4-4.8,4.2-8.1,4.2\n' +
      '\tc-6,0-10.9-4.9-10.9-10.9c0-3,1.2-5.7,3.2-7.7l4.6-4.6L60,34.9L73.2,48l4.6,4.6c2,2,3.2,4.7,3.2,7.7c0,6.1-4.9,11-10.9,11\n' +
      '\tc-3.2,0-6.1-1.8-8.1-4.2V68c0,7.7,5.5,10.5,9,14H60z M82,104c-2.8,0-5-2.3-5-5s2.3-5,5-5s5,2.3,5,5S84.8,104,82,104z"/>',
    'top': '<path style="fill:#FFFFFF;" d="M111.5,101.8L97.8,78.2c-2.9,3.2-6.3,6.1-10,8.5c-0.7,0.4-1.4,0.7-2.2,0.7c-1.3,0-2.6-0.6-3.4-1.8\n' +
      '\tc-1.2-1.9-0.7-4.3,1.2-5.5c3.2-2.1,6.1-4.6,8.6-7.4h0c2.5-2.8,4.7-6,6.4-9.4c0-0.1,0.1-0.2,0.1-0.3c0.3-0.5,0.5-1,0.7-1.5\n' +
      '\tc0.1-0.1,0.1-0.3,0.2-0.5c0.3-0.8,0.7-1.6,1-2.4c0.1-0.3,0.2-0.6,0.3-1c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.4,0.2-0.8,0.4-1.3\n' +
      '\tc0.1-0.3,0.2-0.6,0.3-1c0.2-0.7,0.3-1.3,0.4-2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.7,0.3-1.5,0.3-2.2c0-0.2,0.1-0.5,0.1-0.7\n' +
      '\tc0.1-0.6,0.1-1.2,0.2-1.8c0-0.2,0-0.5,0.1-0.7c0-0.8,0.1-1.6,0.1-2.4c0-23.7-19.3-43-43-43S17,20.3,17,44c0,0.8,0,1.6,0.1,2.4\n' +
      '\tc0,0.2,0,0.5,0.1,0.7c0,0.6,0.1,1.2,0.2,1.8c0,0.2,0.1,0.5,0.1,0.7c0.1,0.7,0.2,1.5,0.3,2.2c0,0.1,0.1,0.3,0.1,0.4\n' +
      '\tc0.1,0.7,0.3,1.3,0.4,2c0.1,0.3,0.2,0.6,0.3,1c0.1,0.4,0.2,0.8,0.4,1.3c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.2,0.6,0.3,1\n' +
      '\tc0.3,0.8,0.6,1.6,1,2.4c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.5,0.5,1,0.7,1.5c0,0.1,0.1,0.2,0.1,0.3c1.7,3.4,3.9,6.5,6.4,9.4h0\n' +
      '\tc2.5,2.8,5.4,5.3,8.6,7.4c1.8,1.2,2.4,3.7,1.2,5.5c-0.8,1.2-2,1.8-3.4,1.8c-0.8,0-1.5-0.2-2.2-0.7c-3.7-2.4-7.1-5.3-10-8.5\n' +
      '\tL8.5,101.8c-1.1,1.9-0.4,4.4,1.5,5.5l11-2.4c2-0.4,4,0.7,4.7,2.7l3.4,10.7c1.9,1.1,4.4,0.4,5.5-1.5l17.6-30.6\n' +
      '\tc2.5,0.5,5.2,0.7,7.8,0.7s5.3-0.3,7.8-0.7l17.6,30.6c1.1,1.9,3.6,2.6,5.5,1.5l3.4-10.7c0.6-2,2.6-3.1,4.7-2.7l11,2.4\n' +
      '\tC111.9,106.2,112.6,103.8,111.5,101.8z M62.3,61.9c-1.5-0.7-3.2-0.7-4.5,0l-17.1,8.9L44,51.7c0.2-1.6-0.2-3.2-1.5-4.3L28.7,33.9\n' +
      '\tl19.1-2.8c1.6-0.2,2.9-1.2,3.7-2.7L60,11.1l8.6,17.4c0.7,1.5,2.1,2.4,3.7,2.7L91.4,34L77.5,47.4c-1.1,1.1-1.7,2.7-1.4,4.3l3.3,19.1\n' +
      '\tL62.3,61.9z"/>',
    'live': '<path style="fill:#FFFFFF;" d="M80.8,8.1L60,14L39.2,8.1c-2.6-0.7-5.2,1.3-5.2,4v7.1v7.1c0,2.7,2.6,4.7,5.2,4L60,24.6l20.8,5.9\n' +
      '\tc2.6,0.7,5.2-1.3,5.2-4v-7.1v-7.1C86,9.4,83.4,7.5,80.8,8.1z"/>\n' +
      '<path style="fill:#FFFFFF;" d="M107.7,48.2c-4.8-4.9-10.8-9-17.8-12.1L64,79.3V96c0,2.2-1.8,4-4,4s-4-1.8-4-4V79.3L30.1,36.1\n' +
      '\tc-7,3.1-13,7.2-17.8,12.1l-4,59.6c-0.2,2.3,1.7,4.3,4,4.3H60h47.7c2.3,0,4.1-2,4-4.3L107.7,48.2z M97,84H81c-2.2,0-4-1.8-4-4\n' +
      '\ts1.8-4,4-4h16c2.2,0,4,1.8,4,4S99.2,84,97,84z"/>',
    'videopoker': '<path style="fill:#FFFFFF;" d="M4,78v6c0,2.2,1.8,4,4,4h18h21c1.6,0,2.6,1.9,1.6,3.2l-9,12c-2.1,4,0.8,8.8,5.3,8.8H60h15.1\n' +
      '\t\tc4.5,0,7.4-4.8,5.3-8.8l-9-12c-1-1.3,0-3.2,1.6-3.2h22h17c2.2,0,4-1.8,4-4v-6H4z"/>\n' +
      '\t<path style="fill:#FFFFFF;" d="M112,14H8c-2.2,0-4,1.8-4,4v52h112V18C116,15.8,114.2,14,112,14z M36,52.4c0,2-1.6,3.6-3.6,3.6H21.6\n' +
      '\t\tc-2,0-3.6-1.6-3.6-3.6V31.6c0-2,1.6-3.6,3.6-3.6h10.7c2,0,3.6,1.6,3.6,3.6V52.4z M69,52.4c0,2-1.6,3.6-3.6,3.6H54.6\n' +
      '\t\tc-2,0-3.6-1.6-3.6-3.6V31.6c0-2,1.6-3.6,3.6-3.6h10.7c2,0,3.6,1.6,3.6,3.6V52.4z M101,52.4c0,2-1.6,3.6-3.6,3.6H86.6\n' +
      '\t\tc-2,0-3.6-1.6-3.6-3.6V31.6c0-2,1.6-3.6,3.6-3.6h10.7c2,0,3.6,1.6,3.6,3.6V52.4z"/>',
    'featured': '<path style="fill:#FFFFFF;" d="M108,62.9c-1.6-1.6-1.6-4.2,0-5.8c2.9-2.9,4.5-7,3.9-11.6c-0.8-6-5.8-10.8-11.8-11.5\n' +
      '\tC99.5,34,99,34,98.5,34c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0c-1.1,0-2.2,0.2-3.3,0.5c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1,0-0.1,0\n' +
      '\tc-5.7,1.8-10,6.8-12.4,9.1c-1.6,1.6-4.1,1.6-5.7,0s-1.6-4.1,0-5.7c2.3-2.3,7.3-6.7,9.1-12.4c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4\n' +
      '\tc0.3-1,0.5-2.1,0.5-3.3c0-0.2,0-0.3,0-0.5c0,0,0,0,0,0c0-0.5,0-1-0.1-1.5c-0.7-6-5.5-11-11.5-11.8c-4.5-0.6-8.7,1-11.6,3.9\n' +
      '\tc-1.6,1.6-4.2,1.6-5.8,0c-2.9-2.9-7-4.5-11.6-3.9C39.5,9,34.8,13.9,34.1,20C34,20.5,34,21,34,21.5c0,0,0,0,0,0c0,0.2,0,0.3,0,0.5\n' +
      '\tc0,1.1,0.2,2.3,0.5,3.3c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1c1.8,5.7,6.8,10,9.1,12.4c0,0,0,0,0,0c1.6,1.6,1.6,4.1,0,5.7\n' +
      '\tc-1.6,1.6-4.1,1.6-5.7,0c-2.2-2.2-6.4-6.9-11.8-8.9c-0.1,0-0.2-0.1-0.4-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.8-0.3-1.6-0.4-2.5-0.5\n' +
      '\tc0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0c0,0,0,0,0,0C14,34,8,40,8,47.5c0,3.8,1.5,7.2,4,9.6\n' +
      '\tc1.6,1.6,1.6,4.2,0,5.8c-2.9,2.9-4.5,7-3.9,11.6c0.8,6,5.8,10.8,11.8,11.5c0.5,0.1,1,0.1,1.5,0.1c0,0,0.3,0,0.5,0\n' +
      '\tc1.1,0,2.2-0.2,3.3-0.5c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c5.7-1.8,10-6.8,12.4-9.1c1.6-1.6,4.1-1.6,5.7,0\n' +
      '\tc1.6,1.6,1.6,4.1,0,5.7c0,0,0,0,0,0c-2.2,2.2-6.9,6.4-8.9,11.8c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.2-0.1,0.3-0.2,0.5\n' +
      '\tc-0.3,0.8-0.4,1.6-0.5,2.5c0,0,0,0.1,0,0.1c0,0.2-0.1,1.4-0.1,1.5c0,7.5,6,13.5,13.5,13.5c3.8,0,7.2-1.5,9.6-4\n' +
      '\tc1.6-1.6,4.2-1.6,5.8,0c2.4,2.5,5.9,4,9.6,4C80,112,86,106,86,98.5c0,0,0,0,0,0c0,0,0,0,0,0c0-0.3,0-0.6-0.1-0.9c0-0.2,0-0.3,0-0.5\n' +
      '\tc0,0,0-0.1,0-0.1c-0.1-0.9-0.3-1.7-0.5-2.5c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.2-0.1-0.4c-2-5.4-6.7-9.5-8.9-11.8\n' +
      '\tc-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0c2.2,2.2,6.4,6.9,11.8,8.9c0.1,0,0.2,0.1,0.4,0.1c0.2,0.1,0.3,0.1,0.5,0.2\n' +
      '\tc0.8,0.3,1.6,0.4,2.5,0.5c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1c0,0,0,0,0,0c0,0,0,0,0,0c7.5,0,13.5-6,13.5-13.5\n' +
      '\tC112,68.7,110.5,65.3,108,62.9z"/>\n' +
      '<path style="fill:#FFFFFF;" d="M26,98.8c0-0.7,0-1.5,0.1-2.3l0-0.1l0-0.3c0.1-0.9,0.3-1.8,0.5-2.7c-1.4,0.3-2.8,0.5-4.3,0.6L22,94\n' +
      '\tc-0.3,0-0.5,0-0.5,0c0,0,0,0,0,0c-0.4,0-0.8,0-1.2,0L0.2,109.9c-0.8,0.8,0.8,3.6,3.5,6.4s5.6,4.3,6.4,3.5l16-20.1\n' +
      '\tC26,99.4,26,99.1,26,98.8z"/>',
    'specialty': '<path style="fill:#FFFFFF;" d="M109.5,43.9l-17.5-7.1l-1.3-18.9l-18.4,4.6L60,8L47.8,22.5l-18.4-4.6l-1.3,18.9l-17.5,7.1l10,16.1\n' +
      '\tl-10,16.1l17.5,7.1l1.3,18.9l18.4-4.6L60,112l12.2-14.5l18.4,4.6l1.3-18.9l17.5-7.1L99.4,60L109.5,43.9z M77.5,63.5\n' +
      '\tc-1.1,1.1-1.7,2.7-1.4,4.3l3.3,19.1L62.3,78c-1.5-0.7-3.2-0.7-4.5,0l-17.1,8.9L44,67.8c0.2-1.6-0.2-3.2-1.5-4.3L28.7,50l19.1-2.8\n' +
      '\tc1.6-0.2,2.9-1.2,3.7-2.7L60,27.2l8.6,17.4c0.7,1.5,2.1,2.4,3.7,2.7l19.1,2.8L77.5,63.5z"/>',
    'provider': '<path style="fill:#FFFFFF;" d="M60,10c-27.6,0-50,22.4-50,50s22.4,50,50,50s50-22.4,50-50S87.6,10,60,10z M33.5,73\n' +
      '\tC26.6,73,21,67.2,21,60s5.6-13,12.5-13S46,52.8,46,60S40.4,73,33.5,73z M60,99c-7.2,0-13-5.6-13-12.5S52.8,74,60,74s13,5.6,13,12.5\n' +
      '\tS67.2,99,60,99z M60,46c-7.2,0-13-5.6-13-12.5S52.8,21,60,21s13,5.6,13,12.5S67.2,46,60,46z M86.5,73C79.6,73,74,67.2,74,60\n' +
      '\ts5.6-13,12.5-13S99,52.8,99,60S93.4,73,86.5,73z"/>',
    'recommended': "<path style=\"fill:#FFFFFF;\" d=\"M105,36H49.5c-1.8,0-2.7-2.2-1.4-3.4l9.7-9.7c3.1-3.1,3.1-8.2,0-11.3c-3.1-3.1-8.2-3.1-11.3,0\n" +
      "\tL32,26.1L15,43c-4.7,4.7-7,10.8-7,17v20c0,13.3,10.7,24,24,24h36c3.9,0,7-3.1,7-7c0-3.9-3.1-7-7-7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2\n" +
      "\tc3.9,0,7-3.1,7-7c0-3.9-3.1-7-7-7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c3.9,0,7-3.1,7-7c0-3.9-3.1-7-7-7c-1.1,0-2-0.9-2-2\n" +
      "\tc0-1.1,0.9-2,2-2h37c3.9,0,7-3.1,7-7C112,39.1,108.9,36,105,36z\"/>",
    'recent': "<path style=\"fill:#FFFFFF;\" d=\"M61.2,8C49,7.7,37.7,11.7,28.7,18.5l-8.6-8.7c-2.3-2.4-6.4-1-6.8,2.3L9.6,41\n" +
      "\t\tc-0.3,2.6,1.8,4.8,4.4,4.5l29-3.2c3.3-0.4,4.8-4.4,2.4-6.8l-8.2-8.4c7.4-5.1,16.5-7.9,26.3-7c19.4,1.7,35,17.5,36.4,36.9\n" +
      "\t\tC101.6,80.5,83,100,60,100c-20.8,0-38-16-39.8-36.4c-0.2-2-1.9-3.6-4-3.6h-4c-2.3,0-4.2,2-4,4.3C10.4,91,32.7,112,60,112\n" +
      "\t\tc28.7,0,52-23.3,52-52C112,31.6,89.6,8.6,61.2,8z\"/>\n" +
      "\t<path style=\"fill:#FFFFFF;\" d=\"M85.6,52.7L70,50.5c-1.3-0.2-2.4-1-3-2.2l-7-14.2l-7,14.2c-0.6,1.2-1.7,2-3,2.2l-15.6,2.3l11.3,11\n" +
      "\t\tc0.9,0.9,1.4,2.2,1.2,3.5l-2.7,15.6l14-7.3c1.2-0.6,2.6-0.6,3.7,0l14,7.3l-2.7-15.6c-0.2-1.3,0.2-2.6,1.2-3.5L85.6,52.7z\"/>"
  };

  providers: ProviderModel[] = [
    //these are the game providers for who we already have the logo
    {routerLink: "rival", name: "Rival", imagePath: "assets/provider_logos/Rival_logo.svg", gameList: []},
    {routerLink: "instanet", name: "InstaNet", imagePath: "assets/provider_logos/Instanet_logo.svg", gameList: []},
    {routerLink: "betsoft", name: "Betsoft", imagePath: "assets/provider_logos/Betsoft_logo.svg", gameList: []},
    {
      routerLink: "tomhorn",
      name: "Tom Horn Games",
      imagePath: "assets/provider_logos/Tomhorn_logo.svg",
      gameList: []
    },
    {routerLink: "saucify", name: "Saucify", imagePath: "assets/provider_logos/Saucify_logo.svg", gameList: []},
    {
      routerLink: "spinomenal",
      name: "Spinomenal",
      imagePath: "assets/provider_logos/Spinomenal_logo.svg",
      gameList: []
    },
    {routerLink: "vivolive", name: "Vivo Live", imagePath: "assets/provider_logos/vivogaming_logo.svg", gameList: []},
    {routerLink: "dragon", name: "Dragon", imagePath: "assets/provider_logos/Dragongaming_logo.svg", gameList: []},
    {routerLink: "qora", name: "Qora", imagePath: "assets/provider_logos/Qora_logo.svg", gameList: []},
    {routerLink: "yggdrasil", name: "Yggdrasil", imagePath: "assets/provider_logos/Yggdrasil_logo.svg", gameList: []},
    {
      routerLink: "freshdeck",
      name: "FreshDeck",
      imagePath: "assets/provider_logos/Fresh_deck_logo.svg",
      gameList: []
    },
    {routerLink: "bgaming", name: "BGaming", imagePath: "assets/provider_logos/bgaming_logo.svg", gameList: []},
    {
      routerLink: "tgs",
      name: "TGS",
      imagePath: "assets/provider_logos/TGS_logo.svg",
      gameList: []
    },
    {
      routerLink: "pragmatic",
      name: "Pragmatic",
      imagePath: "assets/provider_logos/pragmaticplay_logo.svg",
      gameList: []
    },
    {
      routerLink: "sandstorm",
      name: "Sandstorm",
      imagePath: "assets/provider_logos/sandstorm_logo.svg",
      gameList: []
    },
    {routerLink: "felix", name: "Felix Gaming", imagePath: "assets/provider_logos/felixGaming2.png", gameList: []},
    {routerLink: "fugaso", name: "Fugaso", imagePath: "assets/provider_logos/fugaso.svg", gameList: []},
  ]

  private defaultGames = new Games(this.removeGameByTracker(this.configs.games));

  categoriesWithGames: string[] = this.getCategoriesWithGames(this.defaultGames);


  private _gamesSubject = new BehaviorSubject<Games>(this.defaultGames);
  games$ = this._gamesSubject.asObservable();


  private _providersSubject = new BehaviorSubject<ProviderModel[]>(this.defaultGames.byProviders(this.providers));
  providers$ = this._providersSubject.asObservable();

  mostPlayedGames: GameModel[] = []


  constructor(
    private http: HttpClient,
    private configs: ConfigService,
    private toastr: ToastrService,
    private authService: AuthService,
    private trackingService: TrackingHelperService,
    private gameStatService: GameStatService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;
    this.authService.token.subscribe(token => {
      if (token) this.fetchGames();
      if (!token) {
        //reset default games after log out
        this._gamesSubject.next(this.defaultGames);
        this._providersSubject.next(this.defaultGames.byProviders(this.providers));
        this.categoriesWithGames = this.getCategoriesWithGames(this.defaultGames);
      }


    })
  }


  fetchGames() {
    this.http.post<ResponseModel>(`${this.BASE_URL}`, {
      "jsonrpc": "2.0",
      "id": this.CASINO_ID,
      "method": "game.list",
      "params": {}
    })
      .pipe(
        map((responseData: ResponseModel) => {
          if ('result' in responseData && 'games' in responseData.result) {
            return responseData.result['games'] as GameModel[];
          } else {
            if ('error' in responseData) {
              if (responseData.error.message && responseData.error.code) {
                // console.log(responseData)
                throw new Error(`Something went wrong while fetching games. (${responseData.error.code})`);
              }
              throw new Error(responseData.error?.message || 'Something went wrong while fetching games');
            } else {
              throw new Error('Unexpected response format')
            }
          }
        }))
      .subscribe({
        next: (_games: GameModel[]) => {
          const games = new Games(_games);

          this._gamesSubject.next(games);
          // this.groupGamesByProviders(games);
          this._providersSubject.next(games.byProviders(this.providers));

          this.categoriesWithGames = this.getCategoriesWithGames(games);

        },
        error: (err) => {
          // console.error(err)
          if (err.status) {
            this._gamesSubject.next(new Games([]));
          }
          //if there is no status = error comes from interceptor and handled there: 401 invalid token, OR from error response from this call: toastr
          if (err.message !== "Invalid token") {
            this.toastr.error(err.message, 'Error', {toastClass: 'ngx-toastr yourclass'})
          }
        }
      })
  }


  getGames() {
    return this.games$;
  }


  getJackpots(): Observable<{ result: { "jackpots": JackpotModel[] }, jsonrpc: string, id: number }> {
    return this.http.post<{
      result: { "jackpots": JackpotModel[] },
      jsonrpc: string,
      id: number
    }>(`${this.BASE_URL}`, {"jsonrpc": "2.0", "id": this.CASINO_ID, "method": "jackpot.list", "params": {}});
  }

  getJackpotsForAGame(gameId: number): Observable<{
    result: { "jackpots": JackpotModel[] },
    jsonrpc: string,
    id: number
  }> {
    return this.http.post<{ result: { "jackpots": JackpotModel[] }, jsonrpc: string, id: number }>(`${this.BASE_URL}`, {
      "jsonrpc": "2.0", "id": this.CASINO_ID, "method": "jackpot.list", "params": {
        "gameId": gameId
      }
    });
  }

  // groupGamesByProviders(games: GameModel[]) {
  //   // get providers from the list in this service
  //   // this.providers.forEach(provider => {
  //   //   provider.gameList = games.filter(game => game.publisher.toLowerCase().includes(provider.name.toLowerCase()));
  //   // });
  //   // this._providersSubject.next(this.providers);
  //
  //   //get providers with games dynamically
  //   const uniquePublishers = Array.from(new Set(games.map(game => game.publisher)));
  //   const providersWithGames = uniquePublishers.map(publisher => {
  //     const provider = this.providers.find(p => publisher.toLowerCase().includes(p.name.toLowerCase()));
  //     const gameList = games.filter(game => game.publisher.toLowerCase() === publisher.toLowerCase());
  //     return {
  //       routerLink: provider ? provider.routerLink : publisher.toLowerCase().split(" ")[0],
  //       name: publisher,
  //       imagePath: provider ? provider.imagePath : this.generateSvgPlaceholderUrl(publisher),
  //       gameList: gameList
  //     } as ProviderModel;
  //   });
  //
  //   // Order providers by the length of the gameList in descending order
  //   providersWithGames.sort((a, b) => (b.gameList?.length ||0) - (a.gameList?.length || 0));
  //
  //   this._providersSubject.next(providersWithGames);
  //
  // }

  categorizeGames(games: Games): Game[][] {
    let gamesByCategory: Game[][] = [];

    for (let i = 0; i < this.categories.length; i++) {
      const validCategory = this.categories[i].replace(" ", "")
      let categorizedGames = this.getGamesOfValidCategory(validCategory, games);
      gamesByCategory[i] = categorizedGames.length ? categorizedGames : []
    }
    return gamesByCategory;
  }

  getCategoryToNavigate(cat: string): string {
    for (let i = 0; i < this.categories.length; i++) {
      let validCat = this.categories[i].replace(" ", "");
      if (validCat === 'all' || validCat === 'live') {
        continue; //skip to next iteration
      }
      if (cat.replace(" ", "").toLowerCase().includes(validCat)) {
        //for slots, table games and video poker games; new, exclusives/instanet, top, featured
        return validCat
      }
    }
    if (cat.toLowerCase().includes('instanet')) {
      return "slots"
    }
    return "specialty"
  }

  getGamesOfValidCategory(cat: string, games: Games): Game[] {

    if (cat === "all") {
      return games.all
    }
    if (cat === "new") {
      return games.all.filter(game => game.tag && game.tag.toLowerCase().includes("new"))
    }
    if (cat === "top") {
      return games.all.filter(game => game.tag && game.tag.toLowerCase().includes("hot"))
      // // TODO Return recommended games for the "top" category
      // let mostPlayedGames: GameModel[] = []
      // this.getRecommendedGames(games).subscribe({
      //   next: data => mostPlayedGames = data,
      //   error: err => mostPlayedGames = games.filter(game => game.tag && game.tag.toLowerCase().includes("hot"))
      // });
      // return mostPlayedGames


    }
    if (cat === "featured") {
      // return games.filter(game => game.features && game.features.length)
      return games.all.filter(game => game.tag && game.tag.toLowerCase().includes("featured"))
    }
    if (cat === "exclusive") {
      return games.all.filter(game => game.category && game.publisher.toLowerCase().includes("instanet"))
    }
    if (cat === "live") {
      return games.all.filter(game => game.name && game.name.toLowerCase().includes("live"));
    }
    if (cat === "slots") {
      return games.all.filter(game => game.category && (game.category.toLowerCase().includes("slots") || game.category.toLowerCase().includes("instanet")))
    }
    if (cat === "specialty") {
      // get the remaining uncategorized games: category not includes video poker, slots(with instanet) or table
      const mainCategories = ["table", "videopoker", "slots", "instanet"];
      return games.all.filter(game => !game.category || !mainCategories.some(mainCategory => game.category.replace(" ", "").toLowerCase().includes(mainCategory)));
    }

    //for table games and video poker games
    return games.all.filter(game => game.category && game.category.replace(" ", "").toLowerCase().includes(cat));


  }

  //get categories with games
  getCategoriesWithGames(games: Games): string[] {
    if (!games.length) {
      return [];
    }
    const gamesByCategory = this.categorizeGames(games);
    const activeCategories: string[] = [];

    this.categories.forEach((category, index) => {
      const categoryGames = gamesByCategory[index];
      if (categoryGames && categoryGames.length > 0) {
        activeCategories.push(category);
      }
    });
    return activeCategories;
  }

  //removes affiliated game(s) for not logged in users without the affiliate tracker, for logged in users this is/should be handled by api
  removeGameByTracker(games: GameModel[]) {
    const isUserLoggedIn = !!this.authService.token.value;
    if (!isUserLoggedIn && this.configs.affiliatedGames.length) {
      const userTracker = this.trackingService.getTracker();
      for (const affiliate of this.configs.affiliatedGames) {
        let isAffiliatedUser = false;
        if (userTracker) {
          isAffiliatedUser = affiliate.regex.test(userTracker);
        }
        if (!isAffiliatedUser) {
          const gameIndex = games.findIndex(g =>
            g.name.toLowerCase().replace(" ", "") === affiliate.name.toLowerCase().replace(" ", "")
          );
          if (gameIndex !== -1) {
            games.splice(gameIndex, 1);
          }
        }
      }
    }
    return games;
  }

  getRecentGames(games: Games) {
    let recentGames: GameModel[] = [];
    const gameIds = localStorage.getItem('recentGames');
    if (gameIds) {
      const gameIdArray: number[] = gameIds.split(",").map(id => +id);
      for (let i = 0; i < gameIdArray.length; i++) {
        const game = games.get(gameIdArray[i])
        if (game) {
          recentGames.push(game)
        }
      }
    }
    return recentGames;
  }

  addToRecentGames(id: string) {
    let gameIdArray: string[] = [];

    const gameIds = localStorage.getItem('recentGames');
    if (gameIds) {
      //check if the games is already included in recentGames
      gameIdArray = gameIds.split(",");
      const index = gameIdArray.indexOf(id);
      if (index !== -1) {
        gameIdArray.splice(index, 1)
      }
    }

    //add the new element to the beginning of the array
    gameIdArray.unshift(id);

    //if the array exceeds the maximum length of 16, remove the last element
    if (gameIdArray.length > 16) {
      gameIdArray.pop();
    }

    //save new gameIdArray in localstorage
    const newGameIds = gameIdArray.join(',');
    localStorage.setItem('recentGames', newGameIds)

  }

  getRecommendedGames(games: Games): Game[] {
    //this one works
    const recentGameIds = this.getRecentGames(games).map(g => g.id);
    let result: Game[] = [];
    for (let i = 0; i < recentGameIds.length; i++) {
      let gameId = recentGameIds[i].toString();
      const gamesForGameById = mostPlayedGamesData[gameId as keyof typeof mostPlayedGamesData];
      if (gamesForGameById) {
        for (let j = 0; j < gamesForGameById.length; j++) {
          const game = games.get(gamesForGameById[j])
          if (game) {
            if (!result.includes(game) && !recentGameIds.includes(game.id)) {
              result.push(game)
            }
          }
        }
      }
    }

    // console.log('Recommended Games:', result)
    if (result.length < 16) {
      // const instanetGames: GameModel[] =this.gameService.getGamesOfValidCategory('exclusive', games);
      const instanetGames: Game[] = this.pickRandomGames(this.getGamesOfValidCategory('exclusive', games), 16);
      for (let i = 0; i < instanetGames.length; i++) {
        if (!result.includes(instanetGames[i]) && !recentGameIds.includes(instanetGames[i].id) && result.length < 16) {
          result.push(instanetGames[i])
        }
      }
    }

    return result;
  }

  // getRecommendedGames(games: GameModel[]) {
  //   return this.gameStatService.getMostPlayedGames().pipe(
  //     map((mostPlayedGamesData: any) => {
  //       const recentGameIds = this.getRecentGames(games).map(g => g.id);
  //       let result: GameModel[] = [];
  //
  //       for (let i = 0; i < recentGameIds.length; i++) {
  //         const gameId = recentGameIds[i].toString();
  //         const gamesForGameById = mostPlayedGamesData[gameId]; // Retrieve data from JSON
  //
  //         if (gamesForGameById) {
  //           for (let j = 0; j < gamesForGameById.length; j++) {
  //             const game = games.find(g => g.id == gamesForGameById[j]);
  //             if (game && !result.includes(game) && !recentGameIds.includes(game.id)) {
  //               result.push(game);
  //             }
  //           }
  //         }
  //       }
  //
  //       // If less than 16 results, fill with exclusive games
  //       if (result.length < 16) {
  //         const instanetGames: GameModel[] = this.pickRandomGames(
  //           this.getGamesOfValidCategory('exclusive', games),
  //           16
  //         );
  //         for (let i = 0; i < instanetGames.length; i++) {
  //           if (!result.includes(instanetGames[i]) && !recentGameIds.includes(instanetGames[i].id) && result.length < 16) {
  //             result.push(instanetGames[i]);
  //           }
  //         }
  //       }
  //
  //
  //       return result;
  //     })
  //   );
  // }

  getMostPlayedGames(games: Games) {
    // TODO
    const jsonSubs = this.gameStatService.getMostPlayedGamesJson().subscribe({
      next: data => {
        // console.log(data)
        let result: GameModel[] = [];
        const recentGameIds = localStorage.getItem('recentGames');
        if (recentGameIds) {
          const gameIdArray = recentGameIds.split(",")

          for (let i = 0; i < gameIdArray.length; i++) {
            const gameId = +gameIdArray[i];
            const gamesForGameById = data[gameId]; // Retrieve data from JSON

            if (gamesForGameById) {
              for (let j = 0; j < gamesForGameById.length; j++) {
                const game = games.get(gamesForGameById[j]);
                if (game && !result.includes(game) && !recentGameIds.includes(game.id.toString())) {
                  result.push(game);
                }
              }
            }
          }
          // If less than 16 results, fill with exclusive games
          if (result.length < 16) {
            const instanetGames: GameModel[] = this.pickRandomGames(this.getGamesOfValidCategory('exclusive', games), 16);
            for (let i = 0; i < instanetGames.length; i++) {
              if (!result.includes(instanetGames[i]) && !recentGameIds.includes(instanetGames[i].id.toString()) && result.length < 16) {
                result.push(instanetGames[i]);
              }
            }
          }

          this.mostPlayedGames = result;
          //todo make a BS from that ?

          // todo: save in localstorage


        }


      },
      error: err => {
        this.mostPlayedGames = []
      }
    });

  }







  pickRandomGames(games: Game[], count: number): Game[] {
    let enabledGames = games.filter(g => g.enabled !== false); //undefined for Rival, true for GamingPodium games
    let selectedGames: Game[] = [];
    if (enabledGames.length >= count) {
      while (selectedGames.length < count) {
        let randomIndex = Math.floor(Math.random() * enabledGames.length);
        let selectedGame = enabledGames[randomIndex];
        if (!selectedGames.find(game => game.id === selectedGame.id)) {
          selectedGames.push(selectedGame);
        }
      }
    } else {
      // Shuffle games and return
      selectedGames = [...enabledGames].sort(() => Math.random() - 0.5);
    }
    return selectedGames;

  }


}
