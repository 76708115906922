<form class="needs-validation" novalidate (ngSubmit)="submitRegForm()" [formGroup]="regStep3Form">

    <div class="mb-2">
      <label class="form-label mb-2" for="password">{{ 'REGISTER.PW_LABEL' | translate }}</label>
      <div class="position-relative">
        <input class="form-control" [type]="fieldTextType ? 'text' : 'password'" id="password" required formControlName="password"
               placeholder="{{ 'REGISTER.PW_PLACEHOLDER' | translate }}"
               [ngClass]="{'is-invalid': regSubmit && regForm['password'].errors}">
        <label class="password-toggle-btn" [ngClass]="{'invalid-toggle-btn': regSubmit && regForm['password'].errors}" aria-label="Show/hide password">
          <input class="password-toggle-check" type="checkbox">
          <span class="password-toggle-indicator" [ngClass]="{'opacity-50': !fieldTextType, 'opacity-1': fieldTextType}" (click)="toggleFieldTextType()">
            <svg class="eye-off" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g><path d="M9.25,4.5A8.87,8.87,0,0,0,1,10.125a8.862,8.862,0,0,0,16.5,0A8.87,8.87,0,0,0,9.25,4.5Zm0,9.375A3.75,3.75,0,1,1,13,10.125,3.751,3.751,0,0,1,9.25,13.875Zm0-6a2.25,2.25,0,1,0,2.25,2.25A2.247,2.247,0,0,0,9.25,7.875Z"></path></g></svg>
          </span>
        </label>
        <div class="invalid-feedback" *ngIf="!regStep3Form.controls['password'].errors?.['validationErrorFromServer']">{{ 'REGISTER.PW_ALERT' | translate }}</div>
        <div class="invalid-feedback" *ngIf="regStep3Form.controls['password'].errors?.['validationErrorFromServer']">
          {{ regStep3Form.controls['password'].errors?.['validationErrorFromServer'] }}
        </div>
      </div>
    </div>

    <div class="mb-2">
      <label class="form-label mb-0" for="question">{{ 'REGISTER.QUESTION_LABEL' | translate }}</label>
      <input class="form-control mt-1" type="text" id="question" placeholder="{{ 'REGISTER.QUESTION_LABEL' | translate }}" required formControlName="question" [ngClass]="{'is-invalid': regSubmit && regForm['question'].errors}" autocomplete="off">
      <div class="invalid-feedback" *ngIf="!regStep3Form.controls['question'].errors?.['validationErrorFromServer']">{{ 'REGISTER.REQUIRED_ALERT' | translate }}</div>
      <div class="invalid-feedback" *ngIf="regStep3Form.controls['question'].errors?.['validationErrorFromServer']">
        {{ regStep3Form.controls['question'].errors?.['validationErrorFromServer'] }}
      </div>
    </div>

    <div class="mb-2">
      <label class="form-label mb-0" for="answer">{{ 'REGISTER.ANSWER_LABEL' | translate }}</label>
      <input class="form-control mt-1" type="text" id="answer" placeholder="{{ 'REGISTER.ANSWER_LABEL' | translate }}" required formControlName="answer" [ngClass]="{'is-invalid': regSubmit && regForm['answer'].errors}" autocomplete="off">
      <div class="invalid-feedback" *ngIf="!regStep3Form.controls['answer'].errors?.['validationErrorFromServer']">{{ 'REGISTER.REQUIRED_ALERT' | translate }}</div>
      <div class="invalid-feedback" *ngIf="regStep3Form.controls['answer'].errors?.['validationErrorFromServer']">
        {{ regStep3Form.controls['answer'].errors?.['validationErrorFromServer'] }}
      </div>
    </div>


  <div class="row mt-4" *ngIf="!isLoading; else loadingSpinner">
    <div class="col mb-2">
      <button class="btn btn-outline-success btn-lg w-100" type="button" (click)="goToPrevStep()">{{ 'REGISTER.PREV_LABEL' | translate }}</button>
    </div>
    <div class="col mb-2">
      <button class="btn btn-success btn-lg w-100" type="submit">{{ 'REGISTER.NEXT_LABEL' | translate }}</button>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</form>


<!--first version: accepting terms and step 3 finalize-->
<!--<form class="needs-validation" novalidate (ngSubmit)="submitRegForm()" [formGroup]="regStep3Form">-->
<!--  <div class="my-4">-->
<!--    <div class="form-check my-3">-->
<!--      <input class="form-check-input" type="checkbox" id="agree-to-terms" formControlName="agreement" [ngClass]="{'is-invalid': regSubmit && regForm['agreement'].errors}" required>-->
<!--      <label class="form-check-label" for="agree-to-terms">{{ 'REGISTER.STEP3.TERMS_LABEL' | translate }}-->
<!--        <a class="text-decoration-none ms-1" routerLink="/info/terms" data-bs-dismiss="modal">{{ 'REGISTER.STEP3.TERMS_LINK' | translate }}</a></label>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row" *ngIf="!isLoading; else loadingSpinner">-->
<!--    <div class="col">-->
<!--      <button class="btn btn-outline-success btn-lg w-100" type="button" (click)="goToPrevStep()">{{ 'REGISTER.PREV_LABEL' | translate }}</button>-->
<!--    </div>-->
<!--    <div class="col">-->
<!--      <button class="btn btn-success btn-lg w-100" type="submit">{{ 'REGISTER.STEP3.FINALIZE_BTN' | translate }}</button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <ng-template #loadingSpinner>-->
<!--    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">-->
<!--      <div class="spinner-border" role="status">-->
<!--        <span class="visually-hidden">Loading...</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</form>-->







