import {Component, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {

  @ViewChild('closeLogoutButton') closeLogoutButton!: ElementRef;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router
  ) {
  }

  logout(): void {
    this.closeLogoutButton.nativeElement.click();
    this.authService.logout().subscribe({
      next: value => {
        this.toastr.success(this.translate.instant('LOGOUT.TOASTR_SUCCESS_MESSAGE'), this.translate.instant('LOGOUT.TOASTR_SUCCESS_H1'), {toastClass: 'ngx-toastr yourclass'});

        this.authService.resetUserSession();
      }
    });
    this.router.navigate(['']);
  }

}
