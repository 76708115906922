<main class="container-fluid px-0 pb-3 pb-lg-5">
  <div class="container-lg px-2 px-md-3">
    <h1 class="d-flex align-items-center pt-3 mb-2">
      <svg class="casino-icon me-1 opacity-50" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 120 120">
        <g><path style="fill:#FFFFFF;" d="M75,32c-2.2,0-4-1.8-4-4V8H29c-2.2,0-4,1.8-4,4v96c0,2.2,1.8,4,4,4h62c2.2,0,4-1.8,4-4V32H75z M56,93	H38c-2.2,0-4-1.8-4-4s1.8-4,4-4h18c2.2,0,4,1.8,4,4S58.2,93,56,93z M82,73H38c-2.2,0-4-1.8-4-4s1.8-4,4-4h44c2.2,0,4,1.8,4,4S84.2,73,82,73z M82,53H38c-2.2,0-4-1.8-4-4s1.8-4,4-4h44c2.2,0,4,1.8,4,4S84.2,53,82,53z"/></g>
      </svg>
      <span class="h5 fw-bold mb-0">{{ 'FOOTER.LEGAL_TITLE' | translate }}</span>
    </h1>
    <div class="row gx-4 gy-2">
      <div class="d-md-none" id="legalNavDropdownBtn">
          <nav class="nav">
            <div class="dropdown">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ 'LEGAL.'+ selectedLegalSectionTitle + '.TITLE' | translate }}
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" [routerLink]="['/info/about']" routerLinkActive="active">{{ 'LEGAL.ABOUT.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/terms']" routerLinkActive="active">{{ 'LEGAL.TERMS.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/faq']" routerLinkActive="active">{{ 'LEGAL.FAQ.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/support']" routerLinkActive="active">{{ 'LEGAL.SUPPORT.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/aml']" routerLinkActive="active">{{ 'LEGAL.AML.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/banking']" routerLinkActive="active">{{ 'LEGAL.BANKING.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/bitcoin']" routerLinkActive="active">{{ 'LEGAL.BITCOIN.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/cookie_policy']" routerLinkActive="active">{{ 'LEGAL.COOKIE.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/privacy']" routerLinkActive="active">{{ 'LEGAL.PRIVACY.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/responsible_gaming']" routerLinkActive="active">{{ 'LEGAL.RESPONSIBLE.TITLE' | translate}}</a></li>
                <li><a class="dropdown-item" [routerLink]="['/info/security']" routerLinkActive="active">{{ 'LEGAL.SECURITY.TITLE' | translate}}</a></li>
              </ul>
            </div>
          </nav>
      </div>
      <aside class="d-none d-md-block col-md-3">
        <div class="bg-darker rounded-2 py-2">
          <nav class="nav flex-column nav-underline" id="info-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" [routerLink]="['/info/about']" routerLinkActive="active">{{ 'LEGAL.ABOUT.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/terms']" routerLinkActive="active">{{ 'LEGAL.TERMS.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/faq']" routerLinkActive="active">{{ 'LEGAL.FAQ.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/support']" routerLinkActive="active">{{ 'LEGAL.SUPPORT.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/aml']" routerLinkActive="active">{{ 'LEGAL.AML.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/banking']" routerLinkActive="active">{{ 'LEGAL.BANKING.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/bitcoin']" routerLinkActive="active">{{ 'LEGAL.BITCOIN.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/cookie_policy']" routerLinkActive="active">{{ 'LEGAL.COOKIE.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/privacy']" routerLinkActive="active">{{ 'LEGAL.PRIVACY.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/responsible_gaming']" routerLinkActive="active">{{ 'LEGAL.RESPONSIBLE.TITLE' | translate}}</a>
            <a class="nav-link" [routerLink]="['/info/security']" routerLinkActive="active">{{ 'LEGAL.SECURITY.TITLE' | translate}}</a>
          </nav>
        </div>
      </aside>
      <main class="col-12 col-md-9">
        <div class="bg-darker rounded-2 p-0">
          <div class="tab-content" id="info-tabContent">
            <router-outlet></router-outlet>
          </div>
        </div>
      </main>

    </div>
  </div>

</main>

<!--<main class="container-xxl">-->
<!--  <div class="row justify-content-center g-2">-->
<!--    <aside class="col-10 col-md-4">-->
<!--      <div class="bg-dark-subtle rounded-3 p-3">-->
<!--        <nav class="nav flex-column nav-pills me-3" id="info-tab" role="tablist" aria-orientation="vertical">-->
<!--          <button class="nav-link" [routerLink]="['/info/about']" routerLinkActive="active">{{ 'LEGAL.ABOUT.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/terms']" routerLinkActive="active">{{ 'LEGAL.TERMS.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/faq']" routerLinkActive="active">{{ 'LEGAL.FAQ.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/support']" routerLinkActive="active">{{ 'LEGAL.SUPPORT.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/aml']" routerLinkActive="active">{{ 'LEGAL.AML.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/banking']" routerLinkActive="active">{{ 'LEGAL.BANKING.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/bitcoin']" routerLinkActive="active">{{ 'LEGAL.BITCOIN.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/cookie_policy']" routerLinkActive="active">{{ 'LEGAL.COOKIE.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/privacy']" routerLinkActive="active">{{ 'LEGAL.PRIVACY.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/responsible_gaming']" routerLinkActive="active">{{ 'LEGAL.RESPONSIBLE.TITLE' | translate}}</button>-->
<!--          <button class="nav-link" [routerLink]="['/info/security']" routerLinkActive="active">{{ 'LEGAL.SECURITY.TITLE' | translate}}</button>-->
<!--        </nav>-->
<!--      </div>-->
<!--    </aside>-->
<!--    <main class="col-10 col-md-8">-->
<!--      <div class="bg-dark-subtle rounded-3 p-3">-->
<!--        <div class="tab-content" id="info-tabContent">-->
<!--          <router-outlet></router-outlet>-->
<!--        </div>-->
<!--      </div>-->
<!--    </main>-->

<!--  </div>-->

<!--</main>-->


