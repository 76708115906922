import { Component } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {UserService} from "../../../services/user.service";
import {UserModel} from "../../../models/user.model";
import {ActivatedRoute, Router} from "@angular/router";
import {GameService} from "../../../services/game.service";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {TitleService} from "../../../services/title.service";
import {TrackingHelperService} from "../../../services/tracking-helper.service";
import {ChatService} from "../../../services/chat.service";
import {TawkService} from "../../../services/tawk.service";
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-play-game',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.scss']
})
export class PlayGameComponent {
  brandName: string;
  assetsSource: string;
  logoFileName: string;
  logoAltText: string;

  gameId: string | null;
  gameUrl!: SafeResourceUrl | null;
  gameSubs? : Subscription;
  user?: UserModel | null;


  constructor(
    private configs: ConfigService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private gameService: GameService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private translate: TranslateService,
    private titleService: TitleService,
    private router: Router,
    private trackingService: TrackingHelperService,
    private chatService: ChatService,
    private tawkService: TawkService
  ) {
    this.brandName = this.configs.brandName;
    this.assetsSource = this.configs.assetsPath;
    this.logoFileName = this.configs.logoName;
    this.logoAltText = this.configs.logoAltText;

    this.userService.userObject.subscribe(u=>{
      this.user = u;
    })

    this.gameId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.gameId !== null){
      let id = +this.gameId;
      this.gameSubs?.unsubscribe();
      this.gameSubs =  this.gameService.getGames().subscribe({
        next: games => {
          if (games && games.length){
            let game = games.get(id);
            if (game && game.launchUrl){
              //set page Title
              this.titleService.setTitle(`Game / ${game.name}`);

              //save opened game in recentlyPlayedGames in localstorage
              this.gameService.addToRecentGames(this.gameId!)


              //show this game from the launchUrl + add params needed ex. lang, redirectUrl (see more in docs)
              let lang = localStorage.getItem('lang') ?? "en"
              let returnUrl = window.location.href.split("/play")[0];
              let urlWithParams = `${game.launchUrl}&lang=${lang}&returnUrl=${returnUrl}/details/${game.id}&chatButton=0`;

              //add btag and tracker to url
              let bTag = this.trackingService.getBannerTag();
              if (bTag) {
                urlWithParams += `&btag=${bTag}`
              }
              let t = this.trackingService.getTracker();
              if (t) {
                urlWithParams += `&t=${t}&tracker=${t}`
              }
              this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);
            } else {
              //inexisting gameId or no launchurl (if no game, will navigate away to not found)
              this.router.navigate(["details", this.gameId])
            }
          } else if (games){
            this.router.navigate([""])
          }

        },
        error: (err) => {
          this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1'));
        }
      })
    }

  }

  ngOnInit(){
    //hide chat button from the beginning
    if (this.configs.chatSystem === 'tawk'){
      this.tawkService.SetChatVisibility(false);
    } else {
      //rival or other chat systems
      this.chatService.toggleChatBtnShown(false);
    }
  }


  toggleChat() {
    // this.chatService.toggleChat(true);

    if (this.configs.chatSystem === 'tawk'){
      this.tawkService.ExpandChatWindow(true);
    } else {
      //rival or other chat systems
      this.chatService.toggleChat(true);
    }

  }

  ngOnDestroy(): void {
    //show chat button
    if (this.configs.chatSystem === 'tawk'){
      this.tawkService.SetChatVisibility(true);
    } else {
      //rival or other chat systems
      this.chatService.toggleChatBtnShown(true);
    }


    this.gameSubs?.unsubscribe();
  }


}
