<div class="modal fade" id="signUpModal" tabindex="-1" aria-labelledby="signUpModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 fw-bold ms-auto" id="signUpModalLabel">{{ 'REGISTER.TITLE2' | translate }}</h1>
        <button type="button" class="btn-close" (click)="onCloseRegModal()" data-bs-dismiss="modal" aria-label="Close" #closeSignUpButton></button>
      </div>
      <div class="modal-body">
        <div>
          <p class="text-center text-darker me-0 me-lg-4"> {{ 'REGISTER.STEP_LABEL' | translate }} {{currentStep}}: {{ ('REGISTER.STEP' + currentStep + '_TITLE') | translate }}</p>
        </div>
        <app-reg-step1 *ngIf="currentStep == 1"></app-reg-step1>
        <app-reg-step2 *ngIf="currentStep == 2"></app-reg-step2>
        <app-reg-step3 *ngIf="currentStep == 3"></app-reg-step3>
        <app-reg-step4 *ngIf="currentStep == 4"></app-reg-step4>

        <div class="text-center mt-2">
          <p class="mb-5 mb-sm-0 py-2">
            <span>{{ 'REGISTER.FOR_MEMBER_LABEL' | translate }}</span>
            <a class="text-decoration-none text-nowrap ms-2" data-bs-target="#signInModal" data-bs-toggle="modal" href="#signInModal" >{{ 'LOGIN.TITLE' | translate }}</a>
          </p>
        </div>
      </div>

    </div>
  </div>
</div>
