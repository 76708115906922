import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, Observable, Subscription, switchMap, tap, timer} from "rxjs";
import {GameModel} from "../models/game.model";
import {GameService} from "./game.service";

@Injectable({
  providedIn: 'root'
})
export class GameStatService {

  /* this service is for getting the recommended games from the DB = ordered by rtp */


  private jsonUrl = 'assets/most-played-games.json';  // Path to your JSON file TODO error handling when there is no data or way unavalable
  // private jsonUrl = 'https://google.com';  // Path to your JSON file TODO

  private mostPlayedGames = new BehaviorSubject<GameModel[]>([]);
  playedGamesSubs?: Subscription;
  fetchSubs?: Subscription;

  constructor(
    private http: HttpClient
  ) {
    this.loadMostPlayedGames();
    this.scheduleReFetch();
  }

  // Fetch data and store in BehaviorSubject and localStorage
  private loadMostPlayedGames() {
    const cachedData = localStorage.getItem('mostPlayedGames');
    if (cachedData) {
      this.mostPlayedGames.next(JSON.parse(cachedData));
    } else {
      this.playedGamesSubs?.unsubscribe();
      this.playedGamesSubs = this.fetchMostPlayedGames().subscribe();
    }
  }

  private fetchMostPlayedGames(): Observable<GameModel[]> {
    let result: GameModel[] = [];
    return this.http.get<any>(this.jsonUrl).pipe(
      tap((data: any) => {
        this.mostPlayedGames.next(data);
        localStorage.setItem('mostPlayedGames', JSON.stringify(data));
      })

    );

  }

  getMostPlayedGamesJson(): Observable<any> {
    return this.mostPlayedGames.asObservable();
  }

  // getMostPlayedGames(): Observable<any> {
  //   return this.http.get<any>(this.jsonUrl);
  // }

  private scheduleReFetch() {
    // this.fetchSubs?.unsubscribe();
    this.fetchSubs = timer(0, 3600000) // Trigger every 1 hour
      .pipe(switchMap(() => this.fetchMostPlayedGames()))
      .subscribe();
  }



}
