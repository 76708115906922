import { Injectable } from '@angular/core';
import {PromotionModel} from "../models/promotion.model";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  staticPromotions: PromotionModel[];

  BASE_URL: string;
  CASINO_ID: number

  constructor(
    private http: HttpClient,
    private configs: ConfigService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;

    this.staticPromotions = this.configs.publicPromotions;
  }

//   Todo: ? place user promotions here?

  getUserPromos(): Observable<{result: {"promotions": PromotionModel[]}, jsonrpc: string, id: number}>{
    return this.http.post<{result: {"promotions": PromotionModel[]}, jsonrpc: string, id: number}>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id": this.CASINO_ID,"method":"user.promotion.list","params":{}});
  }


  //todo try to fix the carousel on toggling sidebar:
  private sidebarToggleSubject = new BehaviorSubject<void>(undefined);
  sidebarToggleObservable: Observable<void> = this.sidebarToggleSubject.asObservable();
  toggleSidebar(): void {
    this.sidebarToggleSubject.next();
  }
}

