import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {TermsComponent} from "./pages/legal/terms/terms.component";
import {SelectedGameComponent} from "./pages/selected-game/selected-game.component";
import {authGuard} from "./guards/auth.guard";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {SelectedProviderComponent} from "./pages/games/selected-provider/selected-provider.component";
import {providerGuard} from "./guards/provider.guard";
import {ProvidersComponent} from "./pages/games/gameProviders/providers.component";
import {SelectedCategoryComponent} from "./pages/games/selected-category/selected-category.component";
import {categoryGuard} from "./guards/category.guard";
import {LegalSectionComponent} from "./pages/legal/legal-section/legal-section.component";
import {AboutComponent} from "./pages/legal/about/about.component";
import {FaqComponent} from "./pages/legal/faq/faq.component";
import {AmlComponent} from "./pages/legal/aml/aml.component";
import {BankingComponent} from "./pages/legal/banking/banking.component";
import {BitcoinComponent} from "./pages/legal/bitcoin/bitcoin.component";
import {CookiePolicyComponent} from "./pages/legal/cookie-policy/cookie-policy.component";
import {PrivacyComponent} from "./pages/legal/privacy/privacy.component";
import {ResponsibleGamingComponent} from "./pages/legal/responsible-gaming/responsible-gaming.component";
import {SecurityComponent} from "./pages/legal/security/security.component";
import {SupportComponent} from "./pages/legal/support/support.component";
import {LeaderboardComponent} from "./pages/leaderboard/leaderboard.component";
import {SidebarStructureComponent} from "./shared/sidebar-structure/sidebar-structure.component";
import {RegistrationComponent} from "./pages/registration/registration.component";
import {PlayGameComponent} from "./pages/selected-game/play-game/play-game.component";
import {PromotionsComponent} from "./pages/promotions/promotions.component";
import {PromoDetailsComponent} from "./pages/promotions/promo-details/promo-details.component";
import {balanceGuard} from "./guards/balance.guard";
import {LegacyComponent} from "./pages/legacy/legacy.component";
import {JackpotsComponent} from "./account/jackpots/jackpots.component";
import {
  ResetForgotPasswordInLobbyComponent
} from "./shared/reset-forgot-password-in-lobby/reset-forgot-password-in-lobby.component";
import {AlwaysoneComponent} from "./pages/alwaysone/alwaysone.component";
import {UnsubscribeComponent} from "./pages/unsubscribe/unsubscribe.component";
import {affiliateGuard} from "./guards/affiliate.guard";
import {XmasComponent} from "./pages/xmas/xmas.component";
import {languageGuard} from "./guards/language.guard";
import {environment} from "../environments/environment";


const routes: Routes = environment.prettyName === 'Slotvibe' ? [
  { path: 'l', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
  { path: '**', redirectTo: 'l' }
  ] :
  [
  {path: "", redirectTo: "/", pathMatch: "full"},
  {path: "", component: SidebarStructureComponent, children: [
      {path: "", component: HomeComponent},

      {path: "home", component: HomeComponent},
      { path: ":lang/home", component: HomeComponent, canMatch: [languageGuard], data: { lang: true } },

      {path: "instantplay", component: HomeComponent},
      { path: ":lang/instantplay", component: HomeComponent, canMatch: [languageGuard], data: { lang: true } },

      {path: "register", component: HomeComponent},
      { path: ":lang/register", component: HomeComponent, canMatch: [languageGuard], data: { lang: true } },

      {path: "games/all_games", redirectTo: "", pathMatch: 'full'},
      {path: "games/all", redirectTo: "", pathMatch: 'full'},
      {path: "games/jackpot", redirectTo: "", pathMatch: 'full'},
      {path: "games/popular", redirectTo: "games/featured", pathMatch: "full"},
      {path: "games/live_casino", redirectTo: "games/live", pathMatch: "full"},
      {path: "games/new_games", redirectTo: "games/new", pathMatch: "full" },
      {path: "games/arrow_s_edge", redirectTo: "", pathMatch: 'full'},
      {path: "loader", redirectTo: "", pathMatch: 'full'},
      {path: "lps", redirectTo: "", pathMatch: 'full'},
      {path: "landing", redirectTo: "", pathMatch: 'full'},

      // {path: "games/:category", component: SelectedCategoryComponent},
      {path: "games/:category", component: SelectedCategoryComponent, canMatch: [categoryGuard]},
      {path: "providers", component: ProvidersComponent},
      {path: "providers/:provider", component: SelectedProviderComponent, canActivate: [providerGuard]},
      {path: "details/:id", component: SelectedGameComponent},
      // {path: "leaderboard", component: LeaderboardComponent},
      {path: "promotions", component: PromotionsComponent},
      {path: "promotions/:name", component: PromoDetailsComponent},

      {path: "info", component: LegalSectionComponent, children: [
          {path: "about", component: AboutComponent},
          {path: "aml", component: AmlComponent},
          {path: "banking", component: BankingComponent},
          {path: "bitcoin", component: BitcoinComponent},
          {path: "cookie_policy", component: CookiePolicyComponent},
          {path: "faq", component: FaqComponent},
          {path: "privacy", component: PrivacyComponent},
          {path: "responsible_gaming", component: ResponsibleGamingComponent},
          {path: "security", component: SecurityComponent},
          {path: "support", component: SupportComponent},
          {path: "terms", component: TermsComponent},
          {path: "", redirectTo: "about", pathMatch: "full"}
        ]},
      {path: "about", redirectTo: "info/about", pathMatch: "full"},
      {path: "aml", redirectTo: "info/aml", pathMatch: "full"},
      {path: "banking", redirectTo: "info/banking", pathMatch: "full"},
      {path: "bitcoin", redirectTo: "info/bitcoin", pathMatch: "full"},
      {path: "cookie_policy", redirectTo: "info/cookie_policy", pathMatch: "full"},
      {path: "faq", redirectTo: "info/faq", pathMatch: "full"},
      {path: "privacy", redirectTo: "info/privacy", pathMatch: "full"},
      {path: "responsible_gaming", redirectTo: "info/responsible_gaming", pathMatch: "full"},
      {path: "security", redirectTo: "info/security", pathMatch: "full"},
      {path: "support", redirectTo: "info/support", pathMatch: "full"},
      {path: "terms", redirectTo: "info/terms", pathMatch: "full"},

      {path: "u", component: UnsubscribeComponent},

      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },

      { path: ":lang", component: HomeComponent, canMatch: [languageGuard] },
    ]},
  // {path: "registration", component: RegistrationComponent},
  {path: "legacy", component: LegacyComponent},
  {path: "resetPassword", component: ResetForgotPasswordInLobbyComponent}, //we dont need this anymore
  {path: "lp", // Define the route for the landing component
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule), // Lazy load the landing module
    canActivate: [affiliateGuard] // Protect the route with your guard - todo comment out to test with ng s on not affiliated brands
  },
  {path: "xmas", component: XmasComponent },
  {path: "play/:id", component: PlayGameComponent, canActivate: [authGuard]},
  {path: "alwaysone", component: AlwaysoneComponent},
  {path: "not-found", component: NotFoundComponent},
  {path: "**", redirectTo: "not-found"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
