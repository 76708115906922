import { Component } from '@angular/core';
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  brandName: string;
  withdrawalTime: number | null;
  isAffTermsNeeded: boolean;
  licenseInfo?: { pdb_name: string, pdb_address: string, lh_name: string, lh_address: string };

  constructor(
    private configs: ConfigService
  ) {
    this.brandName = this.configs.brandName;
    this.withdrawalTime = this.configs.withdrawalTime;
    this.isAffTermsNeeded = this.configs.isAffTermsNeeded;
    this.licenseInfo = this.configs.licenseInfo;
  }



}
