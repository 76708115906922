import {Component, ElementRef, ViewChild} from '@angular/core';
import {ChatService} from "../../services/chat.service";
import {ChatUser, Maybe, } from "../../models/chat/chat.type";
import {Subscription} from "rxjs";
import {ChatMessageModel} from "../../models/chat/chatMessage.model";
import {ConfigService} from "../../services/config.service";


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {

  messages: any[] = [];
  chatInput = "";

  messageSubs?: Subscription;

  chatAvailable: boolean = false;
  chatUser!: ChatUser;
  chatUserSubs?: Subscription;

  availableSubs?: Subscription;
  channelSubs?: Subscription;

  isTyping: boolean = false;
  typingSubs?: Subscription;

  @ViewChild('chatMessageWrapper') chatWrapper!: ElementRef; //for scrolling to bottom
  private shouldScrollToBottom = false;


  constructor(
    private configs: ConfigService,
    private chatService: ChatService
  ) {
  }

  ngOnInit() {
    //messages from service:
    this.messageSubs = this.chatService.messagesInWs.subscribe(messages => {
      this.messages = messages;
      this.shouldScrollToBottom = true;
    })

    //for Rival chat
    if (this.configs.chatSystem === 'rival'){
      // if user logged in, open chat window, if not initialize websocket;
      this.channelSubs?.unsubscribe();
      this.channelSubs = this.chatService.channel.subscribe(value =>{
        if (!value){
          //if the user is not logged in, there is no ws connection so we have to initialize
          if (!this.chatUser || this.chatUser.role === 'guest'){  //to wait for the ws to close if the guest user had the chat opened
            this.chatService.initialize(true);
          }
        }
      })
    }



    this.availableSubs?.unsubscribe();
    this.availableSubs = this.chatService.available.subscribe((available:boolean)=>{
      if (available){
        this.chatAvailable = true;
        const message: ChatMessageModel = {
          isMe: false,
          user: {username: "Customer Service"},
          message: "We are here to help."
        }
        if (!this.messages.length){
          this.messages.push(message)
        }
      } else if (this.chatAvailable) {
        // the chat was available but that changed to unavailable
        this.chatAvailable = false;
        const message: ChatMessageModel = {
          isMe: false,
          user: {username: "Customer Service"},
          message: "We're not available right now. Please try again later."
        }
        this.messages.push(message) //todo find out why commented out while developing (maybe because it pushes more times this message)
      } else if (this.configs.chatSystem !=='rival'){
        // until not-rival-type chat gets implemented - todo
        this.chatAvailable = false;
        this.messages = [];
        const message: ChatMessageModel = {
          isMe: false,
          user: {username: "Customer Service"},
          message: "We're not available right now. Please try again later."
        }
        this.messages.push(message)

      }
    })


    this.chatUserSubs?.unsubscribe();
    this.chatUserSubs = this.chatService.user.subscribe((user: Maybe<ChatUser>)=> {
      // console.log("recieved about user:", user)
      if (user){
        this.chatUser = user;
      }
    })


    this.typingSubs?.unsubscribe()
    this.typingSubs = this.chatService.typingInProgress.subscribe(isTyping => {
      this.isTyping = isTyping;
      this.shouldScrollToBottom = true;
    })
  }



  ngAfterViewChecked() {
    if (this.shouldScrollToBottom) {
      this.scrollToBottomInChat();
      this.shouldScrollToBottom = false;
    }
  }


  sendMessage(){
    if (this.chatAvailable && this.chatInput.trim() !== '') {
      this.chatService.sendMessage(this.chatInput)
      //reset input field
      this.chatInput = "";
    }
  }

  closeChat(): void {
    this.chatService.toggleChat(false);
  }

  scrollToBottomInChat(){
    try {
      this.chatWrapper.nativeElement.scrollTop = this.chatWrapper.nativeElement.scrollHeight;
    } catch(err) {
      // console.error('Scroll to bottom failed:', err);
    }

  }

  ngOnDestroy(){
    this.chatUserSubs?.unsubscribe();
    this.availableSubs?.unsubscribe();
    this.channelSubs?.unsubscribe();

    this.messageSubs?.unsubscribe();
    this.typingSubs?.unsubscribe();

    //for Rival chat
    if (this.configs.chatSystem === 'rival'){
      //if user is in guest mode, closing the component should close the ws
      if (this.chatUser && this.chatUser.role && this.chatUser.role === 'guest'){
        this.chatService.closeWebSocket();
      }
    }



  }



}
