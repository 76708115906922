<form class="needs-validation" novalidate (ngSubmit)="submitForm()" [formGroup]="regStep2Form">

  <div class="mb-2">
    <label class="form-label mb-0" for="phone">{{ 'REGISTER.PHONE_LABEL' | translate }}</label>
    <input class="form-control mt-1" type="text" id="phone" placeholder="{{ 'REGISTER.PHONE_LABEL' | translate }}" required formControlName="phone" [ngClass]="{'is-invalid': regSubmit && regForm['phone'].errors}" autocomplete="off">
    <div class="invalid-feedback">{{ 'REGISTER.PHONE_ALERT' | translate }}</div>
    <div><small class='fs-sm text-muted'>{{ 'REGISTER.PHONE_HELP' | translate }} </small></div>
  </div>

  <div class="row row-cols-1 row-cols-sm-2">
    <div class="col mb-2">
      <label class="form-label mb-1" for="country">{{ 'REGISTER.COUNTRY_LABEL' | translate }}</label>
      <select class="form-select" id="country" formControlName="country" [ngClass]="{'is-invalid': regSubmit && regForm['country'].errors}">
        <option selected value="">{{ 'REGISTER.COUNTRY_PLACEHOLDER' | translate }}</option>
        <option *ngFor="let country of countryOptions" [value]="country.code">{{ country.name }}</option>
      </select>
    </div>
    <div class="col mb-2">
      <label class="form-label mb-1" for="currency">{{ 'REGISTER.CURRENCY_LABEL' | translate }}</label>
      <select class="form-select" id="currency" formControlName="currency" [ngClass]="{'is-invalid': regSubmit && regForm['currency'].errors}">
        <option selected value="">{{ 'REGISTER.CURRENCY_LABEL' | translate }}</option>
        <option *ngFor="let currency of currencyOptions" [value]="currency.code">{{ currency.name }}</option>
      </select>
    </div>
  </div>

  <div>
    <label class="form-label mb-0" for="address">{{ 'REGISTER.ADDRESS_LABEL' | translate }}</label>
    <div class="row row-cols-1 row-cols-sm-2">
      <div class="col mb-2">
        <input class="form-control mt-1" type="text" id="address" placeholder="{{ 'REGISTER.ADDRESS_LABEL' | translate }}" required formControlName="address" [ngClass]="{'is-invalid': regSubmit && regForm['address'].errors}" autocomplete="off">
      </div>
      <div class="col mb-2">
        <input class="form-control mt-1" type="text" id="address2" placeholder="{{ 'REGISTER.ADDRESS2_LABEL' | translate }} {{ 'REGISTER.OPTIONAL' | translate }}" formControlName="address2" autocomplete="off">
      </div>
    </div>
  </div>



  <div class="row">
    <div class="col-12 col-sm-4 mb-2">
      <label class="form-label mb-1" for="city">{{ 'REGISTER.CITY_LABEL' | translate }}</label>
      <input class="form-control" type="text" id="city" placeholder="{{ 'REGISTER.CITY_LABEL' | translate }}" required formControlName="city" [ngClass]="{'is-invalid': regSubmit && regForm['city'].errors}">
    </div>
    <div class="col-12 col-sm-5 mb-2">
      <label class="form-label mb-0" for="province">{{ 'REGISTER.PROVINCE_LABEL' | translate }} <span *ngIf="regForm['country'].value !== 'US' && regForm['country'].value !== 'AU'">{{ 'REGISTER.OPTIONAL' | translate }}</span></label>
      <select class="form-select mt-1" id="province" formControlName="province" [ngClass]="{'is-invalid': regSubmit && regForm['province'].errors}">
        <option selected value="">{{ 'REGISTER.PROVINCE_LABEL' | translate }}</option>
          <option *ngFor="let province of provinceOptions" [value]="province.code">{{ province.name }}</option>
      </select>
    </div>
    <div class="col-12 col-sm-3 mb-2">
      <label class="form-label mb-0" for="zip">{{ 'REGISTER.ZIP_LABEL' | translate }}</label>
      <input class="form-control mt-1" type="text" id="zip" placeholder="{{ 'REGISTER.ZIP_LABEL' | translate }}" required formControlName="zip" [ngClass]="{'is-invalid': regSubmit && regForm['zip'].errors}" autocomplete="off">
    </div>
  </div>



  <div class="row mt-4" *ngIf="!isLoading; else loadingSpinner">
    <div class="col mb-2">
      <button class="btn btn-outline-success btn-lg w-100" type="button" (click)="goToPrevStep()">{{ 'REGISTER.PREV_LABEL' | translate }}</button>
    </div>
    <div class="col mb-2">
      <button class="btn btn-success btn-lg w-100" type="submit">{{ 'REGISTER.NEXT_LABEL' | translate }}</button>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>

</form>



<!--first version -->
<!--<form class="needs-validation" novalidate (ngSubmit)="submitForm()" [formGroup]="regStep2Form">-->

<!--  <div class="row row-cols-1 row-cols-sm-2">-->
<!--    <div class="col mb-2">-->
<!--      <label class="form-label mb-1" for="firstName">{{ 'REGISTER.STEP2.FNAME_LABEL' | translate }}</label>-->
<!--      <input class="form-control" type="text" id="firstName" placeholder="{{ 'REGISTER.STEP2.FNAME_LABEL' | translate }}" required formControlName="fname" [ngClass]="{'is-invalid': regSubmit && regForm['fname'].errors}" autocomplete="off">-->
<!--      <div class="invalid-feedback">{{ 'REGISTER.STEP2.FNAME_ALERT' | translate }}</div>-->
<!--    </div>-->
<!--    <div class="col mb-2">-->
<!--      <label class="form-label mb-1" for="lastName">{{ 'REGISTER.STEP2.LNAME_LABEL' | translate }}</label>-->
<!--      <input class="form-control" type="text" id="lastName" placeholder="{{ 'REGISTER.STEP2.LNAME_LABEL' | translate }}" required formControlName="lname" [ngClass]="{'is-invalid': regSubmit && regForm['lname'].errors}" autocomplete="off">-->
<!--      <div class="invalid-feedback">{{ 'REGISTER.STEP2.FNAME_ALERT' | translate }}</div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="">-->
<!--    <label class="form-label mb-1" for="birthYear">{{ 'REGISTER.STEP2.BIRTH_DATE_LABEL' | translate }}</label>-->
<!--    <div class="row">-->
<!--      <div class="col-12 col-sm-4 mb-2">-->
<!--        <select class="form-select" id="birthYear" formControlName="birthYear" [ngClass]="{'is-invalid': regSubmit && regForm['birthYear'].errors}">-->
<!--          <option selected value="">{{ 'REGISTER.STEP2.BIRTH_YEAR_LABEL' | translate }}</option>-->
<!--          <option *ngFor="let year of yearOptions" [value]="year">{{year}}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="col-12 col-sm-5 mb-2" *ngIf="monthOptions.length">-->
<!--        <select class="form-select" id="birthMonth" formControlName="birthMonth" [ngClass]="{'is-invalid': regSubmit && regForm['birthMonth'].errors}">-->
<!--          <option selected value="">{{ 'REGISTER.STEP2.BIRTH_MONTH_LABEL' | translate }}</option>-->
<!--          <option *ngFor="let month of monthOptions" [value]="month">{{ 'REGISTER.STEP2.BIRTH_MONTH_'+ month | translate  }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="col-12 col-sm-3 mb-2" *ngIf="dayOptions.length">-->
<!--        <select class="form-select" id="birthDay" formControlName="birthDay" [ngClass]="{'is-invalid': regSubmit && regForm['birthDay'].errors}">-->
<!--          <option selected value="">{{ 'REGISTER.STEP2.BIRTH_DAY_LABEL' | translate }}</option>-->
<!--          <option *ngFor="let day of dayOptions" [value]="day">{{ day }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->



<!--  <div class="row row-cols-1 row-cols-sm-2">-->
<!--    <div class="col mb-2">-->
<!--      <label class="form-label mb-1" for="country">{{ 'REGISTER.STEP2.COUNTRY_LABEL' | translate }}</label>-->
<!--      <select class="form-select" id="country" formControlName="country" [ngClass]="{'is-invalid': regSubmit && regForm['country'].errors}">-->
<!--        <option selected value="">{{ 'REGISTER.STEP2.COUNTRY_PLACEHOLDER' | translate }}</option>-->
<!--        <option *ngFor="let country of countryOptions" [value]="country.code">{{ country.name }}</option>-->
<!--      </select>-->
<!--    </div>-->
<!--    <div class="col mb-2">-->
<!--      <label class="form-label mb-1" for="currency">{{ 'REGISTER.STEP2.CURRENCY_LABEL' | translate }}</label>-->
<!--      <select class="form-select" id="currency" formControlName="currency" [ngClass]="{'is-invalid': regSubmit && regForm['currency'].errors}">-->
<!--        <option selected value="">{{ 'REGISTER.STEP2.CURRENCY_LABEL' | translate }}</option>-->
<!--        <option *ngFor="let currency of currencyOptions" [value]="currency.code">{{ currency.name }}</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div>-->
<!--    <label class="form-label mb-0" for="address">{{ 'REGISTER.STEP2.ADDRESS_LABEL' | translate }}</label>-->
<!--    <div class="row row-cols-1 row-cols-sm-2">-->
<!--      <div class="col mb-2">-->
<!--        <input class="form-control mt-1" type="text" id="address" placeholder="{{ 'REGISTER.STEP2.ADDRESS_LABEL' | translate }}" required formControlName="address" [ngClass]="{'is-invalid': regSubmit && regForm['address'].errors}" autocomplete="off">-->
<!--      </div>-->
<!--      <div class="col mb-2">-->
<!--        <input class="form-control mt-1" type="text" id="address2" placeholder="{{ 'REGISTER.STEP2.ADDRESS2_LABEL' | translate }} {{ 'REGISTER.STEP2.OPTIONAL' | translate }}" formControlName="address2" autocomplete="off">-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->



<!--  <div class="row">-->
<!--    <div class="col-12 col-sm-4 mb-2">-->
<!--      <label class="form-label mb-1" for="city">{{ 'REGISTER.STEP2.CITY_LABEL' | translate }}</label>-->
<!--      <input class="form-control" type="text" id="city" placeholder="{{ 'REGISTER.STEP2.CITY_LABEL' | translate }}" required formControlName="city" [ngClass]="{'is-invalid': regSubmit && regForm['city'].errors}">-->
<!--    </div>-->
<!--    <div class="col-12 col-sm-5 mb-2">-->
<!--      <label class="form-label mb-0" for="province">{{ 'REGISTER.STEP2.PROVINCE_LABEL' | translate }}</label>-->
<!--      <input class="form-control mt-1" type="text" id="province" placeholder="{{ 'REGISTER.STEP2.PROVINCE_LABEL' | translate }}" required formControlName="province" [ngClass]="{'is-invalid': regSubmit && regForm['province'].errors}" autocomplete="off">-->
<!--    </div>-->
<!--    <div class="col-12 col-sm-3 mb-2">-->
<!--      <label class="form-label mb-0" for="zip">{{ 'REGISTER.STEP2.ZIP_LABEL' | translate }}</label>-->
<!--      <input class="form-control mt-1" type="text" id="zip" placeholder="{{ 'REGISTER.STEP2.ZIP_LABEL' | translate }}" required formControlName="zip" [ngClass]="{'is-invalid': regSubmit && regForm['zip'].errors}" autocomplete="off">-->
<!--    </div>-->
<!--  </div>-->


<!--  <div class="mb-2">-->
<!--    <label class="form-label mb-0" for="phone">{{ 'REGISTER.STEP2.PHONE_LABEL' | translate }}</label>-->
<!--    <input class="form-control mt-1" type="text" id="phone" placeholder="{{ 'REGISTER.STEP2.PHONE_LABEL' | translate }}" required formControlName="phone" [ngClass]="{'is-invalid': regSubmit && regForm['phone'].errors}" autocomplete="off">-->
<!--    <div class="invalid-feedback">{{ 'REGISTER.STEP2.PHONE_ALERT' | translate }}</div>-->
<!--    <div><small class='fs-sm text-muted'>{{ 'REGISTER.STEP2.PHONE_HELP' | translate }} </small></div>-->
<!--  </div>-->

<!--  <div class="mb-2">-->
<!--    <label class="form-label mb-0" for="question">{{ 'REGISTER.STEP2.QUESTION_LABEL' | translate }}</label>-->
<!--    <input class="form-control mt-1" type="text" id="question" placeholder="{{ 'REGISTER.STEP2.QUESTION_LABEL' | translate }}" required formControlName="question" [ngClass]="{'is-invalid': regSubmit && regForm['question'].errors}" autocomplete="off">-->
<!--  </div>-->

<!--  <div class="mb-2">-->
<!--    <label class="form-label mb-0" for="answer">{{ 'REGISTER.STEP2.ANSWER_LABEL' | translate }}</label>-->
<!--    <input class="form-control mt-1" type="text" id="answer" placeholder="{{ 'REGISTER.STEP2.ANSWER_LABEL' | translate }}" required formControlName="answer" [ngClass]="{'is-invalid': regSubmit && regForm['answer'].errors}" autocomplete="off">-->
<!--  </div>-->


<!--  <div class="row mt-4" *ngIf="!isLoading; else loadingSpinner">-->
<!--    <div class="col mb-2">-->
<!--      <button class="btn btn-outline-success btn-lg w-100" type="button" (click)="goToPrevStep()">{{ 'REGISTER.PREV_LABEL' | translate }}</button>-->
<!--    </div>-->
<!--    <div class="col mb-2">-->
<!--      <button class="btn btn-success btn-lg w-100" type="submit">{{ 'REGISTER.NEXT_LABEL' | translate }}</button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <ng-template #loadingSpinner>-->
<!--    <div *ngIf="isLoading" class="d-flex justify-content-center my-4">-->
<!--      <div class="spinner-border" role="status">-->
<!--        <span class="visually-hidden">Loading...</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->

<!--</form>-->

