import { Component } from '@angular/core';

@Component({
  selector: 'app-alwaysone',
  templateUrl: './alwaysone.component.html',
  styleUrls: ['./alwaysone.component.scss']
})
export class AlwaysoneComponent {
  //todo add some logic here - ask Ash

}
