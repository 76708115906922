<section class="p-2 p-md-3">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.BITCOIN.TITLE2' | translate }}</h1>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BITCOIN.DEFINITION.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.BITCOIN.DEFINITION.P1' | translate  }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BITCOIN.MECHANISM.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.BITCOIN.MECHANISM.P1' | translate  }}</p>
  <p class="text-darker">{{ 'LEGAL.BITCOIN.MECHANISM.P2' | translate }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BITCOIN.VALUE.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.BITCOIN.VALUE.P1' | translate  }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.BITCOIN.ACQUISITION.TITLE' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.BITCOIN.ACQUISITION.P1' | translate  }} </p>

</section>

