<div class="mb-4" *ngIf="identifier && dataList.length">
  <div class="d-flex align-items-center justify-content-between py-2">
    <h2 class="h5 d-flex align-items-center car-title mb-0">
      <svg class="casino-icon me-1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 120 120">
        <g [innerHTML]="getSanitizedSvgPath(identifier)"></g>
      </svg>
      <a class="h6 fw-bold mb-0 text-decoration-none" [routerLink]="routerLink">{{ title | translate : {param: translateParam} }} </a>
    </h2>

    <div *ngFor="let carousel of carousels" [class]="carousel.displayClass">
      <div *ngIf="carousel.slides && carousel.slides.length > 1" class="d-flex flex-nowrap">
        <button class="btn btn-secondary btn-sm ms-2 py-1 px-3" type="button" (click)="moveSlide('prev', carousel.size, identifier)" [disabled]="!currentSlideIndex">
          <span class="" aria-hidden="true"> < </span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="btn btn-secondary btn-sm ms-2 py-1 px-3" type="button" (click)="moveSlide('next', carousel.size, identifier)"  [disabled]="currentSlideIndex === carousel.slides.length-1">
          <span class="" aria-hidden="true"> > </span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

  </div>

  <div *ngFor="let carousel of carousels" [id]="'carousel' + carousel.size + identifier.toUpperCase()" class="carousel slide" [ngClass]="carousel.displayClass" data-bs-interval="1000">
    <div class="carousel-inner">
      <div *ngFor="let slide of carousel.slides; let idx = index" class="carousel-item" [ngClass]="{'active': idx === 0}">
        <div class="car-item-wrapper">
          <div class="{{'car-item-'+carousel.size.toLowerCase()}}"
               *ngFor="let item of slide; let j=index"
               [ngClass]="{'clickable': (carousel.size ==='Sm' || carousel.size === 'Xs') || j < carousel.itemsPerSlide - 1 || (j===carousel.itemsPerSlide-1 && carousel.slides && idx===carousel.slides.length-1), 'half-div': carousel.size !=='Sm' && carousel.size !== 'Xs' && j===carousel.itemsPerSlide-1 && idx!==numOfSlides-1, 'default-cursor': !item.name}">
            <div *ngIf="contentType === 'game'; else providerCarousel">
              <div class="shadow" [ngClass]="{'visually-hidden': !item.name}">
                <a [routerLink]="(item.launchUrl ? '/play/' : '/details/') + item.id" [ngClass]="{'image-with-ribbon': !(carousel.size !=='Sm' && carousel.size !== 'Xs' && j===carousel.itemsPerSlide-1 && idx!==numOfSlides-1)}">
                  <img [src]=item.image [alt]="item.name" class="rounded" style="width: 100%; height: auto; aspect-ratio: 1/1;" loading="lazy" (error)="updateNotFoundImgUrl($event)">
                  <div *ngIf="item.tag && item.tag.includes('beta') && !(carousel.size !=='Sm' && carousel.size !== 'Xs' && j===carousel.itemsPerSlide-1 && idx!==numOfSlides-1)" class="ribbon">Beta</div>
                </a>
              </div>
            </div>
            <ng-template #providerCarousel>
              <div class="p-1 bg-lighter shadow rounded-2" [ngClass]="{'visually-hidden': !item.name}">
                <a class="d-flex justify-content-center" routerLink="/providers/{{item.routerLink}}">
                  <img [src]=item.imagePath alt="{{item.name}} logo" loading="lazy">
                </a>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>



