<div id="live_chat" class="live_chat d-flex flex-column p-1">
    <div class="chat-closer w-100 text-end" (click)="closeChat()">
        <svg viewBox="0 0 64 64" class="me-2"> <title></title> <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path></svg>
    </div>
    <div #chatMessageWrapper class="overflow-y-auto overflow-x-hidden flex-grow-1 p-2">
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-block">
          <ng-container  *ngIf="messages.length; else chatLoading">
            <div *ngFor="let message of messages; let i = index" class="w-75 rounded-2 p-2 m-1"
                 [class.chat-user-message]="message.isMe" [class.chat-service-message]="!message.isMe">
              <div class="chat-message-name">{{ message.user.username }}</div>
              <div class="chat-message-text">{{ message.message }}</div>
            </div>
          </ng-container>
        </div>

      </div>

      <div class="typing-indicator" *ngIf="isTyping">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ng-template #chatLoading>
        <div class="d-flex justify-content-center">
          <div class="spinner-border spinner-border-sm text-primary-emphasis justify-content-end" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </ng-template>
    </div>




  <div class="input-section d-flex justify-content-center p-2">
      <input type="text" id="myInput" class="form-control me-1" [(ngModel)]="chatInput" (keydown.enter)="sendMessage()">
      <button class="btn btn-primary" (click)="sendMessage()" [disabled]="!chatAvailable" >Send</button>
    </div>

</div>



