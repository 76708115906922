import {   CanMatchFn  } from '@angular/router';
import {inject} from "@angular/core";
import {LanguageService} from "../services/language.service";


export const languageGuard: CanMatchFn = (route, state) => {
  const allowedLanguages = ['en', 'de', 'fr', 'es', 'it', 'pt'];

  if(state[0].path){
    const requestedLang = state[0].path
    if (requestedLang && allowedLanguages.includes(requestedLang)){
      const languageService = inject(LanguageService);
      languageService.setUserSelectedLang(requestedLang);
      return true
    }
  }
  return false

};
