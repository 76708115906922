<!--<div *ngIf="gameUrl">-->
<!--  <iframe [src]="gameUrl" title="game" style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" ></iframe>-->
<!--</div>-->


<main>
  <nav class="nav shadow bg-main py-0 game-iframe-bar">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <div>
        <a routerLink=""><img src="{{assetsSource}}/{{logoFileName}}" [alt]="logoAltText"></a>
      </div>
      <div class="d-flex">
        <a type="button" routerLink="/account/cashier">
          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
            <path style="fill:#FFFFFF;" d="M96.6,68L96.6,68c-0.2-0.6-0.4-1.1-0.6-1.7c0,0,0,0,0-0.1c-0.5-1.1-1-2.1-1.7-3.1c0,0,0-0.1-0.1-0.1
              	c-0.6-1-1.3-1.9-2.1-2.8c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-0.8-0.9-1.2-1.3c0,0,0,0-0.1-0.1c-0.4-0.4-0.8-0.8-1.3-1.1c0,0,0,0-0.1-0.1
              	c-0.4-0.4-0.9-0.7-1.3-1c-0.1,0-0.1-0.1-0.2-0.1C87,55.8,86,55.2,85,54.7c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.2-1-0.5-1.5-0.7
              	c0,0-0.1,0-0.1-0.1c-0.5-0.2-1-0.4-1.5-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.2-1-0.3-1.6-0.5c-0.1,0-0.2,0-0.3-0.1
              	c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.2,0c-0.5-0.1-1.1-0.2-1.7-0.2c-0.1,0-0.3,0-0.4,0C75.2,52,74.6,52,74,52h0h-5h-1V32
              	c0-1.1,0.9-2,2-2h5h1c2.2,0,4,1.8,4,4v3c0,2.2,1.8,4,4,4h8h2c0.8,0,1.5-0.5,1.8-1.2C95.9,38.9,96,38,96,37c0-4.3-1.2-8.3-3.2-11.7h0
              	c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.4-0.5-0.6
              	c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6-0.6-1.2-1.3-1.8-1.8c0,0-0.1-0.1-0.2-0.1
              	c-0.3-0.2-0.6-0.5-0.8-0.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.2-0.5-0.4-0.8-0.6c0,0-0.1-0.1-0.1-0.1h0c-2.4-1.7-5.2-2.9-8.1-3.5
              	C77,14.3,76,15,76,16c0,0,0,0,0,0v1.8c0,0.1,0,0.2,0,0.2h0c0,0.7-0.3,1.4-0.6,2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
              	c-0.1,0.2-0.3,0.3-0.5,0.5c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.3-1.1,0.6-1.7,0.6C70,22.2,68,20.3,68,18v-4V4H52v10v3.8V18
              	c0,1.5-0.9,2.8-2.1,3.5c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1
              	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.1,0-0.2,0
              	c-0.2,0-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1v0c-1.2-0.7-2-2-2-3.4v-2.8c0-0.6-0.4-1-1-1c0,0,0,0-0.1,0
              	l0,0l-0.1,0c-0.1,0-0.3,0-0.4,0.1c-1.5,0.3-3.7,0.8-5.1,1.3C28.4,19.1,22,27.8,22,38v6c0,2.8,0.5,5.5,1.4,8h0
              	c0.2,0.6,0.4,1.1,0.7,1.7c0,0,0,0,0,0.1c0.5,1.1,1,2.1,1.7,3.1c0,0,0,0.1,0.1,0.1c0.6,1,1.3,1.9,2.1,2.8c0,0,0.1,0.1,0.1,0.1
              	c0.4,0.4,0.8,0.9,1.2,1.3c0,0,0,0,0.1,0.1c0.4,0.4,0.8,0.8,1.3,1.1c0,0,0,0,0.1,0.1c0.4,0.4,0.9,0.7,1.3,1c0.1,0,0.1,0.1,0.2,0.1
              	c0.9,0.6,1.9,1.2,2.9,1.8c0.1,0,0.2,0.1,0.3,0.1c0.5,0.2,1,0.5,1.5,0.7c0,0,0.1,0,0.1,0.1c0.5,0.2,1,0.4,1.5,0.5
              	c0.1,0,0.2,0.1,0.3,0.1c0.5,0.2,1,0.3,1.6,0.5c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.3c0.1,0,0.2,0,0.2,0
              	c0.5,0.1,1.1,0.2,1.7,0.2c0.1,0,0.3,0,0.4,0c0.6,0,1.1,0.1,1.7,0.1h0h5h1v20c0,1.1-0.9,2-2,2h-5h-1c-2.2,0-4-1.8-4-4v-3
              	c0-2.2-1.8-4-4-4h-8h-2c-0.8,0-1.5,0.5-1.8,1.2C24,81.1,24,82,24,83c0,4.3,1.2,8.3,3.2,11.7h0c0,0.1,0.1,0.1,0.1,0.2
              	c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.3,0.3,0.4
              	c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.1,0.2,0.2,0.2,0.3c0.6,0.6,1.2,1.3,1.8,1.8c0,0,0.1,0.1,0.2,0.1c0.3,0.2,0.6,0.5,0.8,0.7
              	c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.2,0.5,0.4,0.8,0.6c0,0,0.1,0.1,0.1,0.1h0c2.4,1.7,5.2,2.9,8.1,3.5c0.9,0.2,1.9-0.5,1.9-1.4
              	c0,0,0,0,0,0v-1.8c0-0.1,0-0.2,0-0.2h0c0-0.7,0.3-1.4,0.6-2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.3-0.3,0.5-0.5
              	c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.3c0.5-0.3,1.1-0.6,1.7-0.6c2.4-0.2,4.4,1.6,4.4,4v4v10h16v-10v-3.8V102c0-1.5,0.9-2.8,2.1-3.5
              	c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
              	c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.5,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.2,0.1,0.3,0.1
              	c0.1,0,0.2,0.1,0.2,0.1v0c1.2,0.7,2,2,2,3.4v2.8c0,0.6,0.4,1,1,1c0,0,0,0,0.1,0l0,0l0.1,0c0.1,0,0.3,0,0.4-0.1
              	c1.5-0.3,3.7-0.8,5.1-1.3C91.6,100.9,98,92.2,98,82v-6C98,73.2,97.5,70.5,96.6,68z M47,52h-1h0h-2h-2c-2.2,0-4-1.8-4-4V34
              	c0-2.2,1.8-4,4-4h4h4c1.1,0,2,0.9,2,2v20h0H47z M82,86c0,2.2-1.8,4-4,4h-4h-4c-1.1,0-2-0.9-2-2V68h0h5h1h0h2h2c2.2,0,4,1.8,4,4V86z"/>
          </svg>
        </a>
        <a type="button" (click)="toggleChat()">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 120 120" style="enable-background:new 0 0 120 120;" xml:space="preserve">
<path style="fill:#FFFFFF;" d="M60,17C31.3,17,8,34.5,8,56c0,10.4,5.4,19.9,14.3,26.8c1.1,0.8,1.6,2.2,1.5,3.5
	c-0.8,8.1-4,14.9-8.3,18.6c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.9,0.8,1.6,1.6,1.5c9-1,17.1-3.6,23.3-7.2c-1.9-0.5-3.8-1.1-5.7-1.8
	c-2.1-0.8-3.1-3.1-2.4-5.1c0.8-2.1,3.1-3.1,5.1-2.4c4.2,1.6,8.5,2.6,13,3.3c0.7,0.1,1.4,0.2,2.1,0.3c0.2,0,0.4,0,0.7,0.1
	c0.6,0.1,1.2,0.1,1.8,0.2c0.4,0,0.8,0.1,1.2,0.1c0.5,0,0.9,0.1,1.4,0.1c0.9,0,1.8,0.1,2.7,0.1v0c28.7,0,52-17.5,52-39S88.7,17,60,17
	z M30,66c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10S35.5,66,30,66z M60,66c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10
	S65.5,66,60,66z M90,66c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10S95.5,66,90,66z"/>
</svg>
        </a>
        <a type="button" routerLink="/details/{{gameId}}">
          <svg viewBox="0 0 64 64"> <title></title> <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path></svg>
        </a>
      </div>

    </div>
  </nav>
  <div *ngIf="gameUrl" class="game-iframe">
    <iframe [src]="gameUrl" title="game" style="position: absolute; top: 40px; left: 0; bottom: 0; right: 0; width: 100%; height: calc(100% - 40px);" ></iframe>
  </div>
</main>

