import {APP_BOOTSTRAP_LISTENER, APP_INITIALIZER, ComponentRef, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/legal/terms/terms.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AuthenticationInterceptor} from "./interceptors/auth.interceptor";
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {CommonModule, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SelectedGameComponent } from './pages/selected-game/selected-game.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {ChunkifyPipe} from "./pipes/chunkify.pipe";
import {FilterByTermPipe} from "./pipes/filterByTerm.pipe";
import { SelectedProviderComponent } from './pages/games/selected-provider/selected-provider.component';
import { ProvidersComponent } from './pages/games/gameProviders/providers.component';
import { SelectedCategoryComponent } from './pages/games/selected-category/selected-category.component';
import { LegalSectionComponent } from './pages/legal/legal-section/legal-section.component';
import { AboutComponent } from './pages/legal/about/about.component';
import { FaqComponent } from './pages/legal/faq/faq.component';
import { SupportComponent } from './pages/legal/support/support.component';
import { BitcoinComponent } from './pages/legal/bitcoin/bitcoin.component';
import { ResponsibleGamingComponent } from './pages/legal/responsible-gaming/responsible-gaming.component';
import { PrivacyComponent } from './pages/legal/privacy/privacy.component';
import { AmlComponent } from './pages/legal/aml/aml.component';
import { BankingComponent } from './pages/legal/banking/banking.component';
import { CookiePolicyComponent } from './pages/legal/cookie-policy/cookie-policy.component';
import { SecurityComponent } from './pages/legal/security/security.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { ItemShowcaseComponent } from './shared/item-showcase/item-showcase.component';
import { CafComponent } from './pages/legal/banking/caf/caf.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { SidebarStructureComponent } from './shared/sidebar-structure/sidebar-structure.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { PlayGameComponent } from './pages/selected-game/play-game/play-game.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import { CarouselComponent } from './shared/carousel/carousel.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { ChatComponent } from './shared/chat/chat.component';
import { RegModalComponent } from './shared/header/reg-modal/reg-modal.component';
import { RegStep1Component } from './shared/header/reg-modal/reg-step1/reg-step1.component';
import { RegStep2Component } from './shared/header/reg-modal/reg-step2/reg-step2.component';
import { RegStep3Component } from './shared/header/reg-modal/reg-step3/reg-step3.component';
import { PromoSlideComponent } from './shared/promo-slide/promo-slide.component';
import { NoMoneyModalComponent } from './shared/header/no-money-modal/no-money-modal.component';
import { LogoutModalComponent } from './shared/header/logout-modal/logout-modal.component';
import { LoginModalComponent } from './shared/header/login-modal/login-modal.component';
import {RegInterceptor} from "./interceptors/reg.interceptor";
import { RegStep4Component } from './shared/header/reg-modal/reg-step4/reg-step4.component';
import {
  IGoogleAnalyticsSettings,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from "ngx-google-analytics";
import {environment} from "../environments/environment";
import {TrackingHelperService} from "./services/tracking-helper.service";
import { TawkMessengerAngular, TawkMessengerAngularModule } from '@tawk.to/tawk-messenger-angular/dist/tawk-messenger-angular';
import { LegacyComponent } from './pages/legacy/legacy.component';
import { RecentGamesComponent } from './shared/recent-games/recent-games.component';
import { ResetForgotPasswordInLobbyComponent } from './shared/reset-forgot-password-in-lobby/reset-forgot-password-in-lobby.component';
import { ResetPasswordModalComponent } from './shared/header/reset-password-modal/reset-password-modal.component';
import { AlwaysoneComponent } from './pages/alwaysone/alwaysone.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';
import { XmasComponent } from './pages/xmas/xmas.component';

import * as Sentry from "@sentry/angular";




// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TermsComponent,
    SidebarComponent,
    SelectedGameComponent,
    NotFoundComponent,
    FilterByTermPipe,
    ChunkifyPipe,
    SelectedProviderComponent,
    ProvidersComponent,
    SelectedCategoryComponent,
    LegalSectionComponent,
    AboutComponent,
    FaqComponent,
    SupportComponent,
    BitcoinComponent,
    ResponsibleGamingComponent,
    PrivacyComponent,
    AmlComponent,
    BankingComponent,
    CookiePolicyComponent,
    SecurityComponent,
    ItemShowcaseComponent,
    CafComponent,
    LeaderboardComponent,
    SidebarStructureComponent,
    RegistrationComponent,
    PlayGameComponent,
    CarouselComponent,
    PromotionsComponent,
    ChatComponent,
    RegModalComponent,
    RegStep1Component,
    RegStep2Component,
    RegStep3Component,
    PromoSlideComponent,
    NoMoneyModalComponent,
    LogoutModalComponent,
    LoginModalComponent,
    RegStep4Component,
    LegacyComponent,
    RecentGamesComponent,
    ResetForgotPasswordInLobbyComponent,
    ResetPasswordModalComponent,
    AlwaysoneComponent,
    UnsubscribeComponent,
    XmasComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterOutlet,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
    NgForOf,
    CommonModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      closeButton: true,
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CarouselModule,
    // TawkMessengerAngularModule,
    NgxGoogleAnalyticsModule
    //this is for ga tracking bt missing campaign id at the first pageview
    // NgxGoogleAnalyticsModule.forRoot(environment.GA_TRACKING),
    // NgxGoogleAnalyticsRouterModule


  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass:RegInterceptor, multi: true},
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useValue: {
        trackingCode: environment.GA_TRACKING
      } as IGoogleAnalyticsSettings
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: (analyticsService: TrackingHelperService) => {
        return (component: ComponentRef<any>) => {
          analyticsService.setAppComponentRef(component);
          analyticsService.initializeGoogleAnalytics();
        };
      },
      deps: [TrackingHelperService],
    },
    // TawkMessengerAngular,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
