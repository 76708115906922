<main class="container-fluid px-0 pb-3 pb-lg-5">
  <div class="container-lg">
    <section *ngIf="promo" class="mt-3 p-3 col-10 col-sm-8 col-xl-6 offset-1 offset-sm-2 offset-xl-3">
      <div class="position-relative">
        <a class="back-link position-absolute top-0 start-100 translate-middle" routerLink="/promotions">
          <svg viewBox="0 0 64 64"> <title></title> <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path></svg>
        </a>

        <div class="bg-darker rounded-2 d-flex align-items-center justify-content-center py-4">
          <img src="{{promo.imgPath ?? 'assets/icons/promotions_big.svg'}}" alt="static promo illustration" width="60%">
        </div>
        <p class="mt-4 mb-0 badge bg-lighter">{{ ('PROMOTIONS.' + promo.details.toUpperCase()) | translate }}</p>

        <h3 class="fw-bold my-4">{{ ('PROMOTIONS.' + promo.id + '_NAME') | translate }}</h3>

        <div *ngIf="promoDetailIndexes.length">
          <p *ngFor="let index of promoDetailIndexes" class="mb-2">{{ ('PROMOTIONS.' + promo.id + '_DETAILS.' + index + '.P') | translate : {brandName} }}</p>
        </div>


        <p class="mt-4">{{ 'PROMOTIONS.DETAILS_LINK_TEXT' | translate }} <a class="link-secondary" type="button" routerLink="/info/terms">{{ 'PROMOTIONS.DETAILS_LINK' | translate }}</a> {{ 'PROMOTIONS.DETAILS_LINK_TEXT_END' | translate }}</p>
        <div class="text-center">
          <a class="btn btn-primary" type="button" (click)="goToCashier()">{{ 'PROMOTIONS.GET_NOW_BTN' | translate }}</a>
        </div>
      </div>
    </section>
  </div>
</main>
