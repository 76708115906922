<div class="text-center mt-3" id="showcaseButtons">
  <button class="btn btn-outline-secondary rounded-pill m-2"
          (click)="loadItems('more')"
          *ngIf="totalItems > itemsPerPage && itemsToShow < totalItems">
    {{'HOME.MORE_BTN' | translate }}
  </button>
  <button class="btn btn-outline-secondary rounded-pill m-2"
          (click)="loadItems('less')"
          *ngIf="totalItems > itemsPerPage && itemsToShow > itemsPerPage">
    {{'HOME.LESS_BTN' | translate }}
  </button>
  <button class="btn btn-outline-secondary rounded-pill m-2"
          (click)="loadItems('all')"
          *ngIf="totalItems > itemsPerPage && itemsToShow < totalItems">
    {{'HOME.ALL_BTN' | translate }} ({{totalItems}})
  </button>
</div>
