<div class="modal fade" id="resetPwModal" tabindex="-1" aria-labelledby="resetPwModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-6 ms-auto" id="resetPwModalLabel">{{ 'RESET_PW.TITLE' | translate }}</h1>
        <button type="button" class="btn-close" (click)="onCloseModal()" data-bs-dismiss="modal" aria-label="Close" #closeResetPwButton></button>
      </div>
      <div class="modal-body">
        <form class="needs-validation" novalidate (ngSubmit)="submitResetForm()" [formGroup]="resetForm">

          <div class="mb-4">
            <label class="form-label mb-4 text-center" for="email">{{ 'RESET_PW.INFO' | translate }}</label>
            <input class="form-control" type="email" id="email" placeholder="{{ 'REGISTER.EMAIL_PLACEHOLDER' | translate }}" required formControlName="email"  autocomplete="email" [ngClass]="{'is-invalid': resetSubmit && resetForm.controls['email'].errors}">
            <div class="invalid-feedback" *ngIf="resetForm.controls['email'].errors?.['required']">{{ 'REGISTER.EMAIL_ALERT' | translate }}</div>
            <div class="invalid-feedback" *ngIf="resetForm.controls['email'].errors?.['email']">{{ 'RESET_PW.EMAIL_ALERT' | translate }}</div>
            <div class="invalid-feedback" *ngIf="resetForm.controls['email'].errors?.['validationErrorFromServer']">
              {{ resetForm.controls['email'].errors?.['validationErrorFromServer'] }}
            </div>
          </div>

          <div *ngIf="!isLoading, else loadingSpinner">
            <button  class="btn btn-success btn-lg w-100 mb-4" type="submit">
              <span >{{ 'RESET_PW.SUBMIT_BTN' | translate }}</span>
            </button>
          </div>


          <ng-template #loadingSpinner>
            <div *ngIf="isLoading" class="d-flex justify-content-center mb-4">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </ng-template>

        </form>
      </div>

    </div>
  </div>
</div>
