import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector} from "@angular/core";
import {Observable, catchError, throwError} from "rxjs";
import {AuthService} from "../services/auth.service";


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('sessionToken');
    let request = req;

    if(token){
      request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      })
    }

    return next.handle(request)
      .pipe(catchError(err => {
        if(err.status === 401){
          if (token){
            //means that user session has expired, so we have to reset userSession
            const authService = this.injector.get(AuthService); // Get AuthService using injector
            authService.resetUserSession();
            //401 POST error on console from chrome - nothing to do with that based on stackoverflow
            return throwError( ()=> new Error("Invalid token"))
          }
          // other 401 stutus code errors, ex invalid regToken - handled in service
          return throwError(err)
        }

        //other errors with different status codes
        return throwError(err)
        // return throwError(()=> new Error(`OOPS, something went wrong: ${err.status}, ${err.message}`))
      }));

  }

}
