<section class="p-2 p-md-3" *ngIf="legalSectionIndexes.length">
  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.COOKIE.TITLE' | translate }}</h1>

  <div *ngFor="let item of legalSectionIndexes">
    <h3 class="h5 fw-bold mt-4 mb-3">{{ ('LEGAL.COOKIE.SECTIONS.'+ item.index + '.TITLE') | translate | uppercase}}</h3>
    <div *ngFor="let i of [].constructor(item.contentCount); let contentIndex = index">
      <p class="text-darker">
        {{ ('LEGAL.COOKIE.SECTIONS.' + item.index + '.CONTENT_' + (contentIndex + 1)) | translate }}
        <a *ngIf="item.index == legalSectionIndexes.length" class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}">{{ contactEmail }}</a>
      </p>
    </div>
  </div>

</section>

