import {ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {PromotionModel} from "../../models/promotion.model";
import {CarouselComponent, OwlOptions} from "ngx-owl-carousel-o";
import {PromotionService} from "../../services/promotion.service";
import {UserModel} from "../../models/user.model";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";
import {CarouselService} from "ngx-owl-carousel-o/lib/services/carousel.service";
import {offsetSegment} from "@angular/compiler-cli/src/ngtsc/sourcemaps/src/segment_marker";
// import {OwlCarouselOConfig} from "ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config";

@Component({
  selector: 'app-promo-slide',
  templateUrl: './promo-slide.component.html',
  styleUrls: ['./promo-slide.component.scss']
})
export class PromoSlideComponent {

  staticPromos: PromotionModel[] = [];

  @ViewChild('owlCarousel', { static: true }) owlCarousel?: CarouselComponent;
  @ViewChild('container', { static: true }) container?: ElementRef;


  // owl-carousel-version: TODO when toggling sidebar the size does not change size
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    nav: false,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 6000,
    autoplaySpeed: 1000,
    responsive: {
      0: {
        items: 1,
        dots: false,
        touchDrag: true
      },
      566: {
        items: 2
      },
      992: {
        items: 3
      }
    }
  }

  user!: UserModel | null;
  userSubs: Subscription;
  // sidebarToggledSubs: Subscription;






  constructor(
    private promoService: PromotionService, //todo put sidebar toggle somewhere else if we manage to do the reloading
    private userService: UserService
  ) {
    this.staticPromos = this.promoService.staticPromotions;

    this.userSubs = this.userService.userObject.subscribe(userData => {
      this.user = userData;
    })

    // this.sidebarToggledSubs = this.promoService.sidebarToggleObservable.subscribe(()=> {
    //   console.log("toggled")
    //   // this.refreshOwlCarousel()
    //
    // })




  }




  refreshOwlCarousel(): void {
      console.log('refreshed')
    // console.log(this.owlCarousel.carouselWindowWidth);
    console.log(this.owlCarousel);
    const prevWidth = this.container?.nativeElement.offsetWidth;
    // console.log(initwidth)
    console.log(prevWidth)
    const initwidth = this.owlCarousel?.carouselWindowWidth;
    if(this.owlCarousel){
      const currentWidth = this.owlCarousel.carouselWindowWidth


      const anyService = this.owlCarousel as any;
      const carouselService = anyService.carouselService as CarouselService;
    //   console.log(this.owlCarousel.carouselWindowWidth);
    //   // const currentWidth = this.container?.nativeElement.offsetWidth;
    //
    //   console.log(currentWidth)
      if (currentWidth !== initwidth){
        carouselService.onResize(currentWidth)
      }
    //
    //
    //   // carouselService.refresh();
    //   // carouselService.update();
    //   // this.owlCarousel.()
    //   console.log(this.owlCarousel.carouselWindowWidth);
    //
    //
    //   // this.customOptions = { ...this.customOptions, responsive: {
    //   //     0: {
    //   //       items: 1.2
    //   //     },
    //   //     800: {
    //   //       items: 1.2
    //   //     },
    //   //     1000: {
    //   //       items: 3.2
    //   //     }
    //   //   },} // this will make the carousel refresh

    }

  }





  ngOnDestroy(){
    this.userSubs?.unsubscribe();
    // this.sidebarToggleSubscription?.unsubscribe();


  }


}
