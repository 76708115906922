import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {ProviderModel} from "../../models/provider.model";
import {GameService} from "../../services/game.service";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  brandName: string;
  assetsSource: string;
  logoFileName: string;
  logoAltText: string;

  //for the licence info for pure
  licenseInfo?;

  providers$? : Observable<ProviderModel[]>;
  year: number = new Date().getFullYear();

  constructor(
    private configs: ConfigService,
    private gameService: GameService,
  ) {
    this.brandName = this.configs.brandName;
    this.assetsSource = this.configs.assetsPath;
    this.logoFileName = this.configs.logoName;
    this.logoAltText = this.configs.logoAltText;
    this.licenseInfo = this.configs.licenseInfo;

    this.providers$ = this.gameService.providers$;

    if (this.assetsSource.includes('slothorizon') || this.assetsSource.includes('jackpotnexus')){
      this.logoFileName = 'logo-big.png'
    }
  }


}
