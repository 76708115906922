import { Pipe, PipeTransform } from '@angular/core';
import {GameModel} from "../models/game.model";
import {Game} from "../models/game.class";

@Pipe({
  name: 'chunkify'
})
export class ChunkifyPipe implements PipeTransform {
  // to chunkify the games into arrays of size 12 to have the tile design
  transform(array: Game[], chunkSize: number = 12): Array<any[]> {
    // Check if input is an array
    if (!Array.isArray(array)) {
      throw new Error(`'chunkify' pipe input must be an Array`);
    }

    const _array = array.map(game => game.raw)
    let chunks: Array<GameModel[]> = [];
    for (let i = 0; i < _array.length; i += chunkSize) {
      chunks.push(_array.slice(i, i + chunkSize));
    }
    return chunks;
  }

}
