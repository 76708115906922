import {Component, HostListener} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {PromotionService} from "../../services/promotion.service";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-sidebar-structure',
  templateUrl: './sidebar-structure.component.html',
  styleUrls: ['./sidebar-structure.component.scss']
})
export class SidebarStructureComponent {
  token: string | null = null;
  sidebarToggled = false;
  manualToggle: boolean | null = null;

  isRival: boolean;

  constructor(
    private configs: ConfigService,
    private authService: AuthService,
    private promoService: PromotionService
  ) {
    this.isRival = this.configs.isRivalCasino;
    this.authService.token.subscribe((token: string | null)=>{
      this.token = token;
    })

    if ( typeof window !== 'undefined' && window.innerWidth >= 768){
      this.sidebarToggled = true;
    }
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    if (this.manualToggle === null) {
      this.sidebarToggled = event.target.innerWidth >= 768;
    }
  }

  onSidebarToggle(sidebarToggled: boolean) {
    this.sidebarToggled = sidebarToggled;
    this.manualToggle = true;
    this.promoService.toggleSidebar(); //todo test if toggling sidebar makes carousel config change
  }


}
