<main class="container-fluid px-0 pb-3 pb-lg-5">

  <div class="px-3 px-md-5 py-2 py-md-0 bg-darkest">
    <div class="container-lg">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="h3 fw-bold mb-0">{{ 'PROMOTIONS.TITLE' | translate }}</h1>
        <div class="category-icon-overlay">
          <img src="assets/icons/promotions_big.svg" alt="promotions background image" width="100%">
        </div>
      </div>
    </div>
  </div>

  <div class="px-3 px-md-5">
    <div class="container-lg">
      <div class="container-lg text-center pt-5" *ngIf="isLoading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

<!--      user promotions -->
      <section class="pt-3 pt-lg-5" *ngIf="user && !isLoading">
        <h3 class="h4 fw-bold"> {{ 'ACCOUNT.WELCOME_MESSAGE' | translate : {username: user.fname} }}</h3>
        <h3 *ngIf="!isLoading && userPromotions.length" class="d-flex align-items-center mb-3 car-title">
          <svg class="casino-icon me-1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 120 120">
            <path style="fill:#FFFFFF;" d="M103.1,85.9l-8.9-14c-0.7-1.2-2-1.9-3.4-1.9H86l-5.8,0c-2.1,0-4-1.5-4.2-3.6C75.8,64,77.7,62,80,62h2c2.2,0,4-1.8,4-4V44c0-10.4-6.1-19.3-14.8-23.5c-1.6-0.7-2.5-2.4-2.3-4.1c0.1-0.5,0.1-1,0.1-1.5c-0.1-4.8-4-8.8-8.8-8.9c-5.1-0.1-9.2,4-9.2,9c0,0.5,0,0.9,0.1,1.4c0.3,1.7-0.7,3.4-2.3,4.1C40.1,24.7,34,33.6,34,44v14c0,2.2,1.8,4,4,4l1.8,0c2.1,0,4,1.5,4.2,3.6c0.2,2.4-1.6,4.4-4,4.4h-6h-4.8c-1.4,0-2.6,0.7-3.4,1.9l-8.9,14c-1.7,2.7,0.2,6.1,3.4,6.1h33.1h13.3h33.1C102.9,92,104.8,88.5,103.1,85.9z"/>
            <path style="fill:#FFFFFF;" d="M48.2,100c-0.1,0.7-0.2,1.3-0.2,2c0,6.6,5.4,12,12,12s12-5.4,12-12c0-0.7-0.1-1.3-0.2-2H48.2z"/>
          </svg>
          <a class="h6 fw-bold mb-0 text-decoration-none" routerLink="/account/promotions">{{'PROMOTIONS.USERS.TITLE' | translate:{number: userPromotions.length} }}</a>
        </h3>

        <div class="row gy-2" *ngIf="userPromotions.length; else noPromo">
          <div class="col-12 col-sm-6 col-md-4" *ngFor="let promo of userPromotions">
            <div class="">
              <a class="bg-darker rounded-2 d-flex align-items-center justify-content-center clickable py-3" [routerLink]="'/promotions/'+promo.id">
                <img src="assets/icons/promotions_big.svg" alt="promo illustration" width="60%">
              </a>
              <h3 class="fw-bold mb-0">{{ promo.name }}</h3>
            </div>
          </div>
        </div>
      </section>

      <!--  static promotions-->
      <section class="pt-3 pt-lg-5" *ngIf="!user">
        <h3 class="d-flex align-items-center mb-3">
          <svg class="casino-icon me-1 opacity-25" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 120 120">
            <g><g><path style="fill:#FFFFFF;" d="M52,30H19.2c-2.3,0-4.2,1.9-4.2,4.2v13.6c0,2.3,1.9,4.2,4.2,4.2H23h11.8c2.1,0,4,1.5,4.2,3.6c0.2,2.4-1.6,4.4-4,4.4h-8c-2.2,0-4,1.8-4,4v40c0,4.4,3.6,8,8,8h21V30z"/>
                <path style="fill:#FFFFFF;" d="M100.8,30H68v82h21c4.4,0,8-3.6,8-8V64c0-2.2-1.8-4-4-4h-8c-2.3,0-4.2-2-4-4.4c0.2-2.1,2.1-3.6,4.2-3.6H97h3.8c2.3,0,4.2-1.9,4.2-4.2V34.2C105,31.9,103.1,30,100.8,30z"/></g>
              <path style="fill:#FFFFFF;" d="M80,6c-1.6,0-3.2,0.5-4.4,1.3l0,0l0,0c0,0-0.1,0-0.1,0.1l-13.2,9c-1.4,0.9-3.2,0.9-4.5,0l-13.2-9c0,0-0.1,0-0.1-0.1l0,0l0,0C43.2,6.5,41.6,6,40,6c-4.4,0-8,3.6-8,8s3.6,8,8,8h20h20c4.4,0,8-3.6,8-8S84.4,6,80,6z"/>
            </g>
          </svg>
          <span class="h6 fw-bold mb-0">{{ 'PROMOTIONS.PUBLIC.TITLE' | translate }}</span>
        </h3>

        <div class="row gy-2" *ngIf="publicPromotions.length; else noPromo">
          <div class="col-12 col-sm-6 col-md-4" *ngFor="let promo of publicPromotions">
            <div class="">
              <a class="bg-darker rounded-2 d-flex align-items-center justify-content-center clickable py-3" [routerLink]="'/promotions/'+promo.name">
                <img src="{{ promo.imgPath ?? assetsSource+'/promo.png' }}" alt="static promo illustration" width="60%">
              </a>
              <p class="mt-2 mb-0 text-secondary badge bg-lighter">{{ ('PROMOTIONS.' + promo.details.toUpperCase()) | translate }}</p>
              <h3 class="fw-bold mb-0">{{ ('PROMOTIONS.' + promo.id + '_NAME') | translate }}</h3>
              <p class="text-secondary">{{ ('PROMOTIONS.' + promo.id + '_DETAILS.0.P') | translate : {brandName} }}</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>

</main>

<ng-template #noPromo>
  <div class="container-lg text-center pt-5">
    <h4>{{'PROMOTIONS.NO_PROMO_MESSAGE' | translate}}</h4>
    <p class="h3">{{'PROMOTIONS.NO_PROMO_TIP' | translate}}</p>
  </div>
</ng-template>

