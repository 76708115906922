<main class="container-fluid px-0 pb-3 pb-lg-5">

  <div class="px-3 py-2 py-md-0 bg-darkest">
    <div class="container-lg">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="h3 fw-bold mb-0">{{ 'CATEGORIES.' + (chosenCategory | uppercase) | translate}}</h1>
        <div class="category-icon-overlay">
          <img *ngIf="chosenCategory" [src]="'assets/icons/'+ chosenCategory + '_big.svg'" alt="category background image" width="100%">
        </div>
      </div>
    </div>
  </div>

  <div class="container-lg">
    <div class="px-0">
      <!-- Searchbar -->
      <div class="my-2 my-md-4">
        <form class="input-group searchbar bg-darker">
        <span class="input-group-text" id="search-input">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </span>
          <input type="text" name="searchInput" class="form-control" [placeholder]=" 'HOME.SEARCHBAR_PLACEHOLDER' | translate" aria-label="searchbar" aria-describedby="search-input" [formControl]="searchTerm">
        </form>
      </div>

      <div>
        <div class="d-flex justify-content-between">

          <!-- filter by provider -->
          <div class="d-flex align-items-center my-3">
            <ng-container *ngIf="validProviders.length > 1; else onlyOneProvider">
              <span class="d-none d-sm-block mx-2 text-secondary">{{ 'HOME.FILTER_LABEL' | translate }}</span>
              <button class="btn btn-outline-secondary rounded-pill d-flex align-items-center" (click)="toggleProviderSelector()">
                <span class="me-2">{{'PROVIDERS.TITLE' | translate}}</span>
                <span *ngIf="numOfSelectedProviders" class="me-2 badge rounded-pill bg-primary">{{numOfSelectedProviders}}
                  <span class="visually-hidden">selected</span>
              </span>
                <svg *ngIf="!numOfSelectedProviders" class="casino-icon me-0" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 120 120">
                  <path style="fill:#FFFFFF;" d="M108,35H12c-2.2,0-4-1.8-4-4V19c0-2.2,1.8-4,4-4h96c2.2,0,4,1.8,4,4v12C112,33.2,110.2,35,108,35z"/>
                  <path style="fill:#FFFFFF;" d="M76,101V89c0-2.2-1.8-4-4-4H48c-2.2,0-4,1.8-4,4v12c0,2.2,1.8,4,4,4h24C74.2,105,76,103.2,76,101z"/>
                  <path style="fill:#FFFFFF;" d="M94,66V54c0-2.2-1.8-4-4-4H30c-2.2,0-4,1.8-4,4v12c0,2.2,1.8,4,4,4h60C92.2,70,94,68.2,94,66z"/>
                </svg>
              </button>
            </ng-container>
            <ng-template #onlyOneProvider>
              <span class="mx-2 text-secondary">{{ 'HOME.ONE_PROVIDER_LABEL' | translate }} {{validProviders[0]}}</span>
            </ng-template>

          </div>

          <!-- sort by -->
          <div class="d-flex align-items-center justify-content-end my-3 me-1 sort-by-dropdown">
            <span class="d-none d-sm-block mx-2 text-secondary">{{ 'HOME.SORT_LABEL' | translate }}</span>
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary rounded-pill d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="me-2">{{ 'HOME.SORT_'+ selectedSorting | translate }}</span>
                <svg class="casino-icon me-0" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 120 120">
                  <g>
                    <path style="fill:#FFFFFF;" d="M108.3,55H47c-2-4.7-6.6-8-12-8s-10,3.3-12,8H11.7c-2,0-3.7,1.6-3.7,3.7v2.7c0,2,1.6,3.7,3.7,3.7H23c2,4.7,6.6,8,12,8s10-3.3,12-8h61.3c2,0,3.7-1.6,3.7-3.7v-2.7C112,56.6,110.4,55,108.3,55z"/>
                    <path style="fill:#FFFFFF;" d="M108.3,23H85c-2-4.7-6.6-8-12-8s-10,3.3-12,8H11.7c-2,0-3.7,1.6-3.7,3.7v2.7c0,2,1.6,3.7,3.7,3.7H61c2,4.7,6.6,8,12,8s10-3.3,12-8h23.3c2,0,3.7-1.6,3.7-3.7v-2.7C112,24.6,110.4,23,108.3,23z"/>
                    <path style="fill:#FFFFFF;" d="M108.3,87H84.5c-2.2-4.2-6.5-7-11.5-7s-9.4,2.8-11.5,7H11.7c-2,0-3.7,1.6-3.7,3.7v2.7c0,2,1.6,3.7,3.7,3.7h49c1.7,5.2,6.6,9,12.4,9s10.7-3.8,12.4-9h23c2,0,3.7-1.6,3.7-3.7v-2.7C112,88.6,110.4,87,108.3,87z"/>
                  </g>
                </svg>
              </button>
              <ul class="dropdown-menu">
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('AZ')">{{ 'HOME.SORT_AZ' | translate }}</button></li>
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('ZA')">{{ 'HOME.SORT_ZA' | translate }}</button></li>
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('POPULAR')">{{ 'HOME.SORT_POPULAR' | translate }}</button></li>
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('FEATURED')">{{ 'HOME.SORT_FEATURED' | translate }}</button></li>
              </ul>
            </div>
          </div>

        </div>


        <div class="provider-selector row justify-content-evenly" *ngIf="showProviderSelector">
          <ng-container *ngFor="let provider of providers">
            <div class="col col-6 col-sm-4 col-md-3 col-lg-1" *ngIf="validProviders.includes(provider.name.split('(')[0].trim())">
              <div class="d-flex justify-content-center">
                <input class="btn-check" type="checkbox" id="{{ provider.routerLink }}" [(ngModel)]="providerFilter[provider.routerLink]" (change)="loadItems(undefined)">
                <label class="btn btn-outline-primary p-1 px-4 px-lg-2 px-xl-3 m-2 mx-lg-1 rounded-2" for="{{ provider.routerLink}}" >
                  <img style="min-width: 90px; max-width: 150px; width: 100%; aspect-ratio: 2/1;" [src]=provider.imagePath class="img-fluid" alt="{{provider.name}} logo">
                </label>
              </div>
            </div>
          </ng-container>
        </div>

      </div>

<!--      games -->
      <div *ngIf="filteredGames && filteredGames.length; else noMatchBlock " class="pt-3">
        <div class="container-sm px-0 text-center" *ngFor="let chunk of (displayedGames | chunkify)">
          <div class="row g-2">
            <div class="col-12 col-md-6">
              <div class="row g-2 mb-2">
                <div class="col-8" *ngIf="chunk[0]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[0].enabled === false}" [src]="chunk[0].image" alt="{{chunk[0].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[0].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[0].category">{{chunk[0].category}}</div>
                    <div *ngIf="chunk[0].tag && chunk[0].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row g-2">
                    <div class="col-12 " *ngIf="chunk[1]">
                      <div class="img-container clickable">
                        <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[1].enabled === false}" [src]="chunk[1].image" alt="{{chunk[0].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[1].id)" loading="lazy">
                        <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[1].category">{{chunk[1].category}}</div>
                        <div *ngIf="chunk[1].tag && chunk[1].tag.includes('beta')" class="ribbon">Beta</div>
                      </div>

                    </div>
                    <div class="col-12" *ngIf="chunk[2]">
                      <div class="img-container clickable">
                        <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[2].enabled === false}" [src]="chunk[2].image" alt="{{chunk[0].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[2].id)" loading="lazy">
                        <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[2].category">{{chunk[2].category}}</div>
                        <div *ngIf="chunk[2].tag && chunk[2].tag.includes('beta')" class="ribbon">Beta</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="col-4" *ngIf="chunk[3]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[3].enabled === false}" [src]="chunk[3].image" alt="{{chunk[3].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[3].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[3].category">{{chunk[3].category}}</div>
                    <div *ngIf="chunk[3].tag && chunk[3].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
                <div class="col-4" *ngIf="chunk[4]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[4].enabled === false}" [src]="chunk[4].image" alt="{{chunk[4].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[4].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[4].category">{{chunk[4].category}}</div>
                    <div *ngIf="chunk[4].tag && chunk[4].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
                <div class="col-4" *ngIf="chunk[5]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[5].enabled === false}" [src]="chunk[5].image" alt="{{chunk[5].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[5].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[5].category">{{chunk[5].category}}</div>
                    <div *ngIf="chunk[5].tag && chunk[5].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="row g-2 mb-2">
                <div class="col-4" *ngIf="chunk[6]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[6].enabled === false}" [src]="chunk[6].image" alt="{{chunk[6].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[6].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[6].category">{{chunk[6].category}}</div>
                    <div *ngIf="chunk[6].tag && chunk[6].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
                <div class="col-4" *ngIf="chunk[7]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[7].enabled === false}" [src]="chunk[7].image" alt="{{chunk[7].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[7].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[7].category">{{chunk[7].category}}</div>
                    <div *ngIf="chunk[7].tag && chunk[7].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
                <div class="col-4" *ngIf="chunk[8]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[8].enabled === false}" [src]="chunk[8].image" alt="{{chunk[8].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[8].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[8].category">{{chunk[8].category}}</div>
                    <div *ngIf="chunk[8].tag && chunk[8].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
              </div>
              <div class="row g-2 mb-2">
                <div class="col-4">
                  <div class="row g-2">
                    <div class="col-12" *ngIf="chunk[9]">
                      <div class="img-container clickable">
                        <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[9].enabled === false}" [src]="chunk[9].image" alt="{{chunk[9].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[9].id)" loading="lazy">
                        <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[9].category">{{chunk[9].category}}</div>
                        <div *ngIf="chunk[9].tag && chunk[9].tag.includes('beta')" class="ribbon">Beta</div>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="chunk[10]">
                      <div class="img-container clickable">
                        <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[10].enabled === false}" [src]="chunk[10].image" alt="{{chunk[10].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[10].id)" loading="lazy">
                        <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[10].category">{{chunk[10].category}}</div>
                        <div *ngIf="chunk[10].tag && chunk[10].tag.includes('beta')" class="ribbon">Beta</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8" *ngIf="chunk[11]">
                  <div class="img-container clickable">
                    <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[11].enabled === false}" [src]="chunk[11].image" alt="{{chunk[11].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[11].id)" loading="lazy">
                    <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[11].category">{{chunk[11].category}}</div>
                    <div *ngIf="chunk[11].tag && chunk[11].tag.includes('beta')" class="ribbon">Beta</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  Load more or less games-->
        <app-item-showcase [totalItems]="filteredGames.length" [itemsPerPage]="itemsPerPage" [resetFlag]="resetBySortOrFilterNeeded" (itemsToLoad)="loadItems($event)" ></app-item-showcase>

      </div>

      <ng-template #noMatchBlock>
        <div class="container text-center pt-5">
          <div *ngIf="isLoading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <ng-container *ngIf="!isLoading && !allGames.length">
            <h3>{{'HOME.NOGAMES_MESSAGE' | translate}}</h3>
            <h4>{{'HOME.NOGAMES_TIP' | translate}}</h4>
          </ng-container>
          <h3 *ngIf="!isLoading && !games?.length">{{'HOME.NOMATCH_MESSAGE' | translate}}</h3>
          <h4 *ngIf="searchTerm.value">{{'HOME.NOMATCH_TIP' | translate}}</h4>
        </div>
      </ng-template>


      <div class="mt-4">
        <app-carousel [dataList]="providers" [identifier]="'providerList'"></app-carousel>
        <app-carousel [dataList]="randomGamesForCarousel" [identifier]="'all'"></app-carousel>
      </div>

    </div>
  </div>




</main>


