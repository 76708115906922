import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector} from "@angular/core";
import {Observable, catchError, throwError, } from "rxjs";
import {RegistrationService} from "../services/registration.service";


@Injectable()
export class RegInterceptor implements HttpInterceptor {

  constructor(private regService: RegistrationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const methodsRequiringRegToken = ['registration.addDetails', 'registration.finalize'];
    let request = req;

    if(request.body && methodsRequiringRegToken.includes(request.body.method)) {
      // const token = this.regService.token.getValue();
      const token = localStorage.getItem("regToken");
      if (token){
        request = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        })
      }
    }


    return next.handle(request)
      //errors handled in authInterceptor, services and components

  }

}
