<div>
  <app-carousel [dataList]="recentGames" [identifier]="'recent'"></app-carousel>

  <app-carousel [dataList]="recommendedGames" [identifier]="'recommended'"></app-carousel>

  <!-- own-carousel version -->
<!--  <owl-carousel-o [options]="customOptions">-->
<!--    <ng-container *ngFor="let g of recentGames; let i = index;">-->
<!--      <ng-template carouselSlide>-->
<!--        <div class="d-flex bg-darkest p-3 me-2 h-100">-->
<!--          <div>-->
<!--            <a owlRouterLink="/details/{{g.id}}">-->
<!--              <img class="w-100" [src]="g.image" [alt]="g.name">-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </ng-container>-->
<!--  </owl-carousel-o>-->
</div>





