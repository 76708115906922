import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {UserModel} from "../../models/user.model";
import {TranslateService} from "@ngx-translate/core";
import {GameService} from "../../services/game.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ChatService} from "../../services/chat.service";
import {Subscription} from "rxjs";
import {UserService} from "../../services/user.service";
import {LanguageService} from "../../services/language.service";
import {TawkService} from "../../services/tawk.service";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  brandName: string;
  assetsSource: string;
  logoFileName: string;
  logoAltText: string;
  logoFileNameForAILogo?: string;
  isRival:boolean;

  @Output() sidebarToggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() sidebarToggled: boolean = false;

  categories: string[];
  svgPaths: { [key: string]: string };

  user!: UserModel | null;
  userSubs: Subscription;
  gamesSubs: Subscription;

  selectedLang!: string;

  languageOptions: {value: string, label: string, height: number}[] = [];


  @Output() closeSidebarEvent = new EventEmitter();

  constructor(
    private configs: ConfigService,
    private userService: UserService,
    public translate: TranslateService,
    private gameService: GameService,
    private sanitizer: DomSanitizer,
    public router: Router,
    private chatService: ChatService,
    private langService: LanguageService,
    private tawkService: TawkService
  ) {
    this.isRival = this.configs.isRivalCasino;
    this.brandName = this.configs.brandName;
    this.assetsSource = this.configs.assetsPath;
    this.logoFileName = this.configs.logoName;
    this.logoAltText = this.configs.logoAltText;


    this.languageOptions = this.langService.languageOptions;
    this.categories = this.gameService.categories;
    this.svgPaths = this.gameService.svgPaths;

    if (this.assetsSource.includes('slothorizon') || this.assetsSource.includes('jackpotnexus')){
      this.logoFileNameForAILogo = 'logo-big.png'
    }

    this.userSubs = this.userService.userObject.subscribe( userData => {
      this.user = userData;
    })

    this.gamesSubs = this.gameService.getGames().subscribe(games => {
      if (games && games.length){
       this.categories = this.gameService.getCategoriesWithGames(games)
      }
    })

    this.selectedLang = this.langService.selectedLang;
    // console.log(this.selectedLang)
  }




  switchLang(language: string) {
    this.selectedLang = language;
    this.langService.setUserSelectedLang(language)
    this.decideTogglingSidebar()
  }

  goToCashier(){
    this.router.navigate(['account', 'cashier'])
  }

  openChat(): void {
    // this.chatService.toggleChat();

    if (this.configs.chatSystem === 'tawk'){
      this.tawkService.ExpandChatWindow(true);
    } else {
      //rival or other chat systems
      this.chatService.toggleChat(true);
    }
  }


  decideTogglingSidebar() {
    if (typeof window !== 'undefined' && window.innerWidth < 768){
      this.closeSidebarEvent.emit()
    }
  }

  toggleSidebar(){
    this.closeSidebarEvent.emit()
  }

  getSanitizedSvgPath(category: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.svgPaths[category.toLowerCase()]);
  }

  ngOnDestroy(){
    this.userSubs?.unsubscribe();
    this.gamesSubs?.unsubscribe();
  }

}
