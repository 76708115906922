<main  class="container-lg px-2 px-md-3 py-3 pb-lg-5">
  <h3 class="fw-bold mb-4">{{"UNSUBSCRIBE.TITLE" | translate}}</h3>
  <p class="mb-3"> {{"UNSUBSCRIBE.INTRO" | translate}}</p>
  <h6 class="mt-4 mb-0 fw-bold">{{"UNSUBSCRIBE.SUCCESS_TITLE" | translate}}</h6>
  <p class="mb-3"> {{"UNSUBSCRIBE.SUCCESS_TEXT" | translate}}</p>
  <p class="my-0">{{"UNSUBSCRIBE.ACCOUNT_ALIVE" | translate}}</p>
  <p class="my-0">{{"UNSUBSCRIBE.RESUBSCRIBE" | translate}}</p>
  <h6 class="fw-bold mt-4 mb-0">{{"UNSUBSCRIBE.ASSISTANCE_TITLE" | translate}}</h6>
  <p class="my-0">{{"UNSUBSCRIBE.ASSISTANCE_TEXT" | translate}}</p>
  <p class="mb-3">{{"UNSUBSCRIBE.CONTACT_US_TEXT" | translate}} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ "UNSUBSCRIBE.CONTACT_US_LINK" | translate }}</a></p>
  <p class="my-0">{{"UNSUBSCRIBE.THANK_YOU" | translate}}</p>
  <p class="my-0">{{"UNSUBSCRIBE.SEE_YOU" | translate}}</p>
</main>
