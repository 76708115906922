import { Component } from '@angular/core';
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {
  contactEmail1: string;
  contactEmail2: string;

  constructor(
    private configs: ConfigService,
  ) {
    this.contactEmail1 = this.configs.contactEmail;
    this.contactEmail2 = this.configs.docsEmail;
  }

}
