<section class="p-2 p-md-3">

  <h1 class="h3 fw-bold mb-5">{{ 'LEGAL.PRIVACY.TITLE' | translate }}</h1>

  <p class="text-darker">{{ 'LEGAL.PRIVACY.INTRO' | translate:{brandName} }} </p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.PRIVACY.T1' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.PRIVACY.P1' | translate: {brandName} }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a> {{  'LEGAL.PRIVACY.P1_LINK_END' | translate }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.PRIVACY.T2' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.PRIVACY.P2' | translate }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.PRIVACY.T3' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.PRIVACY.P3' | translate: {brandName}  }}</p>

  <h3 class="h5 fw-bold mt-4 mb-3">{{ 'LEGAL.PRIVACY.T4' | translate | uppercase}}</h3>
  <p class="text-darker">{{ 'LEGAL.PRIVACY.P4' | translate }} <a class="link-primary link-underline-opacity-0" href="mailto:{{contactEmail}}"> {{ contactEmail }}</a>{{  'LEGAL.PRIVACY.P4_LINK_END' | translate }}</p>

</section>

