import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {GameService} from "../services/game.service";


export const providerGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {

  const gameService = inject(GameService);
  const router = inject(Router);

  const requestedProvider = route.params['provider'];
  // console.log("hey:", requestedProvider)
  let providerToNavigate;
  gameService.providers$.subscribe(p=> providerToNavigate = p.find(p => p.routerLink === requestedProvider.replace('_','')))

  //only show valid provider pages
  if (providerToNavigate){
    return true;
  } else {
    if (router.url.includes('games')){
      router.navigate(['/games', requestedProvider]);


    } else {
      router.navigate(['/not-found']);
    }
    // router.navigate(['/not-found']);
    return false;
  }

};
