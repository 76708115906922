import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {RegistrationService} from "../../../../services/registration.service";
import {Subscription, switchMap} from "rxjs";
import {RegdataModel} from "../../../../models/registration/regdata.model";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {DetailsMapModel} from "../../../../models/registration/detailsMap.model";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {TrackingHelperService} from "../../../../services/tracking-helper.service";


declare var bootstrap: any;


@Component({
  selector: 'app-reg-step4',
  templateUrl: './reg-step4.component.html',
  styleUrls: ['./reg-step4.component.scss']
})
export class RegStep4Component  {

  /** step4 communication with regAPI
     - add agreeTerms to details
     - finalize registration (with regToken)
   AND login user if registration has been successful
   todo: handle errors if any
  */

  regStep4Form!: UntypedFormGroup;
  regSubmit: boolean = false;
  regSubs?: Subscription;
  regObject?: RegdataModel | null;

  isLoading:boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private regService: RegistrationService,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private gaService: GoogleAnalyticsService,
    private trackingService: TrackingHelperService
  ) {
    this.regStep4Form = this.formBuilder.group({
      agreement: [false, [Validators.required, Validators.requiredTrue]]
    });
  }

  ngOnInit(){
    this.regSubs?.unsubscribe();
    this.regSubs = this.regService.regData.subscribe(regData => {
        // console.log(regData)
        this.regObject = regData
    })
  }

  goToPrevStep(){
    this.regService.setStep(3);
  }


  submitRegForm() {
    this.regSubmit = true;
    if (this.regForm['agreement'].valid) {

      //send event to GA
      this.gaService.event('register_submit', undefined, undefined, undefined, undefined,{...this.trackingService.getAffiliateOptions() });

      const regStep2Data: DetailsMapModel = {
          agreeTerms: this.regStep4Form.value.agreement
      }

      this.isLoading = true;

      this.regService.addDetails(regStep2Data).pipe(switchMap(response=> {
        // console.log("RESPONSE from addDetails:", response); //result is empty

        //adding tracking
        const trackingInfo:  {bannerId?: number, tracker?: string}  = {}
        const btag = this.trackingService.getBannerTag();
        if (btag){
          trackingInfo.bannerId = +btag
        }
        const tracker = this.trackingService.getTracker();
        if (tracker){
          trackingInfo.tracker = tracker
        }

        return this.regService.finalize(trackingInfo)

      })).subscribe({
        next: ( response ) => {
          if('result' in response){
            if('warning' in response.result){
              console.log(response.result.warning); //result has empty warnings array
            }
          }

          this.regStep4Form.reset();
          this.regSubmit = false;

          //if OK -> login user and toastr success + close modal
          if (this.regObject && this.regObject.username && this.regObject.details.password){

            //send event to GA
            this.gaService.event('register_complete', undefined, undefined, undefined, undefined,{user_id: this.regObject.username, ...this.trackingService.getAffiliateOptions() });

            const loginData =  {
              username: this.regObject.username,
              password: this.regObject.details.password,
              mode: 'real',
            }

            this.authService.login(loginData).subscribe({
              next: ( response ) => {
                // console.log(response);
                this.isLoading = false;
                this.toastr.success(this.translate.instant('REGISTER.TOASTR_SUCCESS_MESSAGE',{username: this.regObject!.details.fname}), this.translate.instant('REGISTER.TOASTR_SUCCESS_H1'), {toastClass: 'ngx-toastr yourclass'});

                this.closeModal();
              },
              error: (err) => {
                console.error(err)
                this.isLoading = false;
                //TODO - some nice toastr error message if log in failes for any reason + handle that case
                this.toastr.error(`${err.message}`, this.translate.instant('REGISTER.TOASTR_UNKNOWN_ERROR_H1'), {toastClass: 'ngx-toastr yourclass'})
              }
            })
          }

        },
        error: (err) => {
           this.isLoading = false;
           this.regStep4Form.reset();
           this.regSubmit = false;

          //send event to GA
          this.gaService.event('register_error', undefined, undefined, undefined, undefined,{...this.trackingService.getAffiliateOptions() });

          //invalid token
          if (err.error.message === 'Could not authorize request.' || err.status === 401){
            this.toastr.error(`${this.translate.instant('REGISTER.TOASTR_TOKEN_ERROR_MESSAGE')}`, this.translate.instant('REGISTER.TOASTR_TOKEN_ERROR_H1'), {toastClass: 'ngx-toastr yourclass'})
          } else {
            //other errors
            // todo: handle other errors (server, etc) ex. reg failed message
            //errors in fields - missing fields or invalid data (if not validated in addDetails in API) - find out how to handle them. Normally we wont have any as we have sent every data to the server in the correct format

            // console.error(err)
            let errorMessage: string;

            //user tries to register from a blocked location - should use VPN
            if (err.error.data && err.error.data.country){
              errorMessage = err.error.data.country.message;
              if (errorMessage){
                this.toastr.error(`${errorMessage}`, `${err.error.message}`, {toastClass: 'ngx-toastr yourclass'})
              }
            } else {
              //other error
              // errorMessage = err.message ?? err.error.message
              // this.toastr.error(`${errorMessage}`, this.translate.instant('REGISTER.TOASTR_UNKNOWN_ERROR_H1'), {toastClass: 'ngx-toastr yourclass'})
              //todo fix as rival provides error messages now:
              //validation errors from finalize method
              if (err.error && err.error.data){
                //means that error comes from server in 200 ok code
                for (const key in err.error.data) {
                  if (err.error.data.hasOwnProperty(key)) {
                    //warn user about the error
                    this.toastr.error(`${err.error.data[key].message}`, `${err.error.message}`, {toastClass: 'ngx-toastr yourclass'})
                  }
                }
              }
            }

          }


        }
      })


    }
  }

  get regForm() {
    return this.regStep4Form.controls;
  }

  closeModal(){
    this.regService.setStep(5);
  }

  ngOnDestroy(){
  this.regSubs?.unsubscribe();
  }


}
