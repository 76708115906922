<div class="modal fade" id="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-6 ms-auto" id="signOutModalLabel">{{ 'LOGOUT.TITLE' | translate }}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeLogoutButton></button>
      </div>
      <div class="modal-body">
        {{ 'LOGOUT.MESSAGE' | translate }}
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-outline-danger" (click)="logout()"> {{ 'LOGOUT.TITLE' | translate }}</button>
      </div>
    </div>
  </div>
</div>
