//this is for master.rival-new.pages.dev (currently the testBrand, version 0 the stake design)
import {environment as defaultEnvironment} from "./environment.default";
import { games } from './rival'


export const environment = {
  ...defaultEnvironment,
  games,

  platform: "rival",
  isAffiliated: false,

  rivalName: "casinolust",
  casinoId: 70,
  prettyName: "TestBrand",
  contactEmail: "support@test.casino",
  docsEmail: "finance@test.casino",
  GA_TRACKING: "G-BMCW4CRKFG",

  rivalChatGroupName: "Casino Lust",
  //tawk_propertyId, tawk_widgetId, tawk_apiKey: undefined from default
  //publicPromotions from default
  //affiliatedGames from default
  //legalData, licenseNo: "#8048/JAZ", license: undefined from default,
  //SEO from default


};


